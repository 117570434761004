import { Item } from "../../generated/axios";
import { Any } from "../../types/Any";

/**
 * Fields without value are set to null
 */
export const fieldValues = (item: Item): Record<string, Any> => {
  const fields = [
    ...(item.thermalAttributes ?? []),
    ...(item.mechanicalAttributes ?? []),
  ];
  return fields.reduce((res, field) => {
    const value = field.value === undefined ? null : field.value;
    if (field.fieldId) {
      res[field.fieldId] = value;
    }
    return res;
  }, {});
};
