import { PaginationConfig } from "antd/lib/pagination/Pagination";
import { getBrowserLanguage } from "../shared/lib/locales";
import { OrderDirection } from "../shared/store/item/typesResults";

export const DEFAULT_LOCALE = getBrowserLanguage();
export const DEFAULT_METRIC_SYSTEM = "base";
export const DEFAULT_CHOSEN_METRIC_SYSTEM = "sis";

export const INITIAL_RESULT_TABLE_ORDER_FIELD = "score";
export const INITIAL_RESULT_TABLE_ORDER_DIRECTION = OrderDirection.asc;

export const DEFAULT_PORTFOLIO = 1;

export const LUVEGROUP_URL = "https://www.luvegroup.com";

export const Z_INDEXES = {
  tabContentTrackConfirm: 1080,
  cancelRequestModal: 1070,
};

/**
 * error messenger durations
 */
export const durations = {
  loading: 600, // seconds
  success: 2, // seconds
  error: 4, // seconds
  info: 4, // seconds
  clearAfter: 4000, // mms
};

/**
 * initial configuration for tables pagination
 * can be overridden in pages
 */
export const paginationConfig: PaginationConfig = {
  defaultCurrent: 1,
  defaultPageSize: 5,
  showSizeChanger: true,
};
