import { get } from "lodash";
import React from "react";
import { FormAction, ItemAttributeOption } from "../../../../generated/axios";
import {
  Action,
  ACTION_TYPES,
  ActionsProvider,
  ActionType,
  InfoBox,
} from "../Actions";

const fallbackActions: FormAction[] = [];

interface Props {
  option: ItemAttributeOption;
  children: React.ReactElement;
  actions: FormAction[];
}

function EnhancedOption({
  option,
  children,
  actions = fallbackActions,
}: Props): React.ReactElement {
  const [visible, setVisible] = React.useState(false);
  const { label = "", value } = option;

  const infoAction: FormAction | undefined = actions.find(
    (item: FormAction) => item.action === ACTION_TYPES.INFO
  );

  const downloadAction: FormAction | undefined = actions.find(
    (item: FormAction) => item.action === ACTION_TYPES.DOWNLOAD
  );

  const info = get(infoAction, "params", {});

  const downloadUri = get(downloadAction, "params.url");

  // remains the same during component life
  const infoBoxData = {
    ...info,
    title: label,
    handleVisibleChange: () => setVisible(false),
    visible,
  };

  const actionClickHandler = (action: string) => {
    if (action === ACTION_TYPES.INFO) {
      setVisible(true);
    }
  };

  return (
    <div
      className="enhanced-option"
      key={value}
      data-test={`enhanced-option-${label.toLowerCase().replace(" ", "_")}`}
    >
      {children}

      <ActionsProvider actions={actions}>
        {(params: { actions: ActionType[] }) =>
          params.actions.map((action: ActionType) =>
            action.action === ACTION_TYPES.DOWNLOAD ? (
              <Action
                key={action.action}
                {...action}
                downloadUri={downloadUri}
              />
            ) : (
              <Action
                key={action.action}
                {...action}
                clickHandler={actionClickHandler}
              />
            )
          )
        }
      </ActionsProvider>

      <InfoBox {...infoBoxData} />
    </div>
  );
}

export default EnhancedOption;
