import { Alert } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { thermalDataChangedSelector } from "../../../../shared/store/item/selectors";

const AlertMessage: React.FC = () => {
  const thermalDataChanged = useSelector(thermalDataChangedSelector);
  const show = thermalDataChanged !== undefined && !thermalDataChanged;
  return show ? (
    <Alert
      message={
        <FormattedMessage
          id="change results reset"
          defaultMessage="Changing input values, results will be reset"
        />
      }
      type="warning"
      closable
    />
  ) : null;
};

export default AlertMessage;
