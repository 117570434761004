import IAction from "../../../types/action";
import { Any } from "../../../types/Any";
import { INJECTED_ENV_LOAD } from "./consts";
import { FeatureFlag, InjectedEnvState } from "./types";

export const initialState: InjectedEnvState = {
  name: "production",
  features: [],
};

export default function reducer(
  state: InjectedEnvState = initialState,
  action: IAction<Any>
): InjectedEnvState {
  switch (action.type) {
    case INJECTED_ENV_LOAD:
      return {
        ...state,
        ...action.payload,
        features:
          action.payload.features?.filter((flag: FeatureFlag) => flag) ?? [],
      };
    default:
      return state;
  }
}
