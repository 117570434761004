import { ColumnProps } from "antd/lib/table/interface";
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { itemDetailPath } from "../../config/paths";
import { Item } from "../../generated/axios";
import TwoLinesPopover from "../../shared/components/TwoLinesPopover/TwoLinesPopover";
import { pricesAreVisible, ShowIfCondition } from "../capability/lib";
import { roundWithTwoDecimals } from "../configurator/components/ItemDetails/Details";
import "./style.scss";

interface Column extends ColumnProps<Item>, ShowIfCondition {}

export default [
  {
    title: () => (
      <FormattedMessage id="item title" defaultMessage="ITEM TITLE" />
    ),
    key: "item",
    render: (item: Item) => {
      return (
        <div key={item.id}>
          <Link to={itemDetailPath(item.id, item.hasResultsTable)}>
            {item.label}
          </Link>
          <br />
          <div className="summary__project-title">{item.project?.title}</div>
        </div>
      );
    },
  },
  {
    title: "",
    key: "note",
    className: "note",
    render: (item: Item) =>
      item.note ? <TwoLinesPopover text={item.note} /> : null,
    width: 220,
  },
  {
    title: "",
    key: "price",
    className: "price",
    render: (item: Item) =>
      item?.price ? `€ ${roundWithTwoDecimals(item.price)}` : <></>,
    showIf: pricesAreVisible,
  },
  {
    title: () => (
      <FormattedMessage id="last opened" defaultMessage="LAST OPENED" />
    ),
    dataIndex: "lastModifiedDate",
    render: (date: string) => {
      return moment(new Date(date)).format("L - LT");
    },
    className: "last-opened",
  },
] as Column[];
