import { Col, Form, Radio, Row } from "antd";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import _ from "lodash";
import * as React from "react";
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  DraggableProvided,
  DraggingStyle,
  Droppable,
  DroppableProvided,
  DropResult,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import {
  BaseField,
  OrientationEnum,
  ResultsTableSettings,
} from "../../../../generated/axios";
import { getUniqId } from "../../../../shared/lib/ajax-token";
import {
  GetResultTableParams,
  getResultTableSettings,
  resetResultTableSettings,
} from "../../../../shared/store/settings/actions";
import { DroppableItem } from "../../../../shared/store/settings/types";
import {
  updateFieldList,
  updateOrientation,
} from "../../../../shared/store/settingsTmp/actions";
import { IStore } from "../../../../shared/store/types";
import { Any } from "../../../../types/Any";
import { Callback } from "../../../../types/Callback";
import IconLink from "../../components/IconLink";
import "./dragAndDrop.scss";
import LoadingMessage from "./LoadingMessage";

const RadioGroup = Radio.Group;

interface IAppState {
  id: string;
  items: DroppableItem[];
  loading: boolean;
  selected: DroppableItem[];
}

interface IMoveResult {
  droppable: DroppableItem[];
  droppable2: DroppableItem[];
}

const reorder = (
  list: DroppableItem[],
  startIndex: number,
  endIndex: number
): DroppableItem[] => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (
  source: DroppableItem[],
  destination: DroppableItem[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
): IMoveResult | Any => {
  const sourceClone = [...source];
  const destClone = [...destination];
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (
  draggableStyle?: DraggingStyle | NotDraggingStyle
): React.CSSProperties => ({
  userSelect: "none",
  background: "white",
  ...draggableStyle,
});

const listStyle: React.CSSProperties = {
  background: "#FFF",
  padding: `${grid} 0`,
};

interface ReduxProps {
  fieldsConfig?: BaseField[];
  resultTableParts?: {
    items: DroppableItem[];
    selected: DroppableItem[];
    id?: string; // TODO: Verificare...
  };
  resultsTableSettings?: ResultsTableSettings;
}

interface DispatchProps {
  getResultTableSettings: (
    params: GetResultTableParams,
    withScope?: string
  ) => Promise<void>;
  resetResultTableSettings: Callback;
  updateFieldList: (payload: DroppableItem[]) => void;
  updateOrientation: (orientation: OrientationEnum) => void;
}

interface OwnProps {
  hasDataToDisplay: boolean;
  itemId?: number;
  projectId?: number;
  scope: string;
}

interface Props extends ReduxProps, DispatchProps, OwnProps {}

class FieldListWithDragAndDrop extends React.Component<Props, IAppState> {
  public id2List = {
    droppable: "items",
    droppable2: "selected",
  };

  constructor(props: Any) {
    super(props);

    this.state = {
      items: [],
      selected: [],
      loading: true,
      id: getUniqId(),
    };

    this.onDragEnd = this.onDragEnd.bind(this);
    this.getList = this.getList.bind(this);
  }

  static getDerivedStateFromProps(props: Props, state: IAppState) {
    const { resultTableParts } = props;
    const { loading } = state;

    // Update internal state with data coming from call done into componentDidMount
    if (resultTableParts && resultTableParts.id === state.id && loading) {
      return {
        items: resultTableParts.items,
        selected: resultTableParts.selected,
        loading: false,
      };
    }
    return null;
  }

  componentDidMount() {
    const { itemId, projectId, getResultTableSettings } = this.props;
    const { id } = this.state;
    getResultTableSettings({
      itemId,
      projectId,
      purpose: "editing",
      id,
    }).finally();
  }

  componentWillUnmount() {
    this.props.resetResultTableSettings();
  }

  public getList(id: string): DroppableItem[] {
    return this.state[this.id2List[id]];
  }

  public onDragEnd(result: DropResult): void {
    const { source, destination } = result;
    const { updateFieldList } = this.props;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state: IAppState = { ...this.state };

      if (source.droppableId === "droppable2") {
        state = { ...this.state, selected: items };
      } else if (source.droppableId === "droppable") {
        state = { ...this.state, items };
      }

      this.setState(
        () => state,
        () => {
          if (source.droppableId === "droppable") {
            updateFieldList(this.getList(source.droppableId));
          }
        }
      );
    } else {
      const resultFromMove: IMoveResult = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      updateFieldList(resultFromMove.droppable);

      this.setState({
        items: resultFromMove.droppable,
        selected: resultFromMove.droppable2,
      });
    }
  }

  renderFormattedLoadingMessage = () => (
    <Row>
      <Col className="field-list__container">
        <Row>
          <Col className="field-list__section-title">
            <LoadingMessage />
          </Col>
        </Row>
      </Col>
    </Row>
  );

  onChangeOrientation = (e: RadioChangeEvent) => {
    this.props.updateOrientation(e.target.value);
  };

  public render() {
    const { items, selected } = this.state;
    const { hasDataToDisplay, resultsTableSettings } = this.props;

    if (!hasDataToDisplay) return this.renderFormattedLoadingMessage();
    if (_.isEmpty(items) && _.isEmpty(selected))
      return this.renderFormattedLoadingMessage();

    return (
      <Form className="field-list">
        <Row>
          <Col className="field-list__container">
            <Row>
              <Col className="field-list__section-title">
                <FormattedMessage
                  id="results table view"
                  defaultMessage="Results table view"
                />
              </Col>
            </Row>
            <Row className={"margin-bottom--20"}>
              <Col span={24}>
                <Form.Item className="customize-modal__label">
                  <RadioGroup
                    name={"orientation"}
                    className="customize-modal__radio"
                    onChange={this.onChangeOrientation}
                    defaultValue={resultsTableSettings?.orientation}
                  >
                    <Radio value={OrientationEnum.VERTICAL}>
                      <FormattedMessage
                        id="vertical"
                        defaultMessage="Vertical"
                      />
                    </Radio>
                    <Radio value={OrientationEnum.HORIZONTAL}>
                      <FormattedMessage
                        id="horizontal"
                        defaultMessage="Horizontal"
                      />
                    </Radio>
                  </RadioGroup>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="field-list__section-title">
                <FormattedMessage
                  id="field-lists"
                  defaultMessage="Field List"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <div className="drag-drop">
                    {/* Selected field(s) column list */}
                    <Droppable droppableId="droppable">
                      {(
                        provided: DroppableProvided
                        // snapshot: DroppableStateSnapshot
                      ) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={listStyle}
                          className="drag-drop__column margin-right--8"
                        >
                          <div className="drag-drop__header">
                            <FormattedMessage
                              id="actual visible fields"
                              defaultMessage="Actual visible fields"
                            />
                          </div>
                          {this.state.items.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(
                                providedDraggable: DraggableProvided
                                // snapshotDraggable: DraggableStateSnapshot
                              ) => (
                                <div>
                                  <div
                                    ref={providedDraggable.innerRef}
                                    {...providedDraggable.draggableProps}
                                    {...providedDraggable.dragHandleProps}
                                    style={getItemStyle(
                                      providedDraggable.draggableProps.style
                                    )}
                                    className="drag-drop__item"
                                  >
                                    <div className="drag-drop__item-text padding-left--8">
                                      {item.content}
                                    </div>
                                    <IconLink
                                      isRouterLink={false}
                                      icon="drag"
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    {/* Unselected field(s) column list */}
                    <Droppable droppableId="droppable2">
                      {(
                        providedDroppable2: DroppableProvided
                        // snapshotDroppable2: DroppableStateSnapshot
                      ) => (
                        <div
                          ref={providedDroppable2.innerRef}
                          style={listStyle}
                          className="drag-drop__column margin-left--8"
                        >
                          <div className="drag-drop__header">
                            <FormattedMessage
                              id="available fields"
                              defaultMessage="Available fields"
                            />
                          </div>

                          {this.state.selected.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(
                                providedDraggable2: DraggableProvided
                                // snapshotDraggable2: DraggableStateSnapshot
                              ) => (
                                <div>
                                  <div
                                    ref={providedDraggable2.innerRef}
                                    {...providedDraggable2.draggableProps}
                                    {...providedDraggable2.dragHandleProps}
                                    style={getItemStyle(
                                      providedDraggable2.draggableProps.style
                                    )}
                                    className="drag-drop__item"
                                  >
                                    <IconLink
                                      isRouterLink={false}
                                      icon="plus"
                                    />
                                    <div className="drag-drop__item-text padding-right--8">
                                      {item.content}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {providedDroppable2.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </DragDropContext>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default connect(
  (state: IStore) => {
    const { fieldsConfig, resultTableParts, resultsTableSettingsForEditing } =
      state.settings;
    return {
      fieldsConfig,
      resultTableParts,
      resultsTableSettings: resultsTableSettingsForEditing,
    };
  },
  {
    getResultTableSettings,
    resetResultTableSettings,
    updateFieldList,
    updateOrientation,
  }
)(FieldListWithDragAndDrop);
