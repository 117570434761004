import { createSelector } from "reselect";
import { USER_SHOW_DETAILED_OPTIONS_PRICES } from "../../../modules/capability/constants";
import { IStore } from "../types";

export const capabilitiesSelector = (state: IStore) => state.capabilities.list;

export const showDetailedOptionsPricesSelector = createSelector(
  capabilitiesSelector,
  (capabilities) => capabilities.includes(USER_SHOW_DETAILED_OPTIONS_PRICES)
);
