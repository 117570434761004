import { Col, Form, Row } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import React, { ReactElement, ReactNode } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { validationMessagesToValidateProps } from "../../../shared/lib/validationMessagesToValidateProps";
import { FormFieldWithValue } from "../../../shared/store/configurator/types";
import { ValidateProps, ValidationMessage } from "../../../types/configurator";
import { Description, EnhancedLabel } from "../components/Section";
import { ChangeHandler } from "./Configurator";
import FieldSystemOfMeasurement from "./FieldSystemOfMeasurement";
import FieldType from "./FieldType";
import FieldVisibility from "./FieldVisibility";

export interface RenderOptions {
  leftField: React.ReactNode;
  span: number;
  forceDisabled: boolean;
}

export interface FieldProps {
  changeHandler: ChangeHandler;
  field: FormFieldWithValue;
  form: WrappedFormUtils;
  renderOptions?: RenderOptions;
  validationMessages: ValidationMessage[];
  // key: string;
  // children?: any;
  // precision?: number;
}

interface IProps extends FieldProps, InjectedIntlProps {}

/**
 * Field
 * @param props
 */
const Field = (props: IProps): ReactElement<ReactNode> | null => {
  const {
    changeHandler,
    field,
    form,
    intl,
    renderOptions,
    validationMessages,
  } = props;
  const { inputType, description } = field;

  const validateProps: ValidateProps = validationMessagesToValidateProps(
    validationMessages,
    intl
  );

  return (
    <Form.Item {...validateProps}>
      {inputType !== "checkbox" && <EnhancedLabel field={field} />}

      {renderOptions ? (
        <Row type={"flex"} justify={"space-between"}>
          <Col span={renderOptions.span}>{renderOptions.leftField}</Col>
          <Col span={24 - renderOptions.span}>
            <FieldType
              data={field}
              onChange={changeHandler}
              form={form}
              forceDisabled={renderOptions.forceDisabled}
            />
          </Col>
        </Row>
      ) : (
        <FieldType data={field} onChange={changeHandler} form={form} />
      )}

      <Description>{description}</Description>
    </Form.Item>
  );
};

const TranslatedField: React.ComponentClass<FieldProps> = injectIntl(Field);

// convert system of measurement
const FieldWithSystemOfMeasurements = FieldSystemOfMeasurement<FieldProps>(
  TranslatedField
) as React.ComponentType<FieldProps>;

// evaluate visibility by expression
const FieldWithVisibility = FieldVisibility<FieldProps>(
  FieldWithSystemOfMeasurements
);

export default FieldWithVisibility;
