import IAction from "../../../types/action";
import { Any } from "../../../types/Any";
import { LOGOUT } from "../../auth/consts";
import { FAILURE, SUCCESS } from "../../lib/asyncActionHelper";
import {
  CLEAN_RESPONSES,
  GET_ACCOUNT,
  GET_COUNTRIES,
  GET_LANGUAGES,
  GET_SUBDIVISIONS,
  POST_CHANGE_PASSWORD,
  POST_REGISTRATION_ACCOUNT,
  POST_REQUEST_PSW_RESET,
  POST_RESEND_INSTRUCTIONS,
  POST_RESET_EMAIL_FINISH,
  POST_RESET_EMAIL_REQUEST,
  POST_RESET_PASSWORD_FINISH,
} from "./consts";
import { AccountState } from "./types";

export const initialState: AccountState = {
  countries: [],
  languages: [],
  subdivisions: [],
  account: {},
  registrationResponse: undefined,
  passwordResetResponse: undefined,
  finishPasswordResetResponse: undefined,
  passwordChangeResponse: undefined,
  passwordChangeError: undefined,
  // change e-mail from View Profile
  requestEmailResetResponse: undefined,
  requestEmailResetError: undefined,
  // change email - response - from Change Email page
  requestEmailResetFinishResponse: undefined,
  requestEmailResetFinishError: undefined,

  resendConfirmationMailResponse: undefined,
};

/**
 * ATTENZIONE: l'utente potrebbe essere autorizzato senza che account.account sia popolato
 *
 * il reducer dovrebbe essere sincronizzato con i dati del local storage o dei cookie!
 *
 */
export default function reducer(
  state: AccountState = initialState,
  action: IAction<Any>
): AccountState {
  switch (action.type) {
    case GET_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };

    case SUCCESS(POST_RESET_PASSWORD_FINISH):
    case FAILURE(POST_RESET_PASSWORD_FINISH):
      return {
        ...state,
        finishPasswordResetResponse: action.payload,
      };

    case GET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case GET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };
    case GET_SUBDIVISIONS:
      return {
        ...state,
        subdivisions: action.payload,
      };
    case POST_REGISTRATION_ACCOUNT:
      return {
        ...state,
        registrationResponse: action.payload,
      };

    case SUCCESS(POST_REQUEST_PSW_RESET):
    case FAILURE(POST_REQUEST_PSW_RESET):
      return {
        ...state,
        passwordResetResponse: action.payload,
      };

    case SUCCESS(POST_RESEND_INSTRUCTIONS):
    case FAILURE(POST_RESEND_INSTRUCTIONS):
      return {
        ...state,
        resendConfirmationMailResponse: action.payload,
      };

    // from "User View"  (ViewProfile component)
    case SUCCESS(POST_CHANGE_PASSWORD):
      return {
        ...state,
        passwordChangeResponse: action.payload,
      };
    case FAILURE(POST_CHANGE_PASSWORD):
      return {
        ...state,
        passwordChangeError: action.payload,
      };

    // called from View Profile after click on Change E-Mail
    // API /account/reset-email/init
    case SUCCESS(POST_RESET_EMAIL_REQUEST):
      return {
        ...state,
        requestEmailResetResponse: action.payload,
      };
    case FAILURE(POST_RESET_EMAIL_REQUEST):
      return {
        ...state,
        requestEmailResetError: action.payload,
      };

    // called from Change Email after click on automatic email link
    // API /account/reset-email/finish
    case SUCCESS(POST_RESET_EMAIL_FINISH):
      return {
        ...state,
        requestEmailResetFinishError: undefined,
        requestEmailResetFinishResponse: action.payload,
      };
    case FAILURE(POST_RESET_EMAIL_FINISH):
      return {
        ...state,
        requestEmailResetFinishResponse: undefined,
        requestEmailResetFinishError: action.payload,
      };

    case CLEAN_RESPONSES:
      return {
        ...state,
        passwordChangeResponse: undefined,
        passwordChangeError: undefined,
        requestEmailResetResponse: undefined,
        requestEmailResetError: undefined,
        requestEmailResetFinishResponse: undefined,
        requestEmailResetFinishError: undefined,
      };

    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
