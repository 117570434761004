import _ from "lodash";
import { AnyAction } from "redux";
import settingsInfo from "../../../config/settings";
import {
  ItemSettingsApi,
  OrientationEnum,
  ProjectSettingsApi,
  UserSettingsApi,
} from "../../../generated/axios";
import Action from "../../../types/action";
import { Any } from "../../../types/Any";
import { apiConfig } from "../../api";
import { IStore } from "../types";
import { DroppableItem, SettingsState } from "./types";

const cfg = apiConfig();

const itemSettingsApi = new ItemSettingsApi(cfg);
const projectSettingsApi = new ProjectSettingsApi(cfg);
const userSettingsApi = new UserSettingsApi(cfg);

// update state.interfaceLanguage value
function langFromIdToString(state: SettingsState, action: Action<Any>) {
  const id = action.payload.interfaceLanguage;
  const langObj = state.languages.find((lang) => lang["id"] === id);
  return langObj ? langObj["key"] : state.interfaceLanguage;
}

function metricSystemFromIdToString(state: SettingsState, action: Action<Any>) {
  const id = action.payload.measurementSystem;
  const msObj = state.metricSystems.find((sys) => sys.id === id);

  return msObj?.key ? msObj.key : state.chosenMetricSystem;
}

// separa i fields in items e selected
function getResultTableParts(state: SettingsState, action: AnyAction) {
  const { fieldsConfig } = state;
  const payloadFields = action.payload.fields;

  if (!fieldsConfig) return undefined;

  const tableFields = fieldsConfig.filter((item) =>
    item["sections"].includes("resultTable")
  );

  const fields = tableFields.map((field) => {
    const { fieldId, label } = field;

    return {
      id: fieldId,
      content: label,
      position: payloadFields[fieldId],
      info: {
        label,
        name: fieldId,
      },
    } as DroppableItem;
  });

  const parts = _.partition(
    fields,
    (field) => _.isNumber(field.position) && field.position >= 0
  );

  return {
    items: _.sortBy(parts[0], ["position"]),
    selected: _.sortBy(parts[1], ["position"]),
    id: action.id,
  };
}

export function genericsEndpointByScope(scope: string) {
  const map = {
    [settingsInfo.scopes.ITEM]:
      itemSettingsApi.getItemGenericSettings.bind(itemSettingsApi),
    [settingsInfo.scopes.PROJECT]:
      projectSettingsApi.getProjectGenericSettings.bind(projectSettingsApi),
    [settingsInfo.scopes.USER]:
      userSettingsApi.getUserGenericSettings.bind(userSettingsApi),
  };
  return map[`${scope}`];
}

export function updateGenericsEndpointByScope(scope: string) {
  const map = {
    [settingsInfo.scopes.ITEM]:
      itemSettingsApi.updateItemGenericSettings.bind(itemSettingsApi),
    [settingsInfo.scopes.PROJECT]:
      projectSettingsApi.updateProjectGenericSettings.bind(projectSettingsApi),
    [settingsInfo.scopes.USER]:
      userSettingsApi.updateUserGenericSettings.bind(userSettingsApi),
  };
  return map[`${scope}`];
}

export function resultTableEndpointByScope(scope: string) {
  const map = {
    [settingsInfo.scopes.ITEM]:
      itemSettingsApi.getItemResultsTableSettings.bind(itemSettingsApi),
    [settingsInfo.scopes.PROJECT]:
      projectSettingsApi.getProjectResultsTableSettings.bind(
        projectSettingsApi
      ),
    [settingsInfo.scopes.USER]:
      userSettingsApi.getUserResultsTableSettings.bind(userSettingsApi),
  };
  return map[`${scope}`];
}

export function updateResultTableEndpointByScope(scope: string) {
  const map = {
    [settingsInfo.scopes.ITEM]:
      itemSettingsApi.updateItemResultsTableSettings.bind(itemSettingsApi),
    [settingsInfo.scopes.PROJECT]:
      projectSettingsApi.saveProjectResultsTableSettings.bind(
        projectSettingsApi
      ),
    [settingsInfo.scopes.USER]:
      userSettingsApi.updateUserResultsTableSettings.bind(userSettingsApi),
  };
  return map[`${scope}`];
}

export function techSpecEndpointByScope(scope: string) {
  const map = {
    [settingsInfo.scopes.ITEM]:
      itemSettingsApi.getItemTechnicalSpecificationSettings.bind(
        itemSettingsApi
      ),
    [settingsInfo.scopes.PROJECT]:
      projectSettingsApi.getProjectTechnicalSpecificationsSettings.bind(
        projectSettingsApi
      ),
    [settingsInfo.scopes.USER]:
      userSettingsApi.getUserTechnicalSpecificationsSettings.bind(
        userSettingsApi
      ),
  };
  return map[`${scope}`];
}

export function updateTechSpecEndpointByScope(scope: string) {
  const map = {
    [settingsInfo.scopes.ITEM]:
      itemSettingsApi.updateItemTechnicalSpecificationSettings.bind(
        itemSettingsApi
      ),
    [settingsInfo.scopes.PROJECT]:
      projectSettingsApi.updateProjectTechnicalSpecificationsSettings.bind(
        projectSettingsApi
      ),
    [settingsInfo.scopes.USER]:
      userSettingsApi.updateUserTechnicalSpecificationsSettings.bind(
        userSettingsApi
      ),
  };
  return map[`${scope}`];
}

export function refactorParams(
  scope: string,
  params: { itemId?: number; projectId?: number }
) {
  if (scope === settingsInfo.scopes.ITEM && params.itemId)
    return [params.itemId];
  if (scope === settingsInfo.scopes.PROJECT && params.projectId)
    return [params.projectId];
  return [];
}

export function onGenericSettings(
  state: SettingsState,
  action: AnyAction
): SettingsState {
  if (action["phase"] === "afterUpdate") {
    // dopo un update dei generic settings aggiorna lingua e unit of measure
    return {
      ...state,
      genericSettings: {
        ...state.genericSettings,
        interfaceLanguage: action.payload.interfaceLanguage,
      },
      interfaceLanguage: langFromIdToString(state, action),
      chosenMetricSystem:
        action["updateScope"] === settingsInfo.scopes.PROJECT
          ? metricSystemFromIdToString(state, action)
          : state.chosenMetricSystem,
    };
  }
  // called by modal settings or not
  return action["purpose"] === "editing"
    ? ({
        ...state,
        genericSettingsForEditing: action.payload,
        interfaceLanguageForEditing: langFromIdToString(state, action),
      } as SettingsState)
    : ({
        ...state,
        genericSettings: action.payload,
        interfaceLanguage: langFromIdToString(state, action),
        chosenMetricSystem: metricSystemFromIdToString(state, action),
      } as SettingsState);
}

export function onResultTableSettings(
  state: SettingsState,
  action: AnyAction
): SettingsState {
  const payload = {
    orientation: OrientationEnum.VERTICAL, // Set default orientation if missing!
    ...action.payload,
  };
  return action["purpose"] === "editing"
    ? {
        ...state,
        resultsTableSettingsForEditing: payload,
        resultTableParts: getResultTableParts(state, action),
      }
    : {
        ...state,
        resultsTableSettings:
          action.meta === settingsInfo.scopes.ITEM
            ? payload
            : state.resultsTableSettings,
        resultTableParts: getResultTableParts(state, action),
      };
}

export function onTechSpecSettings(state: SettingsState, action: Action<Any>) {
  return action["purpose"] === "editing"
    ? {
        ...state,
        techSpecSettingsForEditing: action.payload,
      }
    : {
        ...state,
        techSpecSettings: action.payload,
      };
}

/**
 * returns the api to call to get bounds settings, based on current scope
 */
export const getBoundsApi = (getState: () => IStore) => {
  const scope = getState().ui.modalSettings.scope;
  const projectId = getState().project.id;
  const itemId = getState().item.id;
  switch (scope) {
    case settingsInfo.scopes.USER:
      return (options?: Any) => userSettingsApi.getUserBoundsSettings(options);
    case settingsInfo.scopes.PROJECT:
      if (!projectId) {
        throw new Error("Project id not found");
      }
      return (options?: Any) =>
        projectSettingsApi.getProjectBoundsSettings(projectId, options);
    case settingsInfo.scopes.ITEM:
      if (!itemId) {
        throw new Error("Item id not found");
      }
      return (options?: Any) =>
        itemSettingsApi.getItemBoundsSettings(itemId, options);
    default:
      return (options?: Any) => userSettingsApi.getUserBoundsSettings(options);
  }
};

/**
 * returns the api to call to save bounds settings, based on current scope
 */
export const saveBoundsApi = (getState: () => IStore) => {
  const scope = getState().ui.modalSettings.scope;
  const projectId = getState().project.id;
  const itemId = getState().item.id;
  const boundsConfig = getState().settings.bounds;
  switch (scope) {
    case settingsInfo.scopes.USER:
      return (options?: Any) =>
        userSettingsApi.updateUserBoundsSettings(boundsConfig, options);
    case settingsInfo.scopes.PROJECT:
      if (!projectId) {
        throw new Error("Project id not found");
      }
      return (options?: Any) =>
        projectSettingsApi.updateProjectBoundsSettings(
          projectId,
          boundsConfig,
          options
        );
    case settingsInfo.scopes.ITEM:
      if (!itemId) {
        throw new Error("Item id not found");
      }
      return (options?: Any) =>
        itemSettingsApi.updateItemBoundsSettings(itemId, boundsConfig, options);
    default:
      return (options?: Any) =>
        userSettingsApi.updateUserBoundsSettings(options);
  }
};
