import { Button, Col, Icon, Popover, Row } from "antd";
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import imgOptions from "../../assets/icon_3_dots.svg";
import { CPQ, Project } from "../../generated/axios";
import MultilineText from "../../shared/components/MultilineText/MultilineText";
import { ModalFormMetaData } from "../../shared/store/ui/types";
import { CapabilityCheck, capabilityMap } from "../capability";
import QuotePresentCell from "./QuotePresentCell";

const createButton = (
  action: string,
  label: React.ReactElement,
  onItemClick: (action: string, metaData: ModalFormMetaData) => void,
  id?: number
) => (
  <Button
    size="default"
    type="default"
    onClick={() => onItemClick(action, { projectId: id, refresh: true })}
  >
    {label}
  </Button>
);

export const columns = (
  onItemClick: (action: string, metaData: ModalFormMetaData) => void,
  confirmationModalVisible: boolean,
  shareProject: (projectId: number) => void,
  importSharedProject?: (projectId: number) => Promise<void>
) => {
  return [
    {
      title: () => (
        <FormattedMessage id="project title" defaultMessage="PROJECT TITLE" />
      ),
      key: "project",
      render: (project: Project) => (
        <>
          <Link
            to={`/project/${project.id}/details`}
            className="summary__project-title"
          >
            {project.title}
          </Link>
          {project.owner && !project.owner.isMe ? (
            <div className="summary__project-owner">{project.owner.email}</div>
          ) : null}
        </>
      ),
      sorter: (projectA: Project, projectB: Project) =>
        projectA && projectB && projectA.title && projectB.title
          ? projectA.title < projectB.title
          : false,
    },
    {
      title: () => (
        <FormattedMessage id="project id" defaultMessage="PROJECT ID" />
      ),
      key: "id",
      render: (project: Project) => project.id,
      sorter: (projectA: Project, projectB: Project) =>
        projectA && projectB && projectA.id && projectB.id
          ? projectA.id - projectB.id
          : false,
    },
    {
      title: "",
      dataIndex: "cpq",
      render: (cpq: CPQ) => <QuotePresentCell cpq={cpq} />,
    },
    {
      title: "",
      dataIndex: "note",
      render: (note: string, project: Project) => {
        return (
          <div>
            {project.shared && (
              <Icon className="icon--blue" type="share-alt" theme="outlined" />
            )}

            {note && (
              <div>
                <Popover
                  content={<MultilineText text={note} />}
                  placement="bottomRight"
                  title="Note"
                >
                  <Icon
                    className="icon--blue"
                    type="file-text"
                    theme="outlined"
                  />
                </Popover>
              </div>
            )}

            {/* Duplicate/Delete items always visibile */}
            {/* do not remove the <div> wrapper due to css purpose */}
            <div>
              {!confirmationModalVisible && (
                <Popover
                  overlayClassName="popover"
                  placement={"bottomRight"}
                  content={
                    <>
                      {!importSharedProject && (
                        <Row>
                          <Col style={{ textAlign: "left" }}>
                            {createButton(
                              "duplicateProject",
                              <FormattedMessage
                                id="duplicate project"
                                defaultMessage="Duplicate Project"
                              />,
                              onItemClick,
                              project.id
                            )}
                          </Col>
                        </Row>
                      )}
                      {!importSharedProject && !project.cpq && (
                        <Row>
                          <Col style={{ textAlign: "left" }}>
                            {createButton(
                              "deleteProject",
                              <FormattedMessage
                                id="delete project"
                                defaultMessage="Delete Project"
                              />,
                              onItemClick,
                              project.id
                            )}
                          </Col>
                        </Row>
                      )}
                      {!importSharedProject && (
                        <CapabilityCheck
                          showIfHas={capabilityMap.USER_SHARE_PROJECT}
                        >
                          <Row>
                            <Col style={{ textAlign: "left" }}>
                              <Button
                                size="default"
                                type="default"
                                onClick={() => shareProject(project.id ?? 0)}
                                data-test="share-project"
                              >
                                <FormattedMessage
                                  id="share project"
                                  defaultMessage="Share Project"
                                />
                              </Button>
                            </Col>
                          </Row>
                        </CapabilityCheck>
                      )}
                      {importSharedProject && (
                        <Row>
                          <Col style={{ textAlign: "left" }}>
                            <Button
                              size="default"
                              type="default"
                              onClick={() =>
                                importSharedProject(project.id ?? 0)
                              }
                            >
                              <FormattedMessage
                                id="import shared project"
                                defaultMessage="Import Shared Project"
                              />
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </>
                  }
                >
                  <ReactSVG
                    src={imgOptions}
                    className="icon__content icon__content--32 icon--blue"
                    data-test={"share-project-icon"}
                  />
                </Popover>
              )}
            </div>
          </div>
        );
      },
      className: "icons",
    },
    {
      title: () => <FormattedMessage id="date" defaultMessage="DATE" />,
      dataIndex: "lastModifiedDate",
      className: "date",
      render: (date: Date): string =>
        date ? moment(date).format("L - LT") : "N.A.",
      sorter: (dateA: Project, dateB: Project) =>
        dateA && dateB && dateA.lastModifiedDate && dateB.lastModifiedDate
          ? new Date(dateA.lastModifiedDate).getTime() -
            new Date(dateB.lastModifiedDate).getTime()
          : false,
      defaultSortOrder: "descend",
    },
  ];
};
