import { memoize } from "lodash";
import { Dispatch } from "redux";
import settingsInfo from "../../../config/settings";
import {
  BoundsConfig,
  Configuration,
  ConversionFormula,
  FieldsConfigurationApi,
  FormSection,
  GenericSettings,
  MetricSystem,
  ResultsTableSettings,
  TechnicalSpecificationConfig,
  UnitOfMeasuresApi,
} from "../../../generated/axios";
import { SETTINGS_RES_TABLE_CUSTOMIZE_FIELDS } from "../../../modules/capability/constants";
import { ConfiguratorSection } from "../../../types/configurator";
import { apiConfig } from "../../api";
import apiErrorHandler from "../../lib/apiErrorHandler";
import { FAILURE, SUCCESS } from "../../lib/asyncActionHelper";
import { capabilitiesSelector } from "../capabilities/selectors";
import { UPDATE_LABELS_AND_ACTIONS_AFTER_LANG_CHANGE } from "../configurator/consts";
import {
  createConfiguratorSectionData,
  getApiFunctions,
} from "../configurator/lib";
import { ApiFunctions } from "../configurator/types";
import { ItemState } from "../item/types";
import { IStore } from "../types";
import { CLOSE_MODAL_SETTINGS } from "../ui/consts";
import {
  GET_CONVERSIONS,
  GET_FIELDS_CONFIG,
  GET_GENERIC_SETTINGS,
  GET_METRIC_SYSTEMS,
  GET_RESULT_TABLE_SETTINGS,
  GET_TECH_SPEC_SETTINGS,
  RESET_GENERIC_SETTINGS,
  RESET_RESULT_TABLE_SETTINGS,
  RESET_TECH_SPEC_SETTINGS,
  RESET_UPDATE_INFO,
  SET_BOUNDS_SETTINGS,
  SET_CHOSEN_METRIC_SYSTEM,
  SET_DEFAULT_BOUNDS_SETTINGS,
  SETTINGS_CONTEXT,
  UNSET_DEFAULT_BOUNDS_SETTINGS,
  UPDATE_GENERIC_SETTINGS,
  UPDATE_RESULT_TABLE_SETTINGS,
  UPDATE_TECH_SPEC_SETTINGS,
} from "./consts";
import {
  genericsEndpointByScope,
  getBoundsApi,
  refactorParams,
  resultTableEndpointByScope,
  saveBoundsApi,
  techSpecEndpointByScope,
  updateGenericsEndpointByScope,
  updateResultTableEndpointByScope,
  updateTechSpecEndpointByScope,
} from "./lib";
import { settingsContextSelector } from "./selectors";

const cfg: Configuration = apiConfig();
const unitOfMeasuresApi = new UnitOfMeasuresApi(cfg);
const fieldsConfigurationApi = new FieldsConfigurationApi(cfg);

export const setSettingsContext = (payload: string = "") => {
  return {
    type: SETTINGS_CONTEXT,
    payload,
  };
};

// NOTE: why caching the result when we already can check in redux?
export const getConversions = memoize(
  (): {
    type: string;
    payload: Promise<ConversionFormula[]>;
  } => ({
    type: GET_CONVERSIONS,
    payload: unitOfMeasuresApi.getConversionFormulas().then((res) => res.data),
  })
);

export const getMetricSystems = memoize(
  (): {
    type: string;
    payload: Promise<MetricSystem[]>;
  } => ({
    type: GET_METRIC_SYSTEMS,
    payload: unitOfMeasuresApi.getMetricSystems().then((res) => res.data),
  })
);

// new api
export const getResultsTableFieldsConfiguration = () => {
  return {
    type: GET_FIELDS_CONFIG,
    payload: fieldsConfigurationApi
      .getResultsTableFields()
      .then((res) => res.data)
      .catch((err) => err),
  };
};

export const setChosenMetricSystem = (system: MetricSystem) => ({
  type: SET_CHOSEN_METRIC_SYSTEM,
  payload: system,
});

export const resetResultTableSettings = () => ({
  type: RESET_RESULT_TABLE_SETTINGS,
});

export const resetTechSpecSettings = () => ({
  type: RESET_TECH_SPEC_SETTINGS,
});

export const resetGenericsSettings = () => ({
  type: RESET_GENERIC_SETTINGS,
});

export const resetUpdateInfo = () => ({
  type: RESET_UPDATE_INFO,
});

async function refreshOptionLabelsAndActions(
  dispatch: Dispatch,
  getState: () => IStore,
  currentSection: ConfiguratorSection
) {
  try {
    const item: ItemState = getState().item;
    const currentSectionData = getState().configurator[currentSection];

    const formConfig: FormSection[] = currentSectionData.config;
    const endpoints: ApiFunctions = getApiFunctions(currentSection);
    // call endpoints needed
    const { data: optionLabels } = await endpoints.getFieldOptionLabels();
    const { data: optionActions } = await endpoints.getFieldOptionActions();

    const derivedData = createConfiguratorSectionData({
      sectionName: currentSection,
      item,
      formConfig,
      optionActions,
      optionLabels,
      sectionLabels: currentSectionData.labels,
    });

    const payload = {
      state: {
        optionLabels,
        optionActions,
        ...derivedData,
      },
      section: currentSection,
    };

    dispatch({
      type: SUCCESS(UPDATE_LABELS_AND_ACTIONS_AFTER_LANG_CHANGE),
      payload,
    });
  } catch (error) {
    dispatch({
      type: FAILURE(UPDATE_LABELS_AND_ACTIONS_AFTER_LANG_CHANGE),
      error,
    });
  }
}

/**
 * chiamata dopo update backend
 */
async function getResultTableSettingsAfterUpdate(
  dispatch: Dispatch,
  params: { itemId?: number; projectId?: number }
) {
  try {
    const scope = settingsInfo.scopes.ITEM;
    const endpoint = resultTableEndpointByScope(scope);
    const refactoredParams: number[] = refactorParams(scope, params);
    const { data } = await endpoint(...refactoredParams);

    dispatch({
      type: SUCCESS(GET_RESULT_TABLE_SETTINGS),
      payload: data,
      meta: scope,
    });
  } catch (error) {
    dispatch({
      type: FAILURE(GET_RESULT_TABLE_SETTINGS),
      error,
    });
  }
}

export const updateResultTableSettings =
  (
    settings: ResultsTableSettings,
    params: { itemId?: number; projectId?: number }
  ) =>
  async (dispatch: Dispatch, getState: () => IStore) => {
    const capabilities = capabilitiesSelector(getState());
    const canUpdate = capabilities.includes(
      SETTINGS_RES_TABLE_CUSTOMIZE_FIELDS
    );

    if (!canUpdate) return;

    try {
      const { scope } = getState().ui.modalSettings;
      const endpoint = updateResultTableEndpointByScope(scope);
      const refactoredParams: number[] = refactorParams(scope, params);
      const { data } = await endpoint(...refactoredParams, settings);

      dispatch({
        type: SUCCESS(UPDATE_RESULT_TABLE_SETTINGS),
        payload: data,
      });

      const contextScope = settingsContextSelector(getState());
      if (contextScope === settingsInfo.scopes.ITEM) {
        getResultTableSettingsAfterUpdate(dispatch, params);
      }
    } catch (error) {
      dispatch({
        type: FAILURE(UPDATE_RESULT_TABLE_SETTINGS),
        error,
      });
    }
  };

/**
 * chiamata dopo update backend sotto certe condizioni
 */
async function getGenericsAfterUpdate(
  dispatch: Dispatch,
  scope: string,
  params: { itemId?: number; projectId?: number },
  getState: () => IStore
) {
  // UPDATE:field-option-labels e field-option-actions, no need to do it in try/catch
  /**
   * labels and actions must enter in the form configuration with item data,
   * so I need to load the item data and set up its config again
   *
   */
  refreshOptionLabelsAndActions(
    dispatch,
    getState,
    ConfiguratorSection.THERMAL
  );
  refreshOptionLabelsAndActions(
    dispatch,
    getState,
    ConfiguratorSection.MECHANICAL
  );

  try {
    const endpoint = genericsEndpointByScope(scope);
    const refactoredParams: number[] = refactorParams(scope, params);
    const { data } = await endpoint(...refactoredParams);

    dispatch({
      type: SUCCESS(GET_GENERIC_SETTINGS),
      payload: data,
      phase: "afterUpdate",
      updateScope: scope,
    });
  } catch (error) {
    dispatch({
      type: FAILURE(GET_GENERIC_SETTINGS),
      error,
    });
  }
}

// ------------thunk builder explosion---------------

export interface GetGenericsParams {
  itemId?: number;
  projectId?: number;
  purpose?: string;
  settingsContext?: string; // only on change route
}

export const getGenerics =
  (params: GetGenericsParams) =>
  async (dispatch: Dispatch, getState: () => IStore) => {
    const _purpose = params?.purpose;
    const _settingsContext = params?.settingsContext;

    try {
      const scope =
        _settingsContext ??
        getState().ui.modalSettings.scope ??
        settingsInfo.scopes.USER;
      const endpoint = genericsEndpointByScope(scope);
      const refactoredParams: number[] = refactorParams(scope, params);
      const { data } = await endpoint(...refactoredParams);

      dispatch({
        type: SUCCESS(GET_GENERIC_SETTINGS),
        payload: data,
        purpose: _purpose,
      });
    } catch (error) {
      dispatch({
        type: FAILURE(GET_GENERIC_SETTINGS),
        error,
      });
    }
  };

export interface GetResultTableParams {
  id?: string;
  itemId?: number;
  projectId?: number;
  purpose?: string;
  settingsContext?: string; // only on change route
}

export const getResultTableSettings =
  (params: GetResultTableParams, withScope?: string) =>
  async (dispatch: Dispatch, getState: () => IStore) => {
    const _purpose = params ? params.purpose : undefined;
    const _settingsContext = params ? params.settingsContext : undefined;

    try {
      // in CalculatorResults i need to call the item table settings but i must pass a scope = item
      const scope =
        _settingsContext ?? withScope ?? getState().ui.modalSettings.scope;
      const endpoint = resultTableEndpointByScope(scope);
      const refactoredParams: number[] = refactorParams(scope, params);
      const { data } = await endpoint(...refactoredParams);

      dispatch({
        type: SUCCESS(GET_RESULT_TABLE_SETTINGS),
        payload: data,
        meta: scope,
        purpose: _purpose,
        id: params.id,
      });
    } catch (error) {
      dispatch({
        type: FAILURE(GET_RESULT_TABLE_SETTINGS),
        error,
      });
    }
  };

export interface GetTechSpecParams {
  itemId?: number;
  projectId?: number;
  purpose?: string;
  settingsContext?: string; // only on change route
}

export const getTechSpecSettings =
  (params: GetTechSpecParams) =>
  async (dispatch: Dispatch, getState: () => IStore) => {
    const _purpose = params ? params.purpose : undefined;
    const _settingsContext = params ? params.settingsContext : undefined;

    try {
      const scope = _settingsContext ?? getState().ui.modalSettings.scope;
      const endpoint = techSpecEndpointByScope(scope);
      const refactoredParams: number[] = refactorParams(scope, params);
      const { data } = await endpoint(...refactoredParams);

      dispatch({
        type: SUCCESS(GET_TECH_SPEC_SETTINGS),
        payload: data,
        meta: scope,
        purpose: _purpose,
      });
    } catch (error) {
      dispatch({
        type: FAILURE(GET_TECH_SPEC_SETTINGS),
        error,
      });
    }
  };

export const updateTechSpecSettings =
  (
    settings: Partial<TechnicalSpecificationConfig>,
    params: { itemId?: number; projectId?: number }
  ) =>
  async (dispatch: Dispatch, getState: () => IStore) => {
    try {
      const { scope } = getState().ui.modalSettings;
      const endpoint = updateTechSpecEndpointByScope(scope);
      const refactoredParams: number[] = refactorParams(scope, params);
      const { data } = await endpoint(...refactoredParams, settings);

      dispatch({
        type: SUCCESS(UPDATE_TECH_SPEC_SETTINGS),
        payload: data,
      });
      if (scope === "project") {
        // refresh tech settings
        getTechSpecSettings({ ...params, settingsContext: "project" })(
          dispatch,
          getState
        );
      }
    } catch (error) {
      dispatch({
        type: FAILURE(UPDATE_TECH_SPEC_SETTINGS),
        error,
      });
    }
  };

export const updateGenerics =
  (
    settings: GenericSettings,
    params: { itemId?: number; projectId?: number }
  ) =>
  async (dispatch: Dispatch, getState: () => IStore) => {
    try {
      const { scope } = getState().ui.modalSettings;

      const userInterfaceLanguageChanged =
        scope === settingsInfo.scopes.USER &&
        settings.interfaceLanguage !== undefined &&
        settings.interfaceLanguage !==
          getState().settings.genericSettings?.interfaceLanguage;

      const endpoint = updateGenericsEndpointByScope(scope);
      const refactoredParams: number[] = refactorParams(scope, params);
      const { data } = await endpoint(...refactoredParams, settings);

      userInterfaceLanguageChanged &&
        dispatch(getResultsTableFieldsConfiguration());

      dispatch({
        type: SUCCESS(UPDATE_GENERIC_SETTINGS),
        payload: data,
      });
      getGenericsAfterUpdate(dispatch, scope, params, getState);

      dispatch({
        type: CLOSE_MODAL_SETTINGS,
      });
    } catch (error) {
      dispatch({
        type: FAILURE(UPDATE_GENERIC_SETTINGS),
        error,
      });
    }
  };

export const setBounds = (boundsSettings: BoundsConfig) => ({
  type: SET_BOUNDS_SETTINGS,
  payload: boundsSettings,
});

export const setBoundsToDefault = (applicationTypeKey: number) => ({
  type: SET_DEFAULT_BOUNDS_SETTINGS,
  payload: applicationTypeKey,
});

export const unsetBoundsToDefault = (applicationTypeKey: number) => ({
  type: UNSET_DEFAULT_BOUNDS_SETTINGS,
  payload: applicationTypeKey,
});

/**
 * based on current scope, calls the api to get bounds settings,
 * it can be either user, project or item
 */
export const getBounds = () => (dispatch: Dispatch, getState: () => IStore) => {
  // api based on current scope
  const api = getBoundsApi(getState);

  return api()
    .then((res) => {
      dispatch(setBounds(res.data));
    })
    .catch((err) => {
      // in case of error, always remove previous data
      dispatch(setBounds({}));
      return apiErrorHandler({ dispatch, actionType: SET_BOUNDS_SETTINGS })(
        err
      );
    });
};

/**
 * Calls the api to update bounds settings, based on current scope
 */
export const saveBounds =
  () => (dispatch: Dispatch, getState: () => IStore) => {
    const api = saveBoundsApi(getState);

    return api().catch((err) => {
      return apiErrorHandler({ dispatch, actionType: SET_BOUNDS_SETTINGS })(
        err
      );
    });
  };
