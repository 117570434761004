import { Icon, Spin } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { MissingOptionsMap } from "../../../../generated/axios";
import { optionLabelsSelector } from "../../../../shared/store/configurator/selectors";
import { Any } from "../../../../types/Any";
import MissingOptionsWarning from "./MissingOptionsWarning";
import { NoPriceIcon } from "./NoPriceIcon";

interface IProps {
  fieldId: string;
  missingOptionsMap?: MissingOptionsMap[];
  value?: Any;
  waitingPrices?: boolean;
}

const CellValue: React.FC<IProps> = ({
  fieldId,
  missingOptionsMap,
  value,
  waitingPrices,
}) => {
  const optionLabels = useSelector(optionLabelsSelector);
  if (value === undefined) {
    return null;
  }
  if (value === null) {
    // Case field without value:
    if (fieldId === "price") {
      return waitingPrices ? <Spin size="small" /> : <NoPriceIcon />;
    } else {
      return <>-</>;
    }
  } else if (fieldId === "score") {
    // Case fieldId is score
    // - print a green circle if score is 1
    return value === 1 || value === "1" ? (
      <Icon type="check-circle" theme="filled" className="score-icon" />
    ) : (
      ""
    );
  } else if (fieldId === "optionsConfigurationId") {
    return (
      <>
        <MissingOptionsWarning data={missingOptionsMap} />
        <span dangerouslySetInnerHTML={{ __html: value }} />
      </>
    );
  } else {
    const optionLabelValue = optionLabels[fieldId];
    return optionLabelValue?.[value] ?? value;
  }
};

export default CellValue;
