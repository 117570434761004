import { configureStore } from "@reduxjs/toolkit";
import promiseMiddleware from "redux-promise-middleware";
import thunk from "redux-thunk";
import account from "./account/reducer";
import applicationType from "./applicationType/reducer";
import capabilities from "./capabilities/reducer";
import configurator from "./configurator/reducer";
import injectedEnv from "./injectedEnv/reducer";
import item from "./item/reducer";
import items from "./items/reducer";
import { missingConversionFormulasReporter } from "./middlewares/missingConversionFormulasReporter";
import portfolio from "./portfolio/reducer";
import priceList from "./priceList/reducer";
import project from "./project/reducer";
import projects from "./projects/reducer";
import quoteModal from "./quoteModal/reducer";
import settings from "./settings/reducer";
import settingsTmp from "./settingsTmp/reducer";
import share from "./share/reducer";
import translations from "./translations/reducer";
import ui from "./ui/reducer";
import utils from "./utils/reducer";

const store = configureStore({
  reducer: {
    account,
    applicationType,
    capabilities,
    configurator,
    injectedEnv,
    item,
    items,
    portfolio,
    priceList,
    project,
    projects,
    quoteModal,
    settings,
    settingsTmp,
    share,
    translations,
    ui,
    utils,
  },
  middleware: [thunk, promiseMiddleware, missingConversionFormulasReporter],
  // Turn off devtools in prod, or pass options in dev
  devTools: process.env.NODE_ENV === "development",
});

export default store;
