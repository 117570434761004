export enum INPUT_TYPES {
  CHECKBOX = "checkbox",
  IMAGE = "image",
  NUMBER = "number",
  RADIO = "radio",
  SELECT = "select",
  TEXT = "text",
  CHECKBOX_MULTIPLE = "checkbox-multiple",
  SELECT_MULTIPLE = "select-multiple",
  EXPRESSION = "expression",
}
