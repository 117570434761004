import { Col, Icon, Row } from "antd";
import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import "./AdditionalDetailsButton.scss";

interface Props {
  handler: (e: React.MouseEvent) => void;
}

const IconAndText = memo(() => (
  <>
    <Icon type="plus-circle" theme="outlined" />
    <span className="margin-left--15">
      <FormattedMessage
        id="additional details"
        defaultMessage="Additional details"
      />
    </span>
  </>
));

export default (props: Props) => {
  return (
    <Row>
      <Col span={24} className="item__footer">
        <div className="AdditionalDetailsButton" onClick={props.handler}>
          <IconAndText />
        </div>
      </Col>
    </Row>
  );
};
