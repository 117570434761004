import { message } from "antd";
import { Dispatch } from "redux";
import { durations } from "../../../config/defaults";
import {
  AccountApi,
  Country,
  CountryApi,
  EmailReset,
  KeyAndPassword,
  LanguageApi,
  PasswordChange,
  PasswordReset,
  RegistrationRequest,
  ResendEmailRequest,
  Subdivision,
  SubdivisionsApi,
  User,
} from "../../../generated/axios";
import { apiConfig } from "../../api";
import { eIntl } from "../../eIntl";
import { FAILURE, SUCCESS } from "../../lib/asyncActionHelper";
import { messages } from "../../lib/locales/definedMessages";
import {
  CLEAN_RESPONSES,
  GET_ACCOUNT,
  GET_COUNTRIES,
  GET_LANGUAGES,
  GET_SUBDIVISIONS,
  POST_CHANGE_PASSWORD,
  POST_REGISTRATION_ACCOUNT,
  POST_REQUEST_PSW_RESET,
  POST_RESEND_INSTRUCTIONS,
  POST_RESET_EMAIL_FINISH,
  POST_RESET_EMAIL_REQUEST,
  POST_RESET_PASSWORD_FINISH,
} from "./consts";

export const accountApi = new AccountApi(apiConfig());
export const countryApi = new CountryApi(apiConfig());
export const languageApi = new LanguageApi(apiConfig());
export const subdivisionApi = new SubdivisionsApi(apiConfig());

export const getAccount = () => async (dispatch: Dispatch) => {
  const account: User = await accountApi.getAccount().then((res) => res.data);
  dispatch({
    type: GET_ACCOUNT,
    payload: account,
  });
};

// API POST /account
export const saveAccount = (user: User) => (dispatch: Dispatch) => {
  accountApi
    .saveAccount(user)
    .then((/* no response from server */) => {
      message.success(
        eIntl.formatMessage(messages["message.account_updated"]),
        durations.success
      );
      // reload modified data
      accountApi.getAccount().then((res) => {
        dispatch({
          type: GET_ACCOUNT,
          payload: res.data,
        });
      });
    })
    .catch((err) => {
      message.error(
        eIntl.formatMessage(messages["message.operation_failed_try_later"]),
        durations.error
      );
      dispatch({
        type: FAILURE(POST_RESET_PASSWORD_FINISH),
        payload: err.response, // TODO check res
      });
    });
};

export const getCountries = () => async (dispatch: Dispatch) => {
  const countries: Country[] = await countryApi
    .getCountries()
    .then((res) => res.data);
  dispatch({
    type: GET_COUNTRIES,
    payload: countries,
  });
};

export const getLanguages = () => (dispatch: Dispatch) => {
  languageApi.getLanguages().then((res) => {
    const languages = res.data;
    dispatch({
      type: GET_LANGUAGES,
      payload: languages,
    });
  });
};

export const getSubdivisions =
  (countryId?: number) => async (dispatch: Dispatch) => {
    const subdivisions: Subdivision[] = await subdivisionApi
      .getSubdivisions(countryId)
      .then((res) => res.data);
    dispatch({
      type: GET_SUBDIVISIONS,
      payload: subdivisions,
    });
  };

// API /account/register
export const registerAccount =
  (registrationRequest: RegistrationRequest) => async (dispatch: Dispatch) => {
    dispatch({
      type: POST_REGISTRATION_ACCOUNT,
      payload: await accountApi
        .registerAccount(registrationRequest)
        .then((res) => res.data)
        .catch((error) => error.response.data),
    });
  };

// call API /account/change-password with POST method
export const passwordChange =
  (passwordChange: PasswordChange) => (dispatch: Dispatch) => {
    accountApi
      .changePassword(passwordChange)
      .then((res) => {
        dispatch({
          type: SUCCESS(POST_CHANGE_PASSWORD),
          payload: res.data,
        });
        return res.data;
      })
      .catch((error) => {
        dispatch({
          type: FAILURE(POST_CHANGE_PASSWORD),
          payload: error.response.data,
        });
      });
  };

export const requestPasswordReset =
  (passwordReset: PasswordReset) => (dispatch: Dispatch) => {
    accountApi
      .requestPasswordReset(passwordReset)
      .then((res) => {
        dispatch({
          type: SUCCESS(POST_REQUEST_PSW_RESET),
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FAILURE(POST_REQUEST_PSW_RESET),
          payload: error.response.data,
        });
      });
  };

export const resendConfirmationMail =
  (resendEmailRequest: ResendEmailRequest) => (dispatch: Dispatch) => {
    accountApi
      .resendConfirmationMail(resendEmailRequest)
      .then((res) => {
        dispatch({
          type: SUCCESS(POST_RESEND_INSTRUCTIONS),
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FAILURE(POST_RESEND_INSTRUCTIONS),
          payload: error.response.data,
        });
      });
  };

// API /reset-password/finish
export const finishPasswordReset =
  (keyAndPassword: KeyAndPassword) => (dispatch: Dispatch) => {
    accountApi
      .finishPasswordReset(keyAndPassword)
      .then((res) =>
        dispatch({
          type: SUCCESS(POST_RESET_PASSWORD_FINISH),
          payload: res.data,
        })
      )
      .catch((error) => {
        dispatch({
          type: FAILURE(POST_RESET_PASSWORD_FINISH),
          payload: error.response.data,
        });
      });
  };

// View Profile (PersonalSetting component) --> Change Email --> API /account/reset-email/init
export const requestEmailReset =
  (emailResetRequest: EmailReset) => (dispatch: Dispatch) => {
    accountApi
      .requestEmailReset(emailResetRequest)
      .then((res) =>
        dispatch({
          type: SUCCESS(POST_RESET_EMAIL_REQUEST),
          payload: res.data,
        })
      )
      .catch((error) => {
        dispatch({
          type: FAILURE(POST_RESET_EMAIL_REQUEST),
          payload: error.response.data,
        });
      });
  };

/* Flow :
  1) View Profile (PersonalSetting component) --> Change Email --> email sent to user
  2) link on email --> Change Email page --> on page mount it calls API /account/reset-email/finish
  */
export const finishEmailReset = (key: string) => (dispatch: Dispatch) => {
  accountApi
    .finishEmailReset(key)
    .then((res) =>
      dispatch({
        type: SUCCESS(POST_RESET_EMAIL_FINISH),
        payload: res.data,
      })
    )
    .catch((error) => {
      dispatch({
        type: FAILURE(POST_RESET_EMAIL_FINISH),
        payload: error.response.data,
      });
    });
};

export const cleanResponses = () => ({
  type: CLEAN_RESPONSES,
});
