import { Dispatch } from "redux";
import { CapabilitiesApi } from "../../../generated/axios";
import { apiConfig } from "../../api";
import { FAILURE, REQUEST, SUCCESS } from "../../lib/asyncActionHelper";
import { LOAD_CAPABILITIES } from "./const";

const api = new CapabilitiesApi(apiConfig());

export const getCapabilities = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: REQUEST(LOAD_CAPABILITIES),
    });
    const { data: payload } = await api.getUserCapabilities();
    dispatch({
      type: SUCCESS(LOAD_CAPABILITIES),
      payload,
    });
  } catch (error) {
    console.error({ error });
    dispatch({
      type: FAILURE(LOAD_CAPABILITIES),
      error,
    });
  }
};
