import { Icon, Input, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import { useAppDispatch } from "../../shared/store/hooks";
import { Permission } from "../../generated/axios";
import { messages } from "../../shared/lib/locales/definedMessages";
import { updateItemVersion } from "../../shared/store/item/actions";
import { ExtendedItem } from "../../shared/store/project/types";

interface IProps {
  intl: InjectedIntl;
  item: ExtendedItem;
}

const Version: React.FC<IProps> = ({ intl, item }) => {
  const id = item.id ?? 0; // Item always has an "id"
  const initialVersion = item.version ?? "";
  const updating = item.updating;
  const [editMode, setEditMode] = useState(false);
  const [version, setVersion] = useState(initialVersion);
  const dispatch = useAppDispatch();
  const updateVersion = useCallback(() => {
    setEditMode(false);
    if (version !== initialVersion) {
      dispatch(updateItemVersion(id, version));
    }
  }, [dispatch, id, version, initialVersion, setEditMode]);
  const undoOnEsc = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Escape") {
        setVersion(initialVersion);
        setEditMode(false);
      }
    },
    [initialVersion, setVersion, setEditMode]
  );
  useEffect(() => {
    if (!updating) setVersion(initialVersion);
  }, [updating, setVersion, initialVersion]);

  if (item.permission === Permission.READ) return <div>{version}</div>;
  return editMode ? (
    <Input
      key={`p${initialVersion}`}
      placeholder={intl.formatMessage(messages.versionPlaceholder)}
      value={version}
      onChange={(e) => setVersion(e.target.value)}
      onBlur={updateVersion}
      onPressEnter={updateVersion}
      autoFocus={true}
      onKeyDown={undoOnEsc}
      maxLength={100}
    />
  ) : (
    <div onClick={() => !updating && setEditMode(true)}>
      {updating ? (
        <Spin size="small" />
      ) : (
        <Icon type="edit" className="edit-icon" />
      )}
      &nbsp;{version}
    </div>
  );
};

export default injectIntl(Version);
