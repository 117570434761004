import { Button, Col, Form, Input, Row } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import _ from "lodash";
import React, { SyntheticEvent } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Problem, ResendEmailRequest } from "../../generated/axios";
import { messages } from "../../shared/lib/locales/definedMessages";
import { resendConfirmationMail } from "../../shared/store/account/actions";
import { IStore } from "../../shared/store/types";
import { PAGE_STATE } from "./consts";
import Layout from "./Layout";
import "./layout.scss";

const SideContent = React.memo(() => (
  <>
    <span className="publicPagesLayout__action">
      <FormattedMessage id="resend" defaultMessage="Resend" />
    </span>{" "}
    <FormattedMessage id="instructions" defaultMessage="instructions" />
  </>
));

const DoneSuccessMessage = React.memo(() => (
  <Row>
    <Col span={24} className="publicPagesLayout__success">
      <FormattedMessage
        id="resend instructions ok"
        defaultMessage="Resend instructions request processed, an e-mail with the instructions to register was sent to you"
      />
    </Col>
  </Row>
));

const ServerErrorMessage = React.memo(() => (
  <Row>
    <Col span={24} className="publicPagesLayout__failure">
      <FormattedMessage
        id="resend error"
        defaultMessage="Error with resend instructions request, please retry later"
      />
    </Col>
  </Row>
));

interface IState {
  email: string;
  pageState: PAGE_STATE;
}

interface IProps extends FormComponentProps, InjectedIntlProps {
  resendConfirmationMail: (resendEmailRequest: ResendEmailRequest) => void;
  resendConfirmationMailResponse?: Response & Problem;
}

class ResendInstructions extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      email: "",
      pageState: PAGE_STATE.ENTERED_OR_USER_NOT_EXISTS,
    };
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      this.props.resendConfirmationMailResponse !==
      prevProps.resendConfirmationMailResponse
    ) {
      const response = this.props.resendConfirmationMailResponse; // should always be not null

      // if ok, change right part of the page into success part and Login button link
      if (_.isEmpty(response)) {
        this.setState({ pageState: PAGE_STATE.DONE_WITHOUT_ERRORS });
        return;
      } else {
        // if server responds the email is not in the system
        if (response?.status && response.status == 400) {
          if (response.type?.endsWith("/email-not-found")) {
            // add & show red message under the email input
            this.props.form.setFields({
              email: {
                errors: [
                  new Error(
                    this.props.intl.formatMessage(messages.userNotFound)
                  ),
                ],
              },
            });
          } else if (response.type?.endsWith("/account-already-activated")) {
            this.props.form.setFields({
              email: {
                errors: [
                  new Error(
                    this.props.intl.formatMessage(
                      messages.accountAlreadyActivated
                    )
                  ),
                ],
              },
            });
          } else if (response.type?.endsWith("/account-deactivated")) {
            this.props.form.setFields({
              email: {
                errors: [
                  new Error(
                    this.props.intl.formatMessage(messages.accountDeactivated)
                  ),
                ],
              },
            });
          }
          // it should be always false
          if (this.state.pageState !== PAGE_STATE.ENTERED_OR_USER_NOT_EXISTS) {
            this.setState({ pageState: PAGE_STATE.ENTERED_OR_USER_NOT_EXISTS });
          }
          return;
        }
        // for all other server error's types
        else if (response && response.status != 201) {
          this.setState({ pageState: PAGE_STATE.ERROR_SERVER });
          return;
        }
      }
    }
  }

  handleSubmit = () => {
    let errors = true;
    // validate form
    this.props.form.validateFields((err) => {
      if (err) {
        return; // show red message(s) and do nothing else
      }
      errors = false;
    });
    // if inputs are valid
    if (!errors) {
      // compose the request and call API /api/public/account/resend-confirmation-mail
      const resendInstructionsRequest: ResendEmailRequest = {
        email: this.state.email,
      };
      this.props.resendConfirmationMail(resendInstructionsRequest);
    }
  };

  handleChangeMail = (email: SyntheticEvent) => {
    this.setState({ email: email.target["value"] });
  };

  render() {
    const { intl } = this.props;
    const { getFieldDecorator } = this.props.form; // this.props.form is been injected by Form.create()

    return (
      <Layout>
        <Layout.LeftSide>
          <SideContent />
        </Layout.LeftSide>
        <Layout.RightSide>
          <Form>
            {this.state.pageState === PAGE_STATE.ENTERED_OR_USER_NOT_EXISTS && (
              <>
                <Row>
                  <Form.Item
                    label={
                      <FormattedMessage id="email" defaultMessage="Email" />
                    }
                    colon={false}
                  >
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage(
                            messages.inputRequiredEmail
                          ),
                        },
                        {
                          type: "email",
                          message: intl.formatMessage(
                            messages.inputInvalidEmail
                          ),
                        },
                      ],
                    })(
                      <Input
                        size="large"
                        className="publicPagesLayout__input publicPagesLayout__input--mail"
                        placeholder="johansson.erik@luvegroup.com"
                        data-test="resend-instructions-input"
                        onChange={this.handleChangeMail}
                      />
                    )}
                  </Form.Item>
                </Row>
                {/* Attention: button has to remain direct child of "publicPagesLayout__right" due to positioning */}
                <Button
                  type="primary"
                  htmlType="submit"
                  className="publicPagesLayout__button"
                  data-test="resend-instructions-button"
                  onClick={this.handleSubmit}
                >
                  <FormattedMessage
                    id="resend instructions"
                    defaultMessage="Resend instructions"
                  />
                </Button>
              </>
            )}

            {this.state.pageState === PAGE_STATE.DONE_WITHOUT_ERRORS && (
              <DoneSuccessMessage />
            )}

            {this.state.pageState === PAGE_STATE.ERROR_SERVER && (
              <>
                <ServerErrorMessage />

                {/* Attention: button has to remain direct child of "publicPagesLayout__right" due to positioning */}
                <Button
                  type="primary"
                  htmlType="submit"
                  className="publicPagesLayout__button"
                  onClick={() =>
                    this.setState({
                      pageState: PAGE_STATE.ENTERED_OR_USER_NOT_EXISTS,
                    })
                  }
                >
                  <FormattedMessage id="retry" defaultMessage="Retry" />
                </Button>
              </>
            )}
          </Form>
        </Layout.RightSide>
      </Layout>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  resendConfirmationMailResponse: state.account.resendConfirmationMailResponse,
});

const ResendInstructionsWithForm = Form.create<IProps>()(ResendInstructions);

const ResendInstructionsWithIntl = injectIntl<ReactIntl.InjectedIntlProps>(
  ResendInstructionsWithForm
);

export default connect(mapStateToProps, { resendConfirmationMail })(
  ResendInstructionsWithIntl
);
