import { get, isNaN } from "lodash";
import React, { useContext, useEffect, useRef } from "react";
import "./scrollableList.scss";
import { ScrollContext } from "./ScrollableListWrapper";

interface IProps {
  className?: string;
  initialVisibleIndex?: number;
}

const ScrollableList: React.FC<IProps> = ({
  children,
  className = "scrollable-container",
  initialVisibleIndex,
}) => {
  const indexRef = useRef<number | undefined>(undefined);
  indexRef.current = initialVisibleIndex;
  const {
    itemWidth,
    sliderWidth,
    sliderPosition,
    sliderContainerRef,
    sliderRef,
    setDimensions,
  } = useContext(ScrollContext);
  const sw = isNaN(sliderWidth) ? "auto" : sliderWidth;
  const iw = isNaN(itemWidth) ? "auto" : itemWidth;

  const childrenCount = React.Children.count(children);

  useEffect(() => {
    setDimensions?.(childrenCount, indexRef.current);
  }, [childrenCount, setDimensions, indexRef]);

  return (
    <div className={className + "__container"} ref={sliderContainerRef}>
      <div
        className={className + "__slider"}
        ref={sliderRef}
        style={{
          left: sliderPosition,
          width: sw,
        }}
      >
        {React.Children.map(children, (child, index) => (
          <div style={{ width: iw }} key={get(child, "key", index)}>
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollableList;
