import React, { CSSProperties } from "react";

interface Props {
  onClick: (view: string) => void;
  params: string;
  style?: CSSProperties;
}

/**
 * on click, executes onClick function with params
 */
const TextButton: React.FunctionComponent<Props> = ({
  onClick,
  params,
  children,
  ...rest
}) => (
  <div
    onClick={(e) => {
      e.preventDefault();
      onClick(params);
    }}
    {...rest}
  >
    {children}
  </div>
);

export default TextButton;
