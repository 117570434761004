import { Any } from "../../types/Any";

const write = (name: string, value: Any) => {
  const stringValue = typeof value !== "string" ? JSON.stringify(value) : value;
  localStorage.setItem(name, stringValue);
};

const read = (name: string) => {
  const data: string | null = localStorage.getItem(name);
  return data ? JSON.parse(data) : null;
};

const remove = (name: string) => {
  localStorage.removeItem(name);
};

const clear = () => {
  localStorage.clear();
};

export default {
  write,
  read,
  remove,
  clear,
};
