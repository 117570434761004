import { Props } from "../../containers/FieldType";
import { InputProps } from "../inputs";

export const getInputElementProps = ({
  data,
  forceDisabled,
  form,
  onChange,
}: Props): InputProps => {
  return {
    ...data,
    forceDisabled: forceDisabled,
    form,
    onChange,
  };
};
