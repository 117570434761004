import _ from "lodash";
import { BaseField, ResultsTableSettings } from "../../../../generated/axios";
import { ItemResultExt } from "../../../../shared/store/item/types";

export const createPairs = (
  orderAsSettings: NonNullable<ResultsTableSettings["fields"]>
) => {
  const keys = Object.keys(orderAsSettings);
  const fieldsToHide = keys.filter((key) => !_.isNumber(orderAsSettings[key]));
  const unorderedFields = _.omit(orderAsSettings, fieldsToHide);
  const pairs = _.toPairs(unorderedFields);

  return pairs;
};

export const orderedFields = (
  orderAsSettings: NonNullable<ResultsTableSettings["fields"]>
) => {
  const pairs = createPairs(orderAsSettings);
  const ordered = _.reduce(
    pairs,
    (acc, value) => {
      const temp: string[] = [...acc];
      const [label, position] = value;
      temp[Number(position)] = label;
      return temp;
    },
    []
  );
  return ordered.filter((obj) => obj !== undefined);
};

export const orderedHeaders = (
  orderAsSettings: NonNullable<ResultsTableSettings["fields"]>,
  headers: BaseField[]
) => {
  const pairs = createPairs(orderAsSettings);

  const ordered = _.reduce(
    pairs,
    (acc: BaseField[], value) => {
      const temp = [...acc];
      const [label, position] = value;
      const item = headers.find((header) => header.fieldId === label);
      if (item) {
        temp[Number(position)] = item;
      }
      return temp;
    },
    []
  );
  return ordered.filter((obj) => obj !== undefined);
};

export const removeHiddenValuesFromCalculationValues = (
  results?: ItemResultExt[],
  visibleFields?: ResultsTableSettings["fields"]
) => {
  if (results && visibleFields) {
    const keys = Object.keys(visibleFields);
    const fieldsToHide = keys.filter((key) => !_.isNumber(visibleFields[key]));
    const updatedResults = results.map((result) => {
      const cleanCalculationValues = _.omit(
        result.calculationValues,
        fieldsToHide
      );
      return {
        ...result,
        calculationValues: cleanCalculationValues,
      };
    });

    return updatedResults;
  }
  return [];
};

/**
 * Counts the results for each field in an array of ItemResultExt
 *
 * @param {ItemResultExt[]} results
 * @returns {Record<string, number>} number of results for each item field
 */
const countCalculationValues = (
  results: ItemResultExt[]
): Record<string, number> => {
  return results.reduce((acc: Record<string, number>, result) => {
    if (!result.calculationValues) {
      return acc;
    }

    const { calculationValues } = result;

    Object.keys(calculationValues).forEach((key) => {
      acc[key] = acc[key] || 0;
      if (calculationValues[key]) {
        acc[key] = acc[key] + 1;
      }
    });

    return acc;
  }, {});
};

export function filterEmpty(
  baseFields: BaseField[],
  results: ItemResultExt[]
): BaseField[] {
  const calculationValuesCount = countCalculationValues(results);

  return baseFields.filter((baseField) => {
    const { fieldId } = baseField;
    return Boolean(calculationValuesCount[fieldId]) || fieldId === "price";
  });
}
