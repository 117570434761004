import IAction from "../../../types/action";
import { Any } from "../../../types/Any";
import { LOGOUT } from "../../auth/consts";
import { FAILURE, REQUEST, SUCCESS } from "../../lib/asyncActionHelper";
import { SHARE_TYPE } from "../share/types";
import {
  APPLICATION_UPDATED,
  CLOSE_MODAL_FORM,
  CLOSE_MODAL_SETTINGS,
  CLOSE_REPORT_PROBLEM_MODAL,
  CLOSE_SHARING_MODAL,
  OPEN_MODAL_FORM,
  OPEN_MODAL_SETTINGS,
  OPEN_REPORT_PROBLEM_MODAL,
  OPEN_SHARING_MODAL,
  PLATFORM_INFO,
  SEND_PROBLEM,
  SERVICE_UNAVAILABLE,
  TOGGLE_SIDE_PANEL,
} from "./consts";
import { ReportProblemState, UIState } from "./types";

export const initialState: UIState = {
  isSidePanelActive: false,
  sidePanelView: undefined,
  modalForm: {
    visible: false,
    content: "",
    meta: {},
  },
  platformInfo: {},
  modalSettings: {
    visible: false,
    sections: [],
    scope: "user",
    title: "",
  },
  sharingModal: {
    visible: false,
    // content: {},
    shareType: SHARE_TYPE.PROJECT,
    // ,
    // itemId: ''
  },
  reportProblemModal: {
    visible: false,
    sendState: ReportProblemState.INITIAL_STATE,
  },
  serviceUnavailable: false,
};

export default (state = initialState, action: IAction<Any>): UIState => {
  switch (action.type) {
    case TOGGLE_SIDE_PANEL:
      return {
        ...state,
        isSidePanelActive: !state.isSidePanelActive,
        sidePanelView: action.payload ? action.payload : state.sidePanelView,
      };
    case OPEN_MODAL_FORM:
      return {
        ...state,
        modalForm: {
          visible: true,
          content: action.payload,
          meta: action.meta ?? {},
        },
      };
    case CLOSE_MODAL_FORM:
      return {
        ...state,
        modalForm: {
          ...initialState.modalForm,
        },
      };

    case OPEN_MODAL_SETTINGS:
      return {
        ...state,
        modalSettings: {
          visible: true,
          ...action.payload,
        },
      };
    case CLOSE_MODAL_SETTINGS:
      return {
        ...state,
        modalSettings: initialState.modalSettings,
      };

    case OPEN_SHARING_MODAL:
      return {
        ...state,
        sharingModal: {
          visible: true,
          ...action.payload,
        },
      };
    case CLOSE_SHARING_MODAL:
      return {
        ...state,
        sharingModal: {
          ...initialState.sharingModal, // resets visibility to empty array
          visible: false,
        },
      };

    case SUCCESS(SEND_PROBLEM):
      return {
        ...state,
        reportProblemModal: {
          ...state.reportProblemModal,
          sendState: ReportProblemState.SEND_SUCCESS,
        },
      };
    case REQUEST(SEND_PROBLEM):
      return {
        ...state,
        reportProblemModal: {
          ...state.reportProblemModal,
          sendState: ReportProblemState.LOADING,
        },
      };
    case FAILURE(SEND_PROBLEM):
      return {
        ...state,
        reportProblemModal: {
          ...state.reportProblemModal,
          sendState: ReportProblemState.SEND_ERROR,
        },
      };

    case OPEN_REPORT_PROBLEM_MODAL:
      return {
        ...state,
        reportProblemModal: {
          visible: true,
          sendState: ReportProblemState.INITIAL_STATE,
        },
      };
    case CLOSE_REPORT_PROBLEM_MODAL:
      return {
        ...state,
        reportProblemModal: {
          visible: false,
          sendState: ReportProblemState.INITIAL_STATE,
        },
      };
    case SERVICE_UNAVAILABLE:
      return {
        ...state,
        serviceUnavailable: action.payload,
      };

    case LOGOUT:
      return { ...initialState, serviceUnavailable: state.serviceUnavailable };

    case PLATFORM_INFO:
      return { ...state, platformInfo: action.payload };
    case APPLICATION_UPDATED:
      return { ...state, refreshPage: !!action.payload };
    default:
      return state;
  }
};
