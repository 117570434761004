import React from "react";
import { CalculationMessage } from "../../../../generated/axios";
import "./cellNote.scss";
import NoteDisplay from "./NoteDisplay";
import { WhispererIcon } from "./WhispererIcon";

interface IProps {
  isWarning: boolean;
  messages?: CalculationMessage[];
  whisperer?: boolean;
}

const CellNote = React.memo(({ isWarning, messages, whisperer }: IProps) => {
  return (
    <div className={"cell-note"}>
      <NoteDisplay isWarning={isWarning} messages={messages} />
      {whisperer && <WhispererIcon />}
    </div>
  );
});

export default CellNote;
