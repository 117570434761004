import { Project } from "../../../generated/axios";
import Action from "../../../types/action";
import { Any } from "../../../types/Any";
import { LOGOUT } from "../../auth/consts";
import { FAILURE, SUCCESS } from "../../lib/asyncActionHelper";
import { IMPORT_SHARED_PROJECT, ON_SHARED_PROJECT } from "../share/consts";
import * as types from "./consts";
import { ProjectsState } from "./types";

export const initialState: ProjectsState = {
  list: [],
};

export default (state = initialState, action: Action<Any>): ProjectsState => {
  switch (action.type) {
    case SUCCESS(types.GET_PROJECTS):
      return { ...state, list: action.payload };
    case FAILURE(types.GET_PROJECTS):
      return { ...state, error: action.payload };
    case SUCCESS(IMPORT_SHARED_PROJECT):
      // add imported project to my project list
      return {
        ...state,
        list: state.list.concat([action.payload]), // [...state.list, action.payload]
      };
    case ON_SHARED_PROJECT:
      return {
        ...state,
        list: state.list.reduce((acc: Project[], curr: Project) => {
          return curr.id === action.payload
            ? [...acc, { ...curr, shared: true }]
            : [...acc, curr];
        }, []),
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
