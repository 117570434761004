import classNames from "classnames";
import React from "react";

import "./textIcon.scss";

interface Props {
  text: string;
  visualClassName: string;
}

const TextIcon = (props: Props) => (
  <div
    className={classNames({
      textIcon: true,
      [props.visualClassName]: !!props.visualClassName,
    })}
  >
    {props.text}
  </div>
);

export default TextIcon;
