import { Col, Empty, Row } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { TechnicalDocumentation } from "../../../../generated/axios";
import IconLink from "../IconLink";
import "./AdditionalDetails.scss";

export interface Props {
  additionalDetails: TechnicalDocumentation[];
}

interface DocumentProps {
  title: string;
  list: TechnicalDocumentation[];
}

const EmptyMessage = React.memo(() => (
  <Empty
    className="margin-top--20"
    description={
      <FormattedMessage
        id="no doc available"
        defaultMessage="No documents available"
      />
    }
  />
));

export default (props: Props) => {
  const { additionalDetails } = props;

  if (additionalDetails.length === 0) return <EmptyMessage />;

  // loop over the items and remove the ones with no url (bug  1203)
  const groupedDocumentsWithUrl = additionalDetails.filter(
    (y) => !!y.url // y.url !== "" && y.url !== undefined
  );

  // group by attribute "documentCategory"
  const groupedDocuments = groupedDocumentsWithUrl.reduce((acc, val) => {
    if (!val.documentCategory) return acc;
    if (acc[val.documentCategory]) {
      acc[val.documentCategory] = acc[val.documentCategory].concat([val]);
    } else {
      acc[val.documentCategory] = [val];
    }
    return acc;
  }, {});

  return (
    <Row>
      <Col span={24}>
        <Row className="details__container">
          {Object.keys(groupedDocuments).map((key) => (
            <Document title={key} list={groupedDocuments[key]} key={key} />
          ))}
        </Row>
      </Col>
    </Row>
  );
};

const Document = (props: DocumentProps) => (
  <div className="details__document-wrapper">
    <div className="details__category">{props.title}</div>
    <ul className="details__list">
      {props.list.map((el) => (
        <li key={`${el.name}-${el.documentCategory}`}>
          <Row>
            <Col span={20}>
              <div className="details__name">{el.name}</div>
            </Col>
            <Col span={4}>
              <IconLink
                href={el.url}
                isAbsoluteLink={true}
                icon="download"
                otherProps={{
                  target: "_blank",
                  className: "icon--blue icon--pointer",
                }}
              />
            </Col>
          </Row>
        </li>
      ))}
    </ul>
  </div>
);
