export enum FeatureFlag {
  CPQ = "cpq",
}

export interface InjectedEnv {
  name: string;
  features: FeatureFlag[];
}

export interface InjectedEnvState extends InjectedEnv {}
