import { Icon, Tooltip } from "antd";
import React from "react";
import { CapabilityCheck, capabilityMap } from "../../../capability";

interface IProps {
  className?: string;
  drawingUrl?: string;
  printPdf: (unitId?: string) => void;
  unitId?: string;
}

export default class TableCellTechButtons extends React.Component<IProps> {
  printPdf = () => {
    const { printPdf, unitId } = this.props;
    printPdf(unitId);
  };

  render(): React.ReactNode {
    const { className, drawingUrl } = this.props;

    return (
      <div className={className}>
        <CapabilityCheck showIfHas={capabilityMap.ITEM_EXP_TECH_SPEC}>
          <Tooltip
            placement={"left"}
            title={"Download Technical specification"}
          >
            <Icon
              type="download"
              theme="outlined"
              className="icon--blue icon--pointer"
              onClick={this.printPdf}
            />
          </Tooltip>
        </CapabilityCheck>

        {drawingUrl && (
          <Tooltip placement={"right"} title={"View Drawing"}>
            <a href={drawingUrl} target="_blank" rel="noopener noreferrer">
              <Icon
                type="picture"
                theme="outlined"
                className="icon--blue icon--pointer"
              />
            </a>
          </Tooltip>
        )}
      </div>
    );
  }
}
