import axios from "axios";
import { Dispatch } from "redux";
import { INJECTED_ENV_LOAD } from "./consts";

export const loadInjectedEnv = () => (dispatch: Dispatch) => {
  axios
    .get("/injectedEnv.json")
    .then((response) => {
      const contentType = response.request.getResponseHeader("Content-type");
      if (contentType.includes("application/json")) {
        dispatch({
          type: INJECTED_ENV_LOAD,
          payload: response.data,
        });
      } else {
        console.error(`Missing file injectedEnv.json?`);
      }
    })
    .catch();
};
