import { Modal } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Callback } from "../../../types/Callback";
import "./refreshPageModal.scss";

interface Props {
  visible: boolean;
  resetRefreshPage: Callback;
}

function RefreshPageModal({ visible, resetRefreshPage }: Props) {
  return (
    <Modal
      className="RefreshPageModal"
      title={
        <FormattedMessage
          id="refresh page modal title"
          defaultMessage="Refresh page warning."
        />
      }
      visible={visible}
      onOk={() => {
        resetRefreshPage();
        window.location.reload();
      }}
      onCancel={() => resetRefreshPage()}
    >
      <FormattedMessage
        id="refresh page modal content"
        defaultMessage="You need to refresh the page. Please click the OK button"
      />
    </Modal>
  );
}

export default RefreshPageModal;
