import { Icon } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import "./dropzone.scss";

interface Props {
  onFilesAdded: (files: File[]) => void;
}

interface State {
  highlight: boolean;
}

class Dropzone extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      highlight: false,
    };

    this.fileInputRef = React.createRef();
    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  fileInputRef: React.RefObject<HTMLInputElement>;

  openFileDialog = () => {
    this.fileInputRef.current?.click();
  };

  onFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const array = files ? this.fileListToArray(files) : [];
    this.props.onFilesAdded(array);
  };

  onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.setState({ highlight: true });
  };

  onDragLeave = () => {
    this.setState({ highlight: false });
  };

  onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const array = this.fileListToArray(files);
    this.props.onFilesAdded(array);
    this.setState({ highlight: false });
  };

  fileListToArray = (list: FileList) => {
    const array = [];
    for (let i = 0; i < list.length; i++) {
      const file = list.item(i);
      if (file) array.push(file);
    }
    return array;
  };

  render() {
    return (
      <div
        className={`dropzone ${this.state.highlight ? "highlight" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: "default" }}
      >
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple
          accept=".doc,.docx,.pdf,.jpg,.png,.gif"
          onChange={this.onFilesAdded}
        />

        <Icon type={"upload"} className="dropzone-icon" />
        <FormattedMessage
          id="upload file type"
          defaultMessage="Upload one or more file"
        />
      </div>
    );
  }
}

export default Dropzone;
