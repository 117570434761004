import { ReactNode } from "react";
import { SettingsScope } from "../../../config/settings";
import { ConfiguratorSection } from "../../../types/configurator";
import { SHARE_TYPE } from "../share/types";
import { Item } from "../../../generated/axios";

export interface PlatformInfoType {
  [key: string]: string;
}

export interface ModalFormMetaData {
  item?: Item;
  projectId?: number;
  refresh?: boolean;
}

// used by SidePanel
export interface UIBaseState {
  isSidePanelActive: boolean;
  sidePanelView?: ConfiguratorSection;
  modalForm: {
    visible: boolean;
    content: string;
    meta: ModalFormMetaData;
  };
  platformInfo: PlatformInfoType;
  serviceUnavailable: boolean;
}

export interface UIModalSettings {
  visible: boolean;
  sections: string[];
  scope: SettingsScope;
  title: ReactNode;
}

// used by Modal Settings
export interface UISettingsState {
  modalSettings: UIModalSettings;
  refreshPage?: boolean;
}

export interface UISharingModal {
  sharingModal: {
    visible: boolean;
    shareType: SHARE_TYPE;
    // itemId: number
  };
}

export interface UIReportProblemModal {
  reportProblemModal: {
    visible: boolean;
    sendState: ReportProblemState;
  };
}

export enum ReportProblemState {
  INITIAL_STATE,
  LOADING,
  SEND_SUCCESS,
  SEND_ERROR,
}

export interface UIState
  extends UIBaseState,
    UISettingsState,
    UISharingModal,
    UIReportProblemModal {}
