import { Col, Modal, Row, Tabs } from "antd";
import Search from "antd/lib/input/Search";
import { PaginationConfig } from "antd/lib/table";
import { get, isEmpty } from "lodash";
import React from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Project } from "../../generated/axios";
import { Exceptions } from "../../shared/components/exceptions";
import { messages } from "../../shared/lib/locales/definedMessages";
import pagination from "../../shared/lib/pagination";
import { getProjects } from "../../shared/store/projects/actions";
import { projectsSelector } from "../../shared/store/projects/selectors";
import {
  getProjectShares,
  getSharedProjects,
  importSharedProject,
} from "../../shared/store/share/actions";
import { projectsSharedWithMeSelector } from "../../shared/store/share/selectors";
import { IStore } from "../../shared/store/types";
import { openModalWithForm } from "../../shared/store/ui/actions";
import { ModalFormMetaData } from "../../shared/store/ui/types";
import { Callback } from "../../types/Callback";
import IconLink from "../configurator/components/IconLink";
import { columns } from "./helpers";
import "./openProject.scss";
import SummaryTable from "./SummaryTable";

const { TabPane } = Tabs;

interface ReduxProps {
  projects: Project[];
  projectsSharedWithMe: Project[];
}

interface OwnProps {}

interface DispatchProps {
  getProjectShares: (projectId: number) => void;
  getProjects: Callback;
  getSharedProjects: (page?: number, size?: number, sort?: string) => void;
  importSharedProject: (projectId: number) => Promise<void>;
  openModalWithForm: (formType: string, metaData: ModalFormMetaData) => void;
}

interface Props
  extends OwnProps,
    ReduxProps,
    DispatchProps,
    InjectedIntlProps {}

interface IState {
  filter: string; // the filter string in the search button
  confirmationModalVisible: boolean; // to manage item delete confirmation popup visibility
}

class OpenProject extends React.Component<Props, IState> {
  state: IState;
  paginationInfo: PaginationConfig;

  constructor(props: Props) {
    super(props);
    this.state = {
      filter: "",
      confirmationModalVisible: false,
    };
    this.paginationInfo = pagination();
  }

  componentDidMount() {
    const { getProjects, getSharedProjects } = this.props;

    getProjects();
    getSharedProjects();
  }

  onItemClick = (action: string, metaData: ModalFormMetaData) => {
    this.props.openModalWithForm(action, metaData);
  };

  // given the projectId, it calls '/items/{projectId}/shares'
  // then inside the action the is dispatched
  shareProject = (projectId: number) => {
    // open the modal popup "Share Project"
    this.props.getProjectShares(projectId);
  };

  // filter the table with inserted value inside the Input Search
  onSearch = (filter: string) => {
    this.setState({ filter });
  };

  filterData = (filter: string, projects: Project[] = []): Project[] => {
    if (filter !== "") {
      const filterStr = filter.toLowerCase();
      return projects.filter(
        (project) =>
          (project.title ?? "").toLowerCase().indexOf(filterStr) > -1 ||
          (project.owner?.email ?? "").toLowerCase().indexOf(filterStr) > -1 ||
          String(project.id ?? "").indexOf(filterStr) > -1
      );
    }
    return projects;
  };

  // Confirmation Delete modal / pressed "Cancel" --> means abort delete
  onCancelConfirmation = () =>
    this.setState({ confirmationModalVisible: false });

  // Confirmation Delete modal / pressed "Ok" --> means confirm to delete
  onDeleteConfirmation = () =>
    this.setState({ confirmationModalVisible: false });

  render() {
    const { importSharedProject, intl } = this.props;
    const projects = this.filterData(this.state.filter, this.props.projects);
    const projectsSharedWithMe = this.filterData(
      this.state.filter,
      this.props.projectsSharedWithMe
    );

    return (
      <div className="open">
        <Row className="item-selection__title gutter-bug" gutter={16}>
          <Col span={1} offset={1}>
            <IconLink />
          </Col>
          <Col span={22}>
            <div className="open__title">
              <FormattedMessage
                id="open project"
                defaultMessage="Open Project"
              />
            </div>
          </Col>
        </Row>

        <Exceptions.AsRow>
          <Exceptions.Projects />
        </Exceptions.AsRow>

        <Row>
          <Col span={11} offset={1}>
            <Search
              onSearch={this.onSearch}
              onChange={(e) =>
                this.onSearch(get(e, "nativeEvent.target.value", ""))
              }
              placeholder={intl.formatMessage(messages.inputSearchPlaceholder)}
              size="large"
              enterButton={true}
              className="open__search"
              value={this.state.filter}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22} offset={1}>
            <Tabs defaultActiveKey="1" className="open__tabs">
              <TabPane
                key="1"
                tab={
                  <FormattedMessage
                    id="my projects"
                    defaultMessage="My projects"
                  />
                }
              >
                <div className="summary">
                  <SummaryTable
                    columns={columns(
                      this.onItemClick,
                      this.state.confirmationModalVisible,
                      this.shareProject
                    )}
                    dataSource={projects}
                    paginationInfo={this.paginationInfo}
                  />
                </div>
              </TabPane>
              {!isEmpty(this.props.projectsSharedWithMe) && (
                <TabPane
                  key="2"
                  tab={
                    <FormattedMessage
                      id="projects shared with me"
                      defaultMessage="Projects shared with me"
                    />
                  }
                >
                  <div className="summary">
                    <SummaryTable
                      columns={columns(
                        this.onItemClick,
                        this.state.confirmationModalVisible,
                        this.shareProject,
                        importSharedProject
                      )}
                      dataSource={projectsSharedWithMe}
                      paginationInfo={this.paginationInfo}
                    />
                  </div>
                </TabPane>
              )}
            </Tabs>
          </Col>
        </Row>

        <Modal
          title="Confirmation"
          onOk={this.onDeleteConfirmation}
          onCancel={this.onCancelConfirmation}
          centered
          visible={this.state.confirmationModalVisible}
        >
          <FormattedMessage
            id="modal confirm delete project"
            defaultMessage="Are you sure you want to delete the project?"
          />
        </Modal>
      </div>
    );
  }
}

const withIntlComponent = injectIntl<Props>(OpenProject);

const mapStateToProps = (state: IStore): ReduxProps => ({
  projects: projectsSelector(state),
  projectsSharedWithMe: projectsSharedWithMeSelector(state),
});

const mapDispatchToProps = {
  getProjectShares,
  getProjects,
  getSharedProjects,
  importSharedProject,
  openModalWithForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(withIntlComponent);
