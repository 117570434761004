import numeral from "numeral";
import { Any } from "../../types/Any";
import { FormValue } from "../../types/configurator";
import { isEmpty } from "./validate";

const asNum = (n: FormValue) => numeral(n).value();

export const getVal = (v: FormValue): number | boolean => {
  if (typeof v === "boolean") {
    return v;
  }
  const numericMatch = /^[+-]?[0-9.,]+$/;
  if (numericMatch.test(`${v}`)) {
    return asNum(v);
  }
  return `${v}`.length;
};

const rules = {
  requiredIfEmpty: (value: Any, ...params: Any[]): boolean => {
    return !params.every((param) => isEmpty(param));
  },
  message: (value: Any, message: Any): boolean => !message,
  min: (value: Any, compare: Any): boolean => getVal(value) > getVal(compare),
  max: (value: Any, compare: Any): boolean => getVal(value) < getVal(compare),
  gte: (value: Any, compare: Any): boolean => getVal(value) >= getVal(compare),
  lte: (value: Any, compare: Any): boolean => getVal(value) <= getVal(compare),
  range: (value: Any, min: Any, max: Any): boolean =>
    getVal(value) <= getVal(max) && getVal(value) >= getVal(min),
  equal: (value: Any, compare: Any): boolean =>
    getVal(value) === getVal(compare),
  in: (value: Any, ...values: Any[]): boolean => values.indexOf(value) > -1,
  regexp: (value: Any, exp: string): boolean =>
    new RegExp(exp, "gm").test(value),
};

export default {
  ...rules,
  gt: rules.min,
  lt: rules.max,
};
