export const GET_ACCOUNT = "account/GET_ACCOUNT";
export const GET_COUNTRIES = "account/GET_COUNTRIES";
export const GET_LANGUAGES = "account/GET_LANGUAGES";
export const GET_SUBDIVISIONS = "account/GET_SUBDIVISIONS";
export const POST_REGISTRATION_ACCOUNT = "account/POST_REGISTRATION_ACCOUNT";
export const POST_CHANGE_PASSWORD = "account/POST_CHANGE_PASSWORD";
export const POST_RESET_PASSWORD_FINISH = "account/POST_RESET_PASSWORD_FINISH";
export const POST_REQUEST_PSW_RESET = "account/POST_REQUEST_PSW_RESET";

// API /account/resend-confirmation-mail
export const POST_RESEND_INSTRUCTIONS = "account/POST_RESEND_INSTRUCTIONS";

// API /account/reset-email/init
export const POST_RESET_EMAIL_REQUEST = "account/POST_RESET_EMAIL_REQUEST";
// API /account/reset-email/finish
export const POST_RESET_EMAIL_FINISH = "account/POST_RESET_EMAIL_FINISH";

export const CLEAN_RESPONSES = "account/CLEAN_RESPONSES";
