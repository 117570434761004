import { ConfigProvider } from "antd";
import React from "react";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import App from "./App";
import { CapabilitiesProvider } from "./modules/capability";
import { Ribbon } from "./modules/layout/Ribbon";
import { ErrorHandler } from "./shared/components/ErrorHandler";
import getLocaleData from "./shared/lib/locales";
import { loadInjectedEnv } from "./shared/store/injectedEnv/actions";
import { loadTranslations } from "./shared/store/translations/actions";
import { ITranslations } from "./shared/store/translations/types";
import { IStore } from "./shared/store/types";
import { Callback } from "./types/Callback";

// NOTE: application language depends on the chain: data on the server -> redux store -> language providers

interface Props {
  capabilities: string[];
  injectedEnvName: string;
  interfaceLanguage: string;
  serverTranslations: ITranslations;
  loadTranslations: Callback;
  loadInjectedEnv: Callback;
}

interface State {
  locale: string;
  messages: ITranslations;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ant: any;
  _update: number; // this is a hack to force react-intl to update its dictionary
}

class Root extends React.Component<Props, State> {
  state: State = {
    locale: "", // so getDerivedStateFromProps() initialize locale data calling getLocaleData()
    messages: {},
    ant: {},
    _update: 0,
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    const { interfaceLanguage } = props;

    if (interfaceLanguage != state.locale) {
      const { locale, messages, ant } = getLocaleData(interfaceLanguage);
      return { locale, messages, ant, _update: state._update + 1 };
    }
    return null;
  }

  componentDidMount() {
    this.props.loadInjectedEnv();
    this.props.loadTranslations();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const { interfaceLanguage, serverTranslations } = this.props;
    if (interfaceLanguage !== prevProps.interfaceLanguage) {
      this.props.loadTranslations();
    }
    if (serverTranslations !== prevProps.serverTranslations) {
      this.setState({
        ...getLocaleData(interfaceLanguage, serverTranslations),
        _update: prevState._update + 1,
      });
    }
  }

  // NOTE: ERR a questo livello, ErrorHandler deve avere un messaggio che consiglia di ricaricare la pagina, in caso di errore viene mostrata una pagina vuota con il messaggio di default che come UX è troppo povera

  // NOTE: Root.LocaleProvider & Root.IntlProvider need to react at language changes, so they need to listen redux store changes
  // to do this I need to put Provider one level up and connect Root
  render() {
    const { capabilities, injectedEnvName } = this.props;
    return (
      this.state._update && (
        <ErrorHandler>
          <ConfigProvider locale={this.state.ant}>
            <IntlProvider
              locale={this.state.locale}
              messages={this.state.messages}
            >
              <CapabilitiesProvider value={{ capabilities }}>
                <App />
              </CapabilitiesProvider>
            </IntlProvider>
          </ConfigProvider>
          <Ribbon name={injectedEnvName} />
        </ErrorHandler>
      )
    );
  }
}

export default connect(
  (state: IStore) => ({
    capabilities: state.capabilities.list,
    interfaceLanguage: state.settings.interfaceLanguage,
    injectedEnvName: state.injectedEnv.name,
    serverTranslations: state.translations.serverTranslations,
  }),
  {
    loadTranslations,
    loadInjectedEnv,
  }
)(Root);
