import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Problem } from "../../../generated/axios";
import { ItemsState } from "../../store/items/types";
import "./styles.scss";

interface Props {
  error: Problem;
}

const Message = React.memo(() => (
  <FormattedMessage id="exception items" defaultMessage="Error on Items" />
));

const ItemsException = connect((state: { items: ItemsState }) => ({
  error: state.items.error,
}))((props: Props) => {
  const error = props.error;
  return error?.status && error.message ? (
    <div className="exception">
      <Message />
      {`: [${error.status}] ${error.message}`}
    </div>
  ) : null;
});

export default ItemsException;
