export const TOGGLE_SIDE_PANEL = "ui/TOGGLE_SIDE_PANEL";
export const OPEN_MODAL_FORM = "ui/OPEN_MODAL_FORM";
export const CLOSE_MODAL_FORM = "ui/CLOSE_MODAL_FORM";
export const PLATFORM_INFO = "ui/PLATFORM_INFO";
export const APPLICATION_UPDATED = "ui/APPLICATION_UPDATED";
export const SERVICE_UNAVAILABLE = "ui/SERVICE_UNAVAILABLE";

// "Settings" Modal
export const OPEN_MODAL_SETTINGS = "ui/OPEN_MODAL_SETTINGS";
export const CLOSE_MODAL_SETTINGS = "ui/CLOSE_MODAL_SETTINGS";

// "Sharing" Modal
export const OPEN_SHARING_MODAL = "ui/OPEN_SHARING_MODAL";
export const CLOSE_SHARING_MODAL = "ui/CLOSE_SHARING_MODAL";

// "Custom" Modal
export const OPEN_REPORT_PROBLEM_MODAL = "ui/OPEN_REPORT_PROBLEM_MODAL";
export const CLOSE_REPORT_PROBLEM_MODAL = "ui/CLOSE_REPORT_PROBLEM_MODAL";
export const SEND_PROBLEM = "ui/SEND_PROBLEM";
