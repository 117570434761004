import React from "react";
import { FormFieldWithValue } from "../../../shared/store/configurator/types";

interface RequiredProps {
  field: FormFieldWithValue;
}

const FieldVisibility =
  <Props extends RequiredProps>(
    Children: React.ComponentType<Props>
  ): React.ComponentType<Props> =>
  (props: Props) => {
    return props.field.___isVisible ? <Children {...props} /> : null;
  };

export default FieldVisibility;
