import { Icon, Input, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { InputProps } from ".";
import { hasReadPermission } from "../../../../shared/store/item/selectors";
import { FormEvent } from "../../../../types/configurator";
import "./expression.scss";
import ReadonlyField from "./ReadonlyField";

const LocalIcon: React.FunctionComponent = () => (
  <Tooltip title="Calculated value">
    <Icon type="calculator" />
  </Tooltip>
);

const Expression: React.FunctionComponent<InputProps> = ({
  fieldId,
  value,
  label,
  onChange,
}) => {
  const isReadonly = useSelector(hasReadPermission);

  useEffect(() => {
    const event: FormEvent = { field: fieldId, value };
    onChange(event);
    // Don't add onChange as hook dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldId, value]);

  if (isReadonly) return <ReadonlyField text={value} />;
  const id = "configuration-form--" + fieldId;

  return (
    <Input
      id={id}
      placeholder={label}
      disabled={true}
      value={value}
      className="field-expression"
      addonBefore={<LocalIcon />}
      data-test={id}
    />
  );
};

export default Expression;
