import moment from "moment";
import "moment/locale/cs";
import "moment/locale/da";
import "moment/locale/de";
import "moment/locale/el";
import "moment/locale/en-gb";
import "moment/locale/es";
import "moment/locale/et";
import "moment/locale/fi";
import "moment/locale/fr";
import "moment/locale/hu";
import "moment/locale/it";
import "moment/locale/lt";
import "moment/locale/lv";
import "moment/locale/nl";
// Missing import "moment/locale/no";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/ro";
import "moment/locale/ru";
import "moment/locale/sv";
import "moment/locale/zh-cn";
import { InjectedIntl, IntlProvider } from "react-intl";
import { Any } from "../types/Any";

// external intl
export let eIntl: InjectedIntl;

export function initExternalIntl(locale: string, messages: Any) {
  const { intl } = new IntlProvider(
    {
      locale,
      messages,
    },
    {}
  ).getChildContext();
  let mLocale = locale; // locale used in moment
  if (locale == "en") mLocale = "en-gb";
  else if (locale == "zh") mLocale = "zh-cn";
  const usedLocale = moment.locale(mLocale);
  if (usedLocale !== mLocale) {
    console.error(
      `Cannot set moment locale to "${mLocale}", used "${usedLocale}" instead. Missing import "moment/locale/${mLocale}"?`
    );
  }
  eIntl = intl;
}
