export const GET_PROJECT = "project/GET_PROJECT";
export const NEW_PROJECT = "project/NEW_PROJECT";
export const DELETE_PROJECT = "project/DELETE_PROJECT";
export const DUPLICATE_PROJECT = "project/DUPLICATE_PROJECT";
export const UPDATE_PROJECT = "project/UPDATE_PROJECT";
export const GET_PROJECT_DETAILS = "project/GET_PROJECT_DETAILS";
export const GET_ITEMS_TECHNICAL_SPECIFICATIONS_PDF =
  "item/GET_ITEMS_TECHNICAL_SPECIFICATIONS_PDF";
export const QUOTE_ITEMS = "project/QUOTE_ITEMS";
export const UPDATE_ITEM_POSITION = "project/UPDATE_ITEM_POSITION";
export const UPDATE_CUSTOMER_REFERENCE = "project/UPDATE_CUSTOMER_REFERENCE";
export const UPDATE_ITEM_QUANTITY = "project/UPDATE_ITEM_QUANTITY";
export const UPDATE_ITEM_VERSION = "project/UPDATE_ITEM_VERSION";
