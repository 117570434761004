import React from "react";
import { UnitOfMeasure } from "../../../../../generated/axios";

interface IProps {
  defaultMetricSystem: string;
  metricSystem: string;
  units?: Record<string, UnitOfMeasure>;
}

export const UnitMeasure: React.FC<IProps> = ({
  children,
  defaultMetricSystem,
  metricSystem,
  units,
}) => {
  const unit =
    units?.[metricSystem]?.["symbol"] ??
    units?.[defaultMetricSystem]?.["symbol"] ??
    "";
  return (
    <>
      {children}
      {unit ? <span className={"unit"}> [{unit}]</span> : null}
    </>
  );
};
