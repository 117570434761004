export const GET_ITEM_SHARES = "share/GET_ITEM_SHARES";
export const POST_ITEM_SHARES = "share/POST_ITEM_SHARES";

export const GET_PROJECTS_SHARED_WITH_ME = "share/GET_PROJECTS_SHARED_WITH_ME";
export const IMPORT_SHARED_PROJECT = "share/IMPORT_SHARED_PROJECT";
export const IMPORT_SHARED_ITEM = "share/IMPORT_SHARED_ITEM";

export const GET_PROJECT_SHARES = "share/GET_PROJECT_SHARES";
export const POST_PROJECT_SHARES = "share/POST_PROJECT_SHARES";
export const REMOVE_SHARES = "share/REMOVE_SHARES";

export const ON_SHARED_PROJECT = "share/ON_SHARED_PROJECT";
export const ON_SHARED_ITEM = "share/ON_SHARED_ITEM";

// clear store.share.error
export const CLEAR_SHARE_MODAL_ERROR = "share/CLEAR_SHARE_MODAL_ERROR";
