import { get } from "lodash";
import React, { ErrorInfo } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import settingsInfo from "../../../../config/settings";
import {
  BaseField,
  OrientationEnum,
  ResultsTableSettings,
  TechnicalDocumentation,
} from "../../../../generated/axios";
import { EqualHeightsWrapper } from "../../../../shared/components/EqualHeights";
import { ErrorHandler } from "../../../../shared/components/ErrorHandler";
import { ScrollableListWrapper } from "../../../../shared/components/ScrollableList";
import cloneForRating from "../../../../shared/lib/cloneForRating";
import log from "../../../../shared/log";
import {
  clearResultError,
  exportCSV,
  getResultsTable,
  getResultsTableWithMechanicalOption,
  handleLocking,
  selectResult,
} from "../../../../shared/store/item/actionsResults";
import { getItemTechnicalSpecificationPdf } from "../../../../shared/store/item/lib";
import {
  ErrorOnResultType,
  ItemResultExt,
  ItemState,
} from "../../../../shared/store/item/types";
import {
  getResultsTableFieldsConfiguration,
  GetResultTableParams,
  getResultTableSettings,
} from "../../../../shared/store/settings/actions";
import {
  fieldsConfigForItemDetailsSelector,
  fieldsConfigForResultTableSelector,
  interfaceLanguageSelector,
} from "../../../../shared/store/settings/selectors";
import { IStore } from "../../../../shared/store/types";
import {
  closeSettingsModal,
  openSettingsModal,
} from "../../../../shared/store/ui/actions";
import { UIModalSettings } from "../../../../shared/store/ui/types";
import { Callback } from "../../../../types/Callback";
import { CapabilityCheck, capabilityMap } from "../../../capability";
import { ITEM_EXP_CSV_STANDARD_VERSION } from "../../../capability/constants";
import ItemDetails from "../ItemDetails/ItemDetails";
import ExportButton from "../TableResults/ExportButton";
import TableResults from "../TableResults/TableResults";
import TableResultsProvider from "../TableResults/TableResultsProvider";
import TransposedTableResults from "../TableResults/TransposedTableResults";
import ViewPartialResultsButton from "../TableResults/ViewPartialResultsButton";
import "./calculatorResults.scss";
import ErrorDisplayLocalized from "./ErrorDisplayLocalized";
import { NoResults } from "./NoResults";

export interface StateProps {
  detailsConfig: BaseField[];
  item: ItemState;
  lang?: number | string;
  optionsPricesAreVisible: boolean;
  pricesAreVisible: boolean;
  tableConfig: BaseField[];
  resultsTableSettings?: ResultsTableSettings;
}

interface DispatchProps {
  clearResultError: Callback;
  closeSettingsModal: Callback;
  exportCSV: (itemId: number) => void;
  getResultTableSettings: (
    params: GetResultTableParams,
    withScope?: string
  ) => void;
  getResultsTable: (itemId: number) => Promise<boolean>;
  getResultsTableFieldsConfiguration: Callback;
  getResultsTableWithMechanicalOption: (itemId: number) => void;
  handleLocking: (itemId: number, lock: boolean, unitId?: string) => void;
  openSettingsModal: (payload: Partial<UIModalSettings>) => void;
  selectResult: (unitId: string) => void;
}

export interface PropsFromProvider {
  orderAsSettings: NonNullable<ResultsTableSettings["fields"]>;
  updatedResults: ItemResultExt[];
}

interface OwnProps {
  itemId: number;
}

interface Props extends OwnProps, StateProps, DispatchProps {}

interface State {
  scrollToBottom: boolean; // store IF it has to scroll
  unitId?: string;
}

export class CalculatorResults extends React.Component<Props, State> {
  public state: State;

  constructor(props: Props) {
    super(props);
    const unitId = this.getInitialSelectedUnit();
    this.state = {
      unitId,
      scrollToBottom: false,
    };
  }

  componentDidMount() {
    const {
      getResultTableSettings,
      getResultsTable,
      getResultsTableFieldsConfiguration,
      item: { results },
      itemId,
    } = this.props;
    window.document.addEventListener("click", this.clearScrollToBottom);

    if (!results?.results) {
      getResultsTableFieldsConfiguration();
      getResultTableSettings({ itemId }, settingsInfo.scopes.ITEM);
      getResultsTable(itemId).finally();
    }
  }

  componentWillUnmount() {
    window.document.removeEventListener("click", this.clearScrollToBottom);
  }

  clearScrollToBottom = () => {
    if (this.state.scrollToBottom) this.setState({ scrollToBottom: false });
  };

  componentDidCatch(error: Error, info: ErrorInfo) {
    log.error({ error, info });
  }

  componentDidUpdate = () => {
    // if result table doesn't contain prices and notes, trigger calculate with mechanical options
    // first time it will be undefined as per state in constructor, then could be updated by his children clicking on "View" Column button
    if (this.state.unitId === undefined) {
      const unitId = this.getInitialSelectedUnit();
      if (unitId) {
        this.setState({ unitId });
      }
    }
  };

  getInitialSelectedUnit = (): string | undefined => {
    const results = this.props.item.results?.results ?? [];
    const selected: ItemResultExt | undefined = results.find(
      (result: ItemResultExt) => Boolean(result.selected)
    );
    return selected ? selected.unitId : undefined;
  };

  handleLockingClick = (lock: boolean, unitId?: string) => {
    const { itemId } = this.props;
    this.props.handleLocking(itemId, lock, unitId);
  };

  // method invoked by TableResults component which passes the id of the item to render
  handleViewButtonClick = (unitId?: string): void => {
    if (unitId) {
      this.setState({
        unitId: unitId,
        scrollToBottom: true,
      });
      this.props.selectResult(unitId);
    }
  };

  // same signature of "handleViewButtonClick", it could be merged with it to handle column buttons click, another solution can be using a local context ... but this method is passed down for 2 steps only
  handleRatingButtonClick = (unitId?: string): void => {
    if (unitId) {
      const { itemId } = this.props;
      cloneForRating({ unitId, itemId }).finally();
    }
  };

  // if unitId is undefined, print all itemId unitId's pdf
  printPdf = (unitId?: string): void => {
    getItemTechnicalSpecificationPdf(this.props.itemId, unitId, {
      responseType: "blob",
    }).catch(() =>
      log.warn(`Calculator Results: error downloading pdf for unit ${unitId}`)
    );
  };

  // starts the action to download a csv file
  exportCsvHandler = () => {
    this.props.exportCSV(this.props.itemId);
  };

  hasResultsTable = () => Boolean(this.props.item.results?.results?.length);

  isError403 = (errorOnResult: ErrorOnResultType): boolean =>
    errorOnResult && errorOnResult.status === 403;

  openSettingsModal = (sections: string[]) => {
    this.props.openSettingsModal({
      sections,
      scope: settingsInfo.scopes.ITEM,
      title: (
        <FormattedMessage id="item settings" defaultMessage="Item Settings" />
      ),
    });
  };

  viewPartialResultsHandler = () => {
    this.props.getResultsTableWithMechanicalOption(this.props.itemId);
  };

  render(): React.ReactNode {
    const {
      detailsConfig,
      item,
      optionsPricesAreVisible,
      pricesAreVisible,
      resultsTableSettings,
      tableConfig,
    } = this.props;
    const { errorOnResult, memoScrollPosition, results, waitingPrices } = item;
    const { unitId, scrollToBottom } = this.state;

    if (errorOnResult) {
      return (
        <>
          <ErrorDisplayLocalized {...errorOnResult} />
          {this.isError403(errorOnResult) && (
            <div className="calculator-results_error-tools">
              <CapabilityCheck showIfHas={ITEM_EXP_CSV_STANDARD_VERSION}>
                <ExportButton clickHandler={this.exportCsvHandler} />
              </CapabilityCheck>
              <ViewPartialResultsButton
                clickHandler={this.viewPartialResultsHandler}
              />
            </div>
          )}
        </>
      );
    }

    if (!this.hasResultsTable()) {
      return <NoResults />;
    }

    return (
      <ErrorHandler>
        {resultsTableSettings?.orientation !== OrientationEnum.HORIZONTAL && (
          <ScrollableListWrapper memoScrollPosition={memoScrollPosition}>
            <EqualHeightsWrapper>
              <TableResultsProvider
                results={results?.results}
                visibleFields={resultsTableSettings?.fields}
              >
                {({ updatedResults, orderAsSettings }: PropsFromProvider) => (
                  <TableResults
                    exportCsvHandler={this.exportCsvHandler}
                    handleLockingClick={this.handleLockingClick}
                    handleRatingButtonClick={this.handleRatingButtonClick}
                    handleViewButtonClick={this.handleViewButtonClick}
                    initialVisibleIndex={
                      memoScrollPosition?.sliderStep !== undefined
                        ? -memoScrollPosition?.sliderStep
                        : undefined
                    }
                    lastModifiedDate={results?.lastModifiedDate}
                    missingResults={results?.missingResults}
                    openSettingsModal={this.openSettingsModal}
                    orderAsSettings={orderAsSettings}
                    pricesAreVisible={pricesAreVisible}
                    printPdf={this.printPdf}
                    resultFieldConfig={tableConfig}
                    results={updatedResults}
                    selected={unitId}
                    waitingPrices={waitingPrices}
                  />
                )}
              </TableResultsProvider>
            </EqualHeightsWrapper>
          </ScrollableListWrapper>
        )}

        {resultsTableSettings?.orientation === OrientationEnum.HORIZONTAL && (
          <TableResultsProvider
            results={results?.results}
            visibleFields={resultsTableSettings?.fields}
          >
            {({ updatedResults, orderAsSettings }: PropsFromProvider) => (
              <TransposedTableResults
                exportCsvHandler={this.exportCsvHandler}
                handleLockingClick={this.handleLockingClick}
                handleRatingButtonClick={this.handleRatingButtonClick}
                handleViewButtonClick={this.handleViewButtonClick}
                lastModifiedDate={results?.lastModifiedDate}
                missingResults={results?.missingResults}
                openSettingsModal={this.openSettingsModal}
                orderAsSettings={orderAsSettings}
                pricesAreVisible={pricesAreVisible}
                printPdf={this.printPdf}
                resultFieldConfig={tableConfig}
                results={updatedResults}
                selected={unitId}
                waitingPrices={waitingPrices}
              />
            )}
          </TableResultsProvider>
        )}

        {/* initially itemDetails must be hidden --> shown after a click on "View" button inside TableResults */}
        {unitId && (
          <ItemDetails
            additionalResultFieldConfig={this.getAdditionalDetails(unitId)}
            item={item}
            itemId={this.props.itemId}
            itemResult={this.getColumnData(unitId)}
            optionsPricesAreVisible={optionsPricesAreVisible}
            pricesAreVisible={pricesAreVisible}
            resultFieldConfig={detailsConfig}
            scrollToBottom={scrollToBottom}
            unitId={unitId}
          />
        )}
      </ErrorHandler>
    );
  }

  // once clicked on "View" on a column, get only that column to show its details below
  getColumnData = (id?: string): ItemResultExt | undefined => {
    const results = this.props.item.results?.results;
    if (id && results) {
      return results.find(
        (element: ItemResultExt) => element.unitId === `${id}`
      );
    }
    return undefined;
  };

  // once clicked on "View" on a column, get only that column to show its details below
  getAdditionalDetails = (id?: string): TechnicalDocumentation[] => {
    const results = this.props.item.results?.results;
    if (id && results) {
      const result = results.find(
        (element: ItemResultExt) => element.unitId === `${id}`
      );
      return get(result, "technicalDocumentation", []);
    }
    return [];
  };
}

const mapStateToProps = (state: IStore): StateProps => {
  const { item, settings } = state;

  return {
    detailsConfig: fieldsConfigForItemDetailsSelector(state),
    item,
    lang: interfaceLanguageSelector(state),
    optionsPricesAreVisible: state.capabilities.list.includes(
      capabilityMap.USER_SHOW_OPTIONS_PRICES
    ),
    pricesAreVisible: state.capabilities.list.includes(
      capabilityMap.USER_SHOW_PRICES
    ),
    tableConfig: fieldsConfigForResultTableSelector(state),
    resultsTableSettings: settings.resultsTableSettings,
  };
};

const mapDispatchToProps = {
  clearResultError,
  closeSettingsModal,
  exportCSV,
  getResultTableSettings,
  getResultsTable,
  getResultsTableFieldsConfiguration,
  getResultsTableWithMechanicalOption,
  handleLocking,
  openSettingsModal,
  selectResult,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorResults);
