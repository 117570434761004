import { Col } from "antd";
import classNames from "classnames";
import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { ReactSVG } from "react-svg";
import imgOptions from "../../../../assets/images/Options.svg";
import imgResults from "../../../../assets/images/Results.svg";
import imgThermometer from "../../../../assets/images/Thermo.svg";
import settingsInfo from "../../../../config/settings";
import { load } from "../../../../shared/store/configurator/actions";
import { getResultsTable } from "../../../../shared/store/item/actionsResults";
import { openResultsPage } from "../../../../shared/store/item/lib";
import {
  hasItemResults,
  itemIdSelector,
  projectIdSelector,
} from "../../../../shared/store/item/selectors";
import {
  getResultsTableFieldsConfiguration,
  GetResultTableParams,
  getResultTableSettings,
} from "../../../../shared/store/settings/actions";
import { IStore } from "../../../../shared/store/types";
import { toggleSidePanel } from "../../../../shared/store/ui/actions";
import { Callback } from "../../../../types/Callback";
import { ConfiguratorSection } from "../../../../types/configurator";
import TextButton from "../Buttons/TextButton";
import "./Stepper.scss";

interface IStoreProps {
  currentSection: ConfiguratorSection;
  hasResults: boolean;
  itemId: number;
  projectId?: number;
  waitingPrices?: boolean; // TODO: Capire da dove arriva, se serve...
}

interface IDispatchProps {
  getResultTableSettings: (
    params: GetResultTableParams,
    withScope?: string
  ) => Promise<void>;
  getResultsTable: (itemId: number) => Promise<boolean>;
  getResultsTableFieldsConfiguration: Callback;
  toggleSidePanel: (view?: string) => void;
}

interface OwnProps {}

interface Props
  extends OwnProps,
    IStoreProps,
    IDispatchProps,
    RouteComponentProps {}

interface State {
  currentSection: string;
}

class Stepper extends React.Component<Props, State> {
  state: State = {
    currentSection: "",
  };

  static getDerivedStateFromProps(props: Props) {
    const sectionName = props.location.pathname.split("/").pop();
    return { currentSection: sectionName };
  }

  calculationResultsClickHandler = () => {
    const {
      getResultTableSettings,
      getResultsTable,
      getResultsTableFieldsConfiguration,
      hasResults,
      history,
      itemId,
      projectId,
    } = this.props;

    // NOTE: do not load results if item has results.results
    if (hasResults) {
      openResultsPage(history.push, itemId);
    } else {
      getResultsTableFieldsConfiguration();
      getResultTableSettings(
        { itemId, projectId },
        settingsInfo.scopes.ITEM
      ).finally();
      getResultsTable(itemId).finally(() => {
        openResultsPage(history.push, itemId);
      });
    }
  };

  viewDetailsHandler = (view: string) => {
    this.props.toggleSidePanel(view);
  };

  renderAsLink = (
    sectionName: string,
    img: string,
    text: ReactElement,
    className?: string
  ) => (
    <>
      <Link to={`${this.props.match.url}/${sectionName}`} className={className}>
        {this.renderAsText(img, text)}
      </Link>
      {sectionName && sectionName !== "results" && (
        <TextButton
          params={sectionName}
          onClick={this.viewDetailsHandler}
          style={{ fontSize: "16px", paddingLeft: "32px", cursor: "pointer" }}
          data-test="button-view-details"
        >
          <FormattedMessage id="view details" defaultMessage="View Details" />
        </TextButton>
      )}
    </>
  );

  renderAsButton = (img: string, text: ReactElement) => (
    <div
      className="CalculationResultsClickable"
      onClick={() => this.calculationResultsClickHandler()}
    >
      {this.renderAsText(img, text)}
    </div>
  );

  renderAsText = (img: string, text: ReactElement) => (
    <React.Fragment>
      <ReactSVG src={img} className="icon__content" />
      {text}
    </React.Fragment>
  );

  writeClassNames = (sectionName: string) => {
    return classNames({
      stepper__step: true,
      "stepper__step--selected": this.state.currentSection === sectionName,
    });
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (
      nextState.currentSection !== this.state.currentSection ||
      nextProps.waitingPrices !== this.props.waitingPrices ||
      nextProps.match.url != this.props.match.url
    );
  }

  render() {
    return (
      <>
        <Col span={8} className={this.writeClassNames("thermal")}>
          {this.renderAsLink(
            "thermal",
            imgThermometer,
            <FormattedMessage
              id="stepper.thermal_configuration"
              defaultMessage="Thermal Configuration"
            />,
            this.props.waitingPrices ? "disabled-link" : undefined
          )}
        </Col>
        <Col span={8} className={this.writeClassNames("mechanical")}>
          {this.renderAsLink(
            "mechanical",
            imgOptions,
            <FormattedMessage id="stepper.options" defaultMessage="Options" />,
            this.props.waitingPrices ? "disabled-link" : undefined
          )}
        </Col>
        <Col span={8} className={this.writeClassNames("results")}>
          {this.renderAsButton(
            imgResults,
            <FormattedMessage
              id="stepper.calculation_results"
              defaultMessage="Calculation results"
            />
          )}
        </Col>
      </>
    );
  }
}

const Connected = connect(
  (state: IStore) => ({
    currentSection: state.configurator.currentSection,
    hasResults: hasItemResults(state),
    itemId: itemIdSelector(state),
    projectId: projectIdSelector(state),
  }),
  {
    getResultTableSettings,
    getResultsTable,
    getResultsTableFieldsConfiguration,
    load,
    toggleSidePanel,
  }
)(Stepper);

const WithRouter = withRouter(Connected);

export default WithRouter;
