import classNames from "classnames";
import React from "react";

interface IProps {
  bulletColorCssClass: string;
  className?: string;
  isSelected: boolean;
  unitName?: string;
}

const TableCellUnit: React.FC<IProps> = ({
  bulletColorCssClass,
  className,
  isSelected,
  unitName,
}) => {
  return (
    <div
      className={classNames("cell-unit", className, {
        "selected-unit": isSelected,
        bulletColorCssClass,
      })}
    >
      {unitName}
    </div>
  );
};

export default TableCellUnit;
