import React from "react";
import { FormattedMessage } from "react-intl";
import "./loading.scss";

interface Props {
  messageKey?: string;
}

const createLegendMessage = (messageKey?: string) => {
  switch (messageKey) {
    case "calculating results":
      return (
        <FormattedMessage
          id="calculating results"
          defaultMessage="Calculating results..."
        />
      );
    case "generating pdf":
      return (
        <FormattedMessage
          id="generating pdf"
          defaultMessage="Generating pdf..."
        />
      );
    default:
      return <FormattedMessage id="loading" defaultMessage="Loading..." />;
  }
};

const Loading = (props: Props) => (
  <div className="loading">
    <div className="loading-container">
      <div className="spinner"></div>
      <div className="loading-text">
        {/* remember to pass the INTL keyword (shared through the *.json language files) and not the value itself */}
        {createLegendMessage(props.messageKey)}
      </div>
    </div>
  </div>
);

export default Loading;
