import { Col, Input, Row } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { History } from "history";
import * as React from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { DEFAULT_PORTFOLIO } from "../../config/defaults";
import { Portfolio, Project } from "../../generated/axios";
import { Exceptions } from "../../shared/components/exceptions";
import { messages } from "../../shared/lib/locales/definedMessages";
import { updateSelectedPortfolioId } from "../../shared/store/portfolio/actions";
import {
  availablePortfoliosSelector,
  portfolioBySelectedIdSelector,
} from "../../shared/store/portfolio/selectors";
import { createProject } from "../../shared/store/project/actions";
import { IStore } from "../../shared/store/types";
import IconLink from "../configurator/components/IconLink";
import ContinueButton from "./ContinueButton";
import "./newProject.scss";
import PortfolioList from "./PortfolioList";

const HeadingDisplay = React.memo(() => (
  <>
    <Col span={1} offset={1}>
      <IconLink />
    </Col>
    <Col span={22}>
      <div className="new-project__title">
        <FormattedMessage id="new project" defaultMessage="New Project" />
      </div>
    </Col>
  </>
));

const TitleDisplay = React.memo(() => (
  <Col span={10} offset={1}>
    <div className="new-project__affix">
      <FormattedMessage id="project title" defaultMessage="PROJECT TITLE" />
    </div>
  </Col>
));

const EmptyErrorDisplay = React.memo(() => (
  <Col span={22} offset={1}>
    <span className="new-project__error">
      <FormattedMessage
        id="project title empty"
        defaultMessage="Project title can't be empty"
      />
    </span>
  </Col>
));

const DividerDisplay = React.memo(() => (
  <Row>
    <Col span={16} offset={1}>
      <hr className="new-project__line" />
    </Col>
  </Row>
));

const ChangeSettingsLink = React.memo(() => (
  <div className="new-project__change-settings display--none">
    {/* TODO insert url once Settings page will be available */}
    <Link to="">
      <FormattedMessage
        id="view/change settings"
        defaultMessage="View/change settings"
      />
    </Link>
  </div>
));

interface State {
  title?: string;
  errorEmptyName?: boolean;
}

interface DispatchProps {
  createProject: (push: History["push"], project?: Project) => void;
  updateSelectedPortfolioId: (id: number) => void;
}

interface StateProps {
  availablePortfolios: Portfolio[];
  selectedPortfolio?: Portfolio;
  usePortfolioMenu: boolean;
}

type Props = InjectedIntlProps &
  StateProps &
  DispatchProps &
  RouteComponentProps;

class NewProject extends React.Component<Props, State> {
  public state = {
    title: "",
    errorEmptyName: false,
  };

  componentDidMount() {
    // reset selected portfolio to default
    this.props.updateSelectedPortfolioId(DEFAULT_PORTFOLIO);
  }

  // Create a new object filling just the title has to be passed to API call
  // push() is the function needed in actions to do the redirect after new project creation
  createNewProject = () => {
    // check string emptiness before send
    if (this.state.title === "") {
      // this will reload the component with an error message below the input
      this.setState({
        errorEmptyName: true,
      });
    } else {
      const newProject: Project = {
        title: this.state.title,
      };
      this.props.createProject(this.props.history.push, newProject); // now history is injected by WithRouter inside the props
      // remove the error message
      this.setState({
        errorEmptyName: false,
      });
    }
  };

  portfolioSelectionHandler = (e: RadioChangeEvent) => {
    this.props.updateSelectedPortfolioId(e.target.value);
  };

  saveTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      title: e.target.value,
    });
  };

  render() {
    const { intl, availablePortfolios, usePortfolioMenu } = this.props;

    return (
      <div className="new-project">
        <Row className="item-selection__title">
          <HeadingDisplay />
        </Row>

        <Exceptions.AsRow>
          <Exceptions.Project />
        </Exceptions.AsRow>

        <Row>
          <TitleDisplay />
        </Row>
        <Row>
          <Col span={10} offset={1}>
            <Input
              placeholder={intl.formatMessage(
                messages.inputNewProjectPlaceholder
              )}
              value={this.state.title}
              onChange={this.saveTitle}
              onPressEnter={this.createNewProject}
              data-test="input-title"
            />
          </Col>
          {!usePortfolioMenu && (
            <Col span={3} offset={3}>
              {<ContinueButton clickHandler={this.createNewProject} />}
            </Col>
          )}
        </Row>

        {this.state.errorEmptyName && (
          <Row>
            <EmptyErrorDisplay />
          </Row>
        )}

        <Row className="title__subtitle">
          <Col span={10} offset={1} />
          <Col span={6}>
            <ChangeSettingsLink />
          </Col>
        </Row>

        <DividerDisplay />

        {usePortfolioMenu && (
          <>
            <Row>
              <Col span={10} offset={1}>
                <PortfolioList
                  items={availablePortfolios}
                  changeHandler={this.portfolioSelectionHandler}
                />
              </Col>
            </Row>

            <DividerDisplay />

            <Row>
              <Col span={3} offset={14}>
                {
                  <ContinueButton
                    clickHandler={this.createNewProject}
                    selectedPortfolio={this.props.selectedPortfolio}
                  />
                }
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

const withIntl = injectIntl(NewProject);

const Connected = connect(
  (state: IStore) => {
    const availablePortfolios = availablePortfoliosSelector(state);
    const selectedPortfolio = portfolioBySelectedIdSelector(state);

    return {
      availablePortfolios,
      selectedPortfolio,
      usePortfolioMenu: availablePortfolios.length > 1,
    };
  },
  { createProject, updateSelectedPortfolioId }
)(withIntl);

// withRouter is needed to do the redirect to url "/item/new" after new project creation
export default withRouter(Connected);
