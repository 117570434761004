import { Dispatch } from "redux";
import { QuoteApi } from "../../../generated/axios";
import { apiConfig } from "../../api";
import { FAILURE, REQUEST, SUCCESS } from "../../lib/asyncActionHelper";
import { errorMessenger } from "../../lib/messenger";
import { LOAD_QUOTE_INPUT } from "./consts";

const quoteApi = new QuoteApi(apiConfig());

export const loadModalQuote =
  (ids: number[], unitId?: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: REQUEST(LOAD_QUOTE_INPUT),
      payload: ids,
    });
    quoteApi
      .quoteInput(ids, unitId)
      .then((response) => {
        dispatch({
          type: SUCCESS(LOAD_QUOTE_INPUT),
          payload: response.data,
        });
      })
      .catch((e) => {
        if (e.response) {
          const { data, statusText, status } = e.response;
          const message = data?.type && data.title ? data.title : statusText;
          errorMessenger(status, message);
        }
        dispatch({
          type: FAILURE(LOAD_QUOTE_INPUT),
        });
      });
  };
