import React from "react";
import { ResultsTableSettings } from "../../../../generated/axios";
import { ItemResultExt } from "../../../../shared/store/item/types";
import { removeHiddenValuesFromCalculationValues } from "./lib";
import { PropsFromProvider } from "../CalculatorResults/CalculatorResults";

// It gives better performances!!

interface Props {
  results?: ItemResultExt[];
  visibleFields: ResultsTableSettings["fields"];
  children: (params: PropsFromProvider) => React.JSX.Element;
}

const TableResultsProvider = (props: Props) => {
  const [updatedResults, setResults] = React.useState<ItemResultExt[]>([]);
  const { results, visibleFields } = props;

  React.useEffect(() => {
    // remove hidden fields & order them according to settings
    setResults(() => {
      if (!visibleFields) return results ?? [];

      const tempResults: ItemResultExt[] =
        removeHiddenValuesFromCalculationValues(results, visibleFields);
      return tempResults;
    });
  }, [results, visibleFields]);

  return props.children({
    updatedResults,
    orderAsSettings: visibleFields ?? {},
  });
};

export default TableResultsProvider;
