import React from "react";
import { FormattedMessage } from "react-intl";

export const NEW_ITEM = "tooltip-new-item";
export const SAVE_ITEM = "tooltip-save-item";
export const CLEAR_ITEM = "tooltip-clear-item";
export const DUPLICATE_ITEM = "tooltip-duplicate-item";
export const SHARE_ITEM = "tooltip-share-item";
export const DELETE_ITEM = "tooltip-delete-item";
export const ADD_NOTE_ITEM = "tooltip-add-note-item";
export const VIEW_NOTE_ITEM = "tooltip-view-note-item";

const map = {
  [NEW_ITEM]: (
    <FormattedMessage id={NEW_ITEM} defaultMessage="Create a new Item" />
  ),
  [SAVE_ITEM]: (
    <FormattedMessage
      id={SAVE_ITEM}
      defaultMessage="Save item to another project"
    />
  ),
  [CLEAR_ITEM]: (
    <FormattedMessage id={CLEAR_ITEM} defaultMessage="Clear Item" />
  ),
  [DUPLICATE_ITEM]: (
    <FormattedMessage id={DUPLICATE_ITEM} defaultMessage="Duplicate Item" />
  ),
  [SHARE_ITEM]: (
    <FormattedMessage id={SHARE_ITEM} defaultMessage="Share Item" />
  ),
  [DELETE_ITEM]: (
    <FormattedMessage id={DELETE_ITEM} defaultMessage="Delete Item" />
  ),
  [ADD_NOTE_ITEM]: (
    <FormattedMessage id={ADD_NOTE_ITEM} defaultMessage="Add a note" />
  ),
  [VIEW_NOTE_ITEM]: (
    <FormattedMessage id={VIEW_NOTE_ITEM} defaultMessage="View note" />
  ),
};

export const getTooltip = (id: string): React.ReactNode => {
  return map[id] ?? null;
};
