import { Dropdown, Icon } from "antd";
import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { useAppDispatch } from "../../shared/store/hooks";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { Portfolio } from "../../generated/axios";
import { UPDATE_SELECTED_PORTFOLIO_ID } from "../../shared/store/portfolio/consts";
import PortfolioMenu from "./PortfolioMenu";
import PortfolioMenuLink from "./PortfolioMenuLink";
import { BaseProps } from "./types";

interface Props extends RouteComponentProps, BaseProps {
  availablePortfolios: Portfolio[];
  menuOpenerIcon?: React.ReactElement;
}

const AsDropdown: FunctionComponent<Props> = React.memo((props) => {
  const dispatch = useAppDispatch();
  const {
    children,
    availablePortfolios,
    history,
    linkTo,
    menuOpenerIcon,
    usedBy,
    outerClickHandler,
  } = props;

  const clickHandler = ({ key }: { key: string }) => {
    dispatch({
      type: UPDATE_SELECTED_PORTFOLIO_ID,
      payload: parseInt(key, 10),
    });
    const portfolio: Portfolio | undefined = availablePortfolios.find(
      (portfolio) => `${portfolio.id}` === key
    );
    if (portfolio && !portfolio.url) history.push(linkTo);
  };

  const clickHandlerLink = ({ key }: { key: string }) => {
    dispatch({
      type: UPDATE_SELECTED_PORTFOLIO_ID,
      payload: parseInt(key, 10),
    });
  };

  return (
    <div className="section__content" onClick={outerClickHandler}>
      <Dropdown
        overlayClassName={classNames("dropdown-menu-override", {
          usedInSidebar: usedBy === "sidebar",
        })}
        overlay={
          usedBy === "sidebar" ? (
            <PortfolioMenuLink
              availablePortfolios={availablePortfolios}
              linkTo={linkTo}
              clickHandler={clickHandlerLink}
            />
          ) : (
            <PortfolioMenu
              clickHandler={clickHandler}
              availablePortfolios={availablePortfolios}
            />
          )
        }
        placement="bottomRight"
      >
        <div className="section__title has-dropdown-menu">
          {children && <div className="common">{children}</div>}
          {menuOpenerIcon ?? <Icon type="down" />}
        </div>
      </Dropdown>
    </div>
  );
});

export default withRouter(AsDropdown);
