import { Button, Col, Form, Row } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { HOME, USER_PROFILE } from "../../config/paths";
import { logout } from "../../shared/auth";
import { finishEmailReset } from "../../shared/store/account/actions";
import { evalRequestEmailResetFinish } from "../../shared/store/account/selectors";
import { IStore } from "../../shared/store/types";
import { PAGE_STATE } from "./consts";
import Layout from "./Layout";
import "./layout.scss";

// LeftSide
const SideContent = React.memo(() => (
  <span className="publicPagesLayout__action">
    <FormattedMessage id="change e-mail" defaultMessage="Change e-mail" />
  </span>
));

const EnteredOrElaboratingMessage = React.memo(() => (
  <Row>
    <Col span={24} className="publicPagesLayout__success">
      <FormattedMessage
        id="processing request"
        defaultMessage="Processing request, please wait.."
      />
    </Col>
  </Row>
));

const DoneWithoutErrorsMessage = React.memo(() => (
  <>
    <Row>
      <Col span={24} className="publicPagesLayout__success">
        <FormattedMessage
          id="change email ok return"
          defaultMessage="Change email request processed successfully, remember to use the new email to authenticate to Plair"
        />
      </Col>
    </Row>
    {/* Attention: button has to remain direct child of "publicPagesLayout__right" due to positioning */}
    <Link to={HOME}>
      <Button
        type="primary"
        htmlType="submit"
        className="publicPagesLayout__button"
        onClick={() => logout()}
      >
        <FormattedMessage id="login" defaultMessage="login" />
      </Button>
    </Link>
  </>
));

const InvalidRequestMessage = React.memo(() => (
  <>
    <Row>
      <Col span={24} className="publicPagesLayout__failure">
        <FormattedMessage
          id="error no user"
          defaultMessage="Error: No user was found for the provided reset key, or the email is already in use."
        />
        <FormattedMessage
          id="repeat operation"
          defaultMessage="Repeat the operation in View Profile page"
        />
      </Col>
    </Row>

    {/* Attention: button has to remain direct child of "publicPagesLayout__right" due to positioning */}
    <Link to={USER_PROFILE}>
      <Button
        type="primary"
        htmlType="submit"
        className="publicPagesLayout__button"
      >
        <FormattedMessage id="view profile" defaultMessage="View Profile" />
      </Button>
    </Link>
  </>
));

const ServerErrorMessage = React.memo(() => (
  <>
    <Row>
      <Col span={24} className="publicPagesLayout__failure">
        <FormattedMessage
          id="reset email error"
          defaultMessage="Error with reset e-mail request, please retry later"
        />
      </Col>
    </Row>
    <Button
      type="primary"
      htmlType="submit"
      className="publicPagesLayout__button"
    >
      <FormattedMessage id="retry" defaultMessage="Retry" />
    </Button>
  </>
));

interface ChangeEmailProps {
  finishEmailReset: (key: string) => void;
  evaluatedResponse: PAGE_STATE;
}

class ChangeEmail extends React.Component<ChangeEmailProps> {
  confirmRequest = () => {
    // compose the request and call API /account/register
    const { search } = window.location;
    const key = search?.substring(5);
    this.props.finishEmailReset(key);
  };

  componentDidMount() {
    this.confirmRequest();
  }

  render() {
    const { evaluatedResponse } = this.props;

    // TODO: rimuovere <Form></Form>, non ci sono input o submit
    return (
      <Layout>
        <Layout.LeftSide>
          <SideContent />
        </Layout.LeftSide>
        <Layout.RightSide>
          <Form>
            {evaluatedResponse === PAGE_STATE.ENTERED_OR_ELABORATING && (
              <EnteredOrElaboratingMessage />
            )}

            {evaluatedResponse === PAGE_STATE.DONE_WITHOUT_ERRORS && (
              <DoneWithoutErrorsMessage />
            )}

            {evaluatedResponse === PAGE_STATE.INVALID_REQUEST && (
              <InvalidRequestMessage />
            )}

            {evaluatedResponse === PAGE_STATE.ERROR_SERVER && (
              <ServerErrorMessage />
            )}
          </Form>
        </Layout.RightSide>
      </Layout>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  evaluatedResponse: evalRequestEmailResetFinish(state),
});

export default connect(mapStateToProps, { finishEmailReset })(ChangeEmail);
