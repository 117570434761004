import { Modal } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Z_INDEXES } from "../../../../config/defaults";
import Loading from "../../components/Loading";
import "./cancelRequestModalView.scss";
import { Callback } from "../../../../types/Callback";

interface Props {
  cancelRequest: Callback;
  title?: string;
  visible: boolean;
}

function CancelRequestModal({
  cancelRequest,
  title = "",
  visible = false,
}: Props) {
  const onOk = () => cancelRequest();

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={onOk}
      okText="Stop calculation"
      maskClosable={false}
      zIndex={Z_INDEXES.cancelRequestModal}
      mask={true}
      maskStyle={{ backgroundColor: "rgba(0,0,0,0.1)" }}
      wrapClassName="CancelRequestModal"
    >
      <Loading messageKey="calculating results" />
      <p className="warning-message">
        <FormattedMessage
          id="cancel request warning"
          defaultMessage="Press 'Stop calculation' to interrupt the process"
        />
      </p>
    </Modal>
  );
}

export default CancelRequestModal;
