import { Button, Col, Form, Input, Row } from "antd";
import { FormComponentProps } from "antd/lib/form";
import React, { memo } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { PasswordChange } from "../../generated/axios";
import { messages } from "../../shared/lib/locales/definedMessages";

const ChangePassword = memo(() => (
  <Col className="personal-settings__section-title margin-bottom--20" span={24}>
    <FormattedMessage id="change psw" defaultMessage="Change password" />
  </Col>
));

interface OwnProps {
  openChangePswModal: (changePswRequest: PasswordChange) => void;
}

interface Props extends OwnProps, FormComponentProps, InjectedIntlProps {}

class ChangePasswordForm extends React.Component<Props> {
  openChangePswModal = () => {
    this.props.form.validateFields(
      ["new_psw", "confirm_new_psw", "old_psw"],
      (err) => {
        if (err) {
          return;
        } else {
          const passwordChangeRequest: PasswordChange = {
            newPassword: this.props.form.getFieldValue("new_psw"),
            currentPassword: this.props.form.getFieldValue("old_psw"),
          };
          this.props.openChangePswModal(passwordChangeRequest);
        }
      }
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { intl } = this.props;

    return (
      <Form className="configurator-form">
        <Row>
          <Col span={22} offset={1} className="personal-settings__container">
            <Row>
              <ChangePassword />
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label={
                    <FormattedMessage
                      id="old password"
                      defaultMessage="old password"
                    />
                  }
                  className="personal-settings__label"
                >
                  {getFieldDecorator("old_psw", {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage(
                          messages.oldPasswordRequired
                        ),
                      },
                    ],
                  })(
                    <Input
                      type="password"
                      placeholder={intl.formatMessage(
                        messages.oldPasswordPlaceholder
                      )}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <FormattedMessage
                      id="new password"
                      defaultMessage="new password"
                    />
                  }
                  className="personal-settings__label"
                >
                  {getFieldDecorator("new_psw", {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage(
                          messages.newPasswordPlaceholder
                        ),
                      },
                    ],
                  })(
                    <Input
                      type="password"
                      placeholder={intl.formatMessage(
                        messages.newPasswordPlaceholder
                      )}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <FormattedMessage
                      id="confirm new password"
                      defaultMessage="confirm new password"
                    />
                  }
                  className="personal-settings__label"
                >
                  {getFieldDecorator("confirm_new_psw", {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage(
                          messages.confirmPasswordRequired
                        ),
                      },
                      {
                        message: (
                          <FormattedMessage
                            id="password inconsistent"
                            defaultMessage="The two passwords are inconsistent"
                          />
                        ),
                        validator: (rule, value, cb) =>
                          value === this.props.form.getFieldValue("new_psw")
                            ? cb()
                            : cb(true),
                      },
                    ],
                  })(
                    <Input
                      type="password"
                      placeholder={intl.formatMessage(
                        messages.confirmPasswordPlaceholder
                      )}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col
            span={22}
            offset={1}
            className="margin-top--20 margin-bottom--40"
          >
            <Row type="flex" justify="end">
              <Col>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="text-transform--uppercase"
                  onClick={this.openChangePswModal}
                >
                  {/* <Button htmlType="submit" type="primary" className="text-transform--uppercase" onClick={this.showChangePswModal}>  */}
                  <FormattedMessage
                    id="change psw"
                    defaultMessage="Change password"
                  />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WithIntl = injectIntl<Props>(ChangePasswordForm);

export default Form.create<OwnProps & FormComponentProps>()(WithIntl);
