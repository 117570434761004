import { Icon } from "antd";
import React, { FC, memo, ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";
import { Any } from "../../../../types/Any";
import "./iconLink.scss";

/* 
  TODO valutare se inserire anche
  - eventuale grandezza dell'icona --> default attuale del componente 40x40
  - colore del background --> default attuale componente bianco #FFF
  - colore dell'icona --> default attuale componente grigio #595959
*/

interface Props {
  isRouterLink?: boolean;
  href?: string;
  icon?: string;
  fontSize?: number;
  isAbsoluteLink?: boolean;
  otherProps?: Record<string, Any>; // example {target: "_blank"}
}

// if no props are passed to the component, it returns a default "back" icon square returns to home page
const defaultProps: Props = {
  isRouterLink: true,
  href: "/",
  icon: "left",
  fontSize: 14,
  isAbsoluteLink: false,
  otherProps: {},
};

/**
 * Components renders a square with an ANTD icon inside, centered vertically and horizontally to that square, with some properties:
 * @param isRouterLink if the result will be a link <a></a>
 * @param href in case it's a link, then the url to point
 * @param icon the ANTD icon to be represented
 * @param fontSize the height/width px of the inner icon
 * @param isAbsoluteLink if the string has to be treated as fully external link (like www.google.it) and not a relative path
 * @param otherProps right now used only for target: _blank --> it opens the link in another tab
 * P.S. if no props are passed to the component, it returns a default "back" icon square returns to application home page
 */

interface IconDisplayProps {
  icon?: string;
  fontSize?: number;
}

const IconDisplay: FC<IconDisplayProps> = memo(
  ({ icon = "left", fontSize = 14 }) => (
    <Icon
      type={icon}
      className="icon-link__icon"
      style={{ fontSize: fontSize }}
    />
  )
);

const IconLink = (props: Props): ReactElement<ReactNode> => {
  const { icon, fontSize, isAbsoluteLink, otherProps, href, isRouterLink } =
    props;
  const ico = <IconDisplay icon={icon} fontSize={fontSize} />;

  return (
    <div className="icon-link">
      <div className="icon-link__wrapper">
        {isAbsoluteLink ? (
          <a href={href ?? "/"} className="icon-link__link" {...otherProps}>
            {ico}
          </a>
        ) : isRouterLink ? (
          <Link to={href ?? "/"} className="icon-link__link" {...otherProps}>
            {ico}
          </Link>
        ) : (
          ico
        )}
      </div>
    </div>
  );
};

// the following instruction is required by React itself to set an object as default props,
// otherwise in case no props are passed, React will take an empty object instead of the defaultProps object
IconLink.defaultProps = defaultProps;

export default IconLink;
