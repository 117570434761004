/**
 * List of supported browsers
 */
const supportedBrowsers = {
  // declare browsers per OS
  windows: {
    "Internet Explorer": ">10",
    "Microsoft Edge": ">16",
  },
  macos: {
    safari: ">10.1",
  },

  // per platform (mobile, desktop or tablet)
  mobile: {
    safari: ">9",
    "android browser": ">3.10",
  },

  firefox: ">31",
  opera: ">22",
  chrome: ">69",
};

export default supportedBrowsers;
