import { Button, Col, Form, Input, Row, Select } from "antd";
import { FormComponentProps } from "antd/lib/form";
import React, { memo } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { Country, Subdivision, User } from "../../generated/axios";
import { messages } from "../../shared/lib/locales/definedMessages";
import {
  includeSelectFilter,
  startsWithSelectFilter,
} from "../../shared/lib/selectFilterOptions";
import { Option } from "./ViewProfile";

interface OwnProps extends FormComponentProps {
  updateUser: (updatedUser: User) => void;
  handleSelectCountry: (countryId: number) => void;
  user: User;
  countries: Country[];
  subdivisions: Subdivision[];
}

interface Props extends OwnProps, InjectedIntlProps {}

const PersonalData = memo(() => (
  <Col className="personal-settings__section-title margin-bottom--20" span={24}>
    <FormattedMessage id="personal data" defaultMessage="Personal Data" />
  </Col>
));

class PersonalDataForm extends React.Component<Props> {
  // Call to API /account with POST method
  updateUser = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.form.validateFields((err) => {
      if (err) {
        return; // it will then show the error messages defined within the fields themselves
      }
      const updatedUser: User = {
        firstName: this.props.form.getFieldValue("firstName"),
        lastName: this.props.form.getFieldValue("lastName"),
        companyName: this.props.form.getFieldValue("company"),
        countryId: this.props.form.getFieldValue("countryId"),
        subdivisionId: this.props.form.getFieldValue("subdivisionId"),
        address: this.props.form.getFieldValue("address"),
        zipCode: this.props.form.getFieldValue("zipCode"),
      };
      this.props.updateUser(updatedUser);
    });
  };

  handleSelectCountry = (countryId: number) => {
    this.props.handleSelectCountry(countryId);
  };

  componentDidMount() {
    if (this.props.user.countryId) {
      this.handleSelectCountry(this.props.user.countryId);
    }
  }

  render() {
    const { user, countries, subdivisions, intl } = this.props;
    const { getFieldDecorator } = this.props.form;

    if (!user) return null;

    return (
      <Form className="configurator-form">
        <Row>
          <Col span={22} offset={1} className="personal-settings__container">
            <Row>
              <PersonalData />
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label={
                    <FormattedMessage id="firstName" defaultMessage="Name" />
                  }
                  className="personal-settings__label"
                >
                  {getFieldDecorator("firstName", {
                    initialValue: user.firstName,
                  })(
                    <Input
                      type="text"
                      placeholder={intl.formatMessage(
                        messages.firstNamePlaceholder
                      )}
                    />
                  )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <FormattedMessage id="surname" defaultMessage="Surname" />
                  }
                  className="personal-settings__label"
                >
                  {getFieldDecorator("lastName", {
                    initialValue: user.lastName,
                  })(
                    <Input
                      type="text"
                      placeholder={intl.formatMessage(
                        messages.lastNamePlaceholder
                      )}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <FormattedMessage id="company" defaultMessage="Company" />
                  }
                  className="personal-settings__label"
                >
                  {getFieldDecorator("company", {
                    initialValue: user.companyName,
                  })(
                    <Input
                      type="text"
                      placeholder={intl.formatMessage(
                        messages.companyPlaceholder
                      )}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <FormattedMessage id="address" defaultMessage="Address" />
                  }
                  className="personal-settings__label"
                >
                  {getFieldDecorator("address", {
                    initialValue: user.address,
                  })(
                    <Input
                      type="text"
                      placeholder={intl.formatMessage(
                        messages.addressPlaceholder
                      )}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label={
                    <FormattedMessage id="zip code" defaultMessage="Zip Code" />
                  }
                  className="personal-settings__label"
                >
                  {getFieldDecorator("zipCode", {
                    initialValue: user.zipCode,
                  })(
                    <Input
                      type="text"
                      placeholder={intl.formatMessage(messages.zipPlaceholder)}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={
                    <FormattedMessage id="country" defaultMessage="Country" />
                  }
                  className="personal-settings__label"
                >
                  {getFieldDecorator("countryId", {
                    initialValue: user.countryId,
                    rules: [
                      {
                        required: true,
                        message: "Please input your country!",
                      },
                    ],
                  })(
                    <Select
                      style={{ width: "100%" }}
                      size="default"
                      placeholder={intl.formatMessage(
                        messages.selectCountryPlaceholder
                      )}
                      onChange={this.handleSelectCountry}
                      optionFilterProp="label"
                      filterOption={startsWithSelectFilter}
                      showSearch={true}
                    >
                      {countries?.map((option: Country) => (
                        <Option key={option.id} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={<FormattedMessage id="city" defaultMessage="city" />}
                  className="personal-settings__label"
                >
                  {getFieldDecorator("subdivisionId", {
                    initialValue: user.subdivisionId,
                    rules: [
                      {
                        required: true,
                        message: "Please input your city!",
                      },
                    ],
                  })(
                    <Select
                      style={{ width: "100%" }}
                      size="default"
                      placeholder={intl.formatMessage(
                        messages.selectCityPlaceholder
                      )}
                      optionFilterProp="label"
                      filterOption={includeSelectFilter}
                      showSearch={true}
                    >
                      {subdivisions?.map((option: Subdivision) => (
                        <Option key={option.id} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col
            span={22}
            offset={1}
            className="margin-top--20 margin-bottom--40"
          >
            <Row type="flex" justify="end">
              <Col>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="text-transform--uppercase"
                  onClick={this.updateUser}
                >
                  <FormattedMessage
                    id="save personal data"
                    defaultMessage="Save personal data"
                  />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WithIntl = injectIntl<Props>(PersonalDataForm);

export default Form.create<OwnProps>()(WithIntl);
