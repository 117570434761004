import { Button, Tooltip } from "antd";
import { ButtonProps } from "antd/lib/button/button";
import React from "react";
import { FormattedMessage } from "react-intl";
import QuoteItemDefaultProjectWarning from "./QuoteItemDefaultProjectWarning";

interface IProps extends ButtonProps {
  update: boolean;
  isDefaultProject: boolean;
}

const QuoteButton: React.FC<IProps> = ({
  update,
  isDefaultProject,
  ...rest
}) => {
  return (
    <Tooltip
      placement="bottomRight"
      overlayClassName="no-arrow"
      overlayStyle={{ minWidth: "350px" }}
      title={isDefaultProject ? <QuoteItemDefaultProjectWarning /> : null}
    >
      <Button
        type="primary"
        className="height--40"
        disabled={isDefaultProject}
        {...rest}
      >
        {update ? (
          <FormattedMessage id="update quote" defaultMessage="UPDATE QUOTE" />
        ) : (
          <FormattedMessage id="create quote" defaultMessage="CREATE QUOTE" />
        )}
      </Button>
    </Tooltip>
  );
};

export default QuoteButton;
