import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import _ from "lodash";
import React from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { REGISTRATION_EMAIL } from "../../config/paths";
import { Country, RegistrationRequest } from "../../generated/axios";
import { shortenType } from "../../shared/lib/apiErrorMessages";
import {
  apiErrorsMessages,
  messages,
} from "../../shared/lib/locales/definedMessages";
import { startsWithSelectFilter } from "../../shared/lib/selectFilterOptions";
import {
  getCountries,
  registerAccount,
} from "../../shared/store/account/actions";
import { IStore } from "../../shared/store/types";
import { ACCOUNT_DEACTIVATED, EMAIL_USED, PAGE_STATE } from "./consts";
import Layout from "./Layout";
import "./layout.scss";

const Option = Select.Option;

const SideContent = React.memo(() => (
  <>
    <span className="publicPagesLayout__action">
      <FormattedMessage id="registration" defaultMessage="Registration" />
    </span>
    <br />
    <FormattedMessage id="step 1" defaultMessage="STEP 1" />
  </>
));

const DoneSuccessMessage = React.memo(() => (
  <Row>
    <Col span={24} className="publicPagesLayout__success">
      <FormattedMessage
        id="registration process ok"
        defaultMessage="Registration request processed, an e-mail was sent to you"
      />
    </Col>
  </Row>
));

const ServerErrorMessage = React.memo(() => (
  <Row>
    <Col span={24} className="publicPagesLayout__failure">
      <FormattedMessage
        id="registration error"
        defaultMessage="Error with registration, please retry later"
      />
    </Col>
  </Row>
));

interface ReduxStateProps {
  countries: Country[];
  registrationResponse?: Response;
}

interface DispatchProps {
  getCountries: () => Promise<void>;
  registerAccount: (registrationRequest: RegistrationRequest) => void;
}

interface IProps
  extends FormComponentProps,
    ReduxStateProps,
    DispatchProps,
    InjectedIntlProps {}

interface IState {
  pageState: PAGE_STATE;
}

class RegistrationEmail extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      pageState: PAGE_STATE.ENTERED_OR_USER_EXISTS,
    };
  }

  handleSubmit = async (/* e: FormEvent */) => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        console.error({ err });
      } else {
        this.props.registerAccount(values);
      }
    });
  };

  componentDidUpdate(prevProps: IProps) {
    const { registrationResponse: response, intl, form } = this.props;

    if (response !== prevProps.registrationResponse) {
      // const response = registrationResponse; // should always be not null
      // if ok, change right part of the page into success part and Login button link
      if (_.isEmpty(response)) {
        this.setState(() => ({ pageState: PAGE_STATE.DONE_WITHOUT_ERRORS }));
        return;
      } else if (response) {
        const type = response.type ? shortenType(response.type) : "";
        let error = "";
        switch (type) {
          case EMAIL_USED:
            error = intl.formatMessage(messages.userExists);
            break;
          case ACCOUNT_DEACTIVATED:
            error = intl.formatMessage(
              apiErrorsMessages["api.error.message.account-deactivated"]
            );
            break;
        }
        if (error) {
          form.setFields({
            email: {
              value: form.getFieldValue("email"),
              errors: [new Error(error)],
            },
          });
        } else if (response.status !== 201) {
          this.setState(() => ({ pageState: PAGE_STATE.ERROR_SERVER }));
        }
      }
    }
  }

  componentDidMount() {
    this.props.getCountries().finally();
  }

  handleRetry = () => {
    location.reload();
  };

  render() {
    const { intl } = this.props;
    const { getFieldDecorator } = this.props.form; // this.props.form is been injected by Form.create()

    return (
      <Layout>
        <Layout.LeftSide>
          <SideContent />
        </Layout.LeftSide>
        <Layout.RightSide>
          <Form>
            {this.state.pageState == PAGE_STATE.ENTERED_OR_USER_EXISTS && (
              <>
                <Row>
                  <Form.Item
                    label={
                      <FormattedMessage id="email" defaultMessage="Email" />
                    }
                    colon={false}
                  >
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage(
                            messages.inputRequiredEmail
                          ),
                        },
                        {
                          type: "email",
                          message: intl.formatMessage(
                            messages.inputInvalidEmail
                          ),
                        },
                      ],
                    })(
                      <Input
                        size="large"
                        className="publicPagesLayout__input publicPagesLayout__input--mail"
                        placeholder={intl.formatMessage(
                          messages.inputEmailPlaceholder
                        )}
                        data-test="email"
                      />
                    )}
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item
                    label={
                      <FormattedMessage id="country" defaultMessage="Country" />
                    }
                    colon={false}
                  >
                    {getFieldDecorator("countryId", {
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage(
                            messages.selectRequiredCountry
                          ),
                        },
                      ],
                    })(
                      <Select
                        style={{ width: "100%" }}
                        size="large"
                        placeholder={intl.formatMessage(
                          messages.selectCountryPlaceholder
                        )}
                        optionFilterProp="label"
                        filterOption={startsWithSelectFilter}
                        showSearch={true}
                        data-test="country"
                      >
                        {this.props.countries?.map((option) => (
                          <Option
                            key={option.id}
                            value={option.id}
                            data-test={option.name}
                          >
                            {option.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Row>
                {/* Attention: button has to remain direct child of "publicPagesLayout__right" due to positioning */}
                <Row>
                  <Form.Item className="privacy">
                    {getFieldDecorator("privacy", {
                      valuePropName: "checked",
                      initialValue: false,
                      rules: [
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  intl.formatMessage(
                                    messages.inputRequiredPrivacyPolicy
                                  )
                                ),
                        },
                      ],
                    })(
                      <Checkbox>
                        <FormattedMessage
                          id="privacyPolicyClaim"
                          defaultMessage="I have read and understood the {1}"
                          values={{
                            "1": (
                              <a
                                href={intl.formatMessage(
                                  messages.privacyPolicyUrl
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FormattedMessage
                                  id="privacyPolicy"
                                  defaultMessage="Privacy Policy"
                                />
                              </a>
                            ),
                          }}
                        />
                      </Checkbox>
                    )}
                  </Form.Item>
                </Row>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="publicPagesLayout__button"
                  onClick={this.handleSubmit}
                  data-test="submit"
                >
                  <FormattedMessage
                    id="verify email"
                    defaultMessage="Verify email"
                  />
                </Button>
              </>
            )}

            {this.state.pageState == PAGE_STATE.DONE_WITHOUT_ERRORS && (
              <DoneSuccessMessage />
            )}

            {this.state.pageState == PAGE_STATE.ERROR_SERVER && (
              <>
                <ServerErrorMessage />

                {/* Attention: button has to remain direct child of "publicPagesLayout__right" due to positioning */}
                <Link to={REGISTRATION_EMAIL}>
                  {/* TODO VERIFY CSS OK WITH THE <LINK> TAG  */}
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="publicPagesLayout__button"
                    onClick={this.handleRetry}
                  >
                    <FormattedMessage id="retry" defaultMessage="Retry" />
                  </Button>
                </Link>
              </>
            )}
          </Form>
        </Layout.RightSide>
      </Layout>
    );
  }
}

const mapStateToProps = (state: IStore): ReduxStateProps => ({
  countries: state.account.countries,
  registrationResponse: state.account.registrationResponse,
});

const RegistrationEmailWithForm = Form.create<IProps>()(RegistrationEmail);

const RegistrationEmailWithIntl = injectIntl<
  Omit<IProps, keyof FormComponentProps>
>(RegistrationEmailWithForm);

export default connect(mapStateToProps, { getCountries, registerAccount })(
  RegistrationEmailWithIntl
);
