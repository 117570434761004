const isNotIgnored = (ignore?: string): boolean => {
  if (ignore === undefined) return false;
  return ignore.toLowerCase() !== "true";
};

const protocol = (env?: string): string => {
  return env ? `${env}://` : "//";
};

export const HOST = isNotIgnored(process.env.API_IGNORE_HOST)
  ? `${protocol(process.env.API_PROTOCOL)}${process.env.API_HOSTNAME}:${
      process.env.API_PORT
    }`
  : "";
