import { Affix } from "antd";
import React, { useContext } from "react";
import { ScrollContext } from "../../../../shared/components/ScrollableList";

interface IProps {
  children: React.ReactElement;
  className?: string;
  index: number;
  isSticky: boolean;
}

export const StickyDiv: React.FC<IProps> = ({
  children,
  className,
  index,
  isSticky,
}) => {
  const { sliderStep = 0, visibleChildren = 0 } = useContext(ScrollContext);

  const hide = Boolean(
    index < Math.abs(sliderStep) ||
      index >= visibleChildren + Math.abs(sliderStep)
  );

  if (isSticky && !hide)
    return (
      <Affix offsetTop={0} className={className}>
        {children}
      </Affix>
    );
  else return <>{children}</>;
};
