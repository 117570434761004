import { Icon, Tooltip } from "antd";
import React, { memo } from "react";
import { FormattedMessage } from "react-intl";

export const NoPriceIcon = memo(() => (
  <Tooltip
    title={
      <FormattedMessage
        id="price_not_loaded"
        defaultMessage="Price not loaded"
      />
    }
  >
    <Icon type="close-circle" />
  </Tooltip>
));
