import React, { ReactElement, ReactNode } from "react";

interface Props {
  defaultLabel?: string;
  uom: string;
  fieldId: string;
}

const Label = (props: Props): ReactElement<ReactNode> => {
  return (
    <label>
      {props.defaultLabel ?? ""}
      <span>{` ${props.uom}`}</span>
    </label>
  );
};

export default Label;
