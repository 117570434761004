import { message } from "antd";
import axios, { AxiosResponse } from "axios";
import { History } from "history";
import { get, isEmpty } from "lodash";
import { Dispatch } from "redux";
import { durations } from "../../../config/defaults";
import { createIterator } from "../../../config/messageGenerators";
import { ItemApi } from "../../../generated/axios";
import { ConfiguratorSection } from "../../../types/configurator";
import { apiConfig } from "../../api";
import { eIntl } from "../../eIntl";
import {
  createAjaxToken,
  destroyAjaxToken,
  getAxiosCancelToken,
} from "../../lib/ajax-token";
import apiErrorHandler from "../../lib/apiErrorHandler";
import { FAILURE, REQUEST, SUCCESS } from "../../lib/asyncActionHelper";
import { messages } from "../../lib/locales/definedMessages";
import MessageRunner from "../../lib/messageRunner";
import { resourceDownload } from "../../lib/resourceDownload";
import { validateForms } from "../configurator/actions";
import { loadCurrentPriceList } from "../priceList/actions";
import { IStore } from "../types";
import { SET_RESULTS } from "./consts";
import {
  CLEAR_RESULTS_ERROR,
  EXPORT_CSV,
  GET_RESULTS,
  LOCK_UNIT,
  UNLOCK_ALL_UNITS,
  UNLOCK_UNIT,
} from "./constsResults";
import { openResultsPage } from "./lib";

export const itemApi = new ItemApi(apiConfig());

const dispatchGetResults = async (
  dispatch: Dispatch,
  itemId: number,
  force?: boolean,
  withMechanicalOptions?: boolean,
  push?: History["push"]
) => {
  try {
    const ajaxCancelToken = createAjaxToken();
    dispatch({
      type: REQUEST(GET_RESULTS),
      meta: {
        waitingPrices: !withMechanicalOptions,
        ajaxCancelToken,
        showCancelRequestModal: true,
      },
    });
    const { data } = await itemApi
      .calculateResultsTable(itemId, force, withMechanicalOptions, {
        cancelToken: getAxiosCancelToken(ajaxCancelToken),
      })
      .finally(() => destroyAjaxToken(ajaxCancelToken));

    const { results = [] } = data;

    isEmpty(results)
      ? MessageRunner.onSuccess({
          content:
            messages["message.no_result_found_check_thermal_configuration"],
        })
      : MessageRunner.onSuccess({
          content: messages["message.results_loaded"],
        });

    dispatch({
      type: SUCCESS(GET_RESULTS),
      payload: data,
      meta: { waitingPrices: !withMechanicalOptions },
    });

    push && openResultsPage(push, itemId);
    // must return a promise to be caught
    return data;
  } catch (error) {
    // manage "user cancel request"
    if (axios.isCancel(error)) {
      dispatch({ type: FAILURE(GET_RESULTS) });
      MessageRunner.onError({
        content: messages["message.request_canceled_by_the_user"],
      });
    } else {
      push && openResultsPage(push, itemId);
      // manage api error
      apiErrorHandler({
        dispatch,
        actionType: GET_RESULTS,
        hasTimeout: false,
        useMessageRunner: true,
      })(error);
    }
    return false;
  }
};

const dispatchGetResultsWithMechanicalOption = (
  dispatch: Dispatch,
  itemId: number,
  showCancelRequestModal = false
) => {
  const ajaxCancelToken = createAjaxToken();
  dispatch({
    type: REQUEST(GET_RESULTS),
    meta: {
      waitingPrices: true,
      ajaxCancelToken,
      showCancelRequestModal,
    },
  });
  itemApi
    .calculateResultsTable(
      itemId,
      false, // Non serve il forceCalculate
      true, // Richiede i prezzi
      { cancelToken: getAxiosCancelToken(ajaxCancelToken) }
    )
    .then((data) => {
      dispatch({
        type: SUCCESS(GET_RESULTS),
        payload: data.data,
        meta: { waitingPrices: false },
      });
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        MessageRunner.onError({
          content: messages["message.error_loading_results_prices"],
        });
      }
      dispatch({
        type: FAILURE(GET_RESULTS),
      });
    })
    .finally(() => destroyAjaxToken(ajaxCancelToken));
};

/**
 * @param itemId
 */
export const getResultsTableWithMechanicalOption =
  (itemId: number) => (dispatch: Dispatch) => {
    // clear error, I do not want to see when calculating results
    dispatch({ type: CLEAR_RESULTS_ERROR });
    dispatchGetResultsWithMechanicalOption(dispatch, itemId, true);
  };

/**
 * @param itemId
 */
export const getResultsTable =
  (itemId: number) => (dispatch: Dispatch, getState: () => IStore) => {
    const state: IStore = getState();
    const isPending = state.item.calculating;
    if (isPending) {
      return Promise.resolve(false);
    }

    loadCurrentPriceList(dispatch);

    // clear error, I do not want to see when open CalculatorResult page
    dispatch({ type: CLEAR_RESULTS_ERROR });
    dispatch({
      type: REQUEST(GET_RESULTS),
      meta: { waitingPrices: false },
    });
    return itemApi
      .getResultsTable(itemId)
      .then((response) => {
        const waitingPrices = response.data.mechanicalDataChanged;
        dispatch({
          type: SUCCESS(GET_RESULTS),
          payload: response.data,
          meta: { waitingPrices },
        });
        if (waitingPrices)
          dispatchGetResultsWithMechanicalOption(dispatch, itemId);
        return true;
      })
      .catch(
        apiErrorHandler({
          dispatch,
          actionType: GET_RESULTS,
          hasTimeout: false,
          useMessageRunner: true,
        })
      );
  };

/**
 * it uses apiErrorHandler
 */
export const calculate =
  (
    itemId: number,
    push: History["push"],
    force?: boolean,
    withMechanicalOptions?: boolean
  ) =>
  async (dispatch: Dispatch, getState: () => IStore) => {
    const errors = validateForms(dispatch, getState);
    const invalidForm = Object.values(errors).reduce(
      (result, value) => result || value,
      false
    );
    const state: IStore = getState();
    const { currentSection } = state.configurator;

    // if the form has errors, stop operations
    if (invalidForm) {
      let errorMessage;
      if (errors[currentSection]) {
        errorMessage = messages["message.form_not_valid"];
      } else {
        errorMessage =
          currentSection === ConfiguratorSection.MECHANICAL
            ? messages["message.thermal_configuration_form_not_valid"]
            : messages["message.mechanical_configuration_form_not_valid"];
      }
      MessageRunner.onError({ content: errorMessage });
      return;
    }

    // clear error, I do not want to see when open CalculatorResult page
    dispatch({ type: CLEAR_RESULTS_ERROR });

    // form is valid, start calculations
    MessageRunner.start(createIterator(GET_RESULTS));

    const data = await dispatchGetResults(
      dispatch,
      itemId,
      force,
      withMechanicalOptions,
      push
    );
    if (data) {
      // No errors nella prima chiamata
      const hasResults = !!get(data, "results", []).length;
      if (!hasResults) {
        // riattiva i link nello Stepper
        dispatch({
          type: FAILURE(GET_RESULTS),
          payload: undefined,
        });
      } else if (!withMechanicalOptions) {
        // Nel caso la prima chiamata ha avuto successo
        // e non siano stati richiesti i prezzi
        // seconda chiamata in background a getResults
        dispatchGetResultsWithMechanicalOption(dispatch, itemId);
      }
    }
  };

/**
 * getResultFieldConfig non mi permette di dispacciare actions dal suo interno per cui ho un doppio modo di dispacciare CLEAR_RESULTS_ERROR
 * 1 - con questa action
 * 2 - dispatch({type: CLEAR_RESULTS_ERROR});
 */
export const clearResultError = () => ({ type: CLEAR_RESULTS_ERROR });

/**
 * Set the "selected" property on the selected result
 * @param unitId ItemResultExt.unitId value
 */
export const selectResult = (unitId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_RESULTS,
    payload: unitId,
  });
};

/**
 * it uses apiErroHandler
 *
 * exports and saves locally cvs file
 * action + itemApi
 */
export const exportCSV = (itemId: number) => (dispatch: Dispatch) => {
  const options = {
    responseType: "blob",
  };

  message.loading(
    eIntl.formatMessage(messages["message.exporting_csv"]),
    durations.loading * 20 // ten minutes
  );
  dispatch({ type: REQUEST(EXPORT_CSV) });
  itemApi
    .exportItemResults(itemId, options)
    .then((res: AxiosResponse) => {
      resourceDownload(res);
      dispatch({
        type: SUCCESS(EXPORT_CSV),
      });
      message.success(
        eIntl.formatMessage(messages["message.csv_exported_successfully"]),
        durations.success
      );
      return res.data;
    })
    .catch(apiErrorHandler({ dispatch, actionType: EXPORT_CSV }));
};

export const handleLocking =
  (itemId: number, lock: boolean, unitId?: string) => (dispatch: Dispatch) => {
    if (unitId === undefined) {
      if (!lock) {
        dispatch({ type: REQUEST(UNLOCK_ALL_UNITS) });
        message.loading(
          eIntl.formatMessage(messages["message.unlocking_all_units"]),
          durations.loading
        );
        itemApi
          .unlockAllUnits(itemId)
          .then(() => {
            dispatch({ type: SUCCESS(UNLOCK_ALL_UNITS) });
            message.success(
              eIntl.formatMessage(messages["message.all_units_unlocked"]),
              durations.success
            );
          })
          .catch(() => {
            dispatch({ type: FAILURE(UNLOCK_ALL_UNITS) });
            message.error(
              eIntl.formatMessage(
                messages["message.unlocking_all_units_error"]
              ),
              durations.error
            );
          });
      }
    } else if (lock) {
      message.loading(
        eIntl.formatMessage(messages["message.locking_unit"]),
        durations.loading
      );
      dispatch({ type: REQUEST(LOCK_UNIT), payload: unitId });
      itemApi
        .lockUnit(itemId, unitId)
        .then(() => {
          dispatch({ type: SUCCESS(LOCK_UNIT), payload: unitId });
          message.success(
            eIntl.formatMessage(messages["message.unit_locked"]),
            durations.success
          );
        })
        .catch(() => {
          dispatch({ type: FAILURE(LOCK_UNIT), payload: unitId });
          message.error(
            eIntl.formatMessage(messages["message.locking_unit_error"]),
            durations.error
          );
        });
    } else {
      message.loading(
        eIntl.formatMessage(messages["message.unlocking_unit"]),
        durations.loading
      );
      dispatch({ type: REQUEST(UNLOCK_UNIT), payload: unitId });
      itemApi
        .unlockUnit(itemId, unitId)
        .then(() => {
          dispatch({ type: SUCCESS(UNLOCK_UNIT), payload: unitId });
          message.success(
            eIntl.formatMessage(messages["message.unit_unlocked"]),
            durations.success
          );
        })
        .catch(() => {
          dispatch({ type: FAILURE(UNLOCK_UNIT), payload: unitId });
          message.error(
            eIntl.formatMessage(messages["message.unlocking_unit_error"]),
            durations.error
          );
        });
    }
  };
