import { OrientationEnum } from "../../../generated/axios";
import { SUCCESS } from "../../lib/asyncActionHelper";
import { GET_GENERIC_SETTINGS } from "../settings/consts";

import { IAction } from "./actions";
import {
  FIELDLIST_UPDATE,
  LANGUAGES_UPDATE,
  ORIENTATION_UPDATE,
  RESET_SETTINGS_TEMP,
} from "./consts";
import { format, setNullForHiddenFields } from "./lib";

export interface LocalStore {
  languages?: {
    interfaceLanguage: number;
    exportLanguage: number;
    measurementSystem: number;
    defaultPortfolioId: number;
  };
  fields?: { [key: string]: number };
  orientation?: OrientationEnum;
}

export const initialState: LocalStore = {};

export default (
  state: LocalStore = initialState,
  action: IAction = { type: "UNKNOWN" }
): LocalStore => {
  const { type, payload } = action;

  switch (type) {
    // need initial data to have a correct post object
    case SUCCESS(GET_GENERIC_SETTINGS):
      return {
        ...state,
        languages: action.payload,
      };
    case LANGUAGES_UPDATE:
      return {
        ...state,
        languages: { ...state.languages, ...payload },
      };
    case ORIENTATION_UPDATE:
      return {
        ...state,
        orientation: payload,
      };
    case FIELDLIST_UPDATE:
      return {
        ...state,
        fields: format(setNullForHiddenFields(payload)),
      };
    case RESET_SETTINGS_TEMP:
      return {};
    default:
      return state;
  }
};
