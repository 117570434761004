import { Icon } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Callback } from "../../../../types/Callback";

interface IProps {
  clickHandler: Callback;
}

const ViewPartialResultsButton: React.FC<IProps> = ({ clickHandler }) => {
  return (
    <div
      className="table-footer__text as-button"
      onClick={() => clickHandler()}
    >
      <Icon type="eye" theme="outlined" className="icon--blue" />
      <FormattedMessage
        id="view partial results"
        defaultMessage="View partial results"
      />
    </div>
  );
};

export default ViewPartialResultsButton;
