export const GET_CONVERSIONS = "settings/GET_CONVERSIONS";
export const GET_METRIC_SYSTEMS = "settings/GET_METRIC_SYSTEMS";
export const SET_CHOSEN_METRIC_SYSTEM = "settings/SET_CHOSEN_METRIC_SYSTEM";
export const GET_FIELDS_CONFIG = "settings/GET_FIELDS_CONFIG";

export const RESET_RESULT_TABLE_SETTINGS =
  "settings/RESET_RESULT_TABLE_SETTINGS";
export const RESET_TECH_SPEC_SETTINGS = "settings/RESET_TECH_SPEC_SETTINGS";
export const RESET_GENERIC_SETTINGS = "settings/RESET_GENERIC_SETTINGS";

export const RESET_UPDATE_INFO = "settings/RESET_UPDATE_INFO";

export const SETTINGS_CONTEXT = "settings/SETTINGS_CONTEXT";

// item actions
export const GET_GENERIC_SETTINGS = "settings/GET_GENERIC_SETTINGS";
export const GET_RESULT_TABLE_SETTINGS = "settings/GET_RESULT_TABLE_SETTINGS";
export const GET_TECH_SPEC_SETTINGS = "settings/GET_TECH_SPEC_SETTINGS";

export const UPDATE_GENERIC_SETTINGS = "settings/UPDATE_GENERIC_SETTINGS";
export const UPDATE_RESULT_TABLE_SETTINGS =
  "settings/UPDATE_RESULT_TABLE_SETTINGS";
export const UPDATE_TECH_SPEC_SETTINGS = "settings/UPDATE_TECH_SPEC_SETTINGS";

export const SET_BOUNDS_SETTINGS = "settings/SET_BOUNDS_SETTINGS";

export const SET_DEFAULT_BOUNDS_SETTINGS =
  "settings/SET_DEFAULT_BOUNDS_SETTINGS";

export const UNSET_DEFAULT_BOUNDS_SETTINGS =
  "settings/UNSET_DEFAULT_BOUNDS_SETTINGS";
