import { Any } from "../../types/Any";

export const readBlobInBase64 = (blob: File): Promise<Any> => {
  return new Promise((resolve, reject) => {
    // FileReader is an event emitter
    const reader = new FileReader();
    // if events are associated to it
    reader.addEventListener("load", () => resolve(reader.result ?? ""));
    reader.addEventListener("uploading", () => resolve(reader.result ?? ""));
    reader.addEventListener("pending", () => resolve(reader.result ?? ""));
    reader.addEventListener("error", () => reject(reader));
    reader.addEventListener("done", () => resolve(reader.result ?? ""));
    // it starts reading the file, and it emits one or more events, which are caught by the above lines, refers to the then() and catch() below in customRequest
    reader.readAsDataURL(blob);
  });
};
