import axios from "axios";
import { Dispatch } from "redux";
import { ReportProblemApi, UserProblem } from "../../../generated/axios";
import { apiConfig } from "../../api";
import { FAILURE, REQUEST, SUCCESS } from "../../lib/asyncActionHelper";
import { SHARE_TYPE } from "../share/types";
import {
  APPLICATION_UPDATED,
  CLOSE_MODAL_FORM,
  CLOSE_MODAL_SETTINGS,
  CLOSE_REPORT_PROBLEM_MODAL,
  CLOSE_SHARING_MODAL,
  OPEN_MODAL_FORM,
  OPEN_MODAL_SETTINGS,
  OPEN_REPORT_PROBLEM_MODAL,
  OPEN_SHARING_MODAL,
  SEND_PROBLEM,
  SERVICE_UNAVAILABLE,
  TOGGLE_SIDE_PANEL,
} from "./consts";
import { ModalFormMetaData, UIModalSettings } from "./types";

export const reportProblemApi = new ReportProblemApi(apiConfig());

// open/close side panel
export const toggleSidePanel = (view?: string) => ({
  type: TOGGLE_SIDE_PANEL,
  payload: view,
});

// open the modal with forms
export const openModalWithForm = (
  formType: string,
  metaData: ModalFormMetaData
) => {
  return {
    type: OPEN_MODAL_FORM,
    payload: formType,
    meta: metaData,
  };
};

// close the modal with forms
export const closeModalWithForm = () => {
  return {
    type: CLOSE_MODAL_FORM,
  };
};

// open the settings modal
export const openSettingsModal = (
  payload: Omit<UIModalSettings, "visible">
) => {
  return {
    type: OPEN_MODAL_SETTINGS,
    payload,
  };
};

// close the modal settings
export const closeSettingsModal = () => {
  return {
    type: CLOSE_MODAL_SETTINGS,
  };
};

// open the "sharing" modal
export const openSharingModal = (shareType: SHARE_TYPE) => {
  return {
    type: OPEN_SHARING_MODAL,
    payload: { shareType },
  };
};

// close the "sharing" modal
export const closeSharingModal = () => {
  return {
    type: CLOSE_SHARING_MODAL,
  };
};

// open the "Report a problem" Modal
export const openReportProblemModal = () => {
  return {
    type: OPEN_REPORT_PROBLEM_MODAL,
  };
};

// close the "Report a problem" Modal
export const closeReportProblemModal = () => {
  return {
    type: CLOSE_REPORT_PROBLEM_MODAL,
  };
};

export const resetRefreshPage = () => ({
  type: APPLICATION_UPDATED,
  payload: false,
});

export const serviceUnavailable = (unavailable: boolean) => ({
  type: SERVICE_UNAVAILABLE,
  payload: unavailable,
});

export const checkServiceAvailability = () => () => {
  axios
    .get("/management/health")
    .then((response) => {
      if (response.data?.status === "UP") {
        window.location.reload();
      }
    })
    .catch();
};

export const reportProblem =
  (userProblem: UserProblem) => (dispatch: Dispatch) => {
    dispatch({
      type: REQUEST(SEND_PROBLEM),
    });
    reportProblemApi
      .reportProblem(userProblem)
      .then((res) => {
        dispatch({
          type: SUCCESS(SEND_PROBLEM),
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(SEND_PROBLEM),
          payload: err,
        });
      });
  };
