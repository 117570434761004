import { Input, Modal } from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import React, { useState } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
  UserProblem,
  UserProblemAttachment,
} from "../../../../generated/axios";
import { messages } from "../../../../shared/lib/locales/definedMessages";
import {
  closeReportProblemModal,
  reportProblem,
} from "../../../../shared/store/ui/actions";
import {
  ReportProblemState,
  UIReportProblemModal,
} from "../../../../shared/store/ui/types";
import { Callback } from "../../../../types/Callback";
import "./reportProblemModal.scss";
import Upload from "./Upload";

export interface OwnProps {
  visible: boolean;
  sendState: ReportProblemState;
}

interface OwnState {
  description: string;
  attachments: UserProblemAttachment[];
}

interface DispatchProps {
  closeReportProblemModal: Callback;
  reportProblem: (userProblem: UserProblem) => void;
}

export interface Props
  extends FormComponentProps, // AntD's FormComponentProps necessary with validation
    OwnProps,
    DispatchProps,
    InjectedIntlProps {}

const initialState: OwnState = {
  description: "",
  attachments: Array<UserProblemAttachment>(),
};

const ReportProblemModal = (props: Props) => {
  const [userProblem, setUserProblem] = useState(initialState);

  const handleSubmit = () => {
    let errors = true;
    // validate form
    props.form.validateFields((err) => {
      if (err) {
        console.log("error : ", err);
        return; // show red message(s) and do nothing else
      }
      errors = false;
    });
    // if inputs are valid
    if (!errors) {
      // call API /report-problem and send the userProblem object with the attachment(s)
      props.reportProblem(userProblem);
    }
  };

  const addAttachments = (newAttachments: UserProblemAttachment[]) => {
    if (newAttachments) {
      const problem = userProblem;
      problem.attachments = [...problem.attachments, ...newAttachments];
      setUserProblem(problem);
    }
  };

  /**
   * @param event the event containing the text
   */
  const handleTextAreaChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    const description = event.nativeEvent.target?.["value"] ?? "";
    setUserProblem({ ...userProblem, description });
  };

  const beforeSend: boolean =
    props.sendState === ReportProblemState.INITIAL_STATE ||
    props.sendState === ReportProblemState.LOADING;
  const { intl } = props;
  const { getFieldDecorator } = props.form;

  return (
    <Modal
      className="report-problem"
      visible={props.visible}
      centered={true}
      destroyOnClose={true}
      onOk={
        beforeSend
          ? () => handleSubmit()
          : () => props.closeReportProblemModal()
      }
      onCancel={() => props.closeReportProblemModal()}
      cancelButtonProps={{
        className: "display--none",
      }}
      afterClose={() => setUserProblem(initialState)}
      title={
        <FormattedMessage
          id="report a problem"
          defaultMessage="Report a problem"
        />
      }
      okText={
        beforeSend ? (
          <FormattedMessage id="send" defaultMessage="Send" />
        ) : (
          <FormattedMessage id="ok" defaultMessage="Ok" />
        )
      }
    >
      {beforeSend && (
        <>
          <Form>
            <Form.Item
              colon={false}
              label={
                <span className="report-problem__label">
                  <FormattedMessage
                    id="comment problem"
                    defaultMessage="Type a comment below regarding the problem you've encountered. Maximum lenght 300 words."
                  />
                </span>
              }
            >
              {getFieldDecorator("textArea", {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage(
                      messages.reportProblemTextAreaRequired
                    ),
                  },
                ],
              })(
                <Input.TextArea
                  onChange={handleTextAreaChange}
                  placeholder={props.intl.formatMessage(
                    messages.reportProblemPlaceholder
                  )}
                />
              )}
            </Form.Item>

            <Form.Item
              colon={false}
              label={
                <span className="report-problem__label">
                  <FormattedMessage
                    id="upload files"
                    defaultMessage="Upload one or more file"
                  />
                  :
                </span>
              }
            >
              <Upload addAttachments={addAttachments} />
            </Form.Item>
          </Form>
        </>
      )}

      {props.sendState != ReportProblemState.INITIAL_STATE && (
        <div className="report-problem__send-message">
          {/* loading */}
          {props.sendState === ReportProblemState.LOADING && (
            <div>
              <FormattedMessage id="loading" defaultMessage="loading..." />
            </div>
          )}
          {/* error */}
          {props.sendState === ReportProblemState.SEND_ERROR && (
            <div className="report-problem__send-message--error">
              <FormattedMessage
                id="login error bad request"
                defaultMessage="There's been an error, please try again"
              />
            </div>
          )}
          {/* success */}
          {props.sendState === ReportProblemState.SEND_SUCCESS && (
            <div className="report-problem__send-message--success">
              <FormattedMessage
                id="report sent successfully"
                defaultMessage="Report has been sent successfully"
              />
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

const WithIntl = injectIntl(ReportProblemModal);

const mapStateToProps = (state: {
  ui: UIReportProblemModal;
}): Partial<Props> => {
  return {
    visible: state.ui.reportProblemModal.visible,
    sendState: state.ui.reportProblemModal.sendState,
  };
};

const mapDispatchToProps = {
  closeReportProblemModal,
  reportProblem,
};

const WithIntlAndForm = Form.create()(WithIntl);
const connected = connect(mapStateToProps, mapDispatchToProps)(WithIntlAndForm);

export default connected;
