const handler = (e: KeyboardEvent) => {
  e.preventDefault();
  // e.stopPropagation();
};

// NOTE: document or input?
export const preventDefault = () => {
  document.addEventListener("keyup", handler);
  document.addEventListener("keydown", handler);
  document.addEventListener("keypress", handler);
};

export const restoreDefault = () => {
  document.removeEventListener("keyup", handler);
  document.removeEventListener("keydown", handler);
  document.removeEventListener("keypress", handler);
};
