import React, { ReactElement, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { lastModifiedDateFromNowSelector } from "../../../../shared/store/item/selectors";
import { IStore } from "../../../../shared/store/types";

interface Props {
  dateFromNow: string;
}

const LastModified = React.memo(
  (props: Props): ReactElement<ReactNode> => (
    <span className="saved-at">
      <FormattedMessage id="saved at" defaultMessage="saved at" />
      {` ${props.dateFromNow}`}
    </span>
  )
);

const mapStateToProps = (state: IStore): Props => ({
  dateFromNow: lastModifiedDateFromNowSelector(state),
});

export default connect(mapStateToProps)(LastModified);
