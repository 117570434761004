export const GET_ITEM = "item/GET_ITEM";
export const GET_ITEM_PROJECT = "item/GET_ITEM_PROJECT";
export const NEW_ITEM = "item/NEW_ITEM";
export const SET_ITEM = "item/SET_ITEM";
export const CLEAR_ITEM = "item/CLEAR_ITEM";
export const DELETE_ITEM = "item/DELETE_ITEM";
export const DUPLICATE_ITEM = "item/DUPLICATE_ITEM";
export const SAVE_ITEM = "item/SAVE_ITEM";
// export const TOGGLE_DUTY_MODE = "item/TOGGLE_DUTY_MODE";
export const SET_RESULTS = "item/SET_RESULTS";
export const MEMO_SCROLL_POSITION = "configurator/MEMO_SCROLL_POSITION";

export const GET_TECHNICAL_SPECIFICATION_CONFIG =
  "item/GET_TECHNICAL_SPECIFICATION_CONFIG";
export const GET_TECHNICAL_SPECIFICATION_CONFIG_PDF =
  "item/GET_TECHNICAL_SPECIFICATION_CONFIG_PDF";
export const SET_TECHNICAL_SPECIFICATION_CONFIG =
  "item/SET_TECHNICAL_SPECIFICATION_CONFIG";

export const GUIDED_SELLING = "item/GUIDED_SELLING";
