import { createIterator } from "../../config/messageGenerators";
import { itemConfiguratorDynamicPath } from "../../config/paths";
import { ItemApi } from "../../generated/axios";
import { apiConfig } from "../api";
import { messages } from "./locales/definedMessages";
import MessageRunner from "./messageRunner";

interface ParamsType {
  unitId: string;
  itemId: number;
}

const cloneForRating = async (data: ParamsType) => {
  MessageRunner.init(createIterator());
  MessageRunner.start();

  const itemApi = new ItemApi(apiConfig());

  const { unitId, itemId } = data;
  try {
    const response = await itemApi.cloneItemForRating(itemId, unitId);
    window.open?.(itemConfiguratorDynamicPath(response.data.id ?? 0), "_blank");
    MessageRunner.onSuccess({ content: messages["message.operation_success"] });
  } catch (error) {
    MessageRunner.onError({ content: messages["message.operation_failed"] });
  }
};

export default cloneForRating;
