import { message } from "antd";
import { Dispatch } from "redux";
import { durations } from "../../../config/defaults";
import { ApplicationType, ApplicationTypeApi } from "../../../generated/axios";
import Response from "../../../types/response";
import { apiConfig } from "../../api";
import { eIntl } from "../../eIntl";
import apiErrorHandler from "../../lib/apiErrorHandler";
import { SUCCESS } from "../../lib/asyncActionHelper";
import { messages } from "../../lib/locales/definedMessages";
import { IStore } from "../types";
import { GET_AVAILABLE_APPLICATION_TYPES } from "./consts";
import { groupByType } from "./lib";

const applicationTypeApi = new ApplicationTypeApi(apiConfig());

/**
 * it uses apiErrorHandler
 */
export const getApplicationTypes =
  (portfolioIdFromUrl = "") =>
  (dispatch: Dispatch, getState: () => IStore) => {
    message.loading(
      eIntl.formatMessage(messages["message.data_loading"]),
      durations.loading
    );

    // Get portfolioId from url or store
    let portfolioId: number | undefined = Number.parseInt(portfolioIdFromUrl);
    if (!Number.isInteger(portfolioId)) {
      portfolioId = getState().portfolio.selectedPortfolioId;
    }

    applicationTypeApi
      .getAvailableApplicationTypes(portfolioId)
      .then((res: Response<ApplicationType[]>) => {
        message.success(
          eIntl.formatMessage(messages["message.loading_completed"]),
          durations.success
        );

        dispatch({
          type: SUCCESS(GET_AVAILABLE_APPLICATION_TYPES),
          payload: groupByType(res.data),
        });
        // NOTE: valutare se fare il reset del selectedPortfolioId in questo momento
      })
      .catch(
        apiErrorHandler({
          dispatch,
          actionType: GET_AVAILABLE_APPLICATION_TYPES,
        })
      );
  };
