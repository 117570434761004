import { AnyAction, Dispatch } from "redux";
import { LogMessage } from "../../../generated/axios";
import { logRemote } from "../../log/logger";
import { REPORT_MISSING_CONVERSION_FORMULAS } from "./consts";

const cache = {};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const missingConversionFormulasReporter =
  (/*state: MiddlewareAPI*/) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === REPORT_MISSING_CONVERSION_FORMULAS) {
      const { fieldId, chosenMetricSystem, defaultMetricSystem } =
        action.payload;
      const chosenSymbol = action.payload.chosenSystem
        ? action.payload.chosenSystem.symbol
        : undefined;
      const defaultSymbol = action.payload.defaultSystem
        ? action.payload.defaultSystem.symbol
        : undefined;
      const cacheKey = `${fieldId}-${defaultMetricSystem}-${chosenMetricSystem}`;

      if (!cache[cacheKey]) {
        // call api
        const message =
          `REPORT MISSING CONVERSION FORMULAS for fieldId = ${fieldId}` +
          `, defaultMetricSystem = ${defaultMetricSystem} [${defaultSymbol}]` +
          `, chosenMetricSystem = ${chosenMetricSystem} [${chosenSymbol}]`;
        logRemote(LogMessage.LevelEnum.ERROR, message);

        // update cache
        cache[cacheKey] = fieldId;
      }
    }
  };
