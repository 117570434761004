import React from "react";
import cpqLogo from "../../assets/cpq_logo.svg";
import logoPlair from "../../assets/images/Login/plair-logo.svg";
import { CapabilityCheck } from "../capability";
import { USER_SSO } from "../capability/constants";

interface IProps {}

const UserType: React.FC<IProps> = () => {
  return (
    <>
      <br />
      <div className={"headerWrapper__user-type"}>
        <CapabilityCheck showIfHas={USER_SSO}>
          <img
            src={cpqLogo}
            height={16}
            alt={"SSO/CPQ"}
            style={{ verticalAlign: "top" }}
          />
        </CapabilityCheck>
        <CapabilityCheck showIfHasNot={USER_SSO}>
          <div className={"plair-logo-container"}>
            <img
              src={logoPlair}
              width={24}
              height={13}
              alt={"Plair"}
              style={{
                verticalAlign: "top",
              }}
            />
          </div>
        </CapabilityCheck>
      </div>
    </>
  );
};

export default UserType;
