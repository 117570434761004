import { InputNumber } from "antd";
import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { InputProps } from ".";
import { hasReadPermission } from "../../../../shared/store/item/selectors";
import { chosenMetricSystemSelector } from "../../../../shared/store/settings/selectors";
import { IStore } from "../../../../shared/store/types";
import { Any } from "../../../../types/Any";
import { FormEvent } from "../../../../types/configurator";
import "./numeric.scss";
import ReadonlyField from "./ReadonlyField";

function toNumber(value: Any) {
  return value === null || value === "" || value === undefined
    ? NaN
    : Number(value);
}

interface ReduxProps {
  isReadonly: boolean;
  chosenMetricSystem: string;
}

interface Props extends InputProps, ReduxProps {
  forceDisabled?: boolean;
}

interface IState {
  defaultValue?: string | number;
  errorCounter: string;
}

export class NumberInput extends React.Component<Props, IState> {
  state = {
    defaultValue: undefined,
    precision: undefined,
    errorCounter: "0",
  };

  private inputNumberRef = React.createRef<InputNumber>();

  inputNode() {
    const ref = this.inputNumberRef?.current;
    if (ref) {
      // eslint-disable-next-line react/no-find-dom-node
      return (ReactDOM.findDOMNode(ref) as Element).querySelector("input");
    }
    return null;
  }

  selectText = () => {
    const input = this.inputNode();
    input && setTimeout(() => input?.select(), 0);
  };

  onChangeError = () => {
    this.setState(() => ({
      errorCounter: `${Date.now()}`,
    }));
  };

  onChange = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { fieldId, onChange, value } = this.props;
    if (onChange && toNumber(value) !== toNumber(e.target.value)) {
      const formEvent: FormEvent = {
        field: fieldId,
        value: e.target.value,
        onError: this.onChangeError,
      };
      onChange(formEvent);
    }
  };

  static getDerivedStateFromProps(props: Props, state: IState) {
    const { value } = props;
    if (state.defaultValue !== value) {
      return { defaultValue: value };
    } else {
      return null;
    }
  }

  shouldComponentUpdate(newProps: Props, newState: IState) {
    const {
      chosenMetricSystem,
      fieldId,
      forceDisabled,
      isReadonly,
      placeholder,
      precision,
      unitOfMeasures,
    } = this.props;
    return (
      newState !== this.state ||
      newProps.fieldId !== fieldId ||
      newProps.isReadonly !== isReadonly ||
      newProps.placeholder !== placeholder ||
      newProps.precision !== precision ||
      newProps.forceDisabled !== forceDisabled ||
      newProps.chosenMetricSystem !== chosenMetricSystem ||
      newProps.unitOfMeasures?.[chosenMetricSystem]?.step !==
        unitOfMeasures?.[chosenMetricSystem]?.step
    );
  }

  render = (): ReactNode => {
    const {
      chosenMetricSystem,
      forceDisabled,
      fieldId,
      isReadonly,
      placeholder,
      precision,
      unitOfMeasures,
    } = this.props;
    const { defaultValue, errorCounter } = this.state;

    if (isReadonly) return <ReadonlyField text={defaultValue} />;
    const id = "configuration-form--" + fieldId;

    return (
      <InputNumber
        ref={this.inputNumberRef}
        disabled={forceDisabled}
        id={id}
        key={`${defaultValue}_${errorCounter}`}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onBlur={this.onChange}
        onFocus={this.selectText}
        precision={precision}
        data-test={id}
        autoFocus={fieldId === "capacity" && defaultValue === ""}
        step={unitOfMeasures?.[chosenMetricSystem]?.step}
      />
    );
  };
}

const mapStateToProps = (state: IStore): ReduxProps => {
  const isReadonly = hasReadPermission(state);
  return {
    isReadonly,
    chosenMetricSystem: chosenMetricSystemSelector(state),
  };
};

export default connect(mapStateToProps)(NumberInput);
