import {
  HOME,
  ITEM_NEW,
  PROJECT_NEW,
  PROJECT_OPEN,
} from "../../../config/paths";
import settingsInfo from "../../../config/settings";

/**
 * executes the regexp.test method on pathname
 *
 * @param exp
 * @param pathname
 */
const test = (exp: RegExp, pathname: string) => exp.test(pathname);

export const isProjectDetailsPage = (pathname: string) => {
  // const res = ().test(pathname);
  return test(/^\/project\/\d+\/details$/gi, pathname);
};

const isProjectSection = (pathname: string) => {
  // const res = ().test(pathname);
  return test(/^\/project/gi, pathname) || test(/^\/item/gi, pathname);
};

const isInProjectOpenNew = (pathname: string) => {
  return (
    test(/^\/project\/open$/gi, pathname) ||
    test(/^\/project\/new$/gi, pathname)
  );
};

export const isNewItemPage = (pathname: string) => {
  return test(/^\/item\/new/gi, pathname);
};

export const isInConfiguratorSection = (pathname: string) => {
  return test(/^\/configurator/gi, pathname);
};

export function extractItemIdFromPathname(pathname: string) {
  const _isInConfiguratorSection = isInConfiguratorSection(pathname);
  return _isInConfiguratorSection ? pathname.split("/")[2] : "";
}

export const getActiveSection = (pathname: string) => {
  if (isInConfiguratorSection(pathname)) return settingsInfo.scopes.ITEM;
  if (isInProjectOpenNew(pathname)) return settingsInfo.scopes.USER;
  if (isProjectSection(pathname)) return settingsInfo.scopes.PROJECT;
  return settingsInfo.scopes.USER;
};

export const mustResetConfiguratorSection = (pathname: string) => {
  return (
    pathname === HOME ||
    pathname === PROJECT_OPEN ||
    pathname === PROJECT_NEW ||
    pathname === ITEM_NEW ||
    isProjectDetailsPage(pathname)
  );
};

export function getItemIdFromPathname(pathname: string): number | undefined {
  if (!isInConfiguratorSection(pathname)) return undefined;
  const word = "configurator/";
  const length = word.length;
  const start = pathname.indexOf(word) + length;
  const shorten = pathname.substring(start);
  const end = shorten.lastIndexOf("/");

  return ~~shorten.substring(0, end); // ~~ operator convert to number
}

export function getProjectIdFromPathname(pathname: string): number | undefined {
  if (!isProjectDetailsPage(pathname)) return undefined;
  const word = "project/";
  const length = word.length;
  const start = pathname.indexOf(word) + length;
  const shorten = pathname.substring(start);
  const end = shorten.lastIndexOf("/");

  return ~~shorten.substring(0, end); // ~~ operator convert to number
}

export function isPublicRoute(pathname: string): boolean {
  return (
    test(/^\/login$/gi, pathname) ||
    test(/^\/registration-email$/gi, pathname) ||
    test(/^\/password-reset$/gi, pathname) ||
    test(/^\/registration-confirm$/gi, pathname) ||
    test(/^\/resend-instructions$/gi, pathname)
  );
}
