import { WrappedFormUtils } from "antd/lib/form/Form";
import React, { ReactNode } from "react";
import { FormFieldWithValue } from "../../../shared/store/configurator/types";
import { FormEvent } from "../../../types/configurator";
import { INPUT_TYPES } from "../../../types/inputs";
import { getComponent, InputProps } from "../components/inputs";
import { getInputElementProps } from "../components/Section/helpers";

export interface Props {
  forceDisabled?: boolean;
  data: FormFieldWithValue;
  form: WrappedFormUtils;
  onChange: (formEvent: FormEvent) => void;
}

class FieldType extends React.PureComponent<Props> {
  component: INPUT_TYPES;
  children: React.ElementType<InputProps>;

  constructor(props: Props) {
    super(props);
    this.setInputType(props);
  }

  changedInputType = (props: Props): boolean => {
    return props.data.inputType !== this.component;
  };

  setInputType = (props: Props): void => {
    this.component = props.data.inputType as INPUT_TYPES;
    this.children = getComponent(this.component);
  };

  render = (): ReactNode => {
    if (this.changedInputType(this.props)) {
      this.setInputType(this.props);
    }

    const childrenProps = getInputElementProps(this.props);
    const Children = this.children;
    return <Children {...childrenProps} />;
  };
}

export default FieldType;
