import { Icon, Popover } from "antd";
import React from "react";
import { ApplicationType } from "../../generated/axios";

interface ContentProps {
  html: string;
}

const ContentHTML = ({ html }: ContentProps) => {
  return (
    <div className="infoBox__content">
      <p dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export const ApplicationTypeInfoBox = ({
  description,
  name,
}: ApplicationType) => {
  if (!description) {
    return null;
  }
  return (
    <div className="application-type__infobox">
      <Popover
        content={<ContentHTML html={description} />}
        title={name}
        trigger="click"
        placement="bottom"
        className="infoBoxPopover"
      >
        <Icon
          className="icon--squared icon--squared-transparent"
          type="info-circle"
          theme="outlined"
        />
      </Popover>
    </div>
  );
};
