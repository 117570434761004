import { FormComponentProps } from "antd/lib/form";
import React from "react";
import { ItemAttributeOption } from "../../../../generated/axios";
import { FormFieldWithValue } from "../../../../shared/store/configurator/types";
import { FormEvent } from "../../../../types/configurator";
import { INPUT_TYPES } from "../../../../types/inputs";
import Checkbox from "./Checkbox";
import CheckboxMultiple from "./CheckboxMultiple";
import Expression from "./Expression";
import Image from "./Image";
import Numeric from "./Numeric";
import Radio from "./Radio";
import Select from "./Select";
import SelectMultiple from "./SelectMultiple";
import Input from "./Text";

export {
  Checkbox,
  Image,
  Radio,
  Select,
  Input,
  CheckboxMultiple,
  SelectMultiple,
};

export interface InputProps extends FormFieldWithValue, FormComponentProps {
  options?: ItemAttributeOption[];
  onChange: (formEvent: FormEvent) => void;
  forceDisabled?: boolean;
}

/**
 * @param type INPUT_TYPES
 */
export const getComponent = (type: string): React.ElementType<InputProps> => {
  const _map = {
    [INPUT_TYPES.CHECKBOX]: Checkbox,
    [INPUT_TYPES.CHECKBOX_MULTIPLE]: CheckboxMultiple,
    [INPUT_TYPES.IMAGE]: Image,
    [INPUT_TYPES.RADIO]: Radio,
    [INPUT_TYPES.SELECT]: Select,
    [INPUT_TYPES.SELECT_MULTIPLE]: SelectMultiple,
    [INPUT_TYPES.NUMBER]: Numeric,
    [INPUT_TYPES.TEXT]: Input,
    [INPUT_TYPES.EXPRESSION]: Expression,
  };
  return _map[type] || Input;
};
