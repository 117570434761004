import React from "react";
import { FormattedMessage } from "react-intl";

interface IProps {
  bestChoice?: boolean;
  bestRatio?: boolean;
}

const BestChoiceBestRatio: React.FC<IProps> = ({ bestChoice, bestRatio }) => {
  if (bestChoice) {
    return <FormattedMessage id="best choice" defaultMessage="BEST CHOICE" />;
  } else if (bestRatio) {
    return <FormattedMessage id="best ratio" defaultMessage="BEST RATIO" />;
  } else {
    return null;
  }
};

export default BestChoiceBestRatio;
