import { message } from "antd";
import { History } from "history";
import { Dispatch } from "redux";
import { durations } from "../../../config/defaults";
import {
  ItemTechnicalSpecificationApi,
  Project,
  ProjectApi,
  QuoteApi,
} from "../../../generated/axios";
import { Callback } from "../../../types/Callback";
import { apiConfig } from "../../api";
import { eIntl } from "../../eIntl";
import apiErrorHandler from "../../lib/apiErrorHandler";
import { REQUEST, SUCCESS } from "../../lib/asyncActionHelper";
import { messages } from "../../lib/locales/definedMessages";
import { resourceDownload } from "../../lib/resourceDownload";
import { GUIDED_SELLING } from "../item/consts";
import { GET_PROJECTS } from "../projects/consts";
import { IStore } from "../types";
import { CLOSE_MODAL_FORM } from "../ui/consts";
import { STORE_TEMP_PROJECT_ID } from "../utils/consts";
import * as types from "./consts";

const projectApi = new ProjectApi(apiConfig());
const quoteApi = new QuoteApi(apiConfig());
const technicalSpecification = new ItemTechnicalSpecificationApi(apiConfig());

const refreshProjectsList = (dispatch: Dispatch) => {
  dispatch({
    type: GET_PROJECTS,
    payload: projectApi.getAllProjects().then((res) => res.data),
  });
};

/**
 * it uses apiErrorHandler
 *
 */
export const createProject =
  (push: History["push"], project?: Project) => (dispatch: Dispatch) => {
    message.loading(
      eIntl.formatMessage(messages["message.creating_the_project"]),
      durations.loading
    );

    projectApi
      .createProject(project)
      .then((res) => {
        message.success(
          eIntl.formatMessage(messages["message.project_created"]),
          durations.success
        );
        dispatch({
          type: SUCCESS(types.NEW_PROJECT),
          payload: res.data,
        });
        dispatch({
          type: STORE_TEMP_PROJECT_ID,
          payload: res.data.id,
        });
        push("/item/new");
        return res.data;
      })
      .catch(apiErrorHandler({ dispatch, actionType: types.NEW_PROJECT }));
  };

/**
 * it uses apiErrorhandler + add reducer error
 */
export const deleteProject =
  () => (dispatch: Dispatch, getState: () => IStore) => {
    const projectId = getState().ui.modalForm.meta.projectId;
    if (!projectId) return;

    message.loading(
      eIntl.formatMessage(messages["message.deleting_project"]),
      durations.loading
    );
    projectApi
      .deleteProject(projectId)
      .then((res) => {
        message.success(
          eIntl.formatMessage(messages["message.delete_completed"]),
          durations.success
        );
        refreshProjectsList(dispatch);
        dispatch({
          type: types.DELETE_PROJECT,
          payload: res.data,
        });
      })
      .catch(apiErrorHandler({ dispatch, actionType: types.DELETE_PROJECT }))
      .finally(() => {
        dispatch({
          type: CLOSE_MODAL_FORM,
        });
      });
  };

/**
 * it uses apiErrorHandler + add reducer error
 */
export const duplicateProject =
  () => (dispatch: Dispatch, getState: () => IStore) => {
    const projectId = getState().ui.modalForm.meta.projectId;
    if (!projectId) return;
    message.loading(
      eIntl.formatMessage(messages["message.duplicating_project"]),
      durations.loading
    );
    projectApi
      .cloneProject(projectId)
      .then((res) => {
        message.success(
          eIntl.formatMessage(messages["message.duplicate_completed"]),
          durations.success
        );
        dispatch({
          type: types.DUPLICATE_PROJECT,
          payload: res.data,
        });
      })
      .catch(apiErrorHandler({ dispatch, actionType: types.DUPLICATE_PROJECT }))
      .finally(() => {
        dispatch({
          type: CLOSE_MODAL_FORM,
        });
        refreshProjectsList(dispatch);
      });
  };

export const updateProject =
  (projectId: number, project?: Project) => (dispatch: Dispatch) => {
    message.loading(
      eIntl.formatMessage(messages["message.updating_data"]),
      durations.loading
    );

    projectApi
      .updateProject(projectId, project)
      .then((res) => {
        message.success(
          eIntl.formatMessage(messages["message.updating_completed"]),
          durations.success
        );
        dispatch({
          type: SUCCESS(types.UPDATE_PROJECT),
          payload: res.data,
        });
        return res.data;
      })
      .catch(apiErrorHandler({ dispatch, actionType: types.UPDATE_PROJECT }))
      .finally(() => {
        dispatch({
          type: CLOSE_MODAL_FORM,
        });
        refreshProjectsList(dispatch);
      });
  };

/**
 * it uses apiErrorHandler.ts
 *
 * @param projectId
 * @param withMessenger
 */
export const getProjectDetails =
  (projectId: number, withMessenger = true) =>
  (dispatch: Dispatch) => {
    if (withMessenger) {
      message.loading(
        eIntl.formatMessage(messages["message.loading_data"]),
        durations.loading
      );
    }

    projectApi
      .getProjectDetails(projectId)
      .then((res) => {
        if (withMessenger) {
          message.success(
            eIntl.formatMessage(messages["message.loading_completed"]),
            durations.success
          );
        }
        dispatch({
          type: SUCCESS(types.GET_PROJECT_DETAILS),
          payload: res.data,
        });
        return res.data;
      })
      .catch(
        apiErrorHandler({
          dispatch,
          actionType: types.GET_PROJECT_DETAILS,
          hasTimeout: false,
        })
      );
  };

/**
 * it uses apiErrorHandler
 * TODO: export a type, this is like the one in item.actions
 */
export interface AddNoteData {
  text: string;
}

export const addNoteToProject =
  (data: AddNoteData) => (dispatch: Dispatch, getState: () => IStore) => {
    message.loading(
      eIntl.formatMessage(messages["message.updating_the_project"]),
      durations.loading
    );
    const projectId = getState().ui.modalForm.meta.projectId;

    if (!projectId) {
      dispatch({ type: CLOSE_MODAL_FORM });
      message.error(
        eIntl.formatMessage(messages["message.operation_failed"]),
        durations.error
      );
      return;
    }

    const project = {
      ...getState().project,
      note: data.text,
    };

    projectApi
      .updateProject(projectId, project)
      .then((res) => {
        message.success(
          eIntl.formatMessage(messages["message.operation_success"]),
          durations.success
        );
        dispatch({
          type: SUCCESS(types.UPDATE_PROJECT),
          payload: res.data,
        });
      })
      .catch(apiErrorHandler({ dispatch, actionType: types.UPDATE_PROJECT }))
      .finally(() => {
        dispatch({
          type: CLOSE_MODAL_FORM,
        });
        refreshProjectsList(dispatch);
      });
  };

// reset project reducer needed to allow creation of new items in default project after click on Quick "quick calculation"
export const resetProjectReducer = () => ({ type: "RESET_PROJECT_REDUCER" });

/**
 * API GET /items/technical-specifications
 * @param {number[]} itemIds array of IDs (at least one), as query string
 * @param {boolean} buildOffer
 */
export const getItemsTechnicalSpecificationPdf =
  (itemIds: number[], buildOffer?: boolean) => (dispatch: Dispatch) => {
    message.loading(
      eIntl.formatMessage(messages["message.generating_pdf"]),
      durations.loading
    );

    technicalSpecification
      .getItemsTechnicalSpecifications(itemIds, undefined, buildOffer, {
        responseType: "blob",
      })
      .then((res) => {
        resourceDownload(res);
        // no need to dispatch success to reducer!!
        // dispatch({
        //   type: types.GET_ITEMS_TECHNICAL_SPECIFICATIONS_PDF,
        //   payload: res.data
        // })
        message.success(
          eIntl.formatMessage(messages["message.generating_pdf"]),
          durations.success
        );
      })
      .catch(
        apiErrorHandler({
          dispatch,
          actionType: types.GET_ITEMS_TECHNICAL_SPECIFICATIONS_PDF,
        })
      );
  };

export const quoteItems =
  (itemIds: number[], okCallback?: Callback) => (dispatch: Dispatch) => {
    message.loading(
      eIntl.formatMessage(messages["message.creating_quote"]),
      durations.loading
    );
    dispatch({ type: REQUEST(types.QUOTE_ITEMS) });
    return quoteApi
      .quoteItems(itemIds)
      .then((response) => {
        dispatch({ type: SUCCESS(types.QUOTE_ITEMS) });
        message.success(
          eIntl.formatMessage(messages["message.creating_quote"]),
          durations.success
        );
        okCallback?.();
        return response;
      })
      .catch(
        apiErrorHandler({
          dispatch,
          actionType: types.QUOTE_ITEMS,
        })
      );
  };

/**
 * Executes quoteItems and dispatches GUIDED_SELLING
 * It also extends quoteItems signature by adding "unitId"
 */
export const quoteItemsAndGuidedSelling =
  (itemIds: number[], unitId?: string, okCallback?: Callback) =>
  (dispatch: Dispatch) => {
    return quoteItems(
      itemIds,
      okCallback
    )(dispatch).then((response) => {
      if (response) {
        dispatch({
          type: SUCCESS(GUIDED_SELLING),
          payload: { productId: response.data, unitId },
        });
      }
    });
  };
