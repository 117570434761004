import { message } from "antd";
import { Dispatch } from "redux";
import { durations } from "../../../config/defaults";
import { Item } from "../../../generated/axios";
import IAction from "../../../types/action";
import { Callback } from "../../../types/Callback";
import {
  ConfiguratorSection,
  FormValue,
  ValidationMessage,
  ValidationMessagePayload,
} from "../../../types/configurator";
import { eIntl } from "../../eIntl";
import { preventDefault, restoreDefault } from "../../lib/keyboardEvents";
import { messages } from "../../lib/locales/definedMessages";
import { setItem } from "../item/actions";
import { IStore } from "../types";
import { LOAD, SET_CURRENT_SECTION, VALIDATION_ERRORS } from "./consts";
import {
  changed,
  composeConfig,
  createItemData,
  dispatchSectionData,
  getApiFunctions,
  getFields,
  hasErrors,
  loadItem,
  validateFields,
} from "./lib";

/**
 * it uses apiErrorHandler in the loadItem function
 * see loadItem signature loadItem(..., dispatch, LOAD) *
 */
export const load =
  (itemId: number, section: ConfiguratorSection) =>
  (dispatch: Dispatch, getState: () => IStore) => {
    const apiFunctions = getApiFunctions(section);
    const state = getState();
    const item: Item = state.item;

    if (!item.id) {
      throw new Error(
        'Item is not loaded in the store. Do not call function "load" out of the configurator'
      );
    }

    dispatch({
      type: LOAD,
      payload: loadItem(
        itemId,
        section,
        item,
        apiFunctions,
        state,
        dispatch,
        LOAD
      ),
    });
  };

export const setCurrentSection = (currentSection: ConfiguratorSection) => ({
  type: SET_CURRENT_SECTION,
  payload: currentSection,
});

/**
 * Flow of store update:
 * - Action select (key, value)
 *    - if validation fails:
 *      - DO NOT Dispatch ANY MORE VALIDATION_ERRORS: updates the "configurator" store
 *    - else:
 *      - Dispatch SET_ITEM: updated the "item" store (send key, value)
 *      - Dispatch SELECT: updates the "configurator" store
 *
 * Store:
 * - "item" represents the item data as saved on the server
 * - "configurator" represents the state of the visible form (even thermal and mechanical)
 */
export const select =
  (
    key: string,
    value: FormValue,
    section: ConfiguratorSection,
    onError?: Callback
  ) =>
  async (dispatch: Dispatch, getState: () => IStore) => {
    const previousState: IStore = getState();

    if (!previousState.item.id) {
      throw new Error(
        'Item is not loaded in the store. Do not call function "select" out of the configurator'
      );
    }

    const fields = getFields(section, previousState.item);

    if (!changed(fields, key, value)) {
      // if value is not changed, don't trigger select
      return;
    }

    try {
      // prevent document keyboard events
      preventDefault();

      message.loading(
        eIntl.formatMessage(messages["message.saving_data"]),
        durations.loading
      );
      const itemData = createItemData(section, key, value);

      const data = await setItem(dispatch)(previousState.item.id, itemData);

      if (data) {
        const nextState: IStore = getState();

        // update both form data
        const dispatcher = dispatchSectionData(dispatch, nextState);
        dispatcher(ConfiguratorSection.THERMAL);
        dispatcher(ConfiguratorSection.MECHANICAL);
      } else {
        onError?.();
      }
    } finally {
      // restore document keyboard events
      restoreDefault();
    }
  };

export const validationErrors = (
  validations: Record<string, ValidationMessage[]>,
  section: ConfiguratorSection
): IAction<ValidationMessagePayload> => ({
  type: VALIDATION_ERRORS,
  payload: { validations, section },
});

export const validateForms = (dispatch: Dispatch, getState: () => IStore) => {
  const { configurator, item } = getState();

  if (!item.id) {
    throw new Error(
      'Item is not loaded in the store. Do not call function "validate" out of the configurator'
    );
  }

  return [ConfiguratorSection.THERMAL, ConfiguratorSection.MECHANICAL].reduce(
    (result, currentSection) => {
      const { config } = configurator[currentSection];
      if (!config) {
        throw new Error(
          'Configurator.config is not loaded in the store. Do not call function "validate" out of the configurator'
        );
      }
      const fields = getFields(currentSection, item);
      const composedConfig = composeConfig(fields, config, item);
      const validations = validateFields(composedConfig, item);
      const errors = hasErrors(validations);

      if (errors) {
        dispatch(validationErrors(validations, currentSection));
      }
      result[currentSection] = errors;
      return result;
    },
    {}
  ) as {
    [ConfiguratorSection.THERMAL]: boolean;
    [ConfiguratorSection.MECHANICAL]: boolean;
  };
};
