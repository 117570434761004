/**
 * HOC to convert units of measure of form fields
 * It converts input values and options, and proxies changeHandler function
 *
 * It only accepts components with Props as defined in ./Field.tsx
 */
import { get } from "lodash";
import React from "react";
import { connect, DispatchProp } from "react-redux";
import { ConversionFormula } from "../../../generated/axios";
import upperCaseLabel from "../../../shared/lib/upperCaseLabel";
import { FormFieldWithValue } from "../../../shared/store/configurator/types";
import { REPORT_MISSING_CONVERSION_FORMULAS } from "../../../shared/store/middlewares/consts";
import { IStore } from "../../../shared/store/types";
import convert from "../../../shared/systemOfMeasurement/conversionFunctions";
import { tryConvert } from "../../../shared/systemOfMeasurement/convertCalculationValues";
import { convertValidationMessages } from "../../../shared/systemOfMeasurement/convertValidationMessages";
import { ValidationMessage } from "../../../types/configurator";
import { INPUT_TYPES } from "../../../types/inputs";
import { ChangeHandler } from "./Configurator";

const mapStateToProps = (state: IStore) => {
  const { conversions, chosenMetricSystem, defaultMetricSystem } =
    state.settings;
  return { conversions, chosenMetricSystem, defaultMetricSystem };
};

interface OwnProps {
  field: FormFieldWithValue;
  validationMessages?: ValidationMessage[];
  changeHandler?: ChangeHandler;
}

interface IReduxProps {
  conversions: ConversionFormula[];
  chosenMetricSystem: string;
  defaultMetricSystem: string;
}

interface ExtendedProps extends OwnProps, IReduxProps, DispatchProp {}

/**
 * Generic HOC responsible to convert (if possible) units of measurement according to user preferences
 */
const FieldSystemOfMeasurement = <IProps extends OwnProps>(
  Children: React.ComponentType<IProps>
) =>
  connect(mapStateToProps)((props: ExtendedProps) => {
    // TODO: try to resolve with better typing (the real Type of props should be IProps & IReduxProps)
    const {
      conversions,
      chosenMetricSystem,
      defaultMetricSystem,
      ...otherProps // Real type should be generic IProps
    } = props;
    const childrenProps: IProps = otherProps as unknown as IProps;

    const unitOfMeasures = props.field.unitOfMeasures ?? {};
    const conversion = convert(
      unitOfMeasures,
      conversions,
      chosenMetricSystem,
      defaultMetricSystem
    );

    childrenProps.field = { ...props.field }; // clone field

    const shouldBeConverted = !!conversion.defaultSystem; // && conversion.defaultSystem.symbol;
    const canBeConverted =
      conversion.canBeConvertedTwoWays ||
      (conversion.canBeConvertedOneWay &&
        props.field.inputType === INPUT_TYPES.SELECT) ||
      (conversion.canBeConvertedOneWay &&
        props.field.inputType === INPUT_TYPES.SELECT_MULTIPLE);

    const unitMeasure = canBeConverted
      ? conversion.chosenSystem
      : conversion.defaultSystem;
    const symbol = get(unitMeasure, "symbol");
    const precision = get(unitMeasure, "precision");

    // overwrite label adding unit of measure
    childrenProps.field.label = `${upperCaseLabel(props.field)}${
      symbol ? ` [${symbol}]` : ""
    }`;

    // add precision (used by Numeric component)
    childrenProps.field.precision = precision;

    if (shouldBeConverted) {
      // overwrite options
      if (childrenProps.field.options) {
        childrenProps.field.options = childrenProps.field.options.map(
          (option) => {
            if (option["foundInOptionLabels"]) return option;
            else return conversion.optionFromBaseSystem(option);
          }
        );
      }
    }

    if (canBeConverted) {
      if (
        props.field.inputType !== INPUT_TYPES.SELECT &&
        props.field.inputType !== INPUT_TYPES.SELECT_MULTIPLE
      ) {
        // overwrite value
        childrenProps.field.value = tryConvert(
          childrenProps.field.value,
          conversion
        );
        // overwrite change handler function (if present)
        if (childrenProps.changeHandler) {
          childrenProps.changeHandler = conversion.proxyChangeHandler(
            childrenProps.changeHandler
          );
        }
      }

      // overwrite validation messages
      childrenProps.validationMessages = convertValidationMessages(
        childrenProps.validationMessages ?? [],
        conversion
      );
    } else if (shouldBeConverted) {
      // Can't be converted but should be

      if (props.field.inputType !== INPUT_TYPES.SELECT) {
        // Apply precision
        childrenProps.field.value = conversion.toPrecision(
          childrenProps.field.value
        );
      }
      !!conversion.chosenSystem &&
        props.dispatch({
          type: REPORT_MISSING_CONVERSION_FORMULAS,
          payload: {
            fieldId: props.field.fieldId,
            chosenMetricSystem,
            defaultMetricSystem,
            chosenSystem: conversion.chosenSystem,
            defaultSystem: conversion.defaultSystem,
          },
        });
    }

    return <Children {...childrenProps} />;
  });

export default FieldSystemOfMeasurement;
