import { Modal } from "antd";
import { Z_INDEXES } from "../../config/defaults";
import { HOME } from "../../config/paths";
import { extractItemIdFromPathname } from "../components/RouteChangeHandler/lib";
import { eIntl } from "../eIntl";
import { gotoPage } from "./gotoPage";
import { messages } from "./locales/definedMessages";

const { info } = Modal;

function showConfirm() {
  info({
    title: "Application warning",
    content: eIntl.formatMessage(
      messages["message.item-active-in-another-tab"]
    ),
    zIndex: Z_INDEXES.tabContentTrackConfirm,
    onOk() {
      gotoPage(HOME);
    },
    onCancel() {
      gotoPage(HOME);
    },
  });
}

class TabTracker {
  bc: BroadcastChannel;
  itemId: string;

  constructor() {
    this.itemId = "";
    this.bc = new BroadcastChannel("LuveTabTracker");
    this.bc.onmessage = (be) => {
      if (be.data.shownItemId === this.itemId) {
        showConfirm();
      }
    };
  }

  track(pathname: string) {
    this.itemId = extractItemIdFromPathname(pathname);
    if (this.itemId) {
      this.bc.postMessage({
        shownItemId: this.itemId,
      });
    }
  }
}

export const tabTracker = new TabTracker();
