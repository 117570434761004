import React from "react";
import {
  FormattedMessage,
  InjectedIntl,
  InjectedIntlProps,
  injectIntl,
} from "react-intl";
import { apiErrorsMessages } from "../../../../shared/lib/locales/definedMessages";
import { ErrorOnResultType } from "../../../../shared/store/item/types";
import "./errorDisplay.scss";

// TODO: spostare da qualche parte per un eventuale riuso in altri contesti diversi dal CalculatorResults
function renderMessage(
  intl: InjectedIntl,
  message: FormattedMessage.MessageDescriptor | string
) {
  if (typeof message === "string") return message;
  const { id, defaultMessage } = message;
  if (id && defaultMessage) {
    if (apiErrorsMessages[id]) {
      return intl.formatHTMLMessage(apiErrorsMessages[id]);
    } else {
      console.warn(
        `Missing api error translation key: "${id}": { id: "${id}", defaultMessage: "${id}" },`
      );
      return id;
    }
  }
  return "";
}

interface Props extends ErrorOnResultType, InjectedIntlProps {}

const ErrorDisplayLocalized = (props: Props) => {
  const { intl, message } = props;

  if (!message) return null;

  return (
    <div className="calculator-results_error-display">
      <div
        className="calculator-results_error-display_inner"
        dangerouslySetInnerHTML={{ __html: renderMessage(intl, message) }}
      />
    </div>
  );
};
export default injectIntl(ErrorDisplayLocalized);
