import { Card } from "antd";
import React, { ReactNode } from "react";
import { ErrorHandler } from "../../../../shared/components/ErrorHandler";
import {
  FormFieldWithValue,
  FormSectionWithValues,
} from "../../../../shared/store/configurator/types";
import { RenderOptions } from "../../containers/Field";
import { Description, Fields, Title } from "./";
import SectionNumber from "./SectionNumber";

interface Props {
  index: number;
  sectionCount: number;
  sectionData: FormSectionWithValues;
  // Typescript staff suggests to set children as optional:
  // https://github.com/Microsoft/TypeScript/issues/6471#issuecomment-171456118
  children?: (
    field: FormFieldWithValue,
    renderOptions?: RenderOptions
  ) => ReactNode;
}

const SectionView: React.ComponentType<Props> = ({
  index,
  sectionCount,
  sectionData,
  children = () => null,
}: Props) => (
  <Card bordered={false} className="content">
    {React.useMemo(
      () => (
        <SectionNumber n={index + 1} total={sectionCount} />
      ),
      [index, sectionCount]
    )}

    {React.useMemo(
      () => (
        <Title>{sectionData.name}</Title>
      ),
      [sectionData.name]
    )}

    {React.useMemo(
      () => (
        <Description>{sectionData.description}</Description>
      ),
      [sectionData.description]
    )}

    <ErrorHandler>
      <Fields fields={sectionData.fields || []}>{children}</Fields>
    </ErrorHandler>
  </Card>
);

export default SectionView;
