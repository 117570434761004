import { Button } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Portfolio } from "../../generated/axios";
import "./continueButton.scss";

interface ContinueButtonProps {
  clickHandler: () => void;
  selectedPortfolio?: Portfolio;
}

const ContinueButton: React.FunctionComponent<ContinueButtonProps> = React.memo(
  ({ clickHandler, selectedPortfolio }) => {
    if (selectedPortfolio?.url)
      return (
        <a
          className="as-ant-button"
          href={`${selectedPortfolio.url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="continue" defaultMessage="Continue" />
        </a>
      );
    return (
      <Button
        title="Continue"
        size={"large"}
        type="primary"
        block
        onClick={clickHandler}
      >
        <FormattedMessage id="continue" defaultMessage="Continue" />
      </Button>
    );
  }
);

export default ContinueButton;
