import { Icon, Tooltip } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Callback } from "../../../../types/Callback";
import { CapabilityCheck, capabilityMap } from "../../../capability";
import { SETTING_FIELDS } from "../../modals/SettingsModal/SettingsModal";

interface IProps {
  className?: string;
  openSettingsModal: (sectionsToView: string[]) => void;
  unlockAll: Callback;
}

export const TableResultsSetupContainer: React.FC<IProps> = ({
  className,
  openSettingsModal,
  unlockAll,
}) => {
  return (
    <div className={className}>
      <CapabilityCheck
        showIfHas={capabilityMap.SETTINGS_RES_TABLE_CUSTOMIZE_FIELDS}
      >
        <Tooltip
          title={
            <FormattedMessage
              id="item settings"
              defaultMessage="Item Settings"
            />
          }
          overlayClassName="no-arrow"
        >
          <Icon
            type="setting"
            theme="outlined"
            className="icon--blue"
            onClick={() =>
              openSettingsModal([SETTING_FIELDS.RESULTS_FIELD_LIST])
            }
          />
        </Tooltip>
      </CapabilityCheck>

      <Tooltip
        title={<FormattedMessage id="unlockAll" defaultMessage="Unlock all" />}
        overlayClassName="no-arrow"
      >
        <Icon
          type="unlock"
          theme="outlined"
          className="icon--blue"
          onClick={unlockAll}
        />
      </Tooltip>
    </div>
  );
};
