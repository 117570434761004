export const HOME = "/";

export const LOGIN = "/login";
export const REGISTRATION_EMAIL = "/registration-email";
export const REGISTRATION_CONFIRM = "/registration-confirm";
export const PASSWORD_RESET = "/password-reset";
export const CPQ_LOGIN = "/api/public/oauth2/authorization/sap";
export const PASSWORD_RESET_CONFRIM = "/password-reset/finish";
export const RESEND_INSTRUCTIONS = "/resend-instructions";
export const CHANGE_EMAIL = "/email-reset/finish";
export const USER_PROFILE = "/user-profile";

export const PROJECT_OPEN = "/project/open";
export const PROJECT_NEW = "/project/new";
export const PROJECT_DETAILS = "/project/:projectId/details";

export const ITEM_NEW = "/item/new";

export const CONFIGURATOR = "/configurator/:itemId";
export const CONFIGURATOR_THERMAL = "/thermal";
export const CONFIGURATOR_MECHANICAL = "/mechanical";
export const CONFIGURATOR_RESULTS = "/results";

export const projectDetailsDynamicPath = (pid: number) =>
  `/project/${pid}/details`;

export const itemConfiguratorDynamicPath = (
  id: number,
  landing: string = "thermal"
) => `/configurator/${id}/${landing}`;

export const itemDetailPath = (id?: number, hasResultTable?: boolean) =>
  `/configurator/${id}${
    hasResultTable ? CONFIGURATOR_RESULTS : CONFIGURATOR_THERMAL
  }`;
