import { Any } from "./Any";
import { Callback } from "./Callback";

export interface FormEvent {
  field: string;
  value: Any;
  onError?: Callback;
}

export enum ConfiguratorSection {
  THERMAL = "thermal",
  MECHANICAL = "mechanical",
  RESULTS = "results",
}

export type FormValue = string | number | boolean;

export enum validationStatus {
  error = "error",
  warning = "warning",
  success = "success",
  validating = "validating",
}

export interface ValidationMessage {
  status: validationStatus;
  message?: string;
  params?: ValidationParams;
  skipTranslate?: true;
}

export interface ValidationMessagePayload {
  validations: Record<string, ValidationMessage[]>;
  section: ConfiguratorSection;
}

export interface ValidationParams extends Record<string, Any> {}

export interface ValidateProps {
  validateStatus: validationStatus;
  help?: string;
}

export type ByKey = Record<string, Any>;
