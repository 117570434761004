import { get } from "lodash";
import IAction from "../../../types/action";
import { Any } from "../../../types/Any";
import { FAILURE, SUCCESS } from "../../lib/asyncActionHelper";
import {
  CLEAR_SHARE_MODAL_ERROR,
  GET_ITEM_SHARES,
  GET_PROJECT_SHARES,
  GET_PROJECTS_SHARED_WITH_ME,
  POST_ITEM_SHARES,
  POST_PROJECT_SHARES,
  REMOVE_SHARES,
} from "./consts";
import { ShareState } from "./types";

export const initialState: ShareState = {
  item: {
    itemId: undefined,
    itemShares: [],
  },
  project: {
    projectId: undefined,
    projectShares: [],
    projectSharedWithMe: [],
  },
  addShareError: "",
  removeShareError: "",
};

export default (state = initialState, action: IAction<Any>): ShareState => {
  switch (action.type) {
    case SUCCESS(GET_ITEM_SHARES):
      return {
        ...state,
        item: { ...action.payload },
      };

    case SUCCESS(GET_PROJECT_SHARES):
      return {
        ...state,
        project: { ...action.payload },
      };

    // case xhr.status = 400 --> "Email does not exists" and similar
    case FAILURE(POST_PROJECT_SHARES):
    case FAILURE(POST_ITEM_SHARES):
      return {
        ...state,
        addShareError: get(action, "payload.response.data.title", ""),
      };

    case SUCCESS(POST_PROJECT_SHARES):
      return {
        ...state,
        project: {
          // the rest call returns the new user just added, concat it to the others
          // so the list shown inside the modal will update with it
          ...state.project,
          projectShares: state.project.projectShares.concat([action.payload]),
        },
        addShareError: "", // clean if previous error
      };

    case SUCCESS(POST_ITEM_SHARES):
      return {
        ...state,
        item: {
          // the rest call returns the new user just added, concat it to the others
          // so the list shown inside the modal will update with it
          itemShares: state.item.itemShares.concat([action.payload]),
          itemId: state.item.itemId,
        },
        addShareError: "", // clean if previous error
      };

    case SUCCESS(REMOVE_SHARES):
      // the action returns the id to remove from the store, which is action.payload
      // the store will be updated for the item.itemShare part --> the item.itemShare inside
      // the ShareModal is connected to the store --> the modal will be refreshed
      // (without the share just removed)
      return {
        ...state,
        item: {
          ...state.item,
          itemShares: state.item.itemShares.filter(
            (item) => item.id != action.payload
          ),
        },
        project: {
          ...state.project,
          projectShares: state.project.projectShares.filter(
            (project) => project.id != action.payload
          ),
        },
      };

    case FAILURE(REMOVE_SHARES):
      return {
        ...state,
        removeShareError: action.payload,
      };
    case SUCCESS(GET_PROJECTS_SHARED_WITH_ME):
      return {
        ...state,
        project: {
          ...state.project,
          projectSharedWithMe: [...action.payload],
        },
      };
    case CLEAR_SHARE_MODAL_ERROR:
      return {
        ...state,
        addShareError: "",
        removeShareError: "",
      };
    default:
      return state;
  }
};
