import { isObject } from "lodash";
import { LoggingApi, LogMessage } from "../../generated/axios";
import { Any } from "../../types/Any";
import { apiConfig } from "../api";
import { getToken } from "../auth";

const RemoteLogger = new LoggingApi(apiConfig());

const consoleMethodMapping = {
  [LogMessage.LevelEnum.DEBUG]: "log",
  [LogMessage.LevelEnum.INFO]: "info",
  [LogMessage.LevelEnum.WARN]: "warn",
  [LogMessage.LevelEnum.ERROR]: "error",
};

const convertToString = (input: Any): string => {
  if (input instanceof String) {
    return String(input);
  }
  if (input instanceof Number) {
    return String(input);
  }
  if (input instanceof Error) {
    return `${input.message}\n\t${input.stack}`;
  }
  if (isObject(input)) {
    try {
      return JSON.stringify(input);
    } catch {
      return String(input);
    }
  }
  return String(input);
};

export const logConsole = (severity: LogMessage.LevelEnum, message: Any) => {
  const consoleMethod = consoleMethodMapping[severity] || "log";
  const log = console[consoleMethod];
  log(message);
};

export const logRemote = (level: LogMessage.LevelEnum, message: Any) => {
  const userAgent = window.navigator.userAgent;
  const userToken = getToken();
  const text = convertToString(message);
  const logMessage: LogMessage = {
    userAgent,
    level,
    text,
    userToken,
  };
  RemoteLogger.clientLog(logMessage);
};
