import { Col, Form, Row, Select } from "antd";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { ApplicationType } from "../../../../generated/axios";
import { getApplicationTypes } from "../../../../shared/store/applicationType/actions";
import { applicationTypeSelector } from "../../../../shared/store/applicationType/selectors";
import { useAppDispatch } from "../../../../shared/store/hooks";
import { getBounds } from "../../../../shared/store/settings/actions";
import { boundsSelector } from "../../../../shared/store/settings/selectors";
import "./bounds.scss";
import { ApplicationTypeBoundsForm } from "./bounds/ApplicationTypeBoundsForm";

/**
 * @returns ApplicationTypeState List of user ApplicationTypes
 */
const useApplicationTypes = () => {
  // we need to ensure that the ApplicationTypes are loaded
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getApplicationTypes());
  }, [dispatch]);

  return useSelector(applicationTypeSelector);
};

/**
 * @param id ApplicationType id
 * @returns ApplicationType object
 */
export const useApplicationType = (id: number): ApplicationType | undefined => {
  const applicationTypes = useApplicationTypes();
  let applicationType: ApplicationType | undefined;
  Object.keys(applicationTypes).forEach((group) =>
    applicationTypes[group].find((_applicationType: ApplicationType) => {
      if (_applicationType.id === id) {
        applicationType = _applicationType;
        return true;
      }
      return false;
    })
  );
  return applicationType;
};

/**
 * @returns BoundsConfig Bounds settings for each project
 */
const useBoundsSettings = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getBounds());
  }, [dispatch]);

  return useSelector(boundsSelector);
};

/**
 * Allows to edit bounds for each project
 * First select a project, then edit its bounds
 */
export const Bounds: React.FC = () => {
  const applicationTypes = useApplicationTypes();
  const [currentApplicationType, setCurrentApplicationType] = React.useState<
    number | undefined
  >(undefined);
  const boundsSettings = useBoundsSettings();

  return (
    <Row>
      <Col span={24} className="bounds__container">
        <Row>
          <Col className="bounds__section-title margin-bottom--40" span={24}>
            <FormattedMessage id="bounds" defaultMessage="Bounds" />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form>
              <Form.Item
                className="bounds__label"
                label={
                  <FormattedMessage
                    id="select_application type"
                    defaultMessage="Select application type"
                  />
                }
              >
                <Select
                  onChange={(value: number) => setCurrentApplicationType(value)}
                  style={{ width: "100%", minWidth: 306 }}
                >
                  {Object.keys(applicationTypes).map((group) => (
                    <Select.OptGroup label={group} key={group}>
                      {applicationTypes[group]?.map(
                        (applicationType: ApplicationType) => (
                          <Select.Option
                            value={applicationType.id}
                            key={applicationType.id}
                          >
                            {applicationType.name}
                          </Select.Option>
                        )
                      )}
                    </Select.OptGroup>
                  ))}
                </Select>
              </Form.Item>

              {currentApplicationType && (
                <ApplicationTypeBoundsForm
                  {...{
                    boundsSettings,
                    currentApplicationType,
                  }}
                />
              )}
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
