import React from "react";
import { FormattedMessage } from "react-intl";

import IconMenuVentolina from "../../assets/IconMenuVentolina";

const LinkContent = ({ name }: { name: string }) => (
  <>
    <IconMenuVentolina />
    <span>
      <FormattedMessage
        id="new-config-prefix"
        defaultMessage="New configuration"
      />
      {` ${name}`}
    </span>
  </>
);

export default LinkContent;
