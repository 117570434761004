import React, { FunctionComponent } from "react";
import { BaseField } from "../../../../generated/axios";
import Header from "./Header";
import { Callback } from "../../../../types/Callback";

interface IProps {
  headers: BaseField[];
  orderField: string;
  orderIcon: React.ReactNode;
  toggleDirection: (fieldId: string) => Callback;
}

const Headers: FunctionComponent<IProps> = React.memo((props) => {
  const { headers, orderField, orderIcon, toggleDirection } = props;

  return (
    <>
      {headers.map((header) => (
        <Header
          key={header.fieldId}
          header={header}
          orderField={orderField}
          orderIcon={orderIcon}
          toggleDirection={toggleDirection}
        />
      ))}
    </>
  );
});

export default Headers;
