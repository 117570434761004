import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import React from "react";
import { Any } from "../../../../types/Any";
import "./fieldList.scss";
import FieldListWithDragAndDrop from "./FieldListWithDragAndDrop";

interface OwnProps extends FormComponentProps<Any> {
  itemId?: number;
  projectId?: number;
  scope: string;
  hasDataToDisplay: boolean;
}

class FieldList extends React.Component<OwnProps> {
  render() {
    const { itemId, projectId, scope, hasDataToDisplay /* , getSettings  */ } =
      this.props;

    return (
      <FieldListWithDragAndDrop
        hasDataToDisplay={hasDataToDisplay}
        itemId={itemId}
        projectId={projectId}
        scope={scope}
      />
    );
  }
}

export default Form.create<OwnProps>()(FieldList);
