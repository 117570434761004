import React from "react";

interface IProps {
  subTitle: string | React.ReactElement;
}

const FormHeader = ({ subTitle }: IProps) => (
  <div>
    <p>{subTitle}</p>
  </div>
);

FormHeader.defaultProps = {
  subTitle: "",
};

export default FormHeader;
