import React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { noValueMessages } from "../../../../shared/lib/locales/definedMessages";
import { FormFieldWithValue } from "../../../../shared/store/configurator/types";
import { INPUT_TYPES } from "../../../../types/inputs";
import FieldSystemOfMeasurement from "../../containers/FieldSystemOfMeasurement";
import { writeValue } from "./helpers";

interface IProps extends InjectedIntlProps {
  field: FormFieldWithValue;
}

const SidePanelField: React.FC<IProps> = ({ field, intl }) => {
  const { fieldId, label, inputType, options, validation } = field;
  let value;
  switch (inputType) {
    case INPUT_TYPES.CHECKBOX:
      value = field.value ? "✓" : "✗";
      break;
    case INPUT_TYPES.SELECT:
    case INPUT_TYPES.RADIO:
      value = options?.find((opt) => opt.value === field.value)?.label ?? "";
      if (
        !validation?.required &&
        inputType === INPUT_TYPES.SELECT &&
        (value === "" || value === undefined || value === null)
      ) {
        value = intl.formatMessage({
          id: `noValue.${fieldId}`,
          defaultMessage: intl.formatMessage(noValueMessages.noValue),
        });
      }
      break;
    case INPUT_TYPES.CHECKBOX_MULTIPLE:
    case INPUT_TYPES.SELECT_MULTIPLE: {
      const values = (options ?? [])
        .filter((opt) => field.value.includes(opt.value))
        .map((opt) => opt.label);
      if (inputType === INPUT_TYPES.CHECKBOX_MULTIPLE) {
        value = values?.join(", ");
      } else {
        value = values.length === 0 ? ["-"] : values;
      }
      break;
    }
    case INPUT_TYPES.NUMBER:
    case INPUT_TYPES.EXPRESSION:
    default:
      value = field.value;
  }
  return (
    <div className="SidePanelItem" data-test={`sidebar-${fieldId}`}>
      <div>
        <div className="SidePanelItem-title">{label}</div>
        <div className="SidePanelItem-description">{writeValue(value)}</div>
      </div>
    </div>
  );
};

const SidePanelFieldWithIntl = injectIntl(SidePanelField);

export default FieldSystemOfMeasurement(SidePanelFieldWithIntl);
