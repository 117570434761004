import axios from "axios";
import store from "../store/store";
import { APPLICATION_UPDATED } from "../store/ui/consts";

interface CheckVersion {
  css: string[];
  js: string[];
}

// const endpoint = "https://plair.intesys.it/cgi-bin/check_version.sh";
const endpoint = "/cgi-bin/check_version.sh";
const elapse = 60000; // milliseconds

function jsFiles(): string[] {
  return Array.from(document.querySelectorAll("script"))
    .map((s) => String(s.src))
    .filter((src) => src.endsWith(".js"))
    .map((src) => {
      const { pathname = "" } = new URL(src);
      return pathname.slice(1);
    });
}

function cssFiles(): string[] {
  return Array.from(document.querySelectorAll("link"))
    .map((s) => s.href)
    .filter((src) => src.endsWith(".css"))
    .map((src) => {
      const { pathname = "" } = new URL(src);
      return pathname.slice(1);
    });
}

async function doVerify() {
  try {
    const { data } = await axios.get<CheckVersion>(endpoint); // fakeResult;
    // const { data } = fakeResult;
    const { css, js } = data;

    // Application reload only if getting a valid response of type "CheckVersion"
    if (
      !Array.isArray(css) ||
      !css.every((el) => typeof el === "string") ||
      !Array.isArray(js) ||
      !js.every((el) => typeof el === "string")
    ) {
      throw new Error(
        `Response of ${endpoint} is not a JSON object of type { css: string[], js: string[] }`
      );
    }

    const jsOnClient = jsFiles();
    const cssOnClient = cssFiles();

    console.log({ data, cssOnClient, css, jsOnClient, js });

    if (
      !css.every((cssOnServer) => cssOnClient.includes(cssOnServer)) ||
      !js.every((jsOnServer) => jsOnClient.includes(jsOnServer))
    ) {
      store.dispatch({
        type: APPLICATION_UPDATED,
        payload: true,
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function hashVerify(): void {
  if (process.env.CHECK_VERSION === "true") {
    window.setInterval(doVerify, elapse);
  }
}
