import { Select as SelectAnt } from "antd";
import React, { ReactNode } from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { InputProps } from ".";
import {
  FieldValidation,
  ItemAttribute,
  ItemAttributeOption,
} from "../../../../generated/axios";
import {
  areDifferent,
  areDifferentObj,
} from "../../../../shared/lib/areDifferentObj";
import { noValueMessages } from "../../../../shared/lib/locales/definedMessages";
import { includeSelectFilter } from "../../../../shared/lib/selectFilterOptions";
import { sortOptionsByProperty } from "../../../../shared/lib/sortArrayByField";
import log from "../../../../shared/log";
import { FormConfigWithValues } from "../../../../shared/store/configurator/types";
import { hasReadPermission } from "../../../../shared/store/item/selectors";
import { IStore } from "../../../../shared/store/types";
import { FormEvent } from "../../../../types/configurator";
import ReadonlyField from "./ReadonlyField";
import "./select.scss";

const Option = SelectAnt.Option;

interface ReduxProps {
  fieldList: ItemAttribute[] | [];
  chosenMetricSystem?: string;
  defaultMetricSystem: string;
  config: FormConfigWithValues;
  isReadonly: boolean;
}

interface Props extends InputProps, ReduxProps {
  intl: InjectedIntl;
}

interface IState {
  value?: string | number;
  convertedValue?: string | number;
  errorCounter: string;
}

const f = (value: string | number) => {
  return value || value === 0 ? value : "";
};

class Select extends React.Component<Props, IState> {
  state = {
    value: undefined,
    convertedValue: undefined,
    errorCounter: "0",
  };

  static getDerivedStateFromProps(props: Props, state: IState) {
    const { value, options = [] } = props;
    if (state.value !== value) {
      const obj = options.find((opt) => opt.value === value) ?? { label: "" };
      const convertedValue = obj.label;
      return { value, convertedValue };
    }
    return null;
  }

  shouldComponentUpdate(newProps: Props, newState: IState) {
    return (
      newState.convertedValue !== this.state.convertedValue ||
      // newProps.fieldList !== this.props.fieldList ||
      areDifferentObj(newProps, this.props, [
        "fieldId",
        "defaultMetricSystem",
        "chosenMetricSystem",
        "intl",
        "isReadonly",
        "placeholder",
        "sortDisabled",
        "type",
        "validation",
        "value",
      ]) ||
      areDifferent(newProps.config, this.props.config) ||
      areDifferent(newProps.options, this.props.options) ||
      areDifferent(newProps.fieldList, this.props.fieldList)
    );
  }

  onChangeError = () => {
    this.setState(
      () => ({
        errorCounter: `${Date.now()}`,
      }),
      () => this.forceUpdate()
    );
  };

  onChange = (e: string): void => {
    if (!this.props.fieldId) {
      log.warn("Select: FieldId must be present");
    }
    const value = e !== "" ? e : null;
    if (this.props.onChange) {
      const formEvent: FormEvent = {
        field: this.props.fieldId ? this.props.fieldId : "",
        value,
        onError: this.onChangeError,
      };
      this.props.onChange(formEvent);
    }
  };

  addEmptyOption = (options: ItemAttributeOption[]): ItemAttributeOption[] => {
    const { intl, fieldId } = this.props;
    const label = intl.formatMessage({
      id: `noValue.${fieldId}`,
      defaultMessage: intl.formatMessage(noValueMessages.noValue),
    });
    const option: Partial<ItemAttributeOption>[] = [
      {
        label,
        value: null,
        disabled: false,
      },
    ];
    return option.concat(options);
  };

  render = (): ReactNode => {
    const {
      fieldId,
      isReadonly,
      options,
      placeholder,
      sortDisabled,
      type,
      validation,
      value,
    } = this.props;
    const { errorCounter } = this.state;

    const sortProperty = type === "number" ? "value" : "label";
    let optionList = sortDisabled
      ? options ?? []
      : sortOptionsByProperty(options ?? [], sortProperty);

    if (
      validation?.autoSelection === FieldValidation.AutoSelectionEnum.NEVER ||
      (!validation?.required &&
        validation?.autoSelection !== FieldValidation.AutoSelectionEnum.ALWAYS)
    ) {
      optionList = this.addEmptyOption(optionList);
    }

    if (isReadonly) return <ReadonlyField text={this.state.convertedValue} />;

    // if there's only one option and the field is required, disable the entire select
    const isUniqueDisabled = (): boolean => {
      if (!optionList || optionList.length == 0) return false;
      return Boolean(validation?.required && optionList.length == 1);
    };

    const id = "configuration-form--" + fieldId;
    return (
      <SelectAnt
        key={`${fieldId}-${f(value)}_${errorCounter}`}
        id={id}
        defaultValue={f(value)}
        placeholder={placeholder}
        onChange={this.onChange}
        optionFilterProp="label"
        filterOption={includeSelectFilter}
        showSearch={true}
        disabled={isUniqueDisabled()}
        data-test={id}
        dropdownClassName={id + "-dropdown"}
        dropdownMatchSelectWidth={false}
      >
        {optionList.map((option) => (
          <Option
            key={`${f(option.value)}_${errorCounter}`}
            value={f(option.value)}
            data-test={id + "-" + option.value}
          >
            {option.label}
          </Option>
        ))}
      </SelectAnt>
    );
  };
}

const mapStateToProps = (state: IStore): ReduxProps => {
  const { chosenMetricSystem, defaultMetricSystem } = state.settings;
  const { thermalAttributes = [], mechanicalAttributes = [] } = state.item;
  const fieldList = thermalAttributes.concat(mechanicalAttributes);
  const config = state.configurator[state.configurator.currentSection].config;
  const isReadonly = hasReadPermission(state);
  return {
    chosenMetricSystem,
    defaultMetricSystem,
    fieldList,
    config,
    isReadonly,
  };
};

const connected = connect(mapStateToProps)(Select);

export default injectIntl(connected);
