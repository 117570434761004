import { Icon, Tooltip } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { openReportProblemModal } from "../../shared/store/ui/actions";
import "./iconsMenu.scss";
import { Callback } from "../../types/Callback";

interface DispatchProps {
  openReportProblemModal: Callback;
}

/**
 * The component shows the icons are on the left of the user menu
 */
const IconsMenu = (props: DispatchProps) => {
  return (
    <div className="icons-menu">
      <div className="icon-link__wrapper">
        <Tooltip
          placement="bottom"
          overlayClassName="no-arrow"
          align={{ offset: [0, -5] }}
          title={
            <FormattedMessage
              id="report a problem"
              defaultMessage="Report a problem"
            />
          }
        >
          <a
            className="icon--blue"
            onClick={() => props.openReportProblemModal()}
          >
            <Icon
              type="exception"
              className="icon-link__icon"
              style={{ fontSize: 24 }}
            />
          </a>
        </Tooltip>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  openReportProblemModal,
};

export default connect(null, mapDispatchToProps)(IconsMenu);
