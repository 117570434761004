import React from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Problem } from "../../../generated/axios";
import { mapProblemToMessage } from "../../lib/apiErrorMessages";
import { IStore } from "../../store/types";
import "./styles.scss";

interface Props {
  error?: Problem;
  intl: InjectedIntl;
}

const AccountException = (props: Props) => {
  const { error, intl } = props;
  return error ? (
    <div className="exception">
      {intl.formatMessage(mapProblemToMessage(error))}
    </div>
  ) : null;
};

const mapStateToProps = (state: IStore) => ({
  error:
    state.account.requestEmailResetError ?? state.account.passwordChangeError,
});

const connected = connect(mapStateToProps)(AccountException);

export default injectIntl(connected);
