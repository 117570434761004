import { LogMessage } from "../../generated/axios";
import { Any } from "../../types/Any";
import { logConsole, logRemote } from "./logger";

class Log {
  useConsole: boolean;

  useRemoteLogger: boolean;

  constructor(useConsole: boolean = false, useRemoteLogger: boolean = true) {
    this.useConsole = useConsole;
    this.useRemoteLogger = useRemoteLogger;
  }

  log(severity: LogMessage.LevelEnum, message: Any) {
    if (this.useConsole) {
      logConsole(severity, message);
    }
    if (this.useRemoteLogger) {
      logRemote(severity, message);
    }
  }

  debug(message: Any) {
    this.log(LogMessage.LevelEnum.DEBUG, message);
  }

  info(message: Any) {
    this.log(LogMessage.LevelEnum.INFO, message);
  }

  warn(message: Any) {
    this.log(LogMessage.LevelEnum.WARN, message);
  }

  error(message: Any) {
    this.log(LogMessage.LevelEnum.ERROR, message);
  }
}

export default Log;
