import { Avatar, Dropdown, Icon, Menu } from "antd";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LOGIN, USER_PROFILE } from "../../config/paths";
import settingsInfo from "../../config/settings";
import { logout } from "../../shared/auth";
import { ErrorHandler } from "../../shared/components/ErrorHandler";
import { getAccount } from "../../shared/store/account/actions";
import { selectUserFullName } from "../../shared/store/account/selectors";
import { useAppDispatch } from "../../shared/store/hooks";
import { featuresFlagSelector } from "../../shared/store/injectedEnv/selectors";
import { FeatureFlag } from "../../shared/store/injectedEnv/types";
import {
  getConversions,
  getMetricSystems,
} from "../../shared/store/settings/actions";
import { hasMetricSystems } from "../../shared/store/settings/selectors";
import { openSettingsModal } from "../../shared/store/ui/actions";
import { Callback } from "../../types/Callback";
import { SETTING_FIELDS } from "../configurator/modals/SettingsModal/SettingsModal";
import "./userMenu.scss";
import UserType from "./UserType";

const modalData = {
  sections: [
    SETTING_FIELDS.UI_INTERFACE_LANGUAGE,
    SETTING_FIELDS.UI_MEASUREMENT_SYSTEM,
    SETTING_FIELDS.RESULTS_FIELD_LIST,
    SETTING_FIELDS.TECHNICAL_SPECIFICATION_COL,
  ],
  scope: settingsInfo.scopes.USER,
  title: <FormattedMessage id="settings" defaultMessage="Settings" />,
};

const DropdownMenu = (props: { openSettingsModal: Callback }) => {
  const selectedKeys = [];
  if (window.location.pathname === USER_PROFILE) {
    selectedKeys.push("viewProfile");
  }
  return (
    <Menu selectedKeys={selectedKeys} data-test="user-menu-menu">
      <Menu.Item key="viewProfile">
        <Link to={USER_PROFILE}>
          <Icon
            type="user"
            theme="outlined"
            className="anticon--normal margin-right--20"
          />
          <FormattedMessage id="view profile" defaultMessage="View Profile" />
        </Link>
      </Menu.Item>

      <Menu.Item
        key="settings"
        onClick={() => props.openSettingsModal()}
        data-test="menu-item-settings"
      >
        <>
          <Icon
            type="setting"
            theme="outlined"
            className="anticon--normal margin-right--20"
          />
          <FormattedMessage id="settings" defaultMessage="Settings" />
        </>
      </Menu.Item>

      <Menu.Item key="logout">
        <>
          <Link
            to={LOGIN}
            rel="noopener noreferrer"
            onClick={() => logout()}
            data-test="logout"
          >
            <Icon
              type="logout"
              theme="outlined"
              className="anticon--normal margin-right--20"
            />
            <FormattedMessage id="log out" defaultMessage="Log Out" />
          </Link>
        </>
      </Menu.Item>
    </Menu>
  );
};

const UserMenu: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const name = useSelector(selectUserFullName);
  const features = useSelector(featuresFlagSelector);
  const _hasMetricSystems = useSelector(hasMetricSystems);

  useEffect(() => {
    dispatch(getAccount());
  }, [dispatch]);

  useEffect(() => {
    if (!_hasMetricSystems) {
      dispatch(getConversions());
      dispatch(getMetricSystems());
    }
  }, [dispatch, _hasMetricSystems]);

  const openSettingsModalHandler = () => {
    dispatch(openSettingsModal(modalData));
  };

  return (
    <ErrorHandler>
      <Dropdown
        overlay={<DropdownMenu openSettingsModal={openSettingsModalHandler} />}
        placement="bottomCenter"
        className="headerWrapper__user-menu float--right"
        disabled={false /* capabilityInfo && capabilityInfo.disabled */}
      >
        <div>
          <Avatar icon="user" data-test="avatar" />

          <div className="headerWrapper__user">
            {name}
            {features.includes(FeatureFlag.CPQ) && <UserType />}
          </div>

          <Icon
            type="down"
            theme="outlined"
            className="anticon--16 anticon--blue margin-left--30 margin-right--20"
          />
        </div>
      </Dropdown>
    </ErrorHandler>
  );
};

export default UserMenu;
