import React, { ReactElement, ReactNode, useCallback } from "react";
import { FormAction } from "../../../../../generated/axios";
import { FormFieldWithValue } from "../../../../../shared/store/configurator/types";
import {
  Action,
  ACTION_TYPES,
  ActionsProvider,
  ActionType,
  InfoBox,
} from "../../Actions";
import Label from "../Label";
import {
  canDisplayActionButtons,
  dataBuilderByInputType,
  extractUnitOfMeasure,
} from "./enancedLabelUtils";
import "./enhancedLabel.scss";

export interface LabelParts {
  uom: string;
  cleanLabel: string;
}

export interface InfoBoxData {
  visible?: boolean;
  text?: string;
  media?: string;
  title?: string;
}

export interface ActionProviderData {
  actionsToDisplay: FormAction[];
  info: InfoBoxData;
  downloadUri?: string;
}

const defaultState: InfoBoxData = {
  text: "",
  media: "",
  title: "",
};

interface Props {
  field: FormFieldWithValue;
}

function EnhancedLabel({ field }: Props): ReactElement<ReactNode> {
  // component state hook
  const [infoBoxData, setInfoBoxData] = React.useState(defaultState);

  // item deconstruction
  const { inputType, fieldId, label } = field;

  // according to user choice
  const { uom, cleanLabel }: LabelParts = extractUnitOfMeasure(label);

  // creates data related to Action icons & InfoBox
  const dataBuilder = dataBuilderByInputType(inputType);

  const {
    actionsToDisplay = [],
    info = {},
    downloadUri,
  } = dataBuilder?.(field) ?? {};

  // gestione del click su un'icona action
  const actionClickHandler = useCallback(
    (action: string) => {
      // lo user clicca sull'icona info
      if (action === ACTION_TYPES.INFO) {
        setInfoBoxData({
          visible: true,
          ...info,
        });
      }
    },
    [info, setInfoBoxData]
  );

  const handleClose = () => {
    setInfoBoxData({
      visible: false,
      ...defaultState,
    });
  };

  const isHT = fieldId.endsWith(".ht");

  return (
    <div className="enhanced-label-wrapper">
      <div className="enhanced-label" data-test={`enhanced-label-${fieldId}`}>
        <Label
          defaultLabel={isHT ? "HT" : cleanLabel}
          uom={uom}
          fieldId={fieldId}
        />

        {canDisplayActionButtons(field) && (
          // mostra le actions solo per la option attiva
          <ActionsProvider actions={actionsToDisplay}>
            {(params: { actions: ActionType[] }) =>
              params.actions.map((action: ActionType) =>
                action.action === ACTION_TYPES.DOWNLOAD ? (
                  <Action
                    key={action.icon}
                    {...action}
                    downloadUri={downloadUri}
                  />
                ) : (
                  <Action
                    key={action.icon}
                    {...action}
                    clickHandler={actionClickHandler}
                  />
                )
              )
            }
          </ActionsProvider>
        )}
      </div>

      <InfoBox {...infoBoxData} handleVisibleChange={handleClose} />
    </div>
  );
}

export default React.memo(EnhancedLabel);
