import React from "react";

interface IVisibilityParams {
  conditions?: number[];
}

/**
 * Curried function, it gets a component used for handling visibility logic og bounds,
 * based on rules described in api spec.
 *
 * How to use in a React component:
 * ```
 * const ShowIfEnabled = handleVisibility(applicationTypeId);
 *
 * return (
 *  <ShowIfEnabled settings={bundleVisibilitySettingsForThisField}>
 *    ...
 *  </ShowIfEnabled>
 * )
 * ```
 */
export const handleVisibility: (
  applicationTypeId?: number
) => React.FC<IVisibilityParams> =
  (applicationTypeId) =>
  ({ conditions, children }) => {
    // if not set, always visible
    if (!conditions) {
      return <>{children}</>;
    }

    // without applicationTypeId, hide
    if (!applicationTypeId) {
      return null;
    }

    if (conditions.includes(applicationTypeId)) {
      return <>{children}</>;
    }

    return null;
  };
