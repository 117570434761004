import { ItemResultExt } from "../store/item/types";
import { IConvert, PseudoValue } from "./conversionFunctions";

export const tryConvert = (
  value: string,
  conversion: IConvert
): PseudoValue => {
  if (!conversion) {
    return value;
  }
  if (!conversion.defaultSystem && !conversion.chosenSystem) {
    return value;
  }
  if (conversion.canBeConvertedOneWay) {
    return conversion.fromBaseSystem(value);
  }
  return conversion.toPrecision(value);
};

export const convertCalculationValues = (
  itemResult: ItemResultExt,
  conversions: Record<string, IConvert>
): ItemResultExt => {
  if (!itemResult.calculationValues) {
    return itemResult;
  }
  const calculationValues = Object.keys(itemResult.calculationValues).reduce(
    (res, fieldId) => {
      const value = itemResult.calculationValues?.[fieldId];
      const conversion = conversions[fieldId];
      const convertedValue = tryConvert(value, conversion);
      res[fieldId] = convertedValue;
      return res;
    },
    {}
  );
  return { ...itemResult, calculationValues };
};
