import { FormattedMessage } from "react-intl";
import { Problem } from "../../generated/axios";
import { POST_CHANGE_PASSWORD } from "../store/account/consts";
import { GET_RESULTS } from "../store/item/constsResults";
import { EXPORT_CSV_MULTIPLE_ITEMS } from "../store/items/consts";
import { apiErrorsMessages } from "./locales/definedMessages";

interface BuildersParams {
  status: number;
  statusText: string;
  data?: Problem;
}

/**
 *  check if data has all fields with values
 * @param data
 */
export const isValidErrorData = (data?: Problem) => data?.type && data.status;

/**
 *
 * @param uri from the type
 */
export const shortenType = (uri: string) => {
  const parts = uri.split("/");
  return parts[parts.length - 1];
};

/**
 * default messages
 */
export const defaultErrorMessageBuilder = ({
  statusText,
  data,
}: BuildersParams) => {
  return data?.type && data.title
    ? data.title // map.title()
    : statusText; // map.statusText()
};

/**
 * if error was generated with GET_RESULTS
 */
export const geResultsErrorMessageBuilder = ({
  status,
  data,
}: BuildersParams) => {
  if (data && isValidErrorData(data)) {
    // data can be also a string (es. status 502)
    switch (shortenType(data.type)) {
      case "core-calculation-error":
        return data.message ?? data.title ?? "";

      case "core-calculation-invocation-error":
        if (status === 503) {
          return apiErrorsMessages["api.error.message.503.get_results"];
        }
    }
  }

  switch (status) {
    case 400:
      return apiErrorsMessages["api.error.message.400.get_results"];
    case 403:
      return apiErrorsMessages["api.error.message.403.get_results"];
    case 404:
      return apiErrorsMessages["api.error.message.404.get_results"];
    case 409:
      return apiErrorsMessages["api.error.message.409.get_results"];
    case 500:
      return apiErrorsMessages["api.error.message.500"];
    case 502:
      return apiErrorsMessages["api.error.message.502"];
    case 503:
      return apiErrorsMessages["api.error.message.503"];
    default:
      return apiErrorsMessages["api.error.message.default.get_results"];
  }
};

/**
 * if error was generated with POST_CHANGE_PASSWORD
 */
export const passwordResetErrorMessageBuilder = ({ data }: BuildersParams) => {
  if (data) {
    const type = shortenType(data.type);
    switch (type) {
      case "weak-password":
        return apiErrorsMessages["api.error.message.weak-password"];
      case "incorrect-password":
        return apiErrorsMessages["api.error.message.incorrect-password"];
    }
  }
  return apiErrorsMessages["api.error.message.default"];
};

/**
 * if error was generated with EXPORT_CSV_MULTIPLE_ITEMS because one or more selected project to export in csv format
 * has no item inside it --> a 400 error code is given
 */
export const csvErrorMessageBuilder = ({ data }: BuildersParams) => {
  if (data && data.status === 400) {
    return apiErrorsMessages["api.error.message.400.csv"];
  } else if (data && data.status === 403) {
    return apiErrorsMessages["api.error.message.missing-capability"];
  } else {
    return apiErrorsMessages["api.error.message.400"];
  }
};

export const mapProblemToMessage = (problem: Problem) => {
  switch (shortenType(problem.type)) {
    case "account-already-activated":
      return apiErrorsMessages["api.error.message.account-already-activated"];
    case "account-deactivated":
      return apiErrorsMessages["api.error.message.account-deactivated"];
    case "account-not-confirmed":
      return apiErrorsMessages["api.error.message.account-not-confirmed"];
    // case "conc-failure": return apiErrorsMessages["api.error.message.conc-failure"];
    // case "constraint-violation": return apiErrorsMessages["api.error.message.constraint-violation"];
    // case "core-calculation-error":  return apiErrorsMessages["api.error.message.core-calculation-error"];
    // case "core-calculation-invocation-error":  return apiErrorsMessages[ "api.error.message.core-calculation-invocation-error"];
    case "email-already-used":
      return apiErrorsMessages["api.error.message.email-already-used"];
    case "email-not-found":
      return apiErrorsMessages["api.error.message.email-not-found"];
    // case "entity-not-found": return apiErrorsMessages["api.error.message.entity-not-found"];
    case "incorrect-password":
      return apiErrorsMessages["api.error.message.incorrect-password"];
    case "login-already-used":
      return apiErrorsMessages["api.error.message.login-already-used"];
    case "missing-capability":
      return apiErrorsMessages["api.error.message.missing-capability"];
    // case "no-results-in-item":  return apiErrorsMessages["api.error.message.no-results-in-item"];
    // case "parameterized":  return apiErrorsMessages["api.error.message.parameterized"];
    // case "problem-with-message": return apiErrorsMessages["api.error.message.problem-with-message"];
    case "same-email":
      return apiErrorsMessages["api.error.message.same-email"];
    // case "too-many-results":  return apiErrorsMessages["api.error.message.too-many-results"];
    case "weak-password":
      return apiErrorsMessages["api.error.message.weak-password"];
    default:
      return apiErrorsMessages["api.error.message.default"];
  }
};

export const payloadVariantMessage = (
  message: string | FormattedMessage.MessageDescriptor
) => {
  if (typeof message !== "string" && message.id) {
    const key = message.id + ".inpage";
    if (apiErrorsMessages[key]) {
      return apiErrorsMessages[key];
    }
  }
  return message;
};

/**
 * high order function
 * @param actionType
 */
export const messageBuilders = (actionType: string) => {
  switch (actionType) {
    case GET_RESULTS:
      return geResultsErrorMessageBuilder;
    case POST_CHANGE_PASSWORD:
      return passwordResetErrorMessageBuilder;
    case EXPORT_CSV_MULTIPLE_ITEMS:
      return csvErrorMessageBuilder;
    default:
      return defaultErrorMessageBuilder;
  }
};
