import React from "react";
import { BaseField } from "../../../../generated/axios";
import { EqualHeightsElement } from "../../../../shared/components/EqualHeights";
import { Callback } from "../../../../types/Callback";

interface IProps {
  header: BaseField;
  orderField: string;
  orderIcon: React.ReactNode;
  toggleDirection: (fieldId: string) => Callback;
}

const Header: React.FC<IProps> = ({
  header,
  orderField,
  orderIcon,
  toggleDirection,
}) => {
  if (!header?.fieldId) return null;

  const selected = header.fieldId == orderField;

  return (
    <EqualHeightsElement group={header.fieldId}>
      <div
        className={`table__header ${selected && "table--selected"}`}
        onClick={toggleDirection(header.fieldId)}
      >
        {header.label}
        {selected && orderIcon}
      </div>
    </EqualHeightsElement>
  );
};

export default Header;
