import { Modal } from "antd";
import React from "react";
import { defineMessages, FormattedHTMLMessage } from "react-intl";
import { Callback } from "../../../types/Callback";
import FormHeader from "./FormHeader";
import "./style.scss";

const messages = defineMessages({
  serviceUnavailableTitle: {
    id: "serviceUnavailableTitle",
    defaultMessage: "Service unavailable",
  },
  serviceUnavailableSubTitle: {
    id: "serviceUnavailableSubTitle",
    defaultMessage:
      "The application is currently not available probably due to an update.<br>Please wait a few minutes or log in again later.<br>The application will restart automatically.",
  },
});

interface Props {
  checkServiceAvailability: Callback;
}

class ServiceUnavailable extends React.PureComponent<Props> {
  state = {};
  private intervalId: ReturnType<typeof setInterval> | undefined = undefined;

  componentDidMount(): void {
    const { checkServiceAvailability } = this.props;
    this.intervalId = setInterval(() => checkServiceAvailability(), 15000);
  }

  componentWillUnmount() {
    this.intervalId && clearInterval(this.intervalId);
    this.intervalId = undefined;
  }

  render() {
    return (
      <Modal centered={true} footer={null} visible={true} closable={false}>
        <form className="modal-form" data-test="serviceUnavailableModalForm">
          <FormHeader
            subTitle={
              <FormattedHTMLMessage {...messages.serviceUnavailableSubTitle} />
            }
          />
        </form>
      </Modal>
    );
  }
}

export default ServiceUnavailable;
