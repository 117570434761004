import { Button, Icon, Modal } from "antd";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { OptionLabels, OptionsGroup } from "../../../../../generated/axios";
import { KitDetails } from "./KitDetails";
import OptionDetails from "./OptionDetails";
import { OptionRow } from "./OptionRow";

interface IProps {
  label: React.ReactElement;
  optionLabels: OptionLabels;
  options: OptionsGroup[];
  showFieldName: boolean;
  showPrices: boolean;
  total?: number;
}

export const ModalOptions: React.FC<IProps> = ({
  label,
  optionLabels,
  options,
  showFieldName, // false ==> kit
  showPrices,
  total,
}) => {
  const [opened, setOpened] = useState(false);
  const openModal = useCallback(() => setOpened(true), [setOpened]);
  const closeModal = useCallback(() => setOpened(false), [setOpened]);
  return (
    <>
      {label}
      &nbsp;
      {options.length === 0 && !showPrices && (
        <FormattedMessage id="empty" defaultMessage="Empty" />
      )}
      {options.length > 0 && (
        <>
          <Icon
            className="icon--squared icon--squared-transparent"
            type="info-circle"
            theme="outlined"
            onClick={openModal}
          />
          <Modal
            visible={opened}
            title={label}
            onCancel={closeModal}
            footer={[
              <Button key="submit" type="primary" onClick={closeModal}>
                <FormattedMessage id="close" defaultMessage="Close" />
              </Button>,
            ]}
            width={640}
            wrapClassName="optionsGroup"
          >
            {options.map((optionGroup: OptionsGroup) => {
              return showFieldName &&
                !optionGroup.groupKey?.startsWith("kit") ? (
                <OptionDetails
                  key={optionGroup.groupKey}
                  optionLabels={optionLabels}
                  showPrices={showPrices}
                  optionsGroup={optionGroup}
                />
              ) : (
                <KitDetails
                  key={optionGroup.groupKey}
                  optionLabels={optionLabels}
                  showPrices={showPrices}
                  optionsGroup={optionGroup}
                />
              );
            })}
            {showPrices && total !== undefined ? (
              <OptionRow
                className={"optionsTotal"}
                key="total"
                label={
                  <FormattedMessage
                    id="total price"
                    defaultMessage="Total price"
                  />
                }
                price={total}
                showPrice={true}
              />
            ) : null}
          </Modal>
        </>
      )}
    </>
  );
};
