import React, { FunctionComponent } from "react";
import { Portfolio } from "../../generated/axios";
import AsDropdown from "./AsDropdown";
import AsLink from "./AsLink";
import Frontage, { FrontageProps } from "./Frontage";
import "./styles.scss";
import { BaseProps } from "./types";

interface Props extends FrontageProps, BaseProps {
  availablePortfolios: Portfolio[];
  menuOpenerIcon?: React.ReactElement;
}

const PortfolioSelector: FunctionComponent<Props> = (props) => {
  const {
    availablePortfolios,
    outerClickHandler,
    linkTo,
    menuOpenerIcon,
    icon,
    label,
    usedBy,
  } = props;

  const hasDropdown: boolean =
    availablePortfolios && availablePortfolios.length > 1;

  return hasDropdown ? (
    <AsDropdown
      outerClickHandler={outerClickHandler}
      availablePortfolios={availablePortfolios}
      linkTo={linkTo}
      menuOpenerIcon={menuOpenerIcon}
      usedBy={usedBy}
    >
      {icon && label && <Frontage icon={icon} label={label} />}
    </AsDropdown>
  ) : (
    <AsLink outerClickHandler={outerClickHandler} linkTo={linkTo}>
      <Frontage icon={icon} label={label} />
    </AsLink>
  );
};

export default PortfolioSelector;
