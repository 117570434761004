import React, { FunctionComponent } from "react";

export interface FrontageProps {
  icon?: React.ReactElement;
  label?: React.ReactElement;
}

const Frontage: FunctionComponent<FrontageProps> = React.memo((props) => {
  const { icon, label } = props;
  return (
    <>
      {icon && <div className="section__symbol">{icon}</div>}
      {label && <div>{label}</div>}
    </>
  );
});

export default Frontage;
