{
  "1 unit": "1 unità",
  "Append custom PDF": "Appendi PDF personalizzato",
  "ERROR_SHOWING_ITEM": "Impossibile mostrare l'item",
  "ERROR_UPPERCASE": "ERRORE",
  "Missing results": "Risultati mancanti",
  "Number of units": "Numero di unit",
  "Quoting is not allowed for a default project item": "La quotazioni di item del progetto di default non sono permesse.",
  "Save item into different project for quoting": "Per procedere con la quotazione salva questo item in un altro progetto.",
  "Upload PDF": "Carica PDF",
  "accountAlreadyActivated": "L'account è già stato attivato",
  "accountDeactivated": "L'account è stato disattivato",
  "actual visible fields": "Campi visibili effettivi",
  "add a note": "AGGIUNGI UNA NOTA AL PROGETTO",
  "additional details": "Dettagli aggiuntivi",
  "address": "INDIRIZZO",
  "address.placeholder": "Inserisci qui il tuo indirizzo",
  "api.error.message.400": "A causa di un errore la richiesta non può essere eseguita.",
  "api.error.message.400.csv": "Uno o più item tra quelli selezionati non possono essere esportati",
  "api.error.message.400.get_results": "Il calcolo ha restituito un errore. Prova a modificare i dati termici e premi calcola.",
  "api.error.message.400.get_results.inpage": "Il calcolo ha restituito un errore.<br>Prova a modificare i dati termici e premi calcola.",
  "api.error.message.403.get_results": "Il calcolo ha restituito troppi risultati. Scarica il file CSV per vedere tutti i risultati.",
  "api.error.message.403.get_results.inpage": "Il calcolo ha restituito troppi risultati.<br>Scarica il file CSV per vedere tutti i risultati.",
  "api.error.message.404.get_results": "Nessun risultato disponibile, premi calcola.",
  "api.error.message.409.get_results": "Errore durante la comunicazione col server. Premere di nuovo Calcola",
  "api.error.message.500": "Errore 500: Errore interno del server",
  "api.error.message.502": "Errore 502: Servizio non disponibile. Riprova tra qualche minuto.",
  "api.error.message.502.inpage": "Errore 502: Servizio non disponibile.<br>Riprova tra qualche minuto.",
  "api.error.message.503": "Errore 503: Servizio non disponibile. Riprova tra qualche minuto.",
  "api.error.message.503.get_results": "Servizio di calcolo non disponibile. Riprova tra qualche minuto.",
  "api.error.message.503.get_results.inpage": "Servizio di calcolo non disponibile.<br>Riprova tra qualche minuto.",
  "api.error.message.503.inpage": "Errore 503: Servizio non disponibile.<br>Riprova tra qualche minuto.",
  "api.error.message.account-already-activated": "Account già attivato",
  "api.error.message.account-deactivated": "L'account è stato disattivato, contatta gli amministratori",
  "api.error.message.account-not-confirmed": "L'utente non ha confermato la registrazione",
  "api.error.message.default": "Operazione fallita, riprova più tardi",
  "api.error.message.default.get_results": "Il calcolo ha restituito un errore generico.",
  "api.error.message.email-already-used": "Email già in uso!",
  "api.error.message.email-not-found": "Indirizzo email non registrato",
  "api.error.message.incorrect-password": "La vecchia password è sbagliata",
  "api.error.message.login-already-used": "Utente già registrato!",
  "api.error.message.missing-capability": "Manca il permesso per eseguire questa operazione",
  "api.error.message.same-email": "L'email fornita è la stessa dell'email corrente",
  "api.error.message.weak-password": "La password scelta è troppo semplice",
  "apply discount": "APPLICA SCONTO",
  "available fields": "Campi disponibili",
  "best choice": "MIGLIOR SOLUZIONE",
  "best ratio": "MIGLIOR RAPPORTO",
  "bounds": "Limiti",
  "calculate results": "ESEGUI IL CALCOLO",
  "calculating results": "Calcolo dei risultati...",
  "calculationResultsOf": "Risultati calcolo del {date}",
  "cancel": "Annulla",
  "cancel request warning": "Premi 'Interrompi calcolo' per interrompere il processo.",
  "certificate logo": "LOGO CERTIFICATO",
  "change e-mail": "cambia email",
  "change email ok": "La richiesta di modifica e-mail è stata elaborata correttamente, ti è stata inviata una e-mail",
  "change email ok return": "Richiesta di modifica e-mail elaborata correttamente, ricorda di utilizzare la nuova e-mail per autenticarti su Plair",
  "change email warning": "La modifica dell'indirizzo e-mail cambierà il tuo nome utente. Riceverai un'email con un link per confermare la tua azione. Successivamente è necessario utilizzare il nuovo indirizzo e-mail per autenticarsi su Plair",
  "change psw": "Cambia la password",
  "change psw ok": "Richiesta di modifica password elaborata, ti è stata inviata un'e-mail",
  "change results reset": "Cambiando i valori di input, i risultati verranno resettati ",
  "change your e-mail": "cambia la tua e-mail",
  "changed psw ok": "Password modificata correttamente",
  "choose range products": "Scegli un range di prodotti",
  "city": "città",
  "client data": "Dati cliente",
  "close": "Chiudi",
  "comment problem": "Scrivi un commento qui sotto riguardo al problema che hai riscontrato. Lunghezza massima 300 parole.",
  "company": "Azienda",
  "company.placeholder": "Inserisci qui il nome della tua azienda",
  "condensingTemperatureTolerance": "Tolleranza temp. di condensazione",
  "confirm": "Conferma",
  "confirm new password": "Conferma nuova password",
  "confirm unshare": "Conferma annullamento della condivisione",
  "confirm unshare body": "Sei sicuro di voler eliminare la condivisione con {email}?",
  "confirm.password.placeholder": "Conferma la tua nuova password!",
  "confirm.password.required": "Conferma la tua nuova password!",
  "confirm_password": "Conferma password",
  "confirmation question": "Sei sicuro di voler cambiare la tua password?",
  "continue": "Continua",
  "country": "Paese",
  "create new project": "Crea un nuovo progetto",
  "create quote": "CREA OFFERTA",
  "custom pdf required": "File PDF obbligatorio (Max 3MB)",
  "customPdf": "PDF Personalizzato",
  "customerRefPlaceholder": "Rif. cliente",
  "customerReference": "RIF. CLIENTE",
  "customize": "PERSONALIZZA",
  "customize technical specification": "Personalizza le specifiche tecniche",
  "date": "DATA",
  "delete item": "Elimina articolo",
  "delete project": "Elimina progetto",
  "discountPlaceholder": "Sconto",
  "displayItemNote": "INCLUDI NOTE",
  "download all": "Scarica tutto",
  "drawing": "DISEGNO",
  "duplicate item": "Duplica Articolo",
  "duplicate project": "Duplica Progetto",
  "email": "Email",
  "email.invalid": "L'input non è un'email valida",
  "email.placeholder": "Inserisci qui la tua nuova e-mail",
  "email.required": "Inserisci la tua nuova e-mail!",
  "empty": "vuota",
  "enter share": "Inserisci l'e-mail dell'utente per iniziare la condivisione",
  "err.input.password": "Per favore inserisci la tua password!",
  "err.input.username": "Inserisci la tua username!",
  "error": "Errore",
  "error no user": "Errore: Nessun utente per la chiave di reset, oppure l'e-mail è già in uso.",
  "evaporatingTemperatureTolerance": "Tolleranza temp. di evaporazione",
  "exception application types": "Errore sui Tipi di applicazione",
  "exception item": "Errore sull'Articolo",
  "exception items": "Errore sugli Articoli",
  "exception project": "Errore sul Progetto",
  "exception projects": "Errore sui Progetti",
  "export csv": "Esporta i risultati in CSV",
  "field-lists": "Elenco campi",
  "fileExceedLimit": "Il file supera il limite massimo di {1} MB",
  "first name": "NOME",
  "firstName": "Nome",
  "firstname.placeholder": "Inserisci qui il tuo nome",
  "forcedPdf": "VERSIONE PDF",
  "forgot psw": "Password dimenticata?",
  "generating pdf": "Generazione pdf...",
  "go": "Vai",
  "guided search": "RICERCA GUIDATA",
  "hide discount values": "MOSTRA VALORI SCONTATI",
  "horizontal": "Orizzontale",
  "import shared item": "Importa item condiviso",
  "import shared project": "Importa progetto condiviso",
  "indoor": "Interna",
  "input.insert.address.placeholder": "Inserisci qui l'indirizzo",
  "input.insert.lastname.placeholder": "Inserisci qui il cognome",
  "input.insert.name.placeholder": "Inserisci qui il nome",
  "input.insert.note.placeholder": "Inserisci qui una nota",
  "input.insert.offer_discount.placeholder": "Inserisci qui lo sconto sull'offerta",
  "input.insert.offer_number.placeholder": "Inserisci qui il numero dell'offerta",
  "input.insert.reference.placeholder": "Inserisci qui il riferimento",
  "input.insert.subject.placeholder": "Inserisci qui l'oggetto",
  "input.note": "Note",
  "inputRequiredPrivacyPolicy": "Spunta obbligatoria",
  "instructions": "istruzioni",
  "interface language": "Lingua dell'interfaccia",
  "item settings": "Impostazioni item",
  "item title": "TITOLO ARTICOLO",
  "itemDescription": "Descrizione articolo",
  "kit list": "Lista kit",
  "kit price": "Prezzo kit",
  "language": "Lingua",
  "languages": "Lingue",
  "last opened": "ULTIMO APERTO",
  "lastname.placeholder": "Inserisci qui il tuo cognome",
  "loading": "Caricamento...",
  "lock": "Blocca",
  "log out": "Disconnetti",
  "login": "ACCEDI",
  "login error": "Nome utente o password errati, riprova",
  "login error bad request": "Si è verificato un errore, riprova più tardi",
  "login.message": "per accedere al tuo account",
  "logo": "Logo",
  "logo error": "Un logo è necessario",
  "long": "Estesa",
  "luve logo": "LU-VE LOGO",
  "maxFluidPressureDrop": "Massima caduta pressione fluido",
  "maxResults": "Max num. di risultati",
  "maximumCapacityTolerance": "Massima tolleranza capacità",
  "measurement system": "Sistema di misura",
  "mechanicalConfigurationSidebarTitle": "Mechanical Configuration",
  "message.account_updated": "Account aggiornato",
  "message.adding_share": "Aggiunta condivisione",
  "message.adding_the_note": "Aggiunta nota",
  "message.all_units_unlocked": "Sbloccate tutte le unità",
  "message.clear_completed": "Pulizia completata",
  "message.clearing_item": "Pulizia articolo",
  "message.configuration_correctly_saved": "Configurazione salvata correttamente",
  "message.creating_quote": "Creazione offerta",
  "message.creating_the_project": "Creazione progetto",
  "message.csv_exported_successfully": "CSV esportato con successo",
  "message.data_loading": "Caricamento dati",
  "message.delete_completed": "Cancellazione completata",
  "message.deleting_item": "Cancellazione articolo",
  "message.deleting_project": "Cancellazione progetto",
  "message.duplicate_completed": "Duplicazione completata",
  "message.duplicating_item": "Duplicazione articolo",
  "message.duplicating_project": "Duplicazione progetto",
  "message.error_in_saving_configuration": "Errore nel salvataggio della configurazione",
  "message.error_loading_results_prices": "Errore nel caricamento dei prezzi",
  "message.error_saving_item": "Errore nel salvataggio dell'articolo",
  "message.exporting_csv": "Esportazione CSV",
  "message.form_not_valid": "La form non è valida, ri-controlla!",
  "message.generating_pdf": "Generazione PDF",
  "message.import_completed": "Importazione completata",
  "message.import_item_completed": "Importazione item completata",
  "message.importing_shared_item": "Importazione item condiviso",
  "message.importing_shared_project": "Importazione progetto condiviso",
  "message.invalid_data": "Dati non validi",
  "message.invalid_item_name": "Nome articolo non valido",
  "message.item-active-in-another-tab": "Questo articolo è attivo in un altro tab. Verrai reindirizzato alla home page.",
  "message.item_shared_successfully": "Articolo condiviso con successo",
  "message.loading_completed": "Caricamento completato",
  "message.loading_data": "Caricamento dati",
  "message.loading_data_item": "Caricamento dati articolo",
  "message.loading_item_completed": "Caricamento articolo completato",
  "message.loading_items": "Caricamento articoli",
  "message.loading_new_item_data": "Caricamento dati nuovo articolo...",
  "message.loading_results": "Caricamento risultati...",
  "message.locking_unit": "Bloccaggio unità...",
  "message.locking_unit_error": "Errore nel bloccaggio dell'unità",
  "message.mechanical_configuration_form_not_valid": "La configurazione della form meccanica non è valida, ri-controlla.",
  "message.no_result_found_check_thermal_configuration": "Nessun risultato trovato, ri-controlla la configurazione della form termica e premi calcola",
  "message.operation_failed": "Operazione fallita",
  "message.operation_failed_try_later": "Operazione fallita, riprova più tardi",
  "message.operation_still_in_progress": "Questa operazione è ancora in esecuzione",
  "message.operation_success": "Operazione eseguita con successo",
  "message.pdf_is_ready": "Il PDF è pronto",
  "message.please_wait": "Attendi, per favore",
  "message.please_wait_few_seconds": "Ancora solo alcuni secondi, attendi per favore",
  "message.please_wait_still_loading": "Ancora in caricamento, attendi per favore",
  "message.project_created": "Progetto creato",
  "message.project_shared_successfully": "Progetto condiviso con successo",
  "message.removing_share": "Rimozione condivisione",
  "message.results_loaded": "Risultati caricati",
  "message.saved": "Salvato",
  "message.saving_completed": "Salvataggio completato",
  "message.saving_data": "Salvataggio dati",
  "message.saving_item_in_project": "Salvataggio articolo nel progetto",
  "message.select_at_least_one_element": "Seleziona almeno un elemento",
  "message.share_removed": "Condivisione rimossa",
  "message.still_loading_results_please_wait": "Caricamento risultati, attendi per favore",
  "message.switching_duty_mode": "Cambio in modalità di servizio",
  "message.thermal_configuration_form_not_valid": "La configurazione della form termica non è valida, ri-controlla.",
  "message.unit_locked": "Unità bloccata",
  "message.unit_unlocked": "Unità sbloccata",
  "message.unlocking_all_units": "Sbloccaggio di tutte le unità...",
  "message.unlocking_all_units_error": "Errore nello sbloccaggio di tutte le unità",
  "message.unlocking_unit": "Sbloccaggio unità...",
  "message.unlocking_unit_error": "Errore nello sbloccaggio dell'unità",
  "message.updating_completed": "Aggiornamento completato",
  "message.updating_data": "Aggiornamento dati",
  "message.updating_item_name": "Aggiornamento nome articolo",
  "message.updating_the_project": "Aggiornamento del progetto",
  "message.uploading_error": "Errore nel caricamento",
  "message.you_have_new_item": "Hai un nuovo articolo!",
  "minFluidPressureDrop": "Minima caduta pressione fluido",
  "minimumCapacityTolerance": "Minima tolleranza capacità",
  "missing results export information": "Per visualizzare i dati di tutte le unit esporta i risultati in formato CSV.",
  "missing results information warning": "Il calcolo ha ritornato {N} risultati ma solo {M} sono visualizzati.",
  "modal confirm delete project": "Confermi la cancellazione del progetto?",
  "modal.subtitle.clear.item": "Tutti i dati verranno cancellati. Vuoi continuare?",
  "modal.subtitle.delete.item": "Vuoi cancellare l'item? Perderai tutto il lavoro fatto fino ad ora",
  "modal.subtitle.delete.project": "Vuoi cancellare il progetto? Perderai tutto il lavoro fatto fino ad ora",
  "modal.subtitle.duplicate.item": "Vuoi duplicare l'item? Un nuovo item verrà creato con gli stessi dati",
  "modal.subtitle.duplicate.project": "Vuoi duplicare il progetto? Un nuovo progetto verrà creato con gli stessi dati",
  "modal.subtitle.import.item": "Importa l'item in un progetto",
  "modal.subtitle.save.item": "Salva l'item in un progetto.",
  "modal.title.clear": "Pulisci articolo",
  "modal.title.delete": "Elimina articolo",
  "modal.title.deleteProject": "Elimina progetto",
  "modal.title.duplicate": "Duplica articolo",
  "modal.title.duplicateProject": "Duplica progetto",
  "modal.title.importItem": "Importa l'articolo in un progetto",
  "modal.title.note": "Nota",
  "modal.title.noteProject": "Nota",
  "modal.title.save": "Salva l'articolo in un altro progetto",
  "modalItemSaveAndFinish.quantity": "Quantità",
  "modalPreviewTS.DISCOUNT": "SCONTO",
  "modalPreviewTS.NET VALUE": "VALORE NETTO",
  "modalPreviewTS.QUANTITY": "QUANTITÀ",
  "modalPreviewTS.QUANTITY x VALUE": "QUANTITÀ x VALORE",
  "modalPreviewTS.VERSION": "VERSIONE",
  "modalPreviewTS.customerReference": "RIF.CLIENTE",
  "modalPreviewTS.customizeTechnicalSpecification": "Personalizza le specifiche tecniche",
  "modalPreviewTS.downloadTechnicalSpecification": "SCARICA",
  "modalPreviewTS.name": "NOME",
  "modalPreviewTS.position": "POSIZIONE",
  "modalPreviewTS.projectDiscount": "SCONTO PROGETTO",
  "modalPreviewTS.totalItemNetValue": "VALORE NETTO TOTALE ARTICOLI",
  "modalPreviewTS.totalProjectValue": "VALORE TOTALE PROGETTO",
  "modalQuote.create.submitLabel": "Crea offerta",
  "modalQuote.create.title": "Crea offerta",
  "modalQuote.update.submitLabel": "Aggiorna offerta",
  "modalQuote.update.title": "Aggiorna offerta",
  "my projects": "I miei progetti",
  "name": "NOME",
  "new e-mail": "Nuova e-mail",
  "new password": "nuova password",
  "new project": "Nuovo Progetto",
  "new tab": "(nuovo tab)",
  "new-config-prefix": "Nuova configurazione",
  "new.item.title": "Nuovo articolo",
  "new.password.placeholder": "Inserisci qui la tua nuova password",
  "new.password.required": "Inserisci la tua nuova password!",
  "no confirmation": "Non ho ricevuto le istruzioni di conferma",
  "no doc available": "Nessun documento disponibile",
  "no results": "Nessun risultato trovato, <br/> controlla la configurazione termica e premi 'Calcola'",
  "no.other.logo": "Nessun altro logo in uso",
  "noCustomPdf": "Nessun PDF personalizzato",
  "noValue": "-",
  "noValue.connectionBoxType": "Non selezionato",
  "noValue.tubePattern": "Non selezionato",
  "note": "NOTA",
  "note placeholder": "Digita qui il testo. Max 40 parole.",
  "note subtitle": "Scrivi la nota qui sotto. Lunghezza massima 40 parole",
  "of": "di",
  "offerDate": "Data offerta",
  "offerDiscount": "Sconto offerta",
  "offerNumber": "Offerta N°",
  "ok": "Ok",
  "old password": "Vecchia password",
  "old.password.placeholder": "Inserisci la tua vecchia password",
  "old.password.required": "Inserisci la tua vecchia password!",
  "open project": "Apri Progetto",
  "open quote": "APRI OFFERTA",
  "option list": "Lista opzioni",
  "option price": "Prezzo opzione",
  "optionprice.cablingCover": "Copertura cablaggio",
  "optionprice.casingMaterial": "Materiale involucro",
  "optionprice.coilFrame": "Telaio della bobina",
  "optionprice.connectionBox": "Scatola di connessione",
  "optionprice.connectionBoxType": "Scatola di connessione",
  "optionprice.cover": "Cover",
  "optionprice.defrostSystem": "Sistema di scongelamento",
  "optionprice.dripTray": "Vassoio antigoccia",
  "optionprice.expansionTank": "Vasca d'espansione",
  "optionprice.fan": "Ventilatore",
  "optionprice.feetType": "Tipo di piedi",
  "optionprice.finPack": "Pacchetto alette",
  "optionprice.flanged": "Flangiato",
  "optionprice.header": "Intestazione",
  "optionprice.included": "Incluso",
  "optionprice.insulationType": "Vassoio antigoccia isolato",
  "optionprice.kit": "Kit",
  "optionprice.kitForFan": "Kit per ventilatore",
  "optionprice.packingType": "Packing",
  "optionprice.predispositionFeet": "Predisposizione piedi",
  "optionprice.safetySwitchType": "Interruttore di sicurezza",
  "optionprice.sprayWaterType": "Spray Acqua",
  "optionprice.switchboardType": "Switchboard",
  "optionprice.wiring": "Cablaggio",
  "optionprice.wiringConfigType": "Tipo configurazione cablaggio",
  "or": "oppure",
  "other information": "Altre informazioni",
  "otherLogo": "Altro logo",
  "outdoor": "Esterno",
  "output": "OUTPUT",
  "password": "password",
  "password empty": "Inserisci la tua password",
  "password inconsistent": "Le due password non sono coincidenti",
  "password too weak": "Password troppo debole, deve contenere più di 4 caratteri",
  "password.invalid": "Inserisci la tua password attuale!",
  "password.placeholder": "Inserisci qui la tua password attuale",
  "password.required": "Inserisci la tua password!",
  "percentage": "PERCENTUALE",
  "personal data": "Dati personali",
  "personal settings": "Impostazioni personali",
  "placeholder.input.new.project": "Digita qui il titolo del tuo progetto",
  "placeholder.input.password": "Digita qui la password",
  "placeholder.input.passwordAgain": "Digita nuovamente la password",
  "placeholder.input.search": "inserire il testo di ricerca",
  "placeholder.input.username": "io",
  "placeholder.inputNumber.ExamplePerc": "es. 10%",
  "placeholder.report.problem": "Digita il testo qui. Max 300 parole.",
  "platformInfo.AirThinker": "AirThinker",
  "platformInfo.FrontEnd": "FrontEnd",
  "platformInfo.HSP": "HSP",
  "platformInfo.apiVersion": "Api",
  "platformInfo.backendVersion": "BackEnd",
  "position": "POSIZIONE",
  "positionPlaceholder": "Posizione",
  "price": "PREZZO",
  "priceListInfo": "I prezzi si riferiscono al listino \"{name}\" del {date}.",
  "priceListInfoExpired": "I prezzi si riferiscono al listino \"{name}\" del {date}. Dal {expired} è disponibile il nuovo listino \"{new_name}\". Aggiorna i prezzi con il bottone \"Calcola risultati\".",
  "price_not_loaded": "Prezzo non caricato",
  "privacyPolicy": "Privacy Policy",
  "privacyPolicyClaim": "Ho letto e compreso la {1}",
  "privacyPolicyUrl": "https://www.luve.it/cms/view/registrati/informativa-iscrizione-area-riservata/s91/c1769",
  "processing request": "Richiesta di elaborazione, attendere...",
  "project details": "Dettaglio del progetto",
  "project id": "ID PROGETTO",
  "project settings": "Impostazioni del progetto",
  "project title": "TITOLO",
  "project title empty": "Il titolo del progetto non può essere vuoto",
  "projects shared with me": "Progetti condivisi con me",
  "quantity.required": "Valore obbligatorio",
  "quantityPlaceholder": "Quantità",
  "quick calculation": "Calcolo rapido",
  "quote present": "Presente offerta",
  "rating": "Valutazione",
  "recent works": "Attività recenti",
  "reference": "Riferimento",
  "refresh page modal content": "Per favore, ricarica la pagina. Clicca il pulsante OK.",
  "refresh page modal title": "Ricarica la pagina",
  "register": "Registra",
  "registration": "Registrazione",
  "registration error": "Errore durante la registrazione, riprova più tardi",
  "registration ok": "Processo di registrazione completato correttamente",
  "registration process ok": "Richiesta di registrazione elaborata, ti è stata inviata un'e-mail",
  "registration.email.placeholder": "johansson.erik@luvegroup.com",
  "registration.required.email": "Inserisci la tua email!",
  "registration.select.placeholder": "Seleziona il tuo paese",
  "registration.select.required": "Inserisci il tuo paese!",
  "remember me": "Ricordami",
  "repeat operation": "Ripetere l'operazione nella pagina Visualizza profilo",
  "report a problem": "Segnala un problema",
  "report sent successfully": "Il problema è stato inviato con successo",
  "request_canceled_by_the_user": "Richiesta cancellata dall'utente",
  "resend": "Reinvia",
  "resend error": "Errore nella richiesta di reinvio delle istruzioni, riprova più tardi",
  "resend instructions": "Rimanda intruzioni",
  "resend instructions ok": "Invio richiesta di istruzioni elaborata, ti è stata inviata un'e-mail con le istruzioni per registrarti",
  "reset email error": "Errore durante la reimpostazione della richiesta e-mail, riprova più tardi",
  "reset error": "Errore nella reimpostazione della password, riprova più tardi",
  "reset ok with mail": "Reimpostazione della richiesta di password elaborata, ti è stata inviata un'e-mail",
  "reset password": "Resetta la password",
  "results table view": "Visualizzazione tabella risultati",
  "results-table.missing-options": "Alcune delle opzioni selezionate non sono presenti in questa unit",
  "results-table.note": "NOTE",
  "retry": "Riprova",
  "save": "Salva",
  "save and finish": "SALVA E CONCLUDI",
  "save and finish modal desc": "Vuoi salvare l'unità: {unitId} come la tua scelta preferita?",
  "save personal data": "Salva dati personali",
  "saved at": "salvato",
  "search existing project": "Cerca un progetto esistente",
  "second name": "SECONDO NOME",
  "select a project": "Seleziona un progetto",
  "select-portfolio": "Seleziona portfolio prodotti",
  "select.city.placeholder": "Seleziona la tua città",
  "select.lang": "Seleziona la tua lingua",
  "select.metric.sys": "Seleziona il tuo sistema metrico",
  "select.project.title": "Digita il titolo di un progetto esistente",
  "select_application type": "Seleziona tipo di applicazione",
  "send": "Invia",
  "serviceUnavailableSubTitle": "L'applicazione non è al momento disponibile probabilmente a causa di un aggiornamento.<br>Si prega di attendere qualche minuto o ricollegarsi più tardi.<br>L'applicazione ripartirà in automatico.",
  "serviceUnavailableTitle": "Servizio non disponibile",
  "setToDefaults": "Imposta valori di default",
  "settings": "Impostazioni",
  "settings.measurement system tip in user scope": "Questa impostazione è relativa solo ai nuovi progetti e non ai progetti esistenti. Modificare la configurazione del progetto se si vuole cambiare il sistema di misura dell'articolo.",
  "share": "condividi",
  "share item": "Condividi Articolo",
  "share project": "Condividi Progetto",
  "shared selected": "condivisione selezionata",
  "sharedBy": "Condiviso da",
  "short": "Breve",
  "show sound power level": "MOSTRA LIVELLO DI POTENZA SONORA",
  "showSoundPowerLevel": "MOSTRA LIVELLO DI POTENZA SONORA",
  "soundLevelTolerance": "Livello di tolleranza sonora",
  "spare parts": "PARTI DI SCORTA",
  "step 1": "PASSO 1",
  "step 2": "PASSO 2",
  "stepper.calculation_results": "Risultati calcolo",
  "stepper.options": "Opzioni",
  "stepper.thermal_configuration": "Configurazione termica",
  "subject": "Oggetto",
  "summary table": "TABELLA DI SOMMARIO",
  "surname": "Cognome",
  "technical specification": "SPECIFICHE TECNICHE",
  "text.insert.note": "Inserisci qui le tue note...",
  "textarea.required": "Un testo è obbligatorio",
  "thermalConfigurationSidebarTitle": "Thermal Configuration",
  "tooltip-add-note-item": "Aggiungi una nota",
  "tooltip-clear-item": "Pulisci articolo",
  "tooltip-delete-item": "Cancella articolo",
  "tooltip-duplicate-item": "Duplica articolo",
  "tooltip-new-item": "Crea un nuovo articolo",
  "tooltip-save-item": "Salva l'articolo in un altro progetto",
  "tooltip-share-item": "Condividi l'articolo",
  "tooltip-view-note-item": "Leggi la nota",
  "total": "Totale",
  "total price": "Prezzo totale",
  "type.password.again.placeholder": "Ridigita qui la nuova password",
  "type.password.placeholder": "Digita qui la tua password",
  "unit price": "Prezzo unità",
  "unlock": "Sblocca",
  "unlockAll": "Unlock all",
  "update quote": "AGGIORNA OFFERTA",
  "upload another logo": "CARICA UN ALTRO LOGO",
  "upload custom pdf": "CARICA PDF PERSONALIZZATO",
  "upload file type": "Tipo file: .doc, .docx, .pdf, .jpg, .png, .gif",
  "upload files": "Carica uno o più file",
  "user exists": "Utente già presente a sistema",
  "userNotFound": "Utente non trovato",
  "username": "Nome utente",
  "utilities": "Utilità",
  "validation.equal": "Deve essere uguale a {1}",
  "validation.gt": "Deve essere maggiore di {1}",
  "validation.gte": "Deve essere maggiore o uguale di {1}",
  "validation.in": "Deve essere uguale a una delle opzioni definite",
  "validation.lt": "Deve essere minore di {1}",
  "validation.lte": "Deve essere minore o uguale a {1}",
  "validation.max": "Deve essere minore di {1}",
  "validation.max.exTempDifference": "La differenza deve essere minore di {1}",
  "validation.min": "Deve essere maggiore di {1}",
  "validation.min.exTempDifference": "La differenza deve essere maggiore di {1}",
  "validation.range": "Deve essere compreso tra {1} e {2}",
  "validation.range.evaporatingTemperature": "Deve essere compreso tra {1} e {2}",
  "validation.regexp": "Deve corrispondere {1}",
  "validation.required": "{1} è obbligatorio",
  "validation.required.capacity": "{1} è richiesto",
  "validation.requiredIfEmpty": "Almeno uno di questi campi è obbligatorio",
  "verify email": "Verifica email",
  "version length": "Lunghezza versione",
  "versionPlaceholder": "Versione",
  "vertical": "Verticale",
  "view": "Visualizza",
  "view details": "Dettagli",
  "view partial results": "Vedi risultati parziali",
  "view profile": "Profilo utente",
  "view/change settings": "Modifica le impostazioni",
  "wait for the download": "Attendere il download",
  "warning": "Avviso",
  "zip code": "CAP",
  "zip.placeholder": "Inserisci qui il tuo codice postale",
  "{n} units": "{n} unità"
}
