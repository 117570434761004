import React from "react";
import { Context, IContext } from "./EqualHeightsWrapper";

interface Props {
  group: string;
}

export default class EqualHeightsElement extends React.Component<
  Props,
  IContext
> {
  static contextType = Context;

  ref = React.createRef<HTMLDivElement>();

  constructor(props: Props, context: IContext) {
    super(props, context);
    this.context.addElement(this.props.group, this.ref);
  }

  render() {
    const { children } = this.props;
    return <div ref={this.ref}>{children}</div>;
  }
}
