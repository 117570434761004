import { OrientationEnum } from "../../../generated/axios";
import { Any } from "../../../types/Any";
import { DroppableItem } from "../settings/types";
import {
  FIELDLIST_UPDATE,
  LANGUAGES_UPDATE,
  ORIENTATION_UPDATE,
  RESET_SETTINGS_TEMP,
} from "./consts";

export interface IAction {
  type: string;
  payload?: Any;
  meta?: Any;
}

export const updateLanguage = (payload: object) => ({
  type: LANGUAGES_UPDATE,
  payload,
});

export const updateOrientation = (payload: OrientationEnum) => ({
  type: ORIENTATION_UPDATE,
  payload,
});

export const updateFieldList = (payload: DroppableItem[]) => ({
  type: FIELDLIST_UPDATE,
  payload,
});

export const resetSettingsTemp = () => ({
  type: RESET_SETTINGS_TEMP,
});
