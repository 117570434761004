import React from "react";
import "./ribbon.scss";

interface Props {
  name: string;
}

export const Ribbon = React.memo((props: Props) => {
  const { name } = props;
  if (!name || name === "production") {
    return null;
  }
  return (
    <div className="ribbon">
      <a>{name}</a>
    </div>
  );
});
