import { Button, Checkbox, Form, Input, Row } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import React, { FormEvent, FunctionComponent } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import {
  CPQ_LOGIN,
  HOME,
  PASSWORD_RESET,
  REGISTRATION_EMAIL,
  RESEND_INSTRUCTIONS,
} from "../../config/paths";
import { isAuthenticated, login, logout } from "../../shared/auth";
import { shortenType } from "../../shared/lib/apiErrorMessages";
import { messages } from "../../shared/lib/locales/definedMessages";
import { Callback } from "../../types/Callback";
import RedirectAuthenticated from "../auth/RedirectAuthenticated";
import { ACCOUNT_DEACTIVATED } from "./consts";
import Layout from "./Layout";
import "./layout.scss";

const ErrorRow: FunctionComponent = ({ children }): React.ReactElement => (
  <Row>
    <div className="has-error" data-test="home-login-error">
      <div className="ant-form-explain">{children}</div>
    </div>
  </Row>
);

const initialStateLogin = () => ({
  isAuthenticated: isAuthenticated(),
  error: false,
  errorCode: undefined,
  type: "",
});

const SideContent = React.memo(() => (
  <>
    <span className="publicPagesLayout__action">Log in</span>{" "}
    <FormattedMessage
      id="login.message"
      defaultMessage="to access your account"
    />
  </>
));

const RegistrationLinkMessage = React.memo(() => (
  <Row className="publicPagesLayout__bottom">
    <span className="publicPagesLayout__new-user">New User? </span>
    <Link
      to={REGISTRATION_EMAIL}
      className="publicPagesLayout__new-user publicPagesLayout__new-user--register"
      data-test="register"
    >
      <FormattedMessage id="register" defaultMessage="Register" />
    </Link>
  </Row>
));

const ResendLinkMessage = React.memo(() => (
  <Row>
    <Form.Item className="publicPagesLayout__forgot-psw">
      <Link
        to={RESEND_INSTRUCTIONS}
        data-test="resend-instructions"
        href=""
        className="publicPagesLayout__link"
      >
        <FormattedMessage
          id="no confirmation"
          defaultMessage="Didn't receive confirmation istructions"
        />
      </Link>
    </Form.Item>
  </Row>
));

const ForgotPwdMessage = React.memo(() => (
  <Row>
    <Form.Item className="publicPagesLayout__link">
      <Link to={PASSWORD_RESET} data-test="forgot-password" href="">
        <FormattedMessage id="forgot psw" defaultMessage="Forgot password?" />
      </Link>
    </Form.Item>
  </Row>
));

const BadRequestErrorMessage = React.memo(() => (
  <FormattedMessage
    id="login error bad request"
    defaultMessage="There's been an error, please try again later"
  />
));

const DeactivatedErrorMessage = React.memo(() => (
  <FormattedMessage
    id="api.error.message.account-deactivated"
    defaultMessage="The account has been deactivated, contact the administrators"
  />
));

const GenericErrorMessage = React.memo(() => (
  <FormattedMessage
    id="login error"
    defaultMessage="Incorrect username or password, please retry"
  />
));

interface OwnProps {
  onLoginSuccessOperations: Callback;
  withCPQ: boolean;
}

interface State {
  isAuthenticated: boolean;
  error: boolean;
  errorCode?: number;
  type: string;
}

interface LoginProps extends OwnProps, FormComponentProps, InjectedIntlProps {}

class Login extends React.Component<LoginProps, State> {
  state: State = initialStateLogin();

  submit = (e: FormEvent) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        this.logout();
      } else {
        const { username, password, rememberMe } = values;
        this.login(username, password, rememberMe).finally();
      }
    });
  };

  login = async (username: string, password: string, rememberMe?: boolean) => {
    const { onLoginSuccessOperations } = this.props;
    try {
      await login(username, password, rememberMe);
      this.setState(initialStateLogin());
      if (onLoginSuccessOperations) onLoginSuccessOperations();
    } catch (error) {
      const { response: { status = -1, data = {} } = {} } = error;
      this.setState((state) => ({
        ...state,
        isAuthenticated: isAuthenticated(),
        error: true,
        type: data.type ? shortenType(data.type) : "",
        errorCode: status,
      }));
      logout(); // calling action, not the class method
    }
  };

  logout = () => {
    logout();
    this.setState(initialStateLogin());
  };

  renderError = () => {
    const { error, errorCode, type } = this.state;

    return error ? (
      <ErrorRow>
        {errorCode === 502 ? (
          <BadRequestErrorMessage />
        ) : type === ACCOUNT_DEACTIVATED ? (
          <DeactivatedErrorMessage />
        ) : (
          <GenericErrorMessage />
        )}
      </ErrorRow>
    ) : null;
  };

  render() {
    const { intl, withCPQ, form } = this.props;
    const { getFieldDecorator } = form; // this.props.form is been injected by Form.create()

    return (
      <RedirectAuthenticated to={HOME}>
        <Layout>
          <Layout.LeftSide>
            <SideContent />
          </Layout.LeftSide>
          <Layout.RightSide>
            <Form className="login" onSubmit={this.submit}>
              {withCPQ && (
                <Row>
                  <a className="publicPagesLayout__link" href={CPQ_LOGIN}>
                    Login with CPQ
                  </a>
                </Row>
              )}
              <Row>
                <Form.Item
                  label={
                    <FormattedMessage id="username" defaultMessage="Username" />
                  }
                  colon={false}
                  className="publicPagesLayout__label"
                >
                  {getFieldDecorator("username", {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage(messages.inputUsernameErr),
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      className="publicPagesLayout__input publicPagesLayout__input--mail"
                      placeholder={intl.formatMessage(
                        messages.inputUsernamePlaceholder
                      )}
                      data-test="username"
                    />
                  )}
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  label={
                    <FormattedMessage id="password" defaultMessage="Password" />
                  }
                  colon={false}
                  className="publicPagesLayout__password-row"
                >
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage(messages.inputPasswordErr),
                      },
                    ],
                  })(
                    <Input
                      type="password"
                      size="large"
                      className="publicPagesLayout__input"
                      placeholder={intl.formatMessage(
                        messages.inputPasswordPlaceholder
                      )}
                      data-test="password"
                    />
                  )}
                </Form.Item>
              </Row>

              <ForgotPwdMessage />

              <ResendLinkMessage />

              <Row>
                <Form.Item className="publicPagesLayout__remember">
                  {getFieldDecorator("rememberMe", {
                    valuePropName: "checked",
                    initialValue: true,
                  })(
                    // <Checkbox className="publicPagesLayout__remember">Remember me</Checkbox>
                    <Checkbox>
                      <FormattedMessage
                        id="remember me"
                        defaultMessage="Remember"
                      />
                    </Checkbox>
                  )}
                </Form.Item>
              </Row>

              <RegistrationLinkMessage />

              {this.renderError()}

              <Button
                type="primary"
                htmlType="submit"
                className="publicPagesLayout__button"
                data-test="login"
              >
                <FormattedMessage id="go" defaultMessage="GO" />
              </Button>
            </Form>
          </Layout.RightSide>
        </Layout>
      </RedirectAuthenticated>
    );
  }
}

const LoginForm = Form.create<LoginProps>()(Login);

export default injectIntl<OwnProps>(LoginForm);
