import { Col } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import IconLink from "../configurator/components/IconLink";

export const TitleBar = React.memo(() => (
  <>
    <Col span={1} offset={1}>
      <IconLink />
    </Col>
    <Col span={22}>
      <div>
        <FormattedMessage id="new.item.title" defaultMessage="New Item" />
      </div>
    </Col>
  </>
));

export const DescriptionBar = React.memo(() => (
  <Col span={23} offset={1}>
    <div className="item-selection__description">
      <FormattedMessage
        id="choose range products"
        defaultMessage="Choose a range of products"
      />
    </div>
  </Col>
));
