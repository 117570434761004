import React from "react";
import {
  OptionLabels,
  OptionsGroup,
  OptionValue,
} from "../../../../../generated/axios";
import { OptionRow } from "./OptionRow";

interface IProps {
  optionLabels: OptionLabels;
  showPrices: boolean;
  optionsGroup: OptionsGroup;
}

export const KitDetails: React.FC<IProps> = ({
  optionLabels,
  showPrices,
  optionsGroup,
}) => {
  // A OptionRow for each detail with a value!
  return (
    <>
      {optionsGroup.fields
        ?.filter(
          (field) =>
            field.value !== "" &&
            field.value !== undefined &&
            field.value !== null
        )
        .map((field: OptionValue, index: number) => {
          const optionLabelValue = field.fieldKey
            ? optionLabels[field.fieldKey] ?? {}
            : {};
          const label = optionLabelValue[field.value] || field.value;
          return (
            <OptionRow
              key={index}
              label={label}
              price={field.price}
              showPrice={showPrices}
            />
          );
        })}
    </>
  );
};
