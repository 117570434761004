import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { get, isEmpty } from "lodash";
import React, { ReactElement, ReactNode, useState } from "react";
import { connect } from "react-redux";
import { InputProps } from ".";
import { hasReadPermission } from "../../../../shared/store/item/selectors";
import { IStore } from "../../../../shared/store/types";
import { FormEvent } from "../../../../types/configurator";
import EnhancedOption from "./EnhancedOption";
import ReadonlyField from "./ReadonlyField";

interface IStoreProps {
  isReadonly: boolean;
}

interface IProps extends IStoreProps, InputProps {}

const CheckboxInput = (props: IProps): ReactElement<ReactNode> | null => {
  const [errorCounter, setErrorCounter] = useState("0");

  const {
    isReadonly,
    fieldId = "",
    label = "",
    value,
    options = [],
    fieldActions = [],
    optionActions = {},
  } = props;

  const actions = { ...optionActions };
  if (fieldActions.length) {
    isEmpty(actions["true"]) || (actions["true"] = fieldActions);
    isEmpty(actions["false"]) || (actions["false"] = fieldActions);
  }

  const onChangeError = () => {
    setErrorCounter(`${Date.now()}`);
  };

  const onChange = (e: CheckboxChangeEvent): void => {
    if (props.onChange) {
      const formEvent: FormEvent = {
        field: fieldId,
        value: Boolean(e.target.checked),
        onError: onChangeError,
      };
      props.onChange(formEvent);
    }
  };

  // if it has only once child then disable it
  const hasOnlyOneChild = () => {
    return options.length === 1;
  };

  if (isReadonly) {
    const text: string = value ? "✓" : "✗";
    return (
      <>
        <label>{label}</label>
        <ReadonlyField text={text} />
      </>
    );
  }

  const id = "configuration-form--" + fieldId;

  if (isEmpty(actions)) {
    return (
      <Checkbox
        key={`${fieldId}-${value}_${errorCounter}`}
        id={id}
        disabled={hasOnlyOneChild()}
        defaultChecked={!!value}
        onChange={onChange}
        data-test={id}
      >
        {label}
      </Checkbox>
    );
  }

  return (
    <div>
      <EnhancedOption
        key={label}
        option={{ label, value }}
        actions={get(actions, `${value}`)}
      >
        <Checkbox
          key={`${fieldId}-${value}_${errorCounter}`}
          disabled={hasOnlyOneChild()}
          defaultChecked={!!value}
          onChange={onChange}
          data-test={id}
        >
          {label}
        </Checkbox>
      </EnhancedOption>
    </div>
  );
};
export { CheckboxInput as CheckboxInputDisconnected };
export default connect<IStoreProps>((state: IStore) => ({
  isReadonly: hasReadPermission(state),
}))(CheckboxInput);
