export const ACCOUNT_DEACTIVATED = "account-deactivated";
export const EMAIL_USED = "email-already-used";

// used to manage page state to manage all inside the component
export enum PAGE_STATE {
  ENTERED_OR_ELABORATING, // same view, but when user already exists a red alert message is added later
  INVALID_REQUEST,
  ERROR_SERVER,
  DONE_WITHOUT_ERRORS,
  ENTERED_OR_USER_NOT_EXISTS, // same view, but when user already exists a red alert message is added later
  ENTERED_OR_USER_EXISTS, // same view, but when user already exists a red alert message is added later
}
