import { Col, Row } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ReactSVG } from "react-svg";
import iconTool from "../../assets/images/icon-tool.svg";
import { PortfolioUtility } from "../../generated/axios";
import ExternalLink from "./ExternalLink";
import TextIcon from "./TextIcon";

interface Props {
  list: PortfolioUtility[];
}

const Utilities = React.memo((props: Props) => {
  if (props.list.length === 0) return null;
  return (
    <>
      <Row key="row-1">
        <Col
          span={22}
          offset={1}
          className="item-selection__row item-selection__full-row"
        >
          <ReactSVG src={iconTool} className="icon icon--56 icon--padded" />
          <span className="application-type__title">
            <FormattedMessage id="utilities" defaultMessage="Utilities" />
          </span>
        </Col>
      </Row>
      <Row key="row-2">
        <Col
          span={22}
          offset={1}
          className="item-selection__row item-selection__full-row"
        >
          {props.list.map((utility) => (
            <ExternalLink
              key={utility.id}
              label={utility.name}
              url={utility.url}
            >
              <TextIcon
                text={utility.abbreviation}
                visualClassName="inUtilities"
              />
            </ExternalLink>
          ))}
        </Col>
      </Row>
    </>
  );
});

export default Utilities;
