import React, { ReactNode } from "react";
import "./externalLink.scss";

interface ExtLinkType {
  url: string;
  label: ReactNode | string;
  children: ReactNode;
}

const ExternalLink = (props: ExtLinkType) => {
  return (
    <div className="externalLink" key={props.url}>
      <a href={props.url} target="_blank" rel="noopener noreferrer">
        {props.children}
        <span>{props.label}</span>
      </a>
    </div>
  );
};

export default ExternalLink;
