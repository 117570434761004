import { message } from "antd";
import axios, { AxiosError, AxiosResponse } from "axios";
import pkg from "../../../package.json";
import { LOGIN } from "../../config/paths";
import { logout } from "../auth";
import { shortenType } from "../lib/apiErrorMessages";
import { areDifferent } from "../lib/areDifferentObj";
import store from "../store/store";
import { serviceUnavailable } from "../store/ui/actions";
import { PLATFORM_INFO } from "../store/ui/consts";

class Interceptors {
  private auth: number | undefined;

  initAll = () => {
    interceptors.addPlairServiceRequestHeader();
    interceptors.handleAuth();
    interceptors.handlePlairPlatformResponseHeader();
    interceptors.handle5xxErrors();
    interceptors.handleBlobError();
  };

  // Recognized http 401 Unauthorized errors: logout and redirect to login page
  handleAuth = () => {
    this.auth = axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        if (error?.response?.status === 401) {
          logout();
          window.location.href = LOGIN;
          return;
        }
        throw error;
      }
    );
  };

  ejectAuth = () => {
    if (this.auth !== undefined) {
      axios.interceptors.response.eject(this.auth);
    }
    this.auth = undefined;
  };

  addPlairServiceRequestHeader = () => {
    axios.interceptors.request.use((config) => {
      const value = JSON.stringify({ frontend: pkg.version });
      return {
        ...config,
        headers: { ...config.headers, "Plair-Service": value },
      };
    });
  };

  handlePlairPlatformResponseHeader = () => {
    axios.interceptors.response.use((response) => {
      try {
        if (response.headers["plair-platform"]) {
          // tell the system about the Plair-Platform header
          const payload = JSON.parse(response.headers["plair-platform"]);
          // Dispatch new platformInfo data only if new data is different from previous one...
          if (areDifferent(payload, store.getState().ui.platformInfo)) {
            store.dispatch({
              type: PLATFORM_INFO,
              payload,
            });
          }
        }
      } catch (err) {
        /* do nothing */
      }
      return response;
    });
  };

  // Handle status 5xx errors:
  // - show serviceUnavailable overlay if status is 502 or 503
  // - otherwise show running message
  handle5xxErrors = () => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error?.response?.status;
        if (status && status > 499) {
          if (
            status === 502 ||
            (status === 503 &&
              error.response.data &&
              shortenType(String(error?.response?.data.type)) !==
                "core-calculation-invocation-error")
          ) {
            store.dispatch(serviceUnavailable(true));
          } else {
            message.error(
              `Error ${error.response.status}: ${error.response.statusText}`
            );
          }
        }
        throw error;
      }
    );
  };

  // Handle errors when responseType is a Blob
  handleBlobError = () => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.request?.responseType === "blob" &&
          error.response?.data instanceof Blob &&
          error.response.data.type &&
          error.response.data.type.toLowerCase().indexOf("problem+json") !== -1
        ) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              error.response.data = JSON.parse(reader.result as string);
              resolve(Promise.reject(error));
            };

            reader.onerror = () => {
              reject(error);
            };

            reader.readAsText(error.response.data);
          });
        }
        return Promise.reject(error);
      }
    );
  };
}

export const interceptors = new Interceptors();
