import { Icon } from "antd";
import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { PROJECT_NEW, PROJECT_OPEN } from "../../config/paths";
import "./style.scss";

export const Separator = memo(() => <hr className="home__hr" />);

// Here can't use memo() until upgrade react.intl to 3.x
export const HomeHeading = () => (
  <div className="home__heading" data-test="home-recent-works">
    <FormattedMessage id="recent works" defaultMessage="Recent works" />
  </div>
);

export const OpenProjectLink = memo(() => (
  <div className="section__content">
    <Link className="section__title" to={PROJECT_OPEN}>
      <div className="section__symbol">
        <Icon className="icon--blue" type="folder-open" theme="outlined" />
      </div>
      <div>
        <FormattedMessage id="open project" defaultMessage="Open Project" />
      </div>
    </Link>
  </div>
));

export const NewProjectLink = memo(() => (
  <div className="section__content">
    <Link className="section__title" to={PROJECT_NEW}>
      <div className="section__symbol">
        <Icon className="icon--blue" type="plus-circle" theme="outlined" />
      </div>
      <div>
        <FormattedMessage id="new project" defaultMessage="New Project" />
      </div>
    </Link>
  </div>
));
