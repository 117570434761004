export const getVarsFromString = (str: string): RegExpExecArray[] => {
  // matches every ${something} as: [ ${something}, something ]
  const params = /\${([^}]+)}/gm;
  const arr = [];
  let m;
  while ((m = params.exec(str)) !== null) {
    arr.push(m);
  }
  return arr;
};
