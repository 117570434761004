import { ItemResultExt } from "../../../../shared/store/item/types";

export const getValue = (item: ItemResultExt, field: string) => {
  if (item?.calculationValues) {
    const value = Number(item.calculationValues[field]);
    if (isNaN(value)) {
      return item.calculationValues[field];
    } else {
      return value;
    }
  } else {
    return 0;
  }
};

/**
 * @param field
 * @param a {ItemResultExt}
 * @param b {ItemResultExt}
 * @return -1 if a < b
 *          1 if a > b
 *          0 if a == b
 */
export const compareField = (
  field: string,
  a: ItemResultExt,
  b: ItemResultExt
): number => {
  const valueA = getValue(a, field);
  const valueB = getValue(b, field);
  if (valueA < valueB) return -1;
  else if (valueA > valueB) return 1;
  else return 0;
};
