import { get } from "lodash";
import { OptionsData } from "../../../../generated/axios";
import { ItemResultExt } from "../../../../shared/store/item/types";

export const getBulletColorCssClass = (itemResult: ItemResultExt): string => {
  const deliveryTime = get(itemResult, "optionsData.deliveryTime", "");
  const hasBullet = itemResult.bestChoice ?? itemResult.bestRatio;

  if (!hasBullet) return "";

  switch (deliveryTime.toString()) {
    case OptionsData.DeliveryTimeEnum.IMMEDIATE:
      return "cell-unit--green";
    case OptionsData.DeliveryTimeEnum.SHORTTERM:
      return "";
    case OptionsData.DeliveryTimeEnum.MIDTERM:
      return "cell-unit--yellow";
    case OptionsData.DeliveryTimeEnum.LONGTERM:
      return "cell-unit--red";
    default:
      return "";
  }
};
