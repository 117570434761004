import { Col, Row, Spin } from "antd";
import { get } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import {
  BaseField,
  OptionLabels,
  OptionsGroup,
} from "../../../../generated/axios";
import { normalizeFieldId } from "../../../../shared/store/configurator/lib";
import { ItemResultExt } from "../../../../shared/store/item/types";
import { printWithDecimal } from "../../../../shared/systemOfMeasurement/convertPrecision";
import { Any } from "../../../../types/Any";
import { Detail } from "./ItemDetails";
import { ModalOptions } from "./ModalOptions/ModalOptions";

interface Props {
  chosenMetricSystem?: string;
  data: Record<string, Any>;
  defaultMetricSystem: string;
  details: Detail[];
  errorImage: boolean;
  handleImageError: () => void;
  itemResult: ItemResultExt;
  optionLabels: OptionLabels;
  optionsPricesAreVisible: boolean;
  pricesAreVisible: boolean;
  resultFieldConfig: BaseField[];
  waitingPrices?: boolean;
}

// workaround to round with two decimal but without keeping the
// non-significant-ending zero --> 11.00 must be shown as 11
export const roundWithTwoDecimals = (value: string | number) => {
  return Math.round(Number(value) * 100) / 100;
};

const Details = (props: Props) => {
  const {
    data,
    itemResult,
    optionLabels,
    optionsPricesAreVisible,
    pricesAreVisible,
  } = props;

  const options: OptionsGroup[] = get(itemResult, "optionsData.options", []);
  const kitsOptions: OptionsGroup[] = get(itemResult, "kitsData.options", []);

  const totalPrice =
    data.price !== undefined ? roundWithTwoDecimals(data.price) : "-";

  const optionLabel =
    pricesAreVisible && optionsPricesAreVisible ? (
      <FormattedMessage id="option price" defaultMessage="Option Price" />
    ) : (
      <FormattedMessage id="option list" defaultMessage="Option List" />
    );

  const optionList = (
    <ModalOptions
      label={optionLabel}
      optionLabels={props.optionLabels}
      options={options}
      showFieldName={true}
      showPrices={pricesAreVisible && optionsPricesAreVisible}
      total={props.itemResult.optionsData?.price}
    />
  );

  const kitLabel =
    pricesAreVisible && optionsPricesAreVisible ? (
      <FormattedMessage id="kit price" defaultMessage="Kit Price" />
    ) : (
      <FormattedMessage id="kit list" defaultMessage="Kit List" />
    );

  const kitList = (
    <ModalOptions
      label={kitLabel}
      optionLabels={props.optionLabels}
      options={kitsOptions}
      showFieldName={false}
      showPrices={pricesAreVisible && optionsPricesAreVisible}
      total={props.itemResult.kitsData?.price}
    />
  );

  return (
    <Row className="item__content">
      <Col span={24}>
        <Row>
          <Col span={24} className="box project-details-row">
            {pricesAreVisible ? (
              <Row gutter={16}>
                <Col span={17}>
                  <div className="box__total">
                    <FormattedMessage id="total" defaultMessage="Total" />
                  </div>
                  <div className="box__main-price">
                    {props.waitingPrices ? (
                      <Spin size="large" />
                    ) : (
                      "€ " + totalPrice
                    )}
                  </div>
                </Col>
                <Col span={7} className="box--padded">
                  {props.itemResult.unitPrice === undefined ? (
                    <Row>
                      <Col span={24}>&nbsp;</Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col span={12}>
                        <div className="box__detail box__detail--text-left">
                          <FormattedMessage
                            id="unit price"
                            defaultMessage="Unit Price"
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="box__detail box__detail--price">
                          {"€ "}
                          {roundWithTwoDecimals(props.itemResult.unitPrice)}
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col span={optionsPricesAreVisible ? 12 : 24}>
                      <div className="box__detail box__detail--text-left">
                        {optionList}
                      </div>
                    </Col>
                    {optionsPricesAreVisible ? (
                      <Col span={12}>
                        <div className="box__detail box__detail--price">
                          {props.waitingPrices ? (
                            <Spin size="small" />
                          ) : props.itemResult.optionsData?.price !==
                            undefined ? (
                            "€ " +
                            roundWithTwoDecimals(
                              props.itemResult.optionsData.price
                            )
                          ) : (
                            "-"
                          )}
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                  <Row>
                    <Col span={optionsPricesAreVisible ? 12 : 24}>
                      <div className="box__detail box__detail--text-left">
                        {kitList}
                      </div>
                    </Col>
                    {optionsPricesAreVisible ? (
                      <Col span={12}>
                        <div className="box__detail box__detail--price">
                          {props.waitingPrices ? (
                            <Spin size="small" />
                          ) : props.itemResult.kitsData?.price !== undefined ? (
                            "€ " +
                            roundWithTwoDecimals(
                              props.itemResult.kitsData.price
                            )
                          ) : (
                            "-"
                          )}
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col span={12} className={"box__detail box__detail--text-left"}>
                  {optionList}
                </Col>
                <Col span={12} className={"box__detail box__detail--text-left"}>
                  {kitList}
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <Row className="item__features">
          <Col span={24}>
            <Row>
              {props.details.map(
                (detail) =>
                  (detail.value || detail.value === 0) && (
                    <Col span={8} key={detail.fieldId}>
                      {detail.label}:{" "}
                      {detail.fieldId === "optionsConfigurationId" ? (
                        <span
                          dangerouslySetInnerHTML={{ __html: detail.value }}
                        />
                      ) : (
                        printWithDecimal(
                          detail.fieldId,
                          props.data,
                          props.defaultMetricSystem,
                          props.resultFieldConfig,
                          props.chosenMetricSystem,
                          optionLabels[normalizeFieldId(detail.fieldId)]
                        )
                      )}
                    </Col>
                  )
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Details;
