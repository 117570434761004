import cs from "react-intl/locale-data/cs";
import da from "react-intl/locale-data/da";
import de from "react-intl/locale-data/de";
import el from "react-intl/locale-data/el";
import en from "react-intl/locale-data/en";
import es from "react-intl/locale-data/es";
import et from "react-intl/locale-data/et";
import fi from "react-intl/locale-data/fi";
import fr from "react-intl/locale-data/fr";
import hu from "react-intl/locale-data/hu";
import it from "react-intl/locale-data/it";
import lt from "react-intl/locale-data/lt";
import lv from "react-intl/locale-data/lv";
import nl from "react-intl/locale-data/nl";
import no from "react-intl/locale-data/no";
import pl from "react-intl/locale-data/pl";
import pt from "react-intl/locale-data/pt";
import ro from "react-intl/locale-data/ro";
import ru from "react-intl/locale-data/ru";
import sv from "react-intl/locale-data/sv";
import zh from "react-intl/locale-data/zh";

export default {
  cs,
  da,
  de,
  el,
  en,
  es,
  et,
  fi,
  fr,
  hu,
  it,
  lt,
  lv,
  nl,
  no,
  pl,
  pt,
  ro,
  ru,
  sv,
  zh,
};
