import Action from "../../../types/action";
import { Any } from "../../../types/Any";
import { LOGOUT } from "../../auth/consts";
import { FAILURE, SUCCESS } from "../../lib/asyncActionHelper";
import { EXPORT_CSV_MULTIPLE_ITEMS, GET_ITEMS } from "./consts";
import { ItemsState } from "./types";

export const initialState: ItemsState = {
  list: [],
};

export default function reducer(
  state: ItemsState = initialState,
  action: Action<Any>
): ItemsState {
  switch (action.type) {
    case SUCCESS(GET_ITEMS):
      return { list: action.payload };
    case FAILURE(GET_ITEMS):
      return { list: [], error: action.payload };
    case SUCCESS(EXPORT_CSV_MULTIPLE_ITEMS):
      return state;
    case FAILURE(EXPORT_CSV_MULTIPLE_ITEMS):
      return state;
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
