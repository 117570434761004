/**
 * HOC responsible for reordering columns in the results page (used by ./TableResults.tsx)
 */

import { Icon } from "antd";
import React from "react";
import { connect } from "react-redux";
import { ItemResultExt } from "../../../../shared/store/item/types";
import { OrderDirection } from "../../../../shared/store/item/typesResults";
import { IStore } from "../../../../shared/store/types";
import { Callback } from "../../../../types/Callback";
import { compareField } from "./order-lib";
import { OwnProps, Props } from "./TableResults";

export interface OrderProps {
  getOrderIcon: () => React.ReactNode;
  orderDirectionDefault: OrderDirection;
  orderField: string;
  orderFieldDefault: string;
  reorder: (results: ItemResultExt[]) => ItemResultExt[];
  toggleDirection: (fieldId: string) => Callback;
}

interface State {
  orderField: string;
  orderDirection: OrderDirection;
}

const mapStateToProps = (state: IStore) => {
  return {
    orderDirectionDefault: state.settings.initialResultTableOrderedDirection,
    orderFieldDefault: state.settings.initialResultTableOrderedField,
  };
};

export default (
  Component: React.ComponentType<Props>
): React.ComponentType<OwnProps> => {
  return connect(mapStateToProps)(
    class OrderedComponent extends React.Component<Props, State> {
      public state: State = {
        orderField: this.props.orderFieldDefault,
        orderDirection: this.props.orderDirectionDefault,
      };

      toggleDirection = (fieldId: string) => () => {
        if (fieldId === "score") {
          this.setState(() => ({
            orderField: fieldId,
            orderDirection: OrderDirection.asc,
          }));
        } else {
          const switchDirection = this.state.orderField === fieldId;
          if (switchDirection) {
            const changeSortingDirection = () => ({
              orderDirection: this.switchDirection(),
            });
            this.setState(changeSortingDirection);
          } else {
            const changeSortingField = () => ({
              orderField: fieldId,
            });
            this.setState(changeSortingField);
          }
        }
      };

      reorder = (results: ItemResultExt[]): ItemResultExt[] => {
        return results.sort(this.compareFunction);
      };

      compareFunction = (a: ItemResultExt, b: ItemResultExt): number => {
        // Locked before unlocked
        if (a.locked !== b.locked) {
          return a.locked ? -1 : 1;
        }
        const field = this.state.orderField;
        const result = compareField(field, a, b);
        if (result === 0) {
          // secondary sorting criteria
          return compareField("unitPosition", a, b); // unitPosition ASC
        } else {
          return (
            result * (this.currentDirection() === OrderDirection.asc ? 1 : -1)
          );
        }
      };

      getOrderIcon = (): React.ReactNode => {
        switch (this.currentDirection()) {
          case OrderDirection.asc:
            return <Icon type="caret-left" className="order-icon" />;
          case OrderDirection.desc:
          default:
            return <Icon type="caret-right" className="order-icon" />;
        }
      };

      private switchDirection = (): OrderDirection => {
        switch (this.currentDirection()) {
          case OrderDirection.asc:
            return OrderDirection.desc;
          case OrderDirection.desc:
          default:
            return OrderDirection.asc;
        }
      };

      private currentDirection = (): OrderDirection => {
        return this.state.orderDirection;
      };

      render() {
        const orderProps = {
          orderField: this.state.orderField,
          reorder: this.reorder,
          orderDirection: this.state.orderDirection,
          getOrderIcon: this.getOrderIcon,
          toggleDirection: this.toggleDirection,
        };

        return <Component {...this.props} {...orderProps} />;
      }
    }
  );
};
