import { Button, Col, Modal, Row } from "antd";
import { History } from "history";
import React, { Component, ReactNode } from "react";
import { FormattedMessage, InjectedIntl, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import {
  addNoteToItem,
  clearItem,
  deleteItem,
  duplicateItem,
  saveItem,
} from "../../../shared/store/item/actions";
import {
  AddNoteData,
  addNoteToProject,
  deleteProject,
  duplicateProject,
} from "../../../shared/store/project/actions";
import { importSharedItem } from "../../../shared/store/share/actions";
import { IStore } from "../../../shared/store/types";
import { closeModalWithForm } from "../../../shared/store/ui/actions";
import {
  isModalFormVisible,
  modalFormContentSelector,
} from "../../../shared/store/ui/selectors";
import { Callback } from "../../../types/Callback";
import AddNote from "./AddNote";
import ClearItem from "./ClearItem";
import DeleteItem from "./DeleteItem";
import DuplicateItem from "./DuplicateItem";
import SaveItem from "./SaveItem";

interface StateProps {
  content: string;
  visible: boolean;
}

interface DispatchProps {
  addNoteToItem: (data: AddNoteData) => void;
  addNoteToProject: (data: AddNoteData) => void;
  clearItem: Callback;
  closeModalWithForm: Callback;
  deleteItem: (push?: History["push"]) => void;
  deleteProject: Callback;
  duplicateItem: Callback;
  duplicateProject: Callback;
  saveItem: (projectId?: number, projectName?: string) => void;
  importSharedItem: (projectId?: number, projectName?: string) => void;
}

interface Props
  extends React.ComponentClass,
    RouteComponentProps,
    StateProps,
    DispatchProps {
  intl: InjectedIntl;
}

export const getFooter = (cancelButton: boolean = true): ReactNode => (
  <Row>
    <Col
      span={24}
      className={
        cancelButton
          ? "modal-form__buttons"
          : "modal-form__buttons modal-form__buttons--reverse"
      }
    >
      {cancelButton && (
        <Button htmlType="reset">
          <FormattedMessage id="cancel" defaultMessage="Cancel" />
        </Button>
      )}
      <Button htmlType="submit" type="primary">
        <FormattedMessage id="confirm" defaultMessage="Confirm" />
      </Button>
    </Col>
  </Row>
);

export class BaseModal extends Component<Props> {
  // form cancel button handler
  closeModal = () => {
    this.props.closeModalWithForm();
  };

  deleteItem = () => {
    this.props.deleteItem(this.props.history.push);
  };

  // modal content render
  renderContent = () => {
    const { content } = this.props;
    switch (content) {
      case "save":
        return (
          <SaveItem
            subTitle={
              <FormattedMessage
                id="modal.subtitle.save.item"
                defaultMessage="Connect your item to a project"
              />
            }
            closeModal={this.closeModal}
            confirmHandler={this.props.saveItem}
          />
        );
      case "importItem":
        return (
          <SaveItem
            subTitle={
              <FormattedMessage
                id="modal.subtitle.import.item"
                defaultMessage="Import item into a project"
              />
            }
            closeModal={this.closeModal}
            confirmHandler={this.props.importSharedItem}
          />
        );
      case "clear":
        return (
          <ClearItem
            subTitle={
              <FormattedMessage
                id="modal.subtitle.clear.item"
                defaultMessage="All your inputs will be deleted. Would you like to continue?"
              />
            }
            closeModal={this.closeModal}
            confirmHandler={this.props.clearItem}
          />
        );
      case "duplicate":
        return (
          <DuplicateItem
            title="Duplicate Item"
            subTitle={
              <FormattedMessage
                id="modal.subtitle.duplicate.item"
                defaultMessage="Would you like to duplicate the item? A new item will be created with the same inputs"
              />
            }
            closeModal={this.closeModal}
            confirmHandler={this.props.duplicateItem}
          />
        );
      case "duplicateProject":
        return (
          <DuplicateItem
            title="Duplicate Project"
            subTitle={
              <FormattedMessage
                id="modal.subtitle.duplicate.project"
                defaultMessage="Would you like to duplicate the project? A new project will be created with the same inputs"
              />
            }
            closeModal={this.closeModal}
            confirmHandler={this.props.duplicateProject}
          />
        );
      case "delete":
        return (
          <DeleteItem
            title="Delete Item"
            subTitle={
              <FormattedMessage
                id="modal.subtitle.delete.item"
                defaultMessage="Would you like to delete the item? You will lose all the work done till now"
              />
            }
            closeModal={this.closeModal}
            confirmHandler={this.deleteItem}
          />
        );
      case "deleteProject":
        return (
          <DeleteItem
            title="Delete Project"
            subTitle={
              <FormattedMessage
                id="modal.subtitle.delete.project"
                defaultMessage="Would you like to delete the project? You will lose all the work done till now"
              />
            }
            closeModal={this.closeModal}
            confirmHandler={this.props.deleteProject}
          />
        );
      case "note":
        return (
          <AddNote
            actionName="note"
            closeModal={this.closeModal}
            confirmHandler={this.props.addNoteToItem}
          />
        );
      case "noteProject":
        return (
          <AddNote
            actionName="noteProject"
            closeModal={this.closeModal}
            confirmHandler={this.props.addNoteToProject}
          />
        );
      default:
        return null;
    }
  };

  getTitle = () => {
    const { content } = this.props;
    switch (content) {
      case "save":
        return (
          <FormattedMessage
            id={"modal.title.save"}
            defaultMessage="Save item into another project"
          />
        );
      case "importItem":
        return (
          <FormattedMessage
            id={"modal.title.importItem"}
            defaultMessage="Import item into a project"
          />
        );
      case "clear":
        return (
          <FormattedMessage
            id={"modal.title.clear"}
            defaultMessage="Clear item"
          />
        );
      case "duplicate":
        return (
          <FormattedMessage
            id={"modal.title.duplicate"}
            defaultMessage="Duplicate item"
          />
        );
      case "duplicateProject":
        return (
          <FormattedMessage
            id={"modal.title.duplicateProject"}
            defaultMessage="Duplicate project"
          />
        );
      case "delete":
        return (
          <FormattedMessage
            id={"modal.title.delete"}
            defaultMessage="Delete item"
          />
        );
      case "deleteProject":
        return (
          <FormattedMessage
            id={"modal.title.deleteProject"}
            defaultMessage="Delete project"
          />
        );
      case "note":
        return (
          <FormattedMessage id={"modal.title.note"} defaultMessage="Note" />
        );
      case "noteProject":
        return (
          <FormattedMessage
            id={"modal.title.noteProject"}
            defaultMessage="Note"
          />
        );
      default:
        // Il codice com'era
        return this.props.intl.formatMessage({
          id: this.props.content || "loading",
          defaultMessage: "Confirm",
        });
    }
  };

  shouldComponentUpdate(nextProps: Props) {
    return (
      (nextProps.visible !== this.props.visible &&
        nextProps.content !== this.props.content) ||
      nextProps.intl !== this.props.intl
    );
  }

  render() {
    return (
      <Modal
        visible={this.props.visible}
        centered={true}
        destroyOnClose={true}
        onCancel={this.closeModal}
        footer={null}
        title={this.getTitle()}
      >
        {this.renderContent()}
      </Modal>
    );
  }
}

const connected = connect(
  (state: IStore) => {
    return {
      visible: isModalFormVisible(state),
      content: modalFormContentSelector(state),
    };
  },
  {
    addNoteToItem,
    addNoteToProject,
    clearItem,
    closeModalWithForm,
    deleteItem,
    deleteProject,
    duplicateItem,
    duplicateProject,
    saveItem,
    importSharedItem,
  }
)(BaseModal);

export default injectIntl(connected);
