import { Modal, Table } from "antd";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../shared/store/hooks";
import "./modalPreviewTechnicalSpecification.scss";
import { loadModalQuote } from "../../shared/store/quoteModal/actions";
import { quoteInputSelector } from "../../shared/store/quoteModal/selectors";
import { QuoteInputExt } from "../../shared/store/quoteModal/types";
import { quoteColumnsModal } from "./projectDetailsColumns";

interface IProps {
  itemIds: number | number[];
  onCancel: () => void;
  onOk: () => void;
  unitId?: string;
  update: boolean;
  visible: boolean;
}

export const ModalQuote: React.FC<IProps> = ({
  itemIds,
  onCancel,
  onOk,
  unitId,
  update,
  visible,
}) => {
  const dispatch = useAppDispatch();
  const { list, loading, saving } = useSelector(quoteInputSelector);

  useEffect(() => {
    if (visible) {
      const ids = Array.isArray(itemIds) ? itemIds : [itemIds];
      dispatch(loadModalQuote(ids, unitId));
    }
  }, [dispatch, visible, itemIds, unitId]);

  return (
    <Modal
      centered
      className={"modal-quote"}
      keyboard={false}
      closable={!saving}
      onCancel={onCancel}
      cancelButtonProps={{ disabled: saving }}
      onOk={onOk}
      okButtonProps={{ loading: saving }}
      okText={
        update ? (
          <FormattedMessage
            id="modalQuote.update.submitLabel"
            defaultMessage="Update quote"
          />
        ) : (
          <FormattedMessage
            id="modalQuote.create.submitLabel"
            defaultMessage="Create quote"
          />
        )
      }
      title={
        update ? (
          <FormattedMessage
            id="modalQuote.update.title"
            defaultMessage="Update quote"
          />
        ) : (
          <FormattedMessage
            id="modalQuote.create.title"
            defaultMessage="Create quote"
          />
        )
      }
      visible={visible}
    >
      {visible ? (
        <Table<QuoteInputExt>
          rowKey={(record) => `${record.itemId} - ${record.kitCode}`}
          columns={quoteColumnsModal}
          dataSource={list}
          pagination={false}
          loading={loading}
        />
      ) : null}
    </Modal>
  );
};
