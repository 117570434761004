import { Icon, Tooltip } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CPQ } from "../../generated/axios";

interface IProps {
  cpq?: CPQ;
}

const QuotePresentCell: React.FC<IProps> = ({ cpq }) => {
  return cpq?.quote ? (
    <Tooltip
      title={
        <FormattedMessage
          id={"quote present"}
          defaultMessage={"Quote present"}
        />
      }
    >
      <Icon type="dollar" style={{ fontSize: "20px" }} />
    </Tooltip>
  ) : null;
};

export default QuotePresentCell;
