import * as React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";
import {
  CHANGE_EMAIL,
  CONFIGURATOR,
  HOME,
  ITEM_NEW,
  LOGIN,
  PASSWORD_RESET,
  PASSWORD_RESET_CONFRIM,
  PROJECT_DETAILS,
  PROJECT_NEW,
  PROJECT_OPEN,
  REGISTRATION_CONFIRM,
  REGISTRATION_EMAIL,
  RESEND_INSTRUCTIONS,
  USER_PROFILE,
} from "./config/paths";
import ConfiguratorRouter from "./modules/configurator/ConfiguratorRouter";
import BaseModal from "./modules/configurator/modals/BaseModal";
import CancelRequestModal from "./modules/configurator/modals/CancelRequestModal";
import ReportProblemModal from "./modules/configurator/modals/ReportProblemModal/ReportProblemModal";
import ServiceUnavailable from "./modules/configurator/modals/ServiceUnavailable";
import SettingsModal from "./modules/configurator/modals/SettingsModal/SettingsModal";
import ShareModal from "./modules/configurator/modals/ShareModal";
import Home from "./modules/home/Home";
import ItemSelection from "./modules/itemSelection/ItemSelection";
import Layout from "./modules/layout/Layout";
import NotFound from "./modules/notFound";
import NewProject from "./modules/project/NewProject";
import OpenProject from "./modules/project/OpenProject";
import ProjectDetails from "./modules/project/ProjectDetails";
import ChangeEmail from "./modules/publicPages/ChangeEmail";
import Login from "./modules/publicPages/Login";
import PasswordReset from "./modules/publicPages/PasswordReset";
import passwordResetConfirm from "./modules/publicPages/PasswordResetConfirm";
import RegistrationConfirm from "./modules/publicPages/RegistrationConfirm";
import RegistrationEmail from "./modules/publicPages/RegistrationEmail";
import ResendInstructions from "./modules/publicPages/ResendInstructions";
import ViewProfile from "./modules/settings/ViewProfile";
import { isAuthenticated } from "./shared/auth"; // was commented
import RefreshPageModal from "./shared/components/RefreshPageModal";
import RouteChangeHandler from "./shared/components/RouteChangeHandler";
import { getLanguages } from "./shared/store/account/actions";
import { getCapabilities } from "./shared/store/capabilities/actions";
import { FeatureFlag } from "./shared/store/injectedEnv/types";
import { getAllPortfolio } from "./shared/store/portfolio/actions";
import {
  getConversions,
  getMetricSystems,
  getResultsTableFieldsConfiguration,
} from "./shared/store/settings/actions";
import { IStore } from "./shared/store/types";
import {
  checkServiceAvailability,
  closeSettingsModal,
  resetRefreshPage,
} from "./shared/store/ui/actions";
import { Callback } from "./types/Callback";

interface Props {
  features: FeatureFlag[];
  checkServiceAvailability: Callback;
  closeModal: Callback;
  getAllPortfolio: Callback;
  getCapabilities: Callback;
  getConversions: Callback;
  getLanguages: Callback;
  getMetricSystems: Callback;
  getResultsTableFieldsConfiguration: Callback;
  refreshPageModalVisibility: boolean;
  resetRefreshPage: Callback;
  serviceUnavailable: boolean;
}

class App extends React.Component<Props> {
  componentDidMount() {
    if (isAuthenticated()) {
      this.loadSettingsAndOther();
    }
  }

  loadSettingsAndOther = () => {
    const {
      getCapabilities,
      getConversions,
      getMetricSystems,
      getLanguages,
      getAllPortfolio,
      getResultsTableFieldsConfiguration,
    } = this.props;

    [
      getCapabilities,
      getConversions,
      getMetricSystems,
      getLanguages,
      getResultsTableFieldsConfiguration,
      getAllPortfolio,
    ].forEach((func) => func());
  };

  public render() {
    const {
      checkServiceAvailability,
      features,
      refreshPageModalVisibility,
      resetRefreshPage,
      serviceUnavailable,
    } = this.props;

    return (
      <>
        <ReportProblemModal />
        <Router>
          <RouteChangeHandler>
            <Switch>
              <Route
                exact
                path={HOME}
                render={() => (
                  <Layout id="home">
                    <Home />
                  </Layout>
                )}
              />
              <Route
                exact
                path={LOGIN}
                render={() => (
                  <Login
                    onLoginSuccessOperations={this.loadSettingsAndOther}
                    withCPQ={features.includes(FeatureFlag.CPQ)}
                  />
                )}
              />
              <Route
                exact
                path={REGISTRATION_EMAIL}
                component={RegistrationEmail}
              />
              <Route
                exact
                path={REGISTRATION_CONFIRM}
                component={RegistrationConfirm}
              />
              <Route exact path={PASSWORD_RESET} component={PasswordReset} />
              <Route
                exact
                path={PASSWORD_RESET_CONFRIM}
                component={passwordResetConfirm}
              />
              <Route
                exact
                path={RESEND_INSTRUCTIONS}
                component={ResendInstructions}
              />
              <Route exact path={CHANGE_EMAIL} component={ChangeEmail} />
              <Route
                path={`${ITEM_NEW}/:projectId?/:portfolioId?`}
                render={(props) => (
                  <Layout id="item-new">
                    <ItemSelection {...props} />
                  </Layout>
                )}
              />
              <Route
                path={USER_PROFILE}
                render={() => (
                  <Layout id="settings">
                    <ViewProfile />
                  </Layout>
                )}
              />
              <Route
                path={PROJECT_OPEN}
                render={() => (
                  <Layout id="project-open">
                    <OpenProject />
                  </Layout>
                )}
              />
              <Route
                path={PROJECT_NEW}
                render={() => (
                  <Layout id="project-new">
                    <NewProject />
                  </Layout>
                )}
              />
              <Route
                path={PROJECT_DETAILS}
                render={(props) => (
                  <Layout id="project-details">
                    <ProjectDetails {...props} />
                  </Layout>
                )}
              />
              <Route
                path={CONFIGURATOR}
                render={() => (
                  <Layout id="configurator">
                    <ConfiguratorRouter />
                  </Layout>
                )}
              />
              <Route
                render={() => (
                  <Layout id="item-new">
                    <NotFound />
                  </Layout>
                )}
              />
            </Switch>
            <Route path={HOME} render={(props) => <BaseModal {...props} />} />
            <Route component={ShareModal} />
          </RouteChangeHandler>
        </Router>

        <SettingsModal onCancel={this.props.closeModal} />
        <RefreshPageModal
          visible={refreshPageModalVisibility}
          resetRefreshPage={resetRefreshPage}
        />
        <CancelRequestModal />
        {serviceUnavailable && (
          <ServiceUnavailable
            checkServiceAvailability={checkServiceAvailability}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  features: state.injectedEnv.features,
  refreshPageModalVisibility: !!state.ui.refreshPage,
  serviceUnavailable: state.ui.serviceUnavailable,
  settings: state.settings,
});

const mapDispatchToProps = {
  checkServiceAvailability,
  getConversions,
  getMetricSystems,
  getResultsTableFieldsConfiguration,
  getLanguages,
  getAllPortfolio,
  getCapabilities,
  resetRefreshPage,
  closeModal: closeSettingsModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
