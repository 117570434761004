import React from "react";
import { FormattedMessage } from "react-intl";
import { Callback } from "../../../../types/Callback";
import { TableResultsWarning } from "./TableResultsWarning";

interface IProps {
  exportCsvHandler: Callback;
  displayedResults?: number;
  missingResults?: number;
}

const TableResultsInfo: React.FC<IProps> = ({
  exportCsvHandler,
  displayedResults,
  missingResults,
}) => {
  return (
    <div className={"flex-row-center"}>
      <div style={{ display: "flex" }}>
        <TableResultsWarning
          displayedResults={displayedResults}
          exportCsvHandler={exportCsvHandler}
          hideText={true}
          missingResults={missingResults}
        />
      </div>
      <div>
        {displayedResults === 1 && (
          <FormattedMessage id="1 unit" defaultMessage="1 unit" />
        )}
        {displayedResults && displayedResults !== 1 && (
          <FormattedMessage
            id="{n} units"
            defaultMessage="{n} units"
            values={{ n: displayedResults }}
          />
        )}
      </div>
    </div>
  );
};

export default TableResultsInfo;
