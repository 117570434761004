import { Input } from "antd";
import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { InputProps } from ".";
import { hasReadPermission } from "../../../../shared/store/item/selectors";
import { IStore } from "../../../../shared/store/types";
import { FormEvent } from "../../../../types/configurator";
import ReadonlyField from "./ReadonlyField";

interface ReduxProps {
  isReadonly: boolean;
}

interface Props extends InputProps, ReduxProps {}

class TextInput extends React.Component<Props> {
  onChange = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (this.props.onChange) {
      const formEvent: FormEvent = {
        field: this.props.fieldId || "",
        value: e.target.value,
      };
      this.props.onChange(formEvent);
    }
  };

  render = (): ReactNode => {
    const { value, placeholder, fieldId, isReadonly } = this.props;
    const defaultValue = value || "";

    if (isReadonly) return <ReadonlyField text={defaultValue} />;

    const id = "configuration-form--" + fieldId;

    return (
      <Input
        key={defaultValue}
        id={id}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onBlur={this.onChange}
        data-test={id}
      />
    );
  };
}

const mapStateToProps = (state: IStore): ReduxProps => {
  const isReadonly = hasReadPermission(state);
  return {
    isReadonly,
  };
};
export default connect(mapStateToProps)(TextInput);
