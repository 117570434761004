import { message } from "antd";
import { FormattedMessage } from "react-intl";
import { durations } from "../../config/defaults";
import { eIntl } from "../eIntl";
import { messages } from "./locales/definedMessages";

export function errorMessenger(
  status: number,
  description: string | FormattedMessage.MessageDescriptor
) {
  const error = eIntl.formatMessage(messages["ERROR_UPPERCASE"]);
  const msg =
    typeof description === "string"
      ? description
      : eIntl.formatHTMLMessage(description);
  message.error(`${error}: ${status} ${msg}`, durations.error);
}
