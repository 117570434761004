import { Icon, Popover } from "antd";
import { isEmpty, sortBy } from "lodash";
import React from "react";
import { CalculationMessage } from "../../../../generated/axios";

interface Props {
  isWarning: boolean;
  messages?: CalculationMessage[];
}

const NoteDisplay = React.memo((props: Props) => {
  const { messages, isWarning } = props;
  const icon = isWarning ? (
    <Icon type="info-circle" className="with-warning" />
  ) : (
    <Icon type="check-square" theme="outlined" className="no-warning" />
  );

  if (isEmpty(messages)) return icon;
  return (
    <Popover
      overlayClassName="popover"
      placement={"bottom"}
      content={
        <ul className="messages">
          {sortBy(messages, ["type", "message"]).map(
            (item: CalculationMessage, index: number) => (
              <li key={index}>
                <span>{item.type}</span>: {item.message}
              </li>
            )
          )}
        </ul>
      }
    >
      {icon}
    </Popover>
  );
});

export default NoteDisplay;
