import { message } from "antd";
import _, { isEqual, reduce, toString } from "lodash";
import { Dispatch } from "redux";
import { durations } from "../../../config/defaults";
import {
  FieldOptionAction,
  FormConfig,
  FormField,
  FormSection,
  Item,
  ItemApi,
  ItemAttribute,
  ItemAttributeOption,
  MechanicalDataApi,
  OptionLabels,
  ThermalDataApi,
} from "../../../generated/axios";
import { isCompleteField } from "../../../modules/configurator/containers/isCompleteField";
import { Any } from "../../../types/Any";
import {
  ConfiguratorSection,
  FormValue,
  ValidationMessage,
  validationStatus,
} from "../../../types/configurator";
import { Optional } from "../../../types/optional";
import { apiConfig } from "../../api";
import { eIntl } from "../../eIntl";
import apiErrorHandler from "../../lib/apiErrorHandler";
import { SUCCESS } from "../../lib/asyncActionHelper";
import { fieldValues } from "../../lib/fieldValues";
import isAnEmptySelectField from "../../lib/isAnEmptySelectField";
import { messages } from "../../lib/locales/definedMessages";
import log from "../../log";
import validate from "../../validate/validate";
import validationRules from "../../validate/validationRules";
import { IStore } from "../types";
import { SELECT } from "./consts";
import {
  ApiFunctions,
  ConfiguratorSectionState,
  FlatLabel,
  FormConfigWithValues,
  FormFieldWithValue,
  FormSectionWithValues,
} from "./types";

export interface CreateConfiguratorSectionDataParams {
  sectionName: ConfiguratorSection;
  item: Item;
  formConfig: FormSection[];
  optionActions: FieldOptionAction[];
  optionLabels: OptionLabels;
  sectionLabels: [];
}

export interface IDerivedData {
  config: FormConfigWithValues;
  validations: Record<string, ValidationMessage[]>;
  labels: FlatLabel[];
  flatOptionLabels: Record<string, string>;
}

interface SectionState {
  section: ConfiguratorSection;
  state: ConfiguratorSectionState;
}

export const itemApi = new ItemApi(apiConfig());

export const thermalDataApi = new ThermalDataApi(apiConfig());

export const mechanicalDataApi = new MechanicalDataApi(apiConfig());

export const createItemData = (
  section: ConfiguratorSection,
  key: string,
  value: FormValue /* , unsaved: Record<string, string> = {} */
): Item => {
  switch (section) {
    case ConfiguratorSection.MECHANICAL:
      return {
        thermalData: {},
        mechanicalData: { [key]: value },
      };
    case ConfiguratorSection.THERMAL:
    default:
      return {
        thermalData: { [key]: value },
        mechanicalData: {},
      };
  }
};

export const getFields = (
  section: ConfiguratorSection,
  item: Item
): ItemAttribute[] => {
  switch (section) {
    case ConfiguratorSection.MECHANICAL:
      return item.mechanicalAttributes ?? [];
    case ConfiguratorSection.THERMAL:
    default:
      return item.thermalAttributes ?? [];
  }
};

export const updateFields = (
  fields: ItemAttribute[],
  key: string,
  value: FormValue
): ItemAttribute[] => {
  const index: number = fields.findIndex((field) => field.fieldId === key);
  const exists = index > -1;

  if (exists) {
    return fields.map((field: ItemAttribute) => {
      if (field.fieldId === key) {
        field.value = value;
      }
      return field;
    });
  }

  // TODO: throw? field MUST exist?
  log.warn(`Field ${key} does not exist on Item, please check`);
  // add field
  return fields.concat([{ fieldId: key, value: value }]);
};

export const getApiFunctions = (section: ConfiguratorSection): ApiFunctions => {
  switch (section) {
    case ConfiguratorSection.MECHANICAL:
      return {
        getFormConfig: itemApi.getMechanicalFormConfig.bind(itemApi),
        getFieldOptionLabels:
          mechanicalDataApi.getMechanicalFieldOptionLabels.bind(
            mechanicalDataApi
          ),
        getFieldOptionActions:
          mechanicalDataApi.getMechanicalFieldOptionActions.bind(
            mechanicalDataApi
          ),
      };
    case ConfiguratorSection.THERMAL:
    default:
      return {
        getFormConfig: itemApi.getThermalFormConfig.bind(itemApi),
        getFieldOptionLabels:
          thermalDataApi.getThermalFieldOptionLabels.bind(thermalDataApi),
        getFieldOptionActions:
          thermalDataApi.getThermalFieldOptionActions.bind(thermalDataApi),
      };
  }
};

export const getKey = (option: ItemAttributeOption): string => option.value;

export const fieldInItems = (
  fields: ItemAttribute[],
  fieldId: string
): Optional<ItemAttribute> => {
  return fields.find((item: ItemAttribute) => item.fieldId === fieldId);
};

/**
 * Normalizes fieldId
 */
export const normalizeFieldId = (fieldId: string) => {
  const match = /^(dll)?(.*?)(?:\.(?:ht|lt))?$/.exec(fieldId);
  const dll = match?.[1];
  const normalizedFieldId = match?.[2];
  if (normalizedFieldId) {
    if (dll) {
      const firstLowered = normalizedFieldId[0].toLowerCase();
      return firstLowered + normalizedFieldId.substring(1);
    }
    return normalizedFieldId;
  }
  return fieldId;
};

export const setOptionsLabel = (
  options: ItemAttributeOption[],
  fieldId: string,
  optionLabels: OptionLabels
): ItemAttributeOption[] => {
  const cleanedFieldId = normalizeFieldId(fieldId);
  const optionLabelsForField = optionLabels[cleanedFieldId];
  return options.map((opt: ItemAttributeOption) => {
    const value: Any = opt.value;
    const label: string | undefined = optionLabelsForField?.[value] || value;
    return {
      ...opt,
      label,
      ...(optionLabelsForField?.[value] ? { foundInOptionLabels: true } : {}),
    };
  });
};

export const createFields = (
  sectionFields: FormField[],
  itemFields: ItemAttribute[],
  optionActions?: FieldOptionAction[],
  optionLabels?: OptionLabels
): FormFieldWithValue[] => {
  return sectionFields.map((field: FormField) => {
    // TypeScript & object deconstruction issues?
    // const { fieldId: '' } = field;
    const fieldId: string = field?.fieldId ?? "";

    // remove options, mi aspetto che le options cambino alle modifiche dei valori nel form,
    // per cui non mi interessa avere il valore precedente
    const cleanField = _.omit(field, ["options"]) as FormField;

    const updatedField = fieldInItems(itemFields, fieldId);

    const obj = optionActions?.find(
      (item: { fieldKey: string }) => item.fieldKey === fieldId
    );

    // 1 override formConfig.section.field data with Item data
    const result: FormFieldWithValue = {
      ...cleanField,
      ...updatedField,
      fieldActions: obj?.fieldActions ?? [],
      optionActions: obj?.optionActions ?? {},
      value: updatedField ? updatedField.value : null,
      label: field.label, // bugfix 14/12/2018: in Item we have label = null
    };
    result.options = result.options ?? [];

    // 2 add labels to field.options
    if (optionLabels) {
      result.options = setOptionsLabel(result.options, fieldId, optionLabels);
    }

    return result;
  });
};

export const composeConfig = (
  itemFields: ItemAttribute[],
  formConfig: FormConfig,
  item: Item,
  optionActions?: FieldOptionAction[],
  optionLabels?: OptionLabels
): FormConfigWithValues => {
  const formConfigWithValues = formConfig.reduce(
    (
      acc: FormConfigWithValues,
      section: FormSectionWithValues
    ): FormConfigWithValues => {
      const sectionFields: FormField[] = section.fields || [];
      // recreate the section object
      const newSection = {
        ...section,
        fields: createFields(
          sectionFields,
          itemFields,
          optionActions,
          optionLabels
        ),
      };
      return acc.concat(newSection);
    },
    []
  );

  const visibleFields: Record<string, boolean> = {};
  item?.thermalAttributes?.forEach((itemAttribute) => {
    if (itemAttribute.fieldId) {
      visibleFields[itemAttribute.fieldId] = true;
    }
  });
  item?.mechanicalAttributes?.forEach((itemAttribute) => {
    if (itemAttribute.fieldId) {
      visibleFields[itemAttribute.fieldId] = true;
    }
  });

  // Add ___isVisible flag to each formField
  formConfigWithValues.forEach((section) => {
    section.fields.forEach((formField) => {
      formField.___isVisible = Boolean(
        visibleFields[formField.fieldId] && isCompleteField(formField)
      );
    });
  });
  return formConfigWithValues;
};

export const flattenFormFields = (formConfig: FormConfigWithValues) => {
  return formConfig.reduce((acc, curr: FormSection) => {
    if (!curr.fields) {
      return acc;
    }
    curr.fields.forEach((field) => (acc[field.fieldId] = field));
    return acc;
  }, {}) as Record<string, FormFieldWithValue>;
};

export const flattenVisibilityFields = (formConfig: FormConfigWithValues) => {
  const result: Record<string, boolean> = {};
  formConfig.forEach((section) => {
    section.fields.forEach((field) => {
      if (field.fieldId) {
        result[field.fieldId] = Boolean(field.___isVisible);
      }
    });
  });
  return result;
};

export const validateFields = (
  formConfig: FormConfigWithValues,
  item: Item
): Record<string, ValidationMessage[]> => {
  const visibility = flattenVisibilityFields(formConfig);
  const validator = validate(validationRules, visibility);
  let fields = flattenFormFields(formConfig);

  // exclude some field from validation
  fields = reduce(
    fields,
    (acc, value, key) => {
      if (isAnEmptySelectField(value)) {
        // it's a "select" without options
        return acc; // do not add the field
      }
      if (!value.___isVisible) {
        // it's hidden
        return acc; // do not add the field
      }
      return { ...acc, [key]: value }; // add the field
    },
    {}
  );

  const values = fieldValues(item); //configToValues(formConfig);
  // validate only fields excluding "select" with an empty options array
  return Object.keys(fields).reduce((acc, fieldId) => {
    const field = fields[fieldId];
    acc[fieldId] = validator(field, values);
    return acc;
  }, {});
};

/**
 *
 * @param {FormSection[]} list
 * @returns an Array of reduced ItemAttribute {fieldId, label}
 * it's used in SidePanel
 */
export const mapFieldIdToLabel = (list: FormSection[]): FlatLabel[] => {
  return list.reduce((acc: FlatLabel[], curr: FormSection) => {
    curr.fields = curr.fields ?? [];
    const temp: FlatLabel[] = curr.fields.map(
      (f: FormField): FlatLabel => ({ fieldId: f.fieldId, label: f.label })
    );
    return acc.concat(temp);
  }, []);
};

/**
 *
 * @param {OptionLabels[]} opts
 * @returns an object of options_id: options_label
 */
export const createFlatOptionLabels = (
  opts: OptionLabels
): Record<string, string> => {
  return reduce(
    opts,
    (acc, value) => {
      if (value) return { ...acc, ...value };
      return { ...acc };
    },
    {}
  );
};

export const hasErrors = (
  messages: Record<string, ValidationMessage[]>
): boolean => {
  const errors = Object.values(messages).filter(
    (messages: ValidationMessage[]) => {
      return (
        messages.filter(
          (message: ValidationMessage) =>
            message.status === validationStatus.error
        ).length > 0
      );
    }
  );
  return errors.length > 0;
};

/** Since the stored value ("safeValue") is stored without decimal precision (if it's not present)
 * and the value to check comes from the form field itself could be affected by the unit
 * of measure's precision, the !isEqual method between each other returns false.
 *
 * [+-]?       # Optional plus/minus sign (drop this part if you don't want to allow negatives)
 * (?=.?\d)    # Must have at least one numeral (not an empty string or just `.`)
 * \d*         # Optional integer part of any length
 * (\.\d{0,9}) # Optional decimal part of up to 9 digits
 *
 * "44.00" will be formatted as "44"
 * "44.01" will be formatted as "44.01"
 *
 * @param safeFormValue the value to check
 */
const removeUnnecessaryPrecision = (safeFormValue: string) => {
  const isNumber = /^[+-]?(?=.?\d)\d*(\.\d{0,9})?$/;
  if (isNumber.test(safeFormValue)) {
    return Number(safeFormValue).toString();
  }
  return safeFormValue;
};

/**
 * Checks if value has been modified
 */
export const changed = (
  fields: ItemAttribute[],
  key: string,
  value: FormValue
): boolean => {
  const attribute = fields.find((field) => field.fieldId === key);

  if (!attribute) {
    return false;
  }

  const safeValue = removeUnnecessaryPrecision(toString(attribute.value)); //  null, undefined => ''
  const safeFormValue = removeUnnecessaryPrecision(toString(value));

  return !isEqual(safeValue, safeFormValue);
};

export const getConfig = (
  state: IStore,
  section: ConfiguratorSection
): FormConfigWithValues => {
  const { configurator, item } = state;
  const { config, optionActions, optionLabels }: ConfiguratorSectionState =
    configurator[section];
  const fields = getFields(section, item);
  return composeConfig(fields, config, item, optionActions, optionLabels);
};

/**
 * this function is in common with settings/actions/refreshOptionLabelsAndActions
 */
export const createConfiguratorSectionData = ({
  sectionName,
  item,
  formConfig,
  optionActions,
  optionLabels,
}: CreateConfiguratorSectionDataParams): IDerivedData => {
  const fields = getFields(sectionName, item);
  const config = composeConfig(
    fields,
    formConfig,
    item,
    optionActions,
    optionLabels
  );
  const validations = validateFields(config, item);

  // puts all labels in the store
  const labels = mapFieldIdToLabel(config);
  // I need to keep track of the option labels also if form-config changes
  const flatOptionLabels = createFlatOptionLabels(optionLabels);

  return {
    config,
    validations,
    labels,
    flatOptionLabels,
  };
};

// it uses apiErrorHandler see params
export const loadItem =
  (
    itemId: number,
    section: ConfiguratorSection,
    item: Item,
    apiFunctions: ApiFunctions,
    state: IStore,
    dispatch: Dispatch,
    actionType: string
  ) =>
  async (): Promise<SectionState> => {
    message.loading(
      eIntl.formatMessage(messages["message.loading_data_item"]),
      durations.loading
    );

    // promise rejection is captured in apiErrorHandler
    const [formConfig, optionLabels, optionActions] = (await Promise.all([
      apiFunctions.getFormConfig(itemId).then((res) => res.data),
      apiFunctions.getFieldOptionLabels().then((res) => res.data),
      apiFunctions.getFieldOptionActions().then((res) => res.data),
    ]).catch(apiErrorHandler({ dispatch, actionType }))) as [
      FormConfig,
      OptionLabels,
      FieldOptionAction[],
    ];
    // TODO: Gestire meglio gli errori... così non funziona bene!

    // TODO: ERR add a try/catch to capture errors in the following operations

    const derivedData = createConfiguratorSectionData({
      sectionName: section,
      item,
      formConfig,
      optionActions,
      optionLabels,
      sectionLabels: state.configurator[section].labels,
    });

    message.success(
      eIntl.formatMessage(messages["message.loading_item_completed"]),
      durations.success
    );

    return {
      state: {
        optionLabels,
        optionActions,
        ...derivedData,
      },
      section,
    } as SectionState;
  };

/**
 * see select action
 * it updates both thermal and mechanical form after a form value update
 */
export const dispatchSectionData =
  (dispatch: Dispatch, nextState: IStore) => (section: ConfiguratorSection) => {
    const config = getConfig(nextState, section);
    const validations = validateFields(config, nextState.item);

    dispatch({
      type: SUCCESS(SELECT),
      payload: {
        section,
        state: {
          config,
          validations,
        },
      },
    });
  };
