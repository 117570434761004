import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Item } from "../../../generated/axios";
import { load } from "../../../shared/store/configurator/actions";
import { IStore } from "../../../shared/store/types";
import { ConfiguratorSection } from "../../../types/configurator";

interface IStoreProps {
  item: Item;
}

interface IDispatchProps {
  load: (itemId: number, section: ConfiguratorSection) => void;
}

export interface IProps extends IStoreProps, IDispatchProps {
  children: React.ReactNode;
  itemId: number;
}

const ConfiguratorLoader: React.FunctionComponent<IProps> = (props) => {
  const { item, itemId, load, children } = props;
  const { dutyMode } = item;
  useEffect(() => {
    load(itemId, ConfiguratorSection.THERMAL);
    load(itemId, ConfiguratorSection.MECHANICAL);
  }, [itemId, dutyMode, load]);

  return <Fragment>{children}</Fragment>;
};

const mapStateToProps = (state: IStore) => ({ item: state.item });

const mapDispatchToProps = {
  load,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfiguratorLoader);
