import { Icon } from "antd";
import React, { FunctionComponent } from "react";
import { FormAction } from "../../../../generated/axios";
import "./index.scss";
import InfoBox from "./InfoBox";

interface ActionProps {
  clickHandler?: (action: string) => void;
  icon: string;
  action: string;
  downloadUri?: string;
}

interface ActionType {
  icon: string;
  action: string;
}

enum ACTION_TYPES {
  INFO = "info",
  DOWNLOAD = "download",
}

const Action: FunctionComponent<ActionProps> = ({
  clickHandler = () => {},
  icon,
  action,
  downloadUri,
}): React.ReactElement => {
  if (downloadUri) {
    return (
      <a
        href={`${downloadUri}`}
        target="_blank"
        rel="noreferrer noopener"
        key={icon}
        data-test={`${action}`}
      >
        <Icon
          className="icon--squared icon--squared-transparent"
          type={icon}
          theme="outlined"
        />
      </a>
    );
  }

  return (
    <Icon
      key={icon}
      className="icon--squared icon--squared-transparent"
      type={icon}
      theme="outlined"
      onClick={() => clickHandler(action)}
    />
  );
};

/**
 * used in provider pattern
 */
const ActionsProvider: FunctionComponent<{
  actions?: FormAction[];
  children: (object: { actions?: FormAction[] }) => React.ReactNode;
}> = (props) => {
  const { actions = [], children } = props;

  return (
    <div className="actionsProvider" key="actionsProvider">
      {children({ actions })}
    </div>
  );
};

export { Action, ActionsProvider, InfoBox, ActionType, ACTION_TYPES };
