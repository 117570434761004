import { addLocaleData } from "react-intl";
import localeMessages from "../../../locales";
import { initExternalIntl } from "../../eIntl";
import { ITranslations } from "../../store/translations/types";
import antLocales from "./ant";
import intlLocaleData from "./intlLocaleData";

interface LocaleConfig {
  locale: string;
  messages: ITranslations;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ant: { [key: string]: any };
}

export default function getLocaleData(
  locale: string,
  serverTranslations: ITranslations = {}
): LocaleConfig {
  const intl = intlLocaleData[locale] || intlLocaleData["en"];
  const ant = antLocales[locale] || antLocales["en"];
  const locMessages = localeMessages[locale] || localeMessages["en"];
  const messages = { ...locMessages, ...serverTranslations };

  addLocaleData(intl);
  initExternalIntl(locale, messages);

  return {
    locale,
    messages,
    ant,
  };
}

export function getAvailableLanguages() {
  return Object.keys(intlLocaleData);
}

export function getBrowserLanguage() {
  const availableLanguages = getAvailableLanguages();
  // read param from env.development(.local) file to force language
  // if (process.env.CYPRESS_LANG) {
  //   return process.env.CYPRESS_LANG;
  // }
  for (let i = 0; i < navigator.languages.length; i++) {
    const lang = navigator.languages[i].substring(0, 2).toLowerCase();
    if (availableLanguages.includes(lang)) {
      return lang;
    }
  }
  return "en";
}
