import { Col, Icon, Row, Table } from "antd";
import { PaginationConfig } from "antd/lib/table";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { ITEM_NEW } from "../../config/paths";
import { Item, Portfolio } from "../../generated/axios";
import { ErrorHandler } from "../../shared/components/ErrorHandler";
import { Exceptions } from "../../shared/components/exceptions";
import pagination from "../../shared/lib/pagination";

import { getItems } from "../../shared/store/items/actions";
import { availablePortfoliosSelector } from "../../shared/store/portfolio/selectors";
import { resetProjectReducer } from "../../shared/store/project/actions";
import { SettingsState } from "../../shared/store/settings/types";
import { IStore } from "../../shared/store/types";
import { clearProjectId } from "../../shared/store/utils/actions";
import { Callback } from "../../types/Callback";
import { filterByShowIfCondition } from "../capability/lib";
import PortfolioSelector from "../portfolioSelector";
import { Banners } from "./Banners";
import columns from "./columns";
import {
  HomeHeading,
  NewProjectLink,
  OpenProjectLink,
  Separator,
} from "./staticParts";
import "./style.scss";

interface ReduxProps {
  availablePortfolios: Portfolio[];
  capabilities: string[];
  items: Item[];
  settings: SettingsState;
}

interface DispatchProps {
  clearProjectId: Callback;
  getItems: (sort?: string) => void;
  resetProjectReducer: Callback;
}

interface Props extends ReduxProps, DispatchProps {}

class Home extends React.Component<Props> {
  paginationInfo: PaginationConfig;

  constructor(props: Props) {
    super(props);
    this.paginationInfo = pagination();
    props.getItems("lastModifiedDate,desc");
  }

  // TODO: (ale) why not call in constructor? I don't like to call on click, too fragile
  clearLastProjectId = () => {
    // when click QuickCalculations call "clearProjectId" to add theItem created to the default project
    this.props.resetProjectReducer();
    this.props.clearProjectId();
  };

  render() {
    const { capabilities, items } = this.props;

    return (
      <div className="home">
        <Exceptions.AsRow>
          <Exceptions.Items />
        </Exceptions.AsRow>
        <Row>
          <Col span={22} offset={1}>
            <div className="home__sections">
              <Row gutter={16}>
                <Col span={8} className="section gutter-row">
                  <NewProjectLink />
                </Col>
                <Col span={8} className="section gutter-row">
                  <OpenProjectLink />
                </Col>
                <Col span={8} className="section gutter-row">
                  <PortfolioSelector
                    availablePortfolios={this.props.availablePortfolios}
                    outerClickHandler={this.clearLastProjectId}
                    linkTo={ITEM_NEW}
                    icon={
                      <Icon
                        className="icon--blue"
                        type="calculator"
                        theme="outlined"
                      />
                    }
                    label={
                      <FormattedMessage
                        id="quick calculation"
                        defaultMessage="Quick Calculation"
                      />
                    }
                  />
                </Col>
              </Row>
            </div>

            <Banners />

            <Separator />

            <HomeHeading />

            <ErrorHandler
              warning={
                <FormattedMessage id="ERROR_UPPERCASE" defaultMessage="ERROR" />
              }
            >
              <div className="summary">
                <Table
                  rowKey={"id"}
                  columns={filterByShowIfCondition(columns, capabilities)}
                  dataSource={items}
                  pagination={this.paginationInfo}
                  rowClassName={() => "data-test-row-class"}
                />
              </div>
            </ErrorHandler>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: IStore): ReduxProps => ({
  availablePortfolios: availablePortfoliosSelector(state),
  capabilities: state.capabilities.list,
  items: state.items.list,
  settings: state.settings,
});

const mapDispatchToProps = {
  clearProjectId,
  getItems,
  resetProjectReducer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
