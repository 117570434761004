import { Spin } from "antd";
import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { useSelector } from "react-redux";
import { itemCalculatingSelector } from "../../../../shared/store/item/selectors";
import "./noResults.scss";

export const NoResults: React.FC = () => {
  const calculating = useSelector(itemCalculatingSelector);
  return (
    <div className="no-results">
      {calculating ? (
        <Spin size="large" />
      ) : (
        <FormattedHTMLMessage
          id="no results"
          defaultMessage="No result found,<br/>please check thermal configuration and press 'Calculate'"
        />
      )}
    </div>
  );
};
