import IAction from "../../../types/action";
import { Any } from "../../../types/Any";
import { FAILURE, REQUEST, SUCCESS } from "../../lib/asyncActionHelper";
import { LOAD_TRANSLATIONS } from "./actions";
import { TranslationsState } from "./types";

const EMPTY_SERVER_TRANSLATIONS = {};

const initialState: TranslationsState = {
  serverTranslations: EMPTY_SERVER_TRANSLATIONS,
  ajaxCancelToken: null,
};

export default function reducer(
  state: TranslationsState = initialState,
  action: IAction<Any>
): TranslationsState {
  switch (action.type) {
    case REQUEST(LOAD_TRANSLATIONS):
      return {
        ...state,
        ajaxCancelToken: action.payload,
      };

    case SUCCESS(LOAD_TRANSLATIONS):
      return {
        ...state,
        serverTranslations: action.payload,
        ajaxCancelToken: null,
      };

    case FAILURE(LOAD_TRANSLATIONS):
      if (state.ajaxCancelToken === action.payload) {
        return {
          ...state,
          serverTranslations: EMPTY_SERVER_TRANSLATIONS,
          ajaxCancelToken: null,
        };
      } else {
        return state;
      }

    default:
      return state;
  }
}
