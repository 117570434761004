import { createSelector } from "reselect";
import { PAGE_STATE } from "../../../modules/publicPages/consts";
import { IStore } from "../types";

const requestEmailResetFinishResponse = (state: IStore) =>
  state.account.requestEmailResetFinishResponse;

const requestEmailResetFinishError = (state: IStore) =>
  state.account.requestEmailResetFinishError;

const firstName = (state: IStore) => state.account.account.firstName ?? "";

const lastName = (state: IStore) => state.account.account.lastName ?? "";

export const evalRequestEmailResetFinish = createSelector(
  requestEmailResetFinishResponse,
  requestEmailResetFinishError,
  (completed, error): PAGE_STATE => {
    if (error === undefined) {
      if (completed === undefined) {
        return PAGE_STATE.ENTERED_OR_ELABORATING;
      } else {
        return PAGE_STATE.DONE_WITHOUT_ERRORS;
      }
    } else if (error?.status && error.type.endsWith("/problem-with-message")) {
      return PAGE_STATE.INVALID_REQUEST;
    } else if (error) {
      return PAGE_STATE.ERROR_SERVER;
    }
    return PAGE_STATE.ENTERED_OR_ELABORATING;
  }
);

export const selectUserFullName = createSelector(
  firstName,
  lastName,
  (firstName: string, lastName: string) => `${firstName} ${lastName}`
);
