import { Icon } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Callback } from "../../../../types/Callback";
import { CapabilityCheck, capabilityMap } from "../../../capability";
import OpenTechSpecButton from "../ItemDetails/OpenTechSpecButton";

interface IProps {
  exportCsvHandler: Callback;
  printAllPdf: Callback;
  openSettingsModal: (sectionsToView: string[]) => void;
}

export const TableResultsFooter: React.FC<IProps> = ({
  exportCsvHandler,
  openSettingsModal,
  printAllPdf,
}) => {
  return (
    <div className="table-footer">
      <CapabilityCheck showIfHas={capabilityMap.ITEM_EXP_ALL_TECH_SPEC}>
        <span className="table-footer__text  as-button" onClick={printAllPdf}>
          <Icon
            type="download"
            theme="outlined"
            className="icon--blue icon--pointer  margin-left--0"
          />
          <span>
            <FormattedMessage id="download all" defaultMessage="Download all" />{" "}
            <FormattedMessage
              id="technical specification"
              defaultMessage="technical specification"
            />
          </span>
        </span>
      </CapabilityCheck>
      <CapabilityCheck showIfHas={capabilityMap.ITEM_EXP_CSV_STANDARD_VERSION}>
        <span
          className="table-footer__text margin-left--40 as-button"
          onClick={exportCsvHandler}
        >
          <Icon type="download" theme="outlined" className="icon--blue" />
          <FormattedMessage
            id="export csv"
            defaultMessage="Export results in CSV"
          />
        </span>
      </CapabilityCheck>
      <CapabilityCheck showIfHasNot={capabilityMap.ITEM_READONLY}>
        <OpenTechSpecButton openSettingsModal={openSettingsModal} />
      </CapabilityCheck>
    </div>
  );
};
