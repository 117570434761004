import { ValidationMessage, ValidationParams } from "../../types/configurator";
import { IConvert } from "./conversionFunctions";
import { tryConvert } from "./convertCalculationValues";

const convertParams = (
  params: ValidationParams,
  conversion: IConvert
): ValidationParams => {
  return Object.keys(params).reduce((res, key: string) => {
    const value = params[key];
    const convertedValue = tryConvert(value, conversion);
    res[key] = convertedValue;
    return res;
  }, {});
};

export const convertValidationMessages = (
  messages: ValidationMessage[],
  conversion: IConvert
): ValidationMessage[] => {
  return messages.map((message) => {
    if (
      message.message &&
      String(message.message).startsWith("validation.required")
    ) {
      return message;
    }
    const _message = { ...message };
    const params = message.params ?? {};
    _message.params = convertParams(params, conversion);
    return _message;
  });
};
