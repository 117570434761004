import React, { FC } from "react";

import "./multilineText.scss";

interface Props {
  text?: string;
}

const MultilineText: FC<Props> = ({ text }) => {
  return text ? <div className="as-pre">{text}</div> : null;
};

export default MultilineText;
