import React from "react";

import "./notFound.scss";

const NotFound = () => {
  return (
    <div className="page-not-found">
      <h2>Page not found</h2>
    </div>
  );
};

export default NotFound;
