import { Slider } from "antd";
import React, { useCallback, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { ScrollableListArrow } from "./index";
import { minus, ScrollContext, ScrollDirection } from "./ScrollableListWrapper";

interface IProps {}

export const Scrollbar: React.FC<IProps> = () => {
  const {
    itemCount = 0,
    visibleChildren = 0,
    sliderStep = 0,
    scrollTo,
    sliderContainerRef,
  } = useContext(ScrollContext);
  const max =
    itemCount > visibleChildren ? itemCount - visibleChildren : itemCount;
  const changeHandler = useCallback(
    (value: number) => scrollTo?.(value),
    [scrollTo]
  );
  const width = sliderContainerRef
    ? sliderContainerRef.current?.offsetWidth
    : undefined;

  const formatter = useCallback(
    (value: number) => `${value + 1} - ${value + visibleChildren}`,
    [visibleChildren]
  );

  if (itemCount <= visibleChildren) return null;
  return (
    <div className="scrollbar">
      <div className="scrollbar__item_counter">
        <FormattedMessage
          id="Number of units"
          defaultMessage="Number of units"
        />
        : {itemCount}
      </div>
      <div className="scrollbar__controls" style={{ width }}>
        <div className="scrollbar__arrow-container">
          <ScrollableListArrow direction={ScrollDirection.left} />
        </div>
        <div
          className="scrollbar__scroll-slider"
          style={{ width: width ? width - 72 : undefined }}
        >
          <Slider
            min={0}
            max={max}
            value={minus(sliderStep)}
            onChange={changeHandler}
            tipFormatter={formatter}
          />
        </div>
        <div className="scrollbar__arrow-container">
          <ScrollableListArrow direction={ScrollDirection.right} />
        </div>
      </div>
    </div>
  );
};
