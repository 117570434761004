import { Any } from "../../types/Any";

interface O {
  [key: string]: Any;
}

/**
 * Compare two object testing only some properties
 * @param a
 * @param b
 * @param propertiesToCompare
 * @return boolean true if objects have different property values (shallow compare)
 */
export const areDifferentObj = (
  a: O,
  b: O,
  propertiesToCompare: string[] = []
) => {
  for (const i of propertiesToCompare) {
    if (a[i] !== b[i]) return true;
  }
  return false;
};

/**
 * compare two object throw serialization
 * @param a
 * @param b
 * @return boolean true if object have different serialization
 */
export const areDifferent = (a: Any, b: Any) =>
  JSON.stringify(a) !== JSON.stringify(b);
