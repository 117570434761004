import csCZ from "antd/lib/locale-provider/cs_CZ";
import daDK from "antd/lib/locale-provider/da_DK";
import deDE from "antd/lib/locale-provider/de_DE";
import elGR from "antd/lib/locale-provider/el_GR";
import enGB from "antd/lib/locale-provider/en_GB";
import esES from "antd/lib/locale-provider/es_ES";
import etEE from "antd/lib/locale-provider/et_EE";
import fiFI from "antd/lib/locale-provider/fi_FI";
import frFR from "antd/lib/locale-provider/fr_FR";
import huHU from "antd/lib/locale-provider/hu_HU";
import itIT from "antd/lib/locale-provider/it_IT";
import nlNL from "antd/lib/locale-provider/nl_NL";
import plPL from "antd/lib/locale-provider/pl_PL";
import ptPT from "antd/lib/locale-provider/pt_PT";
import ruRU from "antd/lib/locale-provider/ru_RU";
import svSE from "antd/lib/locale-provider/sv_SE";
import zhCN from "antd/lib/locale-provider/zh_CN";

export default {
  cs: csCZ,
  da: daDK,
  de: deDE,
  el: elGR,
  en: enGB,
  es: esES,
  et: etEE,
  fi: fiFI,
  fr: frFR,
  hu: huHU,
  it: itIT,
  // lt: , // Missing
  // lv: , // Missing
  nl: nlNL,
  // no: , // Missing
  pl: plPL,
  pt: ptPT,
  // ro: , // Missing
  ru: ruRU,
  sv: svSE,
  zh: zhCN,
};
