import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { DEFAULT_PORTFOLIO } from "../../config/defaults";
import { Portfolio } from "../../generated/axios";
import "./newProject.scss";

interface Props {
  items: Portfolio[];
  changeHandler: (e: RadioChangeEvent) => void;
}

const PortfolioList: FunctionComponent<Props> = (props) => {
  return (
    <div>
      <div className="select-portfolio-title">
        <FormattedMessage
          id="select-portfolio"
          defaultMessage="Seleziona portfolio prodotti"
        />
      </div>
      <Radio.Group
        name="portfolio"
        defaultValue={DEFAULT_PORTFOLIO}
        className="portfolio-list"
        onChange={props.changeHandler}
      >
        {props.items.map((item) => (
          <div key={item.id} className="portfolio-list_item">
            <img src={item.logoUrl} alt="" />
            <Radio value={item.id}>
              <span>{item.name}</span>
            </Radio>
          </div>
        ))}
      </Radio.Group>
    </div>
  );
};

export default PortfolioList;
