import { Icon } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

interface IProps {}

const QuoteItemDefaultProjectWarning: React.FC<IProps> = () => {
  return (
    <>
      <h3>
        <Icon type="warning" />
        &nbsp;
        <FormattedMessage id="warning" defaultMessage="Warning" />
      </h3>
      <div style={{ marginBottom: "8px" }}>
        <FormattedMessage
          id="Quoting is not allowed for a default project item"
          defaultMessage="Quoting is not allowed for a default project item."
        />
      </div>
      <div>
        <FormattedMessage
          id="Save item into different project for quoting"
          defaultMessage="Save this item into a different project to proceed with quoting."
        />
      </div>
    </>
  );
};

export default QuoteItemDefaultProjectWarning;
