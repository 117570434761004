import React, { ReactElement, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

/**
 * Section badge
 */
export default function SectionNumber(props: {
  n: number;
  total: number;
}): ReactElement<ReactNode> {
  return (
    <div className="section-number">
      <span className="big">{props.n}</span>{" "}
      <FormattedMessage id="of" defaultMessage="of" />{" "}
      <span>{props.total}</span>
    </div>
  );
}
