import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Problem } from "../../../generated/axios";
import { ProjectState } from "../../store/project/types";
import "./styles.scss";

interface Props {
  error: Problem;
}

const Message = React.memo(() => (
  <FormattedMessage id="exception project" defaultMessage="Error on Project" />
));

const ProjectException = connect((state: { project: ProjectState }) => ({
  error: state.project.error,
}))((props: Props) => {
  const error = props.error;
  return error?.status ? (
    <div className="exception">
      <Message />
      {`: [${error.status}] ${error.message}`}
    </div>
  ) : null;
});

export default ProjectException;
