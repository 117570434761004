import { Button } from "antd";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { SETTING_FIELDS } from "../../modals/SettingsModal/SettingsModal";
import "./openTechSpecButton.scss";

interface IProps {
  openSettingsModal: (sectionsToView: string[]) => void;
}

const OpenTechSpecButton: React.FC<IProps> = ({ openSettingsModal }) => {
  const onClickHandler = useCallback(
    () =>
      openSettingsModal([SETTING_FIELDS.TECHNICAL_SPECIFICATION_FULL_MODAL]),
    [openSettingsModal]
  );

  return (
    <div className="openTechSpecButton">
      <Button
        type="primary"
        className="float--right button--white height--40"
        onClick={onClickHandler}
      >
        <FormattedMessage id="customize" defaultMessage="CUSTOMIZE" />
        <> </>
        <FormattedMessage
          id="technical specification"
          defaultMessage="TECHNICAL SPECIFICATION"
        />
      </Button>
    </div>
  );
};

export default OpenTechSpecButton;
