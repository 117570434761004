import { InputNumber } from "antd";
import { InputNumberProps } from "antd/lib/input-number";
import numeral from "numeral";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  ConversionFormula,
  UnitOfMeasure,
} from "../../../../../generated/axios";
import { conversionsSelector } from "../../../../../shared/store/settings/selectors";
import convert from "../../../../../shared/systemOfMeasurement/conversionFunctions";

/**
 * Converts from base to current metric system
 */
const convertValueFn =
  (
    conversions: ConversionFormula[],
    metricSystem: string,
    defaultMetricSystem: string,
    units: Record<string, UnitOfMeasure> = {}
  ) =>
  (value?: number) =>
    Number(
      convert(
        units,
        conversions,
        metricSystem,
        defaultMetricSystem,
        true
      ).fromBaseSystem(numeral(value).value())
    );

/**
 * Converts from current to base metric system
 */
const backConvertValueFn =
  (
    conversions: ConversionFormula[],
    metricSystem: string,
    defaultMetricSystem: string,
    units: Record<string, UnitOfMeasure> = {}
  ) =>
  (value: number) =>
    Number(
      convert(
        units,
        conversions,
        metricSystem,
        defaultMetricSystem,
        true
      ).toBaseSystem(value)
    );

interface ConvertedInputNumberProps extends InputNumberProps {
  defaultMetricSystem: string;
  metricSystem: string;
  units?: Record<string, UnitOfMeasure>;
}

export const ConvertedInputNumber: React.FC<ConvertedInputNumberProps> = ({
  defaultMetricSystem,
  defaultValue,
  disabled,
  metricSystem,
  onChange,
  units,
  min,
  max,
}) => {
  const conversions = useSelector(conversionsSelector);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const convertValue = useCallback(
    convertValueFn(conversions, metricSystem, defaultMetricSystem, units),
    [metricSystem, defaultMetricSystem, conversions, units]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const backConvertValue = useCallback(
    backConvertValueFn(conversions, metricSystem, defaultMetricSystem, units),
    [metricSystem, defaultMetricSystem, conversions, units]
  );

  const precision = useMemo(
    () =>
      units?.[metricSystem]?.["precision"] ??
      units?.[defaultMetricSystem]?.["precision"] ??
      0,
    [units, metricSystem, defaultMetricSystem]
  );

  // Step is the increment/decrement value when clicking the up/down arrows
  // precision 0 = step 1; precision 1 = step 0.1; precision 2 = step 0.01; etc.
  const step = useMemo(() => Math.pow(10, -(precision || 0)), [precision]);

  const onBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    const numericValue = numeral(e.target.value).value();
    const convertedValue = backConvertValue(numericValue);
    onChange?.(convertedValue);
  };

  return (
    <InputNumber
      key={`${defaultValue}`}
      defaultValue={convertValue(defaultValue)}
      disabled={disabled}
      max={convertValue(max)}
      min={convertValue(min)}
      onBlur={onBlur}
      precision={precision}
      step={step}
    />
  );
};

ConvertedInputNumber.displayName = "ConvertedInputNumber";
