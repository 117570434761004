/**
 * Exports function factory convert()
 * returns functions to:
 * - understand if provided data is enough to make conversions
 * - convert from base to user system
 * - convert from user to base system
 * - proxy the form event handler and convert the emitted value
 */
import expr from "expression-eval";
import { get, isNil } from "lodash";
import numeral from "numeral";
import {
  BaseField,
  ConversionFormula,
  ItemAttributeOption,
  UnitOfMeasure,
} from "../../generated/axios";
import { ChangeHandler } from "../../modules/configurator/containers/Configurator";
import { FormEvent } from "../../types/configurator";
import { fillParam, FillParamsError } from "../lib/fillParams";
import { getFieldId } from "./convertLabel";
import { applyPrecision } from "./convertPrecision";

export type PseudoValue = number | string | null;
// Form inputs transform values to various formats:
export type PseudoNumber = PseudoValue | undefined;

export interface IConvert {
  canBeConvertedOneWay: boolean;
  canBeConvertedTwoWays: boolean;
  fromBaseSystem: (value: PseudoNumber) => PseudoValue;
  toBaseSystem: (value: PseudoNumber) => PseudoValue;
  toPrecision: (value: PseudoNumber) => PseudoValue;
  optionFromBaseSystem: (option: ItemAttributeOption) => ItemAttributeOption;
  proxyChangeHandler: (changeHandler: ChangeHandler) => ChangeHandler;
  chosenSystem?: UnitOfMeasure;
  defaultSystem?: UnitOfMeasure;
}

const findSystem = (
  unitOfMeasures: Record<string, UnitOfMeasure>,
  metricSystem: string
): UnitOfMeasure | undefined => {
  return unitOfMeasures[metricSystem];
};

const findConversion = (
  conversions: ConversionFormula[],
  from?: string,
  to?: string
): ConversionFormula | undefined => {
  if (!from || !to) {
    return undefined;
  }
  if (from === to) {
    return { from, to, formula: "${x}" };
  }
  return conversions.find(
    (conversion) => conversion.from === from && conversion.to === to
  );
};

const executeConversion =
  (conversionFormula?: ConversionFormula) =>
  (precisionConv?: number, defaultPrecision?: number) =>
  (x: PseudoNumber): PseudoValue => {
    if (isNil(x) || x === "" || x === "NaN" || x === "Infinity") {
      return null;
    }

    let value: number = numeral(x).value();
    let precision = precisionConv;

    const formula = conversionFormula?.formula;
    if (formula) {
      try {
        const filled = fillParam(formula, { x: value });
        value = expr.compile(filled)({});
      } catch (err) {
        if (err instanceof FillParamsError) {
          return null;
        } else {
          throw err;
        }
      }
    } else {
      precision = defaultPrecision;
    }

    return applyPrecision(precision)(value);
  };

const convertOptions =
  (fromBaseSystem: (x: PseudoNumber) => PseudoValue) =>
  (option: ItemAttributeOption): ItemAttributeOption => {
    const label =
      option.label || option.label == "0"
        ? `${fromBaseSystem(numeral(option.label).value())}`
        : undefined;

    const value =
      option.value || option.value === 0
        ? numeral(option.value).value()
        : undefined;

    return {
      ...option,
      label,
      value,
    };
  };

/**
 * Default export
 */
const convert = (
  unitOfMeasures: Record<string, UnitOfMeasure>, // from BaseField
  conversions: ConversionFormula[], // from redux
  chosenMetricSystem: string, // from redux
  defaultMetricSystem: string, // from redux
  fallbackToDefault: boolean = false // if set to true, when chosen system is not found, default is used
): IConvert => {
  const defaultSystem = findSystem(unitOfMeasures, defaultMetricSystem);

  let chosenSystem = findSystem(unitOfMeasures, chosenMetricSystem);
  if (!chosenSystem) {
    if (fallbackToDefault) {
      chosenSystem = defaultSystem;
    }
  }

  const conversionFromBaseSystem = findConversion(
    conversions,
    get(defaultSystem, "symbol"),
    get(chosenSystem, "symbol")
  );

  const conversionToBaseSystem = findConversion(
    conversions,
    get(chosenSystem, "symbol"),
    get(defaultSystem, "symbol")
  );

  const basePrecision = get(chosenSystem, "precision");
  const defaultPrecision = get(defaultSystem, "precision");

  // task ALFAPS-708: modifica get(chosenSystem, 'precision') in: get(chosenSystem, 'precision') || get(defaultSystem, 'precision')
  const fromBaseSystem = (x: PseudoNumber): PseudoValue =>
    executeConversion(conversionFromBaseSystem)(
      basePrecision,
      defaultPrecision
    )(x);

  const toBaseSystem = (x: PseudoNumber): PseudoValue =>
    executeConversion(conversionToBaseSystem)(defaultPrecision, basePrecision)(
      x
    );

  const toPrecision = (x: PseudoNumber): PseudoValue =>
    applyPrecision(get(defaultSystem, "precision"))(x);

  const canBeConvertedOneWay: boolean =
    chosenSystem !== undefined &&
    defaultSystem !== undefined &&
    conversionFromBaseSystem !== undefined;

  const canBeConvertedTwoWays: boolean =
    canBeConvertedOneWay && conversionToBaseSystem !== undefined;

  const optionFromBaseSystem: (
    option: ItemAttributeOption
  ) => ItemAttributeOption = convertOptions(fromBaseSystem);

  const proxyChangeHandler = (changeHandler: ChangeHandler): ChangeHandler => {
    return (e: FormEvent) => {
      const value = canBeConvertedTwoWays ? toBaseSystem(e.value) : e.value;
      changeHandler({ ...e, value });
    };
  };

  return {
    canBeConvertedOneWay,
    canBeConvertedTwoWays,
    chosenSystem,
    defaultSystem,
    fromBaseSystem,
    toBaseSystem,
    toPrecision,
    optionFromBaseSystem,
    proxyChangeHandler,
  };
};

/**
 * Given an array of BaseField, returns an array of IConvert objects for each BaseField
 */
export const getConversions = (
  baseFields: BaseField[],
  conversions: ConversionFormula[],
  chosenMetricSystem: string,
  defaultMetricSystem: string
): Record<string, IConvert> => {
  if (!baseFields) {
    return {};
  }
  return baseFields.reduce((res, baseField) => {
    const fieldId = getFieldId(baseField);
    const _convert = convert(
      baseField.unitOfMeasures ?? {},
      conversions,
      chosenMetricSystem,
      defaultMetricSystem
    );
    res[fieldId] = _convert;
    return res;
  }, {});
};

export default convert;
