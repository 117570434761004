import { Item, Permission, Project } from "../../../generated/axios";
import {
  ITEM_READONLY,
  PROJECT_READONLY,
} from "../../../modules/capability/constants";
import arrayUniqValues from "../../lib/arrayUniqValues";
import { CapabilitiesState, IActionArray, IActionObject } from "./reducer";

export function onRequest() {
  return {
    list: [],
    error: undefined,
  };
}

export function onLoad(state: CapabilitiesState, action: IActionArray) {
  const { payload = [] } = action;
  return {
    ...state,
    error: undefined,
    list: arrayUniqValues([...payload, ...state.list]),
  };
}

export function onItemData(state: CapabilitiesState, action: IActionArray) {
  const { payload } = action; // is an Item
  // const shared = payload.shared ? ;
  const { permission } = payload as Item; // field of Item
  return {
    ...state,
    error: undefined,
    list:
      permission !== Permission.WRITE
        ? arrayUniqValues([...state.list, ITEM_READONLY]) // ensure ITEM_READONLY is unique
        : state.list.reduce((acc, curr) => {
            return curr === ITEM_READONLY ? [...acc] : [...acc, curr];
          }, []),
  };
}

export function onProjectDetails(
  state: CapabilitiesState,
  action: IActionObject
) {
  const { payload = {} } = action;
  const { permission } = payload as Project;
  return {
    ...state,
    error: undefined,
    list:
      permission !== Permission.WRITE
        ? arrayUniqValues([...state.list, PROJECT_READONLY]) // ensure ITEM_READONLY is unique
        : state.list.reduce((acc, curr) => {
            return curr === PROJECT_READONLY ? [...acc] : [...acc, curr];
          }, []),
  };
}

export function onError(state: CapabilitiesState, action: IActionArray) {
  const { error } = action;
  return { ...state, list: [], error };
}
