import { isNil } from "lodash";
import { ItemAttributeOption } from "../../generated/axios";

/**
 * @param options the object array to be sorted
 * @param propertyName the field used to order
 *
 * WARN: sort non torna un nuovo array ma modifica quello su cui opera, è un side effect
 */
export const sortOptionsByProperty = (
  options: ItemAttributeOption[],
  propertyName: string
): ItemAttributeOption[] => {
  if (!propertyName) return Array<ItemAttributeOption>();
  return options.sort(function (a, b) {
    a[propertyName] = isNil(a[propertyName]) ? "" : a[propertyName];
    b[propertyName] = isNil(b[propertyName]) ? "" : b[propertyName];
    if (a[propertyName] < b[propertyName]) {
      return -1;
    }
    if (a[propertyName] > b[propertyName]) {
      return 1;
    }
    return 0;
  });
};
