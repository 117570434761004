import { OptionProps } from "antd/lib/select";
import React from "react";

export const includeSelectFilter = (
  inputValue: string,
  option: React.ReactElement<OptionProps>
) => {
  return Boolean(
    option.props?.children &&
      typeof option.props.children === "string" &&
      option.props.children.toLowerCase().includes(inputValue.toLowerCase())
  );
};

export const startsWithSelectFilter = (
  inputValue: string,
  option: React.ReactElement<OptionProps>
) => {
  return Boolean(
    option.props?.children &&
      typeof option.props.children === "string" &&
      option.props.children.toLowerCase().startsWith(inputValue.toLowerCase())
  );
};
