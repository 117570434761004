import { Button, Col, Form, InputNumber, Modal, Row } from "antd";
import { FormComponentProps } from "antd/lib/form";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import "./SaveAndFinishModal.scss";

interface IProps extends FormComponentProps {
  quantity?: number;
  saveAndFinish: (quantity: number) => void;
  unitDescription?: string;
}

const SaveAndFinishModal: React.FC<IProps> = ({
  form,
  quantity = 1,
  saveAndFinish,
  unitDescription,
}) => {
  const { getFieldDecorator, validateFields } = form;
  const [visible, setVisible] = useState(false);

  const onOk = useCallback(() => {
    validateFields((err, values) => {
      if (!err) {
        saveAndFinish(values.quantity);
        setVisible(false);
      }
    });
  }, [saveAndFinish, setVisible, validateFields]);

  return (
    <>
      <Button
        type="primary"
        className="height--40 margin-bottom--20"
        onClick={() => setVisible(true)}
      >
        <FormattedMessage
          id="save and finish"
          defaultMessage="Save and finish"
        />
      </Button>

      <Modal
        title={
          <FormattedMessage
            id="save and finish"
            defaultMessage="Save and finish"
          />
        }
        visible={visible}
        onOk={onOk}
        onCancel={() => setVisible(false)}
      >
        <Form className={"saveAndFinishModal"}>
          <FormattedMessage
            id="save and finish modal desc"
            defaultMessage="Please, do you want to save the Unit: {unitId} as your best choice?"
            values={{ unitId: unitDescription }}
          />
          <Row style={{ marginTop: "1em" }}>
            <Col span={8}>
              <Form.Item
                className="saveAndFinishModal__label"
                label={
                  <FormattedMessage
                    id="modalItemSaveAndFinish.quantity"
                    defaultMessage="Quantity"
                  />
                }
              >
                {getFieldDecorator("quantity", {
                  initialValue: quantity,
                  rules: [
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="quantity.required"
                          defaultMessage="A value is required"
                        />
                      ),
                    },
                  ],
                })(<InputNumber min={1} step={1} precision={0} />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default Form.create<IProps>()(SaveAndFinishModal);
