import React from "react";
import { Provider } from "react-redux";
import { hashVerify } from "../api";
import store from "./store";

const StoreProvider: React.FunctionComponent = (props) => {
  React.useEffect(() => hashVerify(), []);

  return <Provider store={store}>{props.children}</Provider>;
};

export default StoreProvider;
