import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { PROJECT_OPEN, projectDetailsDynamicPath } from "../../../config/paths";
import { Problem } from "../../../generated/axios";
import { ItemState } from "../../store/item/types";
import { UtilsState } from "../../store/utils/types";
import "./styles.scss";

interface Props {
  error: Problem;
  parentProjectId?: number;
  hasRedirect?: boolean;
}

const Message = React.memo(() => (
  <FormattedMessage id="exception item" defaultMessage="Error on Item" />
));

const ItemException = connect(
  (state: { item: ItemState; utils: UtilsState }) => ({
    error: state.item.errorOnItem,
    parentProjectId: state.utils ? state.utils.parentProjectId : undefined,
  })
)((props: Props) => {
  const { parentProjectId } = props;
  const error = props.error;

  /**
   * no goBack() perché non ho mai la sicurezza di provenire da un url interno all'app ()
   */

  if (props.hasRedirect && error && error.status === 400) {
    const url = parentProjectId
      ? projectDetailsDynamicPath(parentProjectId)
      : PROJECT_OPEN;
    return <Redirect to={url} />;
  }

  return error?.status ? (
    <div className="exception">
      <Message />
      {`: [${error.status}] ${error.message}`}
    </div>
  ) : null;
});

export default ItemException;
