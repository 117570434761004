import { Button, Col, Row } from "antd";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useStateBoolean } from "../../../../shared/hooks/useStateBoolean";
import { ItemState } from "../../../../shared/store/item/types";
import { CapabilityCheck, capabilityMap } from "../../../capability";
import { ModalQuote } from "../../../project/ModalQuote";
import QuoteButton from "./QuoteButton";

interface IProps {
  description: string;
  getItemProject: (id: number) => void;
  item: ItemState;
  guidedSelling: () => Promise<void>;
  quoteItems: (ids: number[], callback?: () => void) => void;
  saveAndFinish: (callback?: () => void) => void;
  unitId?: string;
}

const DescriptionRow: React.FC<IProps> = ({
  description,
  getItemProject,
  item,
  guidedSelling,
  quoteItems,
  saveAndFinish,
  unitId,
  children,
}) => {
  const [visible, open, close] = useStateBoolean(false);
  const update = Boolean(item.project?.cpq?.quote);
  const url = item.project?.cpq?.quote?.url;
  const isDefaultProject = Boolean(item.project?.isDefault);
  const [guidingSearch, setGuidingSearch] = useState(false);

  const onGuidedSearch = useCallback(() => {
    setGuidingSearch(true);
    guidedSelling().finally(() => setGuidingSearch(false));
  }, [guidedSelling, setGuidingSearch]);

  const onQuoteItem = useCallback(() => {
    const closeModalCallback = () => {
      getItemProject(item.id ?? 0);
      close();
    };
    const callback = () => quoteItems([item.id ?? 0], closeModalCallback);
    saveAndFinish(callback);
  }, [close, getItemProject, item, quoteItems, saveAndFinish]);

  const openQuote = useCallback(() => {
    if (url) window.open(url, "_blank")?.focus();
  }, [url]);

  return (
    <Row className="item__heading">
      <Col span={10}>
        <Row>
          <Col span={24} className="item__title">
            <FormattedMessage
              id="itemDescription"
              defaultMessage="Item Description"
            />
            :
          </Col>
          <Col span={24} className="item__title">
            {description}
          </Col>
        </Row>
      </Col>
      <Col span={14} className="text-align--right">
        {children}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "16px",
          }}
        >
          {item.cpq?.configurationId !== undefined && (
            <React.Fragment>
              <Button
                type="primary"
                onClick={onGuidedSearch}
                loading={guidingSearch}
                disabled={guidingSearch}
              >
                <FormattedMessage
                  id="guided search"
                  defaultMessage="GUIDED SEARCH"
                />
              </Button>
              <CapabilityCheck showIfHas={capabilityMap.ITEM_QUOTE}>
                <Button type="primary" onClick={openQuote} disabled={!url}>
                  <FormattedMessage
                    id="open quote"
                    defaultMessage="OPEN QUOTE"
                  />
                </Button>

                <QuoteButton
                  onClick={open}
                  isDefaultProject={isDefaultProject}
                  update={update}
                />

                <ModalQuote
                  update={update}
                  itemIds={item.id as number}
                  onCancel={close}
                  onOk={onQuoteItem}
                  visible={visible}
                  unitId={unitId}
                />
              </CapabilityCheck>
            </React.Fragment>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default DescriptionRow;
