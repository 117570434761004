import { message } from "antd";
import { History } from "history";
import { get } from "lodash";
import { durations } from "../../../config/defaults";
import { ItemTechnicalSpecificationApi } from "../../../generated/axios";
import Action from "../../../types/action";
import { Any } from "../../../types/Any";
import { apiConfig } from "../../api";
import { eIntl } from "../../eIntl";
import { messages } from "../../lib/locales/definedMessages";
import { resourceDownload } from "../../lib/resourceDownload";
import { ItemState } from "./types";

const technicalSpecification = new ItemTechnicalSpecificationApi(apiConfig());

export const getItemTechnicalSpecificationPdf = (
  itemId: number,
  unitId?: string,
  options?: Any // AxiosRequestConfig
) => {
  message.loading(
    eIntl.formatMessage(messages["message.generating_pdf"]),
    durations.loading
  );

  return technicalSpecification
    .getItemTechnicalSpecificationPdf(itemId, unitId, options)
    .then((res) => {
      message.success(
        eIntl.formatMessage(messages["message.pdf_is_ready"]),
        durations.success
      );
      resourceDownload(res);
    });
};

export function safeResults(state: ItemState, action: Action<Any>) {
  const currentResults = get(state, "results.results");

  if (currentResults) {
    return {
      ...state,
      ...action.payload,
      results: {
        ...action.payload.results,
        results: currentResults,
      },
    };
  }
  return {
    ...state,
    ...action.payload,
  };
}

export function openResultsPage(push: History["push"], itemId: number): void {
  push(`/configurator/${itemId}/results`);
}
