import { Dispatch } from "redux";
import { PriceListApi } from "../../../generated/axios";
import { apiConfig } from "../../api";
import { FAILURE, REQUEST, SUCCESS } from "../../lib/asyncActionHelper";
import { LOAD_CURRENT_PRICE_LIST } from "./consts";

const priceListApi = new PriceListApi(apiConfig());

export const loadCurrentPriceList = (dispatch: Dispatch) => {
  dispatch({ type: REQUEST(LOAD_CURRENT_PRICE_LIST) });
  priceListApi
    .getCurrentPriceList()
    .then((response) => {
      dispatch({
        type: SUCCESS(LOAD_CURRENT_PRICE_LIST),
        payload: response.data,
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: FAILURE(LOAD_CURRENT_PRICE_LIST) });
    });
};
