import { Alert } from "antd";
import moment from "moment";
import React from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import { messages } from "../../../../shared/lib/locales/definedMessages";
import { itemResultsPriceListSelector } from "../../../../shared/store/item/selectors";
import { priceListSelector } from "../../../../shared/store/priceList/selectors";

const formatDate = (date?: string | Date): string =>
  date ? moment(date).format("L") : "";

interface IProps {
  intl: InjectedIntl;
}

const PriceListInfo: React.FC<IProps> = ({ intl }) => {
  const itemResultsPriceList = useSelector(itemResultsPriceListSelector);
  const priceList = useSelector(priceListSelector);
  if (itemResultsPriceList && !priceList.isLoading) {
    const messageDescriptor = itemResultsPriceList.expired
      ? messages.priceListInfoExpired
      : messages.priceListInfo;
    const values = {
      name: itemResultsPriceList.name ?? "",
      date: formatDate(itemResultsPriceList.releaseDate),
      new_name: priceList.current?.name ?? "",
      expired: formatDate(priceList.current?.releaseDate),
    };
    const message = intl.formatMessage(messageDescriptor, values);
    return (
      <Alert
        message={message}
        type="warning"
        showIcon={itemResultsPriceList.expired}
      />
    );
  }
  return null;
};

export default injectIntl(PriceListInfo);
