import React, { ReactElement, ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

const Description = (props: Props): ReactElement<ReactNode> =>
  props.children ? <div className="description">{props.children}</div> : <></>;

export default Description;
