import { Layout, Row } from "antd";
import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import iconIndoor from "../../assets/images/icon-indoor.svg";
import iconOutdoor from "../../assets/images/icon-outdoor.svg";
import { PortfolioUtility, Problem } from "../../generated/axios";
import { Exceptions } from "../../shared/components/exceptions";
import { getApplicationTypes } from "../../shared/store/applicationType/actions";
import { ApplicationTypeState } from "../../shared/store/applicationType/types";
import { createNewItem } from "../../shared/store/item/actions";
import { NewItemParams } from "../../shared/store/item/types";
import {
  getUtilities,
  updateSelectedPortfolioId,
} from "../../shared/store/portfolio/actions";
import { projectIdSelector } from "../../shared/store/project/selectors";
import { IStore } from "../../shared/store/types";
import { clearProjectId } from "../../shared/store/utils/actions";
import { tempProjectIdSelector } from "../../shared/store/utils/selectors";
import { Callback } from "../../types/Callback";
import "./itemSelection.scss";
import ListRenderer from "./ListRenderer";
import { DescriptionBar, TitleBar } from "./staticParts";
import Utilities from "./Utilities";

interface StateProps {
  applicationType: ApplicationTypeState;
  currentProjectId?: number;
  errorOnItem?: Problem;
  tempProjectId?: number;
  utilities: PortfolioUtility[];
}

interface DispatchProps {
  clearProjectId: Callback;
  createNewItem: (params: NewItemParams) => void;
  getApplicationTypes: (portfolioIdFromUrl?: string) => void;
  getUtilities: (portfolioIdFromUrl: string) => void;
  updateSelectedPortfolioId: (id: number) => void;
}

interface Props extends RouteComponentProps, StateProps, DispatchProps {}

interface IState {
  freezeUI: boolean;
}

class ItemSelection extends React.Component<Props, IState> {
  state = {
    freezeUI: false,
  };

  static getDerivedStateFromProps(props: Props /* , state: IState */) {
    if (props.errorOnItem) {
      return { freezeUI: false };
    }
    return null;
  }

  componentDidMount() {
    // used when open from configurator sidebar
    const portfolioId = this.props.match.params["portfolioId"];

    if (portfolioId)
      this.props.updateSelectedPortfolioId(Number.parseInt(portfolioId));

    this.props.getApplicationTypes(portfolioId);
    this.props.getUtilities(portfolioId);
  }

  createItem = (applicationTypeId: number) => {
    if (!this.state.freezeUI) {
      const { history, tempProjectId, currentProjectId, createNewItem } =
        this.props;
      const { push } = history;

      const pid = this.props.match.params["projectId"];
      const projectIdFromUrl = pid || undefined;
      const projectId = tempProjectId ?? currentProjectId ?? projectIdFromUrl;

      this.setState(() => ({ freezeUI: true }));
      createNewItem({ applicationTypeId, push, projectId });
    }
  };

  render() {
    const { freezeUI } = this.state;
    const { applicationType, utilities } = this.props;
    return (
      <Layout className="item-selection">
        <Row className="item-selection__title">
          <TitleBar />
        </Row>

        <Exceptions.AsRow>
          <Exceptions.Item />
        </Exceptions.AsRow>

        <Exceptions.AsRow>
          <Exceptions.ApplicationTypes />
        </Exceptions.AsRow>

        <Row>
          <DescriptionBar />
        </Row>

        <Row
          className={classNames(
            "item-selection__row",
            "gutter-bug",
            freezeUI ? "frozen" : undefined
          )}
          gutter={16}
        >
          {!!applicationType.INDOOR.length && (
            <ListRenderer
              icon={iconIndoor}
              items={applicationType.INDOOR}
              callback={this.createItem}
              offset={1}
              span={applicationType.OUTDOOR.length ? 11 : 22}
              title={<FormattedMessage id="indoor" defaultMessage="Indoor" />}
            />
          )}
          {!!applicationType.OUTDOOR.length && (
            <ListRenderer
              icon={iconOutdoor}
              items={applicationType.OUTDOOR}
              callback={this.createItem}
              offset={applicationType.INDOOR.length ? 0 : 1}
              span={applicationType.INDOOR.length ? 11 : 22}
              title={<FormattedMessage id="outdoor" defaultMessage="Outdoor" />}
            />
          )}
        </Row>

        <Utilities list={utilities} />
      </Layout>
    );
  }
}

const mapStateToProps = (state: IStore): StateProps => ({
  applicationType: state.applicationType,
  currentProjectId: projectIdSelector(state),
  errorOnItem: state.item.errorOnItem,
  tempProjectId: tempProjectIdSelector(state),
  utilities: state.portfolio.utilities.list,
});

const mapDispatchToProps = {
  clearProjectId,
  createNewItem,
  getApplicationTypes,
  getUtilities,
  updateSelectedPortfolioId,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemSelection);
