import settingsInfo from "../../../../config/settings";
import { capabilitiesSelector } from "../../../../shared/store/capabilities/selectors";
import { IStore } from "../../../../shared/store/types";
import { capabilityMap } from "../../../capability";
import { SETTING_FIELDS } from "./SettingsModal";

export const displayInfo = (state: IStore) => {
  const scope = state.ui.modalSettings.scope;
  const sections = state.ui.modalSettings.sections;
  const capabilities = capabilitiesSelector(state);
  const {
    resultTableParts,
    genericSettingsForEditing,
    techSpecSettingsForEditing,
  } = state.settings;

  // data loading status info
  const partialLoadingStatus = [
    genericSettingsForEditing,
    techSpecSettingsForEditing,
  ];
  const completeLoadingStatus = [...partialLoadingStatus, resultTableParts];

  // show/hide sections based on capabilities if needed
  const showGenericsForm =
    sections.includes(SETTING_FIELDS.UI_INTERFACE_LANGUAGE) ||
    sections.includes(SETTING_FIELDS.UI_MEASUREMENT_SYSTEM);

  const showTechSpecFormCol = sections.includes(
    SETTING_FIELDS.TECHNICAL_SPECIFICATION_COL
  );

  const showTechSpecFormFull = sections.includes(
    SETTING_FIELDS.TECHNICAL_SPECIFICATION_FULL_MODAL
  );

  const showResTableForm =
    sections.includes(SETTING_FIELDS.RESULTS_FIELD_LIST) &&
    capabilities.includes(capabilityMap.SETTINGS_RES_TABLE_CUSTOMIZE_FIELDS);

  // scope item: show only res table config if it has capability
  if ((!scope || scope === settingsInfo.scopes.ITEM) && showResTableForm) {
    return {
      total: 1,
      isComplete: !!resultTableParts,
      // forms to show
      parts: {
        ui: false,
        resultFields: true,
        techSpecCol: false,
        techSpecFull: false,
      },
    };
  }

  // i nomi sono quelli usati in SettingsModal prima del refactoring
  const _parts = {
    ui: showGenericsForm,
    resultFields: showResTableForm,
    techSpecCol: showTechSpecFormCol,
    techSpecFull: showTechSpecFormFull,
  };

  return showResTableForm
    ? {
        total: 3,
        isComplete: completeLoadingStatus.every((item) => !!item),
        parts: _parts,
      }
    : {
        total: 2,
        isComplete: partialLoadingStatus.every((item) => !!item),
        parts: { ..._parts, resultFields: false },
      };
};
