import React from "react";
import { Redirect } from "react-router-dom";
import { isAuthenticated } from "../../shared/auth";

interface Props {
  orLogin?: string;
  children: React.ReactNode;
}

export default (props: Props) => {
  const orLogin = props.orLogin || "/login";

  if (isAuthenticated()) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  return <Redirect to={orLogin} />;
};
