import { Button } from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

const BackButton = React.memo((props: RouteComponentProps) => (
  <Button
    icon="left"
    className="icon--40 icon__no-border"
    onClick={() => props.history.goBack()}
  />
));

export default withRouter(BackButton);
