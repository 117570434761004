import { Button, Col, Form, Input, Row } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import { get } from "lodash";
import React, { ChangeEvent } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { HOME } from "../../config/paths";
import { KeyAndPassword, Problem } from "../../generated/axios";
import { shortenType } from "../../shared/lib/apiErrorMessages";
import {
  apiErrorsMessages,
  messages,
} from "../../shared/lib/locales/definedMessages";
import { finishPasswordReset } from "../../shared/store/account/actions";
import { AuthenticationResponse } from "../../shared/store/account/types";
import { IStore } from "../../shared/store/types";
import { ACCOUNT_DEACTIVATED } from "./consts";
import Layout from "./Layout";
import "./layout.scss";

interface IntlProps {
  intl: ReactIntl.InjectedIntl;
}

interface PasswordResetConfirmProps extends FormComponentProps, IntlProps {
  finishPasswordReset: (keyAndPassword: KeyAndPassword) => void;
  finishPasswordResetResponse?: AuthenticationResponse & Problem;
}

interface IState {
  psw: string;
  confirmationPsw: string;
  // pageState: PAGE_STATE,
  key: string; // "key" param in url querystring
  pswEmptyError: boolean;
  confirmationPswEmptyError: boolean;
  pswInconsistent: boolean;
}

const SideContent = React.memo(() => (
  <>
    <span className="publicPagesLayout__action">
      <FormattedMessage id="forgot psw" defaultMessage="Forgot password?" />
    </span>
    <br />
    <FormattedMessage id="step 2" defaultMessage="STEP 2" />
  </>
));

const EmptyPwdMessage = React.memo(() => (
  <span className="publicPagesLayout__error">
    <FormattedMessage
      id="password empty"
      defaultMessage="Please insert your password"
    />
  </span>
));

const InconsistentPwdMessage = React.memo(() => (
  <span className="publicPagesLayout__error">
    <FormattedMessage
      id="password inconsistent"
      defaultMessage="The two passwords are inconsistent"
    />
  </span>
));

const GenericPwdErrorMessage = React.memo(() => (
  <span className="publicPagesLayout__error" style={{ color: "red" }}>
    <FormattedMessage
      id="password too weak"
      defaultMessage="Password too weak, it must be greater than 4 characters"
    />
  </span>
));

const EmptyConfirmPwdMessage = React.memo(() => (
  <span className="publicPagesLayout__error">
    <FormattedMessage
      id="password empty"
      defaultMessage="Please insert your password"
    />
  </span>
));

const RequestSuccessMessage = React.memo(() => (
  <>
    <Row>
      <Col span={24} className="publicPagesLayout__success">
        <FormattedMessage
          id="changed psw ok"
          defaultMessage="Password changed correctly"
        />
      </Col>
    </Row>

    {/* Attention: button has to remain direct child of "publicPagesLayout__right" due to positioning */}
    <Link to={HOME}>
      <Button
        type="primary"
        htmlType="submit"
        className="publicPagesLayout__button"
      >
        <FormattedMessage id="login" defaultMessage="LOGIN" />
      </Button>
    </Link>
  </>
));

class PasswordResetConfirm extends React.Component<
  PasswordResetConfirmProps,
  IState
> {
  constructor(props: PasswordResetConfirmProps) {
    super(props);
    this.state = {
      psw: "",
      confirmationPsw: "",
      key: window.location.search?.substring(5)
        ? window.location.search.substring(5)
        : "",
      pswEmptyError: false,
      confirmationPswEmptyError: false,
      pswInconsistent: false,
    };
  }

  handleSubmit = () => {
    const { pswEmptyError, confirmationPswEmptyError } = this.state;
    // if already input but then deleted
    if (pswEmptyError || confirmationPswEmptyError) {
      return;
    }

    const { form } = this.props;
    const { psw, confirmationPsw, key } = this.state;
    // if entered for the first time and press directly to the button leaving inputs empty (avoiding the red empty message error as the user lands on the page)
    if (!form.getFieldValue("psw") || !form.getFieldValue("psw_confirm")) {
      if (!form.getFieldValue("psw")) {
        this.setState(() => ({ pswEmptyError: true }));
      }
      if (!form.getFieldValue("psw_confirm")) {
        this.setState(() => ({ confirmationPswEmptyError: true }));
      }
      return;
    }

    // custom rule: check if passwords are equal between each other
    if (psw !== confirmationPsw) {
      this.setState(() => ({
        pswInconsistent: true,
      }));
      return;
    } else {
      this.setState(() => ({ pswInconsistent: false }));
    }

    // prepare POST call object input
    const keyAndPassword: KeyAndPassword = {
      key,
      newPassword: psw,
    };
    // call API /account/reset-password/finish
    this.props.finishPasswordReset(keyAndPassword);
  };

  handleChangePassword = (psw: ChangeEvent) => {
    const value = get(psw, "target.value", "");
    this.setState(() => ({
      psw: value,
      pswEmptyError: !value,
    }));
  };

  handleChangeConfirmationPassword = (confirmPsw: ChangeEvent) => {
    const value = get(confirmPsw, "target.value", "");
    this.setState(() => ({
      confirmationPsw: value,
      confirmationPswEmptyError: !value,
    }));
  };

  handleRetry = () => {
    location.reload();
  };

  render() {
    const { pswInconsistent, pswEmptyError, confirmationPswEmptyError } =
      this.state;
    const { intl, form, finishPasswordResetResponse: response } = this.props;
    const { getFieldDecorator } = form; // this.props.form is been injected by Form.create()
    // specific server validation error response which has to be managed with a red message under the first input (as per empty inputs) --> the "!this.state.pswInconsistent" is not to show double messages
    const requestFailurePswWeak = !!(
      !pswInconsistent &&
      response &&
      response.status &&
      response.status === 400 &&
      response.type &&
      response.type.endsWith("/problem/invalid-password")
    );
    // generic server error response
    const requestFailureGenericError = !!(
      !requestFailurePswWeak &&
      response &&
      response.status &&
      response.status !== 200
    );
    const type = response?.type ? shortenType(response.type) : "";

    // in case the request successes, the response has no status and anything a part a plain object with id_token property
    const requestSuccess = !!response?.id_token;

    return (
      <Layout>
        <Layout.LeftSide>
          <SideContent />
        </Layout.LeftSide>
        <Layout.RightSide>
          <Form>
            {/* Cases involved: 
              - just entered the page
              - one or more field are empty and the user presses the button
              - password are not equal between each other
              - server side validation error (psw weak)
              The layout is the same for every case a part when an error is printed under the input(s)
            */}
            {(response === undefined ||
              requestFailurePswWeak ||
              pswEmptyError ||
              confirmationPswEmptyError ||
              pswInconsistent) && (
              <>
                <Row>
                  <Form.Item
                    label={
                      <FormattedMessage
                        id="password"
                        defaultMessage="Password"
                      />
                    }
                    colon={false}
                    className="publicPagesLayout__label"
                  >
                    {getFieldDecorator(
                      "psw",
                      {}
                    )(
                      <Input
                        size="large"
                        type="password"
                        className="publicPagesLayout__input publicPagesLayout__input--mail"
                        placeholder={intl.formatMessage(
                          messages.typePasswordPlaceholder
                        )}
                        data-test="reset-password"
                        onChange={this.handleChangePassword}
                      />
                    )}

                    {pswEmptyError && <EmptyPwdMessage />}
                    {/* condition "!this.state.pswEmptyError" is to avoid the double message error */}
                    {!pswEmptyError &&
                      !confirmationPswEmptyError &&
                      pswInconsistent && <InconsistentPwdMessage />}

                    {/* condition "!this.state.pswEmptyError" is to avoid the double message error */}
                    {!pswEmptyError && requestFailurePswWeak && (
                      <GenericPwdErrorMessage />
                    )}
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item
                    label={
                      <FormattedMessage
                        id="confirm_password"
                        defaultMessage="Confirm Password"
                      />
                    }
                    colon={false}
                    className="publicPagesLayout__label"
                  >
                    {getFieldDecorator(
                      "psw_confirm",
                      {}
                    )(
                      <Input
                        size="large"
                        type="password"
                        className="publicPagesLayout__input publicPagesLayout__input--mail"
                        placeholder={intl.formatMessage(
                          messages.typePasswordAgainPlaceholder
                        )}
                        data-test="reset-password-confirm"
                        onChange={this.handleChangeConfirmationPassword}
                      />
                    )}

                    {confirmationPswEmptyError && <EmptyConfirmPwdMessage />}
                  </Form.Item>
                </Row>

                {/* Attention: button has to remain direct child of "publicPagesLayout__right" due to positioning */}
                <Button
                  type="primary"
                  htmlType="submit"
                  className="publicPagesLayout__button"
                  data-test="reset-confirm-button"
                  onClick={this.handleSubmit}
                >
                  <FormattedMessage id="go" defaultMessage="GO" />
                </Button>
              </>
            )}

            {/* Case server responds with generic error */}
            {requestFailureGenericError && (
              <>
                <Row>
                  <Col span={24} className="publicPagesLayout__failure">
                    {type === ACCOUNT_DEACTIVATED ? (
                      this.props.intl.formatMessage(
                        apiErrorsMessages[
                          "api.error.message.account-deactivated"
                        ]
                      )
                    ) : (
                      <FormattedMessage
                        id="reset error"
                        defaultMessage="Error with reset password, please retry later"
                      />
                    )}
                  </Col>
                </Row>

                {/* Attention: button has to remain direct child of "registration-email__right" due to positioning */}
                <Button
                  type="primary"
                  htmlType="submit"
                  className="registration-email__button"
                  onClick={this.handleRetry}
                >
                  <FormattedMessage id="retry" defaultMessage="Retry" />
                </Button>
              </>
            )}

            {/* Case registration success & redirect to Home after the user presses on Login */}
            {requestSuccess && <RequestSuccessMessage />}
          </Form>
        </Layout.RightSide>
      </Layout>
    );
  }
}

const WithIntl = injectIntl<IntlProps>(PasswordResetConfirm);

const PasswordResetWithForm =
  Form.create<PasswordResetConfirmProps>()(WithIntl);

const mapStateToProps = (state: IStore) => ({
  finishPasswordResetResponse: state.account.finishPasswordResetResponse,
});

export default connect(mapStateToProps, { finishPasswordReset })(
  PasswordResetWithForm
);
