import axios, { CancelTokenSource } from "axios";

const AxiosCancelToken = axios.CancelToken;

export interface AjaxTokens {
  [key: string]: CancelTokenSource;
}

const ajaxTokens: AjaxTokens = {};

export function getUniqId() {
  return new Date().getTime() + Math.random().toString().replace(".", "");
}

export function createAjaxToken() {
  const token = getUniqId();
  ajaxTokens[token] = AxiosCancelToken.source();
  return token;
}

export function destroyAjaxToken(token: string) {
  if (token && ajaxTokens[token]) {
    delete ajaxTokens[token];
  }
}

export function getAxiosCancelToken(token: string) {
  return ajaxTokens[token]?.token;
}

export function cancelAjaxCall(token: string | null, message?: string) {
  if (token) {
    const cancelToken = ajaxTokens[token];
    if (cancelToken) {
      cancelToken.cancel(message);
      destroyAjaxToken(token);
    }
  }
}
