import { Any } from "../../../types/Any";
import { LOGOUT } from "../../auth/consts";
import { SUCCESS } from "../../lib/asyncActionHelper";
import { GET_PROJECT_DETAILS } from "../project/consts";
import { CLEAR_TEMP_PROJECT_ID, STORE_TEMP_PROJECT_ID } from "./consts";
import { UtilsState } from "./types";

export const initialState: UtilsState = {
  tempProjectId: undefined,
  parentProjectId: undefined,
};

export default (state: UtilsState = initialState, action: Any): UtilsState => {
  switch (action.type) {
    case STORE_TEMP_PROJECT_ID:
      return { ...state, tempProjectId: action.payload };
    case CLEAR_TEMP_PROJECT_ID:
      return { ...state, tempProjectId: undefined };
    case LOGOUT:
      return initialState;
    case SUCCESS(GET_PROJECT_DETAILS):
      return { ...state, parentProjectId: action.payload.id };
    default:
      return state;
  }
};
