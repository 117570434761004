import { ActionProviderData, LabelParts } from ".";
import {
  FormAction,
  ItemAttributeOption,
} from "../../../../../generated/axios";
import { FormFieldWithValue } from "../../../../../shared/store/configurator/types";
import { ACTION_TYPES } from "../../Actions";

const TEXT = "text";
const SELECT = "select";
const NUMBER = "number";
const fallbackActions: FormAction[] = [];

type BuildInputFunction = (field: FormFieldWithValue) => ActionProviderData;

export const allowedFields: string[] = [SELECT, TEXT, NUMBER];

export function extractUnitOfMeasure(label?: string): LabelParts {
  if (!label || label.indexOf("[") === -1)
    return {
      uom: "",
      cleanLabel: label ?? "",
    };

  return {
    uom: label.substring(label.indexOf("[")),
    cleanLabel: label.substring(0, label.indexOf("[") - 1),
  };
}

// only some inputTypes have action buttons
export function canDisplayActionButtons(field: FormFieldWithValue) {
  const { inputType = "" } = field;
  return allowedFields.includes(inputType);
}

// inputType = text
export const textCase: BuildInputFunction = (field) => {
  const { fieldActions = [], label = "" } = field;

  const infoAction = fieldActions.find(
    (action) => action["action"] === ACTION_TYPES.INFO
  );
  const downloadAction = fieldActions.find(
    (action) => action["action"] === ACTION_TYPES.DOWNLOAD
  );

  const params = infoAction?.params;
  const downloadUri = downloadAction?.params?.url;

  return {
    actionsToDisplay: field.fieldActions ?? fallbackActions,
    info: {
      title: label,
      ...params,
    },
    downloadUri,
  };
};

// inputType = select
export const selectCase: BuildInputFunction = (field) => {
  const { value, label, options, optionActions, fieldActions = [] } = field;

  const selectedOption: ItemAttributeOption | undefined = options
    ? options.find((opt) => opt.value === value)
    : {};

  // icons displayed in the label component
  let actionsToDisplay =
    optionActions[value] ?? fieldActions ?? fallbackActions;

  // NOTE: in some cases optionActions is an object, not an array
  if (!Array.isArray(actionsToDisplay)) actionsToDisplay = [actionsToDisplay];

  const infoAction = actionsToDisplay.find(
    (action: FormAction) => action.action === ACTION_TYPES.INFO
  );

  const downloadAction = actionsToDisplay.find(
    (action: FormAction) => action.action === ACTION_TYPES.DOWNLOAD
  );

  // info action params to be passed to InfoBox
  const infoData = infoAction?.params;
  const downloadUri = downloadAction?.params?.url;

  const title: string | undefined = selectedOption
    ? `${label} - ${selectedOption.label}`
    : label;

  return {
    actionsToDisplay,
    info: {
      ...infoData,
      title,
    },
    downloadUri,
  };
};

// maps a function to a defined inputType value
export const dataBuilderByInputType = (
  inputType = "none"
): BuildInputFunction | undefined => {
  const map = {
    [SELECT]: selectCase,
    [TEXT]: textCase,
    [NUMBER]: textCase,
  };

  return map[inputType];
};
