import React from "react";
import { connect } from "react-redux";
import { cancelAjaxCall } from "../../../../shared/lib/ajax-token";
import { IStore } from "../../../../shared/store/types";
import CancelRequestModalView from "./CancelRequestModalView";

interface Props {
  visible: boolean;
  ajaxCancelToken?: string;
}

function CancelRequestModal({ ajaxCancelToken, visible }: Props) {
  const cancelRequest = () => {
    if (ajaxCancelToken) cancelAjaxCall(ajaxCancelToken, "cancel");
  };

  return (
    <CancelRequestModalView visible={visible} cancelRequest={cancelRequest} />
  );
}

const mapStateToProps = (store: IStore): Props => {
  const { ajaxCancelToken, showCancelRequestModal } = store.item;
  return {
    visible: Boolean(showCancelRequestModal),
    ajaxCancelToken,
  };
};

export default connect(mapStateToProps)(CancelRequestModal);
