import { List, message } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { durations } from "../../../../config/defaults";
import { UserProblemAttachment } from "../../../../generated/axios";
import Dropzone from "./Dropzone";
import "./upload.scss";

interface OwnProps {
  addAttachments: (newAttachments: UserProblemAttachment[]) => void;
}

interface State {
  files: File[];
}

class Upload extends React.Component<OwnProps, State> {
  constructor(props: OwnProps) {
    super(props);
    this.state = {
      files: [],
    };
  }

  onFilesAdded = (files: File[]) => {
    this.setState((prevState) => ({
      files: prevState.files.concat(files),
    }));
    this.uploadFiles().finally();
  };

  toBase64 = async (file: File): Promise<string> => {
    const reader = new FileReader();
    await new Promise((resolve, reject) => {
      reader.onload = resolve;
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
    return reader.result as string;
  };

  uploadFiles = async () => {
    const promises: Promise<void>[] = [];
    this.state.files.forEach((file) => {
      promises.push(this.sendRequest(file));
    });
    try {
      await Promise.all(promises);
      await this.uploadFiles();
    } catch (e) {
      console.error("Uploading error", e);
      message.error(
        <FormattedMessage
          id="message.uploading_error"
          defaultMessage="Uploading error"
        />,
        durations.error
      );
    }
  };

  sendRequest = async (file: File): Promise<void> => {
    const base64 = await this.toBase64(file);
    return new Promise(() => {
      if (base64) {
        const newAttachment: UserProblemAttachment = {
          name: file.name,
          content: base64,
        };
        this.props.addAttachments([newAttachment]);
      }
    });
  };

  render() {
    return (
      <div className="Upload">
        <div className="Content">
          <Dropzone onFilesAdded={this.onFilesAdded} />

          {this.state.files && this.state.files.length > 0 && (
            <div className="Content__file-list">
              <List
                size={"small"}
                dataSource={this.state.files}
                renderItem={(item: File) => (
                  <List.Item>
                    <div key={item.name}>{item.name}</div>
                  </List.Item>
                )}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Upload;
