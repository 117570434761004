import { ApplicationType } from "../../../generated/axios";
import { ApplicationTypeState } from "./types";

export const groupByType = (
  applicationTypes: ApplicationType[]
): ApplicationTypeState => {
  return applicationTypes.reduce(
    (res, applicationType) => {
      if (applicationType.type) {
        res[applicationType.type].push(applicationType);
      }
      return res;
    },
    { INDOOR: [] as ApplicationType[], OUTDOOR: [] as ApplicationType[] }
  );
};
