import { Carousel } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Campaign, CampaignApi } from "../../generated/axios";
import { apiConfig } from "../../shared/api";
import { interfaceLanguageSelector } from "../../shared/store/settings/selectors";

const campaignApi = new CampaignApi(apiConfig());

interface IProps {}

export const Banners: React.FC<IProps> = () => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [speed, setSpeed] = useState(3000);
  const interfaceLanguage = useSelector(interfaceLanguageSelector);

  const durations = useMemo(
    () => campaigns.map((campaign) => campaign.duration ?? 3000),
    [campaigns]
  );

  const afterChange = useCallback(
    (index) => {
      setSpeed(durations[index]);
    },
    [setSpeed, durations]
  );

  // Load active campaigns on mount and whenever change interfaceLanguage
  // Banners are language related!
  useEffect(() => {
    campaignApi.getActiveCampaigns().then((response) => {
      if (response.data) setCampaigns(response.data);
    });
  }, [setCampaigns, interfaceLanguage]);

  if (campaigns.length === 0) return null;
  return (
    <Carousel autoplay autoplaySpeed={speed} afterChange={afterChange}>
      {campaigns.map((campaign, index) => (
        <div key={index}>
          <a
            href={campaign.targetUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="banner" src={campaign.imageUrl} alt="" />
          </a>
        </div>
      ))}
    </Carousel>
  );
};
