import { isEmpty } from "lodash";
import { InjectedIntl } from "react-intl";
import {
  ValidateProps,
  ValidationMessage,
  validationStatus,
} from "../../types/configurator";

/**
 * Validation statuses ordered by importance, from lower to higher
 */
const validationStatusOrder: validationStatus[] = [
  validationStatus.validating,
  validationStatus.success,
  validationStatus.warning,
  validationStatus.error,
];

/**
 * Returns the most important status of the two
 */
const getNextStatus = (
  previous: validationStatus,
  actual: validationStatus
): validationStatus => {
  const previousIndex = validationStatusOrder.indexOf(previous);
  const actualIndex = validationStatusOrder.indexOf(actual);
  return previousIndex > actualIndex ? previous : actual;
};

const joinHelpMessages = (...messages: string[]): string => {
  return messages.filter((msg) => msg?.length).join(". ");
};

/**
 * Applies intl function to validation message
 */
const translate = (
  validation: ValidationMessage,
  intl: InjectedIntl
): ValidationMessage => {
  const message = validation.message
    ? intl.formatMessage({ id: validation.message }, validation.params)
    : "";
  return { ...validation, message };
};

/**
 * Converts and translates Validation messages to Ant-friendly messages
 *
 * @param {ValidationMessage[]} messages is produced by validate()
 * @param {InjectedIntl} intl react-intl translation function
 * @returns {ValidateProps} are Ant-compliant messages
 */
export const validationMessagesToValidateProps = (
  messages: ValidationMessage[],
  intl?: InjectedIntl
): ValidateProps => {
  const res: ValidateProps = { validateStatus: validationStatus.success };

  if (isEmpty(messages)) {
    return res;
  }

  return messages.reduce((res, message) => {
    if (intl && !message.skipTranslate) {
      message = translate(message, intl);
    }
    res.validateStatus = getNextStatus(res.validateStatus, message.status);
    res.help = joinHelpMessages(res.help ?? "", message.message ?? "");
    return res;
  }, res);
};
