import React from "react";
import { ReactSVG } from "react-svg";
import logoLuve from "../../assets/images/luve-logo.svg";
import { LUVEGROUP_URL } from "../../config/defaults";
import "./logoLuve.scss";

interface Props {
  style?: string;
}

/**
 * Renders Lu-Ve logo in the top left corner of the components in which its wrapped
 * use "style" prop to pass the type of css properties to use
 */
export default (props: Props) => {
  const style = props.style === "absolute" ? " logo-luve--absolute" : "";

  return (
    <a href={LUVEGROUP_URL} target="_blank" rel="noopener noreferrer">
      <ReactSVG src={logoLuve} className={`logo-luve${style}`} />
    </a>
  );
};
