import IAction from "../../../types/action";
import { Any } from "../../../types/Any";
import { LOGOUT } from "../../auth/consts";
import { FAILURE, SUCCESS } from "../../lib/asyncActionHelper";
import { GET_AVAILABLE_APPLICATION_TYPES } from "./consts";
import { ApplicationTypeState } from "./types";

export const initialState: ApplicationTypeState = {
  INDOOR: [],
  OUTDOOR: [],
  error: undefined,
};

export default function reducer(
  state: ApplicationTypeState = initialState,
  action: IAction<Any>
): ApplicationTypeState {
  switch (action.type) {
    case SUCCESS(GET_AVAILABLE_APPLICATION_TYPES):
      return action.payload;
    case FAILURE(GET_AVAILABLE_APPLICATION_TYPES):
      return { INDOOR: [], OUTDOOR: [], error: action.payload };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
