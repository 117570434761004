import React, { ErrorInfo } from "react";
import store from "../../store/store";
import log from "../../log";
import "./styles.scss";

interface Props {
  children: React.ReactNode;
  warning?: React.ReactNode;
}

interface State {
  error?: Error;
  hasError: boolean;
  info?: ErrorInfo;
}

// TODO: APP_ERRORS use it to wrap components that can throw exceptions you need to capture

class ErrorHandler extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: undefined,
      hasError: false,
      info: undefined,
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true, error, info });
    log.error({ error, info, state: store.getState() });
  }

  renderGenericError = () => (
    <div className="component-exception centered">
      There is an error in this section.
    </div>
  );

  render() {
    const { warning } = this.props;
    if (this.state.hasError) {
      return warning ?? this.renderGenericError();
    }
    return this.props.children;
  }
}

export default ErrorHandler;
