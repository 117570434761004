import React, { FC } from "react";
import { defineMessages, InjectedIntl, injectIntl } from "react-intl";
import { connect } from "react-redux";
import pkg from "../../../package.json";
import { IStore } from "../../shared/store/types";
import { PlatformInfoType } from "../../shared/store/ui/types";

// prettier-ignore
defineMessages({
    "platformInfo.AirThinker": {id: "platformInfo.AirThinker", defaultMessage: "AirThinker"},
    "platformInfo.FrontEnd": {id: "platformInfo.FrontEnd", defaultMessage: "FrontEnd"},
    "platformInfo.HSP": {id: "platformInfo.HSP", defaultMessage: "HSP"},
    "platformInfo.apiVersion": {id: "platformInfo.apiVersion", defaultMessage: "Api"},
    "platformInfo.backendVersion": {id: "platformInfo.backendVersion", defaultMessage: "BackEnd"},
});

interface IProps {
  intl: InjectedIntl;
  platformInfo: PlatformInfoType;
}

const PlatformInfo: FC<IProps> = React.memo(({ intl, platformInfo }) => {
  const swVersions: PlatformInfoType = {
    FrontEnd: pkg.version,
    ...platformInfo,
  };
  const x = Object.entries(swVersions)
    .sort((a, b) => String(a[0]).localeCompare(b[0]))
    .map(([key, version]) => [
      intl.formatMessage({ id: `platformInfo.${key}`, defaultMessage: key }),
      version,
    ]);

  return (
    <div className="platformInfo">
      PlatformInfo:{" "}
      {x.map(([key, version], index) => (
        <span key={key}>
          {index === 0 ? "" : ", "}
          {key}: {version}
        </span>
      ))}
    </div>
  );
});

const connected = connect((state: IStore) => ({
  platformInfo: state.ui.platformInfo,
}))(PlatformInfo);

export default injectIntl(connected);
