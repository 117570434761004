import { Radio } from "antd";
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { DutyMode } from "../../../../generated/axios";
import { toggleDutyMode } from "../../../../shared/store/item/actions";
import { dutyModeSelector } from "../../../../shared/store/item/selectors";
import { IStore } from "../../../../shared/store/types";
import { Callback } from "../../../../types/Callback";

interface Props {
  toggleDutyMode: Callback;
  value: string;
}

class Switcher extends React.Component<Props> {
  changeHandler = () => {
    this.props.toggleDutyMode();
  };

  shouldComponentUpdate(nextProps: Props) {
    return nextProps.value !== this.props.value;
  }

  render() {
    const { value } = this.props;

    return (
      <Radio.Group
        defaultValue="design"
        buttonStyle="solid"
        size="large"
        className="radio-group"
        value={value}
        onChange={this.changeHandler}
      >
        <Radio.Button
          className={classNames({
            "radio-group_active": value === DutyMode.DESIGN,
          })}
          value={DutyMode.DESIGN}
        >
          Design
        </Radio.Button>
        <Radio.Button
          className={classNames({
            "radio-group_active": value === DutyMode.RATING,
          })}
          value={DutyMode.RATING}
          data-test="switcher-rating"
        >
          Rating
        </Radio.Button>
      </Radio.Group>
    );
  }
}

export default connect(
  (state: IStore) => ({ value: dutyModeSelector(state) }),
  {
    toggleDutyMode,
  }
)(Switcher);
