import { Button } from "antd";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useAppDispatch } from "../../../../shared/store/hooks";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import settingsInfo from "../../../../config/settings";
import { isValidConfigurationSelector } from "../../../../shared/store/configurator/selectors";
import { calculate } from "../../../../shared/store/item/actionsResults";
import {
  itemIdSelector,
  itemResultsSelector,
} from "../../../../shared/store/item/selectors";
import { projectIdSelector } from "../../../../shared/store/project/selectors";
import {
  getResultsTableFieldsConfiguration,
  getResultTableSettings,
} from "../../../../shared/store/settings/actions";

export const CalculateButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const itemId = useSelector(itemIdSelector);
  const projectId = useSelector(projectIdSelector);
  const isValidConfiguration = useSelector(isValidConfigurationSelector);
  const results = useSelector(itemResultsSelector);

  const {
    mechanicalDataChanged = false,
    thermalDataChanged = false,
    coreCalculationChanged = false,
    priceList,
  } = results ?? {};

  const withMechanicalOptions = !thermalDataChanged && mechanicalDataChanged;
  const derivedForceCalculate = !(mechanicalDataChanged && !thermalDataChanged);

  const onClick = useCallback(() => {
    if (itemId !== undefined && projectId !== undefined) {
      dispatch(getResultsTableFieldsConfiguration());
      dispatch(
        getResultTableSettings({ itemId, projectId }, settingsInfo.scopes.ITEM)
      );
      dispatch(
        calculate(itemId, push, derivedForceCalculate, withMechanicalOptions)
      );
    }
  }, [
    derivedForceCalculate,
    dispatch,
    itemId,
    projectId,
    push,
    withMechanicalOptions,
  ]);

  const noCalculateButton =
    results &&
    priceList &&
    !priceList.expired &&
    !thermalDataChanged &&
    !mechanicalDataChanged &&
    !coreCalculationChanged;
  if (noCalculateButton) return null;

  // Usata la class disabled e non l'attributo disabled per permettere che il click
  // sposti il focus e attivi l'onBlur su un eventuale campo modificato
  const buttonClassNames =
    "float--right margin-left--15 height--40" +
    (isValidConfiguration ? "" : " disabled");

  return (
    <Button
      data-test="calculate"
      type="primary"
      className={buttonClassNames}
      disabled={!isValidConfiguration}
      onClick={onClick}
    >
      <FormattedMessage
        id="calculate results"
        defaultMessage="CALCULATE RESULTS"
      />
    </Button>
  );
};
