import { DEFAULT_PORTFOLIO } from "../../../config/defaults";
import { Portfolio } from "../../../generated/axios";
import IAction from "../../../types/action";
import { FAILURE, REQUEST, SUCCESS } from "../../lib/asyncActionHelper";
import {
  GET_UTILITIES,
  LOAD_ALL_PORTFOLIO,
  UPDATE_SELECTED_PORTFOLIO_ID,
} from "./consts";
import { PortfolioState } from "./types";

const initialState: PortfolioState = {
  allPortfolios: [],
  selectedPortfolioId: DEFAULT_PORTFOLIO,
  utilities: {
    isLoading: false,
    list: [],
  },
};

function onSuccess(
  state: PortfolioState,
  action: IAction<Portfolio[]>
): PortfolioState {
  if (!action.payload) return state;
  const allPortfolios = action.payload as Portfolio[];
  return {
    ...state,
    error: undefined,
    allPortfolios,
    selectedPortfolioId: allPortfolios.filter((portfolio) =>
      Boolean(portfolio.available)
    )[0]?.id,
  };
}

function onFail(state: PortfolioState, action: IAction): PortfolioState {
  return {
    ...state,
    error: action.error,
    allPortfolios: [],
  };
}

function onUpdateId(state: PortfolioState, action: IAction): PortfolioState {
  return { ...state, selectedPortfolioId: action.payload };
}

const handlers = new Map();
handlers.set(SUCCESS(LOAD_ALL_PORTFOLIO), onSuccess);
handlers.set(FAILURE(LOAD_ALL_PORTFOLIO), onFail);
handlers.set(UPDATE_SELECTED_PORTFOLIO_ID, onUpdateId);

handlers.set(
  REQUEST(GET_UTILITIES),
  (state: PortfolioState): PortfolioState => ({
    ...state,
    utilities: { isLoading: true, list: [] },
  })
);

handlers.set(
  SUCCESS(GET_UTILITIES),
  (state: PortfolioState, action: IAction): PortfolioState => ({
    ...state,
    utilities: { isLoading: false, list: action.payload },
  })
);

handlers.set(
  FAILURE(GET_UTILITIES),
  (state: PortfolioState, action: IAction): PortfolioState => ({
    ...state,
    utilities: { isLoading: false, list: [], error: action.error },
  })
);

export default (
  state: PortfolioState = initialState,
  action: IAction
): PortfolioState => {
  const handler = handlers.get(action.type);
  return handler ? handler(state, action) : state;
};
