import { debounce } from "lodash";
import React from "react";
import { MemoScrollPosition } from "../../store/item/types";

export enum ScrollDirection {
  left = "left",
  right = "right",
}

const ITEM_MIN_SIZE = 130;

export const minus = (value: number): number => (value === 0 ? 0 : -value);

interface Props {
  memoScrollPosition?: MemoScrollPosition;
}

export interface IScrollContext {
  arrowEnabled: Record<ScrollDirection, boolean>;
  itemCount: number;
  itemWidth: number;
  scroll: (direction: ScrollDirection) => () => void;
  scrollTo: (index: number) => void;
  setDimensions: (count: number, firstVisibleIndex?: number) => void;
  sliderContainerRef: React.RefObject<HTMLDivElement>;
  sliderPosition: number;
  sliderRef: React.RefObject<HTMLDivElement>;
  sliderStep: number;
  sliderWidth: number;
  visibleChildren: number;
}

export const ScrollContext = React.createContext<Partial<IScrollContext>>({});

export default class ScrollableListWrapper extends React.Component<
  Props,
  IScrollContext
> {
  constructor(props: Props) {
    super(props);
    const memoScrollPosition = props.memoScrollPosition;
    this.state = {
      arrowEnabled: {
        [ScrollDirection.left]: false,
        [ScrollDirection.right]: true,
      },
      itemCount: 0,
      itemWidth: memoScrollPosition?.itemWidth ?? 100,
      scroll: this.scroll,
      scrollTo: this.scrollTo,
      setDimensions: this.setDimensions,
      sliderContainerRef: React.createRef(),
      sliderPosition: memoScrollPosition?.sliderPosition ?? 0,
      sliderRef: React.createRef(),
      sliderStep: memoScrollPosition?.sliderStep ?? 0,
      sliderWidth: 100,
      visibleChildren: 0,
    };
    this.handleWindowResize = debounce(this.handleWindowResize.bind(this), 300);
    window.addEventListener("resize", this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  setDimensions = (itemCount: number, firstVisibleIndex?: number) => {
    this.setState((oldState: IScrollContext) => {
      const containerWidth = this.getContainerWidth();
      const visibleChildren = Math.floor(containerWidth / ITEM_MIN_SIZE);
      const itemWidth = containerWidth / visibleChildren;
      const sliderWidth = itemWidth * itemCount;

      const sliderStep = Math.max(
        firstVisibleIndex !== undefined
          ? minus(firstVisibleIndex)
          : oldState.sliderStep,
        Math.min(visibleChildren - itemCount, 0)
      );
      const sliderPosition = sliderStep * itemWidth;
      return {
        itemCount,
        itemWidth,
        sliderWidth,
        sliderStep,
        sliderPosition,
        visibleChildren,
        arrowEnabled: {
          [ScrollDirection.left]: sliderPosition < 0,
          [ScrollDirection.right]: sliderStep > visibleChildren - itemCount,
        },
      };
    });
  };

  scroll = (direction: ScrollDirection) => () => {
    this.setState((oldState) => {
      const stepIncrement = this.getStepIncrement(direction);
      const sliderStep = oldState.sliderStep + stepIncrement;
      const sliderPosition = sliderStep * oldState.itemWidth;
      return {
        sliderPosition,
        sliderStep,
        arrowEnabled: {
          [ScrollDirection.left]: sliderPosition < 0,
          [ScrollDirection.right]:
            sliderStep > oldState.visibleChildren - oldState.itemCount,
        },
      };
    });
  };

  scrollTo = (destinationIndex: number = 0) => {
    this.setState((oldState) => {
      const sliderStep: number = minus(destinationIndex);
      const sliderPosition: number = sliderStep * oldState.itemWidth;
      return {
        sliderPosition,
        sliderStep,
        arrowEnabled: {
          [ScrollDirection.left]: sliderPosition < 0,
          [ScrollDirection.right]:
            sliderStep > oldState.visibleChildren - oldState.itemCount,
        },
      };
    });
  };

  render(): React.ReactNode {
    const { children } = this.props;
    return (
      <ScrollContext.Provider value={this.state}>
        {children}
      </ScrollContext.Provider>
    );
  }

  private getContainerWidth = (): number =>
    this.state.sliderContainerRef.current?.offsetWidth ?? 0;

  private readonly handleWindowResize = () => {
    if (!this.state.sliderContainerRef.current) {
      return;
    }
    const itemCount = this.state.itemCount;
    this.setDimensions(itemCount);
  };

  private getStepIncrement = (direction: ScrollDirection) =>
    direction === ScrollDirection.left ? 1 : -1;
}
