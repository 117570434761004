'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports['default'] = {
  // Options.jsx
  items_per_page: '/ side',
  jump_to: 'Gå til',
  jump_to_confirm: 'bekræft',
  page: '',

  // Pagination.jsx
  prev_page: 'Forrige Side',
  next_page: 'Næste Side',
  prev_5: 'Forrige 5 Sider',
  next_5: 'Næste 5 Sider',
  prev_3: 'Forrige 3 Sider',
  next_3: 'Næste 3 Sider'
};
module.exports = exports['default'];