import { Button, Dropdown, Icon, Menu } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Route, RouteComponentProps, withRouter } from "react-router-dom";
import * as paths from "../../../config/paths";
import settingsInfo from "../../../config/settings";
import { MetricSystem } from "../../../generated/axios";
import { SETTING_FIELDS } from "../../../modules/configurator/modals/SettingsModal/SettingsModal";
import { chosenMetricSystemAsObjectSelector } from "../../store/settings/selectors";
import { IStore } from "../../store/types";
import { openSettingsModal } from "../../store/ui/actions";
import { UIModalSettings } from "../../store/ui/types";
import { MeasurementSystem } from "./MeasurementSystem";

interface Props extends RouteComponentProps {
  title: string;
  projectId: number;
  chosenMetricSystem?: MetricSystem;
  openSettingsModal: (payload: Omit<UIModalSettings, "visible">) => void;
}

interface ClickParams {
  key: string;
}

class ProjectTitle extends React.Component<Props> {
  // can be removed, see function "theTitle"
  static defaultProps = {
    title: "Default Project Title",
  };

  onMenuClick = (info: ClickParams) => {
    const { history } = this.props;

    switch (info.key) {
      case "details":
        if (this.props.projectId) {
          window.open(
            paths.projectDetailsDynamicPath(this.props.projectId),
            "_blank"
          );
        }
        return;
      case "settings":
        this.props.openSettingsModal({
          sections: [
            SETTING_FIELDS.UI_MEASUREMENT_SYSTEM,
            SETTING_FIELDS.RESULTS_FIELD_LIST,
            SETTING_FIELDS.TECHNICAL_SPECIFICATION_COL,
          ],
          scope: settingsInfo.scopes.PROJECT,
          title: (
            <FormattedMessage
              id="project settings"
              defaultMessage="Project settings"
            />
          ),
        });
        return;
      case "new":
        return history.push(paths.PROJECT_NEW);
      case "open":
        return history.push(paths.PROJECT_OPEN);
      default:
        return null;
    }
  };

  renderMenu = () => {
    return (
      <Menu onClick={this.onMenuClick}>
        <Menu.Item key="details">
          <FormattedMessage
            id="project details"
            defaultMessage="Project details"
          />{" "}
          <FormattedMessage id="new tab" defaultMessage="(new tab)" />
        </Menu.Item>
        <Menu.Item key="settings">
          <FormattedMessage
            id="project settings"
            defaultMessage="Project settings"
          />
        </Menu.Item>
        <Menu.Item key="new">
          <FormattedMessage id="new project" defaultMessage="New Project" />
        </Menu.Item>
        <Menu.Item key="open">
          <FormattedMessage id="open project" defaultMessage="Open Project" />
        </Menu.Item>
      </Menu>
    );
  };

  shouldComponentUpdate(nextProps: Props) {
    return (
      nextProps.projectId !== this.props.projectId ||
      nextProps.title !== this.props.title
    );
  }

  render() {
    return (
      <Route
        path={paths.CONFIGURATOR}
        render={() => (
          <React.Fragment>
            <span>{this.props.title}</span>
            <Dropdown overlay={this.renderMenu()} trigger={["click"]}>
              <Button style={{ border: "0 none" }}>
                <Icon
                  type="down"
                  theme="outlined"
                  className="anticon--lighter"
                />
              </Button>
            </Dropdown>
            <MeasurementSystem metricSystem={this.props.chosenMetricSystem} />
          </React.Fragment>
        )}
      />
    );
  }
}

// TODO: refactor, now we have reselect
// work around
// needed because of the lack of project.title in initialState of state.item
// a better solution is using reselect
const theTitle = (state: IStore) => {
  if (state?.item?.project) return state.item.project.title || "_";
  return "-";
};
// same as above
const thePid = (state: IStore) => {
  if (state?.item?.project) return state.item.project.id;
  return null;
};

const Connected = connect(
  (state: IStore) => ({
    title: theTitle(state),
    projectId: thePid(state),
    chosenMetricSystem: chosenMetricSystemAsObjectSelector(state),
  }),
  {
    openSettingsModal,
  }
)(ProjectTitle);

const WithRouter = withRouter(Connected);

export default WithRouter;
