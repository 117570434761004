import { Project, Share } from "../../../generated/axios";

export interface ShareState {
  projectId?: number;
  item: {
    itemId?: number;
    itemShares: Share[];
  };
  project: {
    projectId?: number;
    projectShares: Share[];
    projectSharedWithMe: Project[];
  };
  addShareError: string;
  removeShareError: string;
}

// used to manage page state to manage all inside the component
export enum SHARE_TYPE {
  PROJECT, // has value = 0
  ITEM, // has value = 1
}
