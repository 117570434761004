import { ActionType } from "redux-promise-middleware";
import Action from "../../../types/action";
import { Any } from "../../../types/Any";
import { LOGOUT } from "../../auth/consts";
import { FAILURE, REQUEST, SUCCESS } from "../../lib/asyncActionHelper";
import { UPDATE_ITEM_QUANTITY } from "../project/consts";
import { RESET_BEFORE_RELOAD } from "../utils/consts";
import {
  CLEAR_ITEM,
  DELETE_ITEM,
  DUPLICATE_ITEM,
  GET_ITEM,
  GET_ITEM_PROJECT,
  GET_TECHNICAL_SPECIFICATION_CONFIG,
  GET_TECHNICAL_SPECIFICATION_CONFIG_PDF,
  GUIDED_SELLING,
  MEMO_SCROLL_POSITION,
  NEW_ITEM,
  SAVE_ITEM,
  SET_ITEM,
  SET_RESULTS,
  SET_TECHNICAL_SPECIFICATION_CONFIG,
} from "./consts";
import {
  CLEAR_RESULTS_ERROR,
  EXPORT_CSV,
  GET_RESULTS,
  LOCK_UNIT,
  UNLOCK_ALL_UNITS,
  UNLOCK_UNIT,
} from "./constsResults";
import { safeResults } from "./lib";
import { ItemResultExt, ItemResultsExt, ItemState } from "./types";

export const initialState: ItemState = {
  calculating: false,
  errorOnResult: undefined,
  config: {},
  errorOnItem: undefined,
  blocking: false,
  waitingPrices: undefined,
  ajaxCancelToken: undefined,
  showCancelRequestModal: undefined,
  updating: undefined,
};

export default function reducer(
  state: ItemState = initialState,
  action: Action<Any>
): ItemState {
  switch (action.type) {
    // case SUCCESS(DUPLICATE_ITEM): // genera un bug xkè il payload non ha results
    case SUCCESS(NEW_ITEM):
    case SUCCESS(GET_ITEM):
    case SUCCESS(DELETE_ITEM):
    case SUCCESS(CLEAR_ITEM):
      return {
        ...initialState,
        ...action.payload,
      };

    case SUCCESS(GET_ITEM_PROJECT): {
      const project = action.payload?.project;
      return {
        ...state,
        project,
      };
    }

    case SUCCESS(SET_ITEM):
      return {
        ...initialState,
        ...action.payload,
        blocking: false,
      };

    case SUCCESS(SAVE_ITEM):
      return safeResults(state, action);

    case SUCCESS(GET_RESULTS):
      return {
        ...state,
        results: action.payload,
        errorOnResult: undefined,
        calculating: false,
        waitingPrices: action.meta.waitingPrices,
        ajaxCancelToken: undefined,
        showCancelRequestModal: undefined,
      };

    case REQUEST(SET_ITEM):
      return {
        ...state,
        blocking: true,
      };

    case REQUEST(GET_RESULTS):
      return {
        ...state,
        calculating: true,
        waitingPrices: action.meta.waitingPrices,
        ajaxCancelToken: action.meta.ajaxCancelToken,
        showCancelRequestModal: action.meta.showCancelRequestModal,
      };

    case SET_RESULTS:
      if (state.results?.results) {
        const unitId = action.payload;
        const results = [...state.results.results];
        let selected = results.find((r) => r.selected);
        // selected unit is changed?
        if (selected?.unitId !== unitId) {
          if (selected) selected.selected = false;
          selected = results.find((r) => r.unitId === unitId);
          if (selected) selected.selected = true;
          return {
            ...state,
            results: {
              ...state.results,
              results,
            },
          };
        }
      }
      return state;

    case CLEAR_RESULTS_ERROR:
      return {
        ...state,
        calculating: true,
        errorOnResult: undefined,
      };

    case REQUEST(EXPORT_CSV):
      return { ...state, exportingCSV: true };

    case FAILURE(EXPORT_CSV):
    case SUCCESS(EXPORT_CSV):
      return { ...state, exportingCSV: false };

    case SUCCESS(GET_TECHNICAL_SPECIFICATION_CONFIG):
      return {
        ...state,
        config: action.payload,
      };

    case REQUEST(GET_TECHNICAL_SPECIFICATION_CONFIG):
      return state;

    case SET_TECHNICAL_SPECIFICATION_CONFIG:
    case SUCCESS(GET_TECHNICAL_SPECIFICATION_CONFIG_PDF):
      return {
        ...state,
        config: action.payload,
        calculating: false,
      };

    case REQUEST(GET_TECHNICAL_SPECIFICATION_CONFIG_PDF):
      return {
        ...state,
        calculating: true,
      };

    case LOGOUT:
      return initialState;

    case RESET_BEFORE_RELOAD:
      return initialState;

    // apiErrorHandler return payload: errore intercettato nella catch
    case FAILURE(NEW_ITEM):
    case FAILURE(SAVE_ITEM):
    case FAILURE(DELETE_ITEM):
    case FAILURE(CLEAR_ITEM):
    case FAILURE(GET_ITEM):
    case FAILURE(DUPLICATE_ITEM):
      return { ...state, errorOnItem: action.payload };

    case FAILURE(SET_ITEM):
      return { ...state, errorOnItem: action.payload, blocking: false };

    case FAILURE(GET_RESULTS):
      return {
        ...state,
        errorOnResult: action.payload,
        calculating: false,
        waitingPrices: false,
        ajaxCancelToken: undefined,
        showCancelRequestModal: undefined,
      };

    case FAILURE(GET_TECHNICAL_SPECIFICATION_CONFIG_PDF):
    case FAILURE(SET_TECHNICAL_SPECIFICATION_CONFIG):
      return {
        ...state,
        calculating: false,
        errorOnItem: action.payload,
      };

    case FAILURE(GET_TECHNICAL_SPECIFICATION_CONFIG):
      return {
        ...state,
        calculating: false,
      };

    case REQUEST(LOCK_UNIT):
    case REQUEST(UNLOCK_UNIT):
    case SUCCESS(LOCK_UNIT):
    case SUCCESS(UNLOCK_UNIT):
    case FAILURE(LOCK_UNIT):
    case FAILURE(UNLOCK_UNIT): {
      const update = action.type.endsWith(ActionType.Pending)
        ? { lockChanging: true, lockChanged: undefined }
        : action.type.endsWith(ActionType.Rejected)
        ? { lockChanging: false, lockChanged: undefined }
        : {
            locked: action.type === SUCCESS(LOCK_UNIT),
            lockChanged: true,
            lockChanging: undefined,
          };
      const index = state.results?.results?.findIndex(
        (result) => result.unitId === action.payload
      );
      if (index !== undefined && index !== -1) {
        const resultsObj = state.results as ItemResultsExt;
        const itemsArray = resultsObj.results as ItemResultExt[];
        const results = Object.assign([], itemsArray, {
          [index]: {
            ...itemsArray[index],
            ...update,
          },
        });
        return {
          ...state,
          results: {
            ...resultsObj,
            results,
          },
        };
      } else {
        return state;
      }
    }

    case SUCCESS(UNLOCK_ALL_UNITS): {
      const results = state.results?.results;
      if (results) {
        return {
          ...state,
          results: {
            ...(state.results as ItemResultsExt),
            results: results.map((result) => ({ ...result, locked: false })),
          },
        };
      }
      return state;
    }

    case MEMO_SCROLL_POSITION:
      return {
        ...state,
        memoScrollPosition: action.payload,
      };

    case REQUEST(UPDATE_ITEM_QUANTITY): {
      if (action.payload.id === state.id) {
        return {
          ...state,
          updating: true,
        };
      }
      return state;
    }

    case SUCCESS(UPDATE_ITEM_QUANTITY): {
      if (action.payload.id === state.id) {
        return {
          ...state,
          quantity: action.payload.quantity,
          updating: undefined,
        };
      }
      return state;
    }

    case FAILURE(UPDATE_ITEM_QUANTITY): {
      if (action.payload.id === state.id) {
        return {
          ...state,
          updating: undefined,
        };
      }
      return state;
    }

    case SUCCESS(GUIDED_SELLING): {
      if (state.results?.results) {
        return {
          ...state,
          results: {
            ...state.results,
            results: state.results?.results.map((result) => {
              if (result.unitId !== action.payload.unitId) {
                return result;
              } else {
                return {
                  ...result,
                  calculationValues: {
                    ...result.calculationValues,
                    productId: action.payload.productId,
                  },
                };
              }
            }),
          },
        };
      } else {
        return state;
      }
    }

    default:
      return state;
  }
}
