import { Icon, Input, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import { useAppDispatch } from "../../shared/store/hooks";
import { Permission } from "../../generated/axios";
import { messages } from "../../shared/lib/locales/definedMessages";
import { updateItemPosition } from "../../shared/store/item/actions";
import { ExtendedItem } from "../../shared/store/project/types";

interface IProps {
  intl: InjectedIntl;
  item: ExtendedItem;
}

const Position: React.FC<IProps> = ({ intl, item }) => {
  const id = item.id ?? 0; // Item always has an "id"
  const initialPosition = item.position ?? "";
  const updating = item.updating;
  const [editMode, setEditMode] = useState(false);
  const [position, setPosition] = useState(initialPosition);
  const dispatch = useAppDispatch();
  const updatePosition = useCallback(() => {
    setEditMode(false);
    if (position !== initialPosition) {
      dispatch(updateItemPosition(id, position));
    }
  }, [dispatch, id, position, initialPosition, setEditMode]);
  const undoOnEsc = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Escape") {
        setPosition(initialPosition);
        setEditMode(false);
      }
    },
    [initialPosition, setPosition, setEditMode]
  );
  useEffect(() => {
    if (!updating) setPosition(initialPosition);
  }, [updating, setPosition, initialPosition]);

  if (item.permission === Permission.READ) return <div>{position}</div>;
  return editMode ? (
    <Input
      key={`p${initialPosition}`}
      placeholder={intl.formatMessage(messages.positionPlaceholder)}
      value={position}
      onChange={(e) => setPosition(e.target.value)}
      onBlur={updatePosition}
      onPressEnter={updatePosition}
      autoFocus={true}
      onKeyDown={undoOnEsc}
      maxLength={10}
    />
  ) : (
    <div onClick={() => !updating && setEditMode(true)}>
      {updating ? (
        <Spin size="small" />
      ) : (
        <Icon type="edit" className="edit-icon" />
      )}
      &nbsp;{position}
    </div>
  );
};

export default injectIntl(Position);
