import React, { useContext, useEffect, useRef } from "react";
import { useAppDispatch } from "../../../../shared/store/hooks";
import { ScrollContext } from "../../../../shared/components/ScrollableList";
import { MEMO_SCROLL_POSITION } from "../../../../shared/store/item/consts";

export const MemoScrollbarPosition: React.FC = () => {
  const dispatch = useAppDispatch();
  const context = useContext(ScrollContext);
  const ref = useRef(context);
  ref.current = context;

  useEffect(() => {
    // Simulate componentWillUnmount()
    return () => {
      const { itemWidth, sliderPosition, sliderStep } = ref.current;
      dispatch({
        type: MEMO_SCROLL_POSITION,
        payload: { itemWidth, sliderPosition, sliderStep },
      });
    };
  }, [dispatch, ref]);

  return null;
};
