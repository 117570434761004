import classNames from "classnames";
import React from "react";
import BestChoiceBestRatio from "./BestChoiceBestRatio";

interface IProps {
  bestChoice?: boolean;
  bestRatio?: boolean;
}

const TableCellHeading: React.FC<IProps> = ({ bestChoice, bestRatio }) => {
  return (
    <div
      className={classNames("best-choice-best-ratio", {
        "best-choice-best-ratio--empty": !bestChoice && !bestRatio,
      })}
    >
      <BestChoiceBestRatio bestChoice={bestChoice} bestRatio={bestRatio} />
    </div>
  );
};

export default TableCellHeading;
