import { Icon, Modal, Tooltip } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useStateBoolean } from "../../../../shared/hooks/useStateBoolean";
import { Callback } from "../../../../types/Callback";
import { CapabilityCheck, capabilityMap } from "../../../capability";

interface IProps {
  displayedResults?: number;
  exportCsvHandler: Callback;
  hideText?: boolean;
  missingResults?: number;
}

export const TableResultsWarning: React.FC<IProps> = ({
  displayedResults = 0,
  exportCsvHandler,
  hideText = false,
  missingResults = 0,
}) => {
  const [open, setOpen, setClose] = useStateBoolean(false);
  if (!missingResults) return null;
  const title = (
    <FormattedMessage id="Missing results" defaultMessage="Missing results" />
  );
  return (
    <>
      <Tooltip placement={"bottom"} title={title} overlayClassName="no-arrow">
        <Icon
          type="warning"
          theme="outlined"
          className="icon--error"
          onClick={setOpen}
        />
      </Tooltip>
      <Modal
        visible={open}
        onOk={setClose}
        onCancel={setClose}
        title={title}
        footer={
          <CapabilityCheck
            showIfHas={capabilityMap.ITEM_EXP_CSV_STANDARD_VERSION}
          >
            <span
              className="table-footer__text as-button"
              onClick={exportCsvHandler}
            >
              <Icon
                type="download"
                theme="outlined"
                className="icon--blue"
                style={{ marginLeft: 0 }}
              />
              <FormattedMessage
                id="export csv"
                defaultMessage="Export results in CSV"
              />
            </span>
          </CapabilityCheck>
        }
      >
        <p>
          <FormattedMessage
            id="missing results information warning"
            defaultMessage="The calculation returned {N} results but only {M} are displayed."
            values={{
              M: displayedResults,
              N: missingResults + displayedResults,
            }}
          />
          <br />
          <CapabilityCheck
            showIfHas={capabilityMap.ITEM_EXP_CSV_STANDARD_VERSION}
          >
            <FormattedMessage
              id="missing results export information"
              defaultMessage="To view the data of all units, export the results in CSV."
            />
          </CapabilityCheck>
        </p>
      </Modal>

      {!hideText && (
        <div>
          {title}: {missingResults}
        </div>
      )}
    </>
  );
};
