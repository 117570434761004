import { Button, Col, Form, Icon, Input, List, Modal, Row } from "antd";
import { FormComponentProps } from "antd/lib/form";
import React, { ReactNode, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { UISharingModal } from "shared/store/ui/types";
import { NewShare, Permission, Share } from "../../../generated/axios";
import {
  clearError,
  postItemShare,
  postProjectShare,
  removeShare,
} from "../../../shared/store/share/actions";
import { SHARE_TYPE, ShareState } from "../../../shared/store/share/types";
import { closeSharingModal } from "../../../shared/store/ui/actions";
import { Callback } from "../../../types/Callback";
import "./shareModal.scss";

interface OwnProps {
  content?: string;
  shareType: SHARE_TYPE;
  visible: boolean;
  itemId: number;
  projectId: number;
  itemShares: Share[] | AugmentedShare[];
  projectShares: Share[];
  addShareError: string;
  removeShareError: string;
}

interface ReduxProps {
  clearError: Callback;
  closeSharingModal: Callback;
  postShareItem: (itemId: number, newShare: NewShare) => Promise<void>;
  postShareProject: (projectId: number, newShare: NewShare) => Promise<void>;
  removeShare: (shareId: number) => Promise<void>;
}

interface AugmentedShare extends Share {
  noAction: boolean;
}

interface Props
  extends FormComponentProps, // AntD's FormComponentProps necessary with validation
    OwnProps,
    ReduxProps {}

function removeActionOnShareItem(shares: Share[]): AugmentedShare[] {
  return shares.map((share) => ({ ...share, noAction: true }));
}

const ShareModal = (props: Props) => {
  const EMAIL = "email";
  const { setFields, getFieldValue, getFieldError } = props.form;

  // Get title from Redux - it could be Project or Item (the component is shared between them)
  const getTitle = () => (
    <div className="share-modal__title">
      {props.shareType === SHARE_TYPE.PROJECT ? (
        <FormattedMessage id="share project" defaultMessage="Share Project" />
      ) : (
        <FormattedMessage id="share item" defaultMessage="Share Item" />
      )}
    </div>
  );

  // watch store.share.error and update the message error in case
  useEffect(() => {
    setFields({
      email: {
        value: getFieldValue(EMAIL),
        error: getFieldError(EMAIL) ?? props.addShareError ?? [],
      },
    });
  }, [props.addShareError, setFields, getFieldValue, getFieldError]);

  // after validation, calls the API '/items/{itemId}/shares' to add the new email
  const addUser = () => {
    const typedEmail = getFieldValue(EMAIL);

    props.form.validateFields((err) => {
      if (err) {
        setFields({
          email: {
            // errors: [new Error("User not found in the system")],
            errors: [new Error(err)],
            // the following line sets the original inserted email, otherwise once the button
            // "Share" is pressed the input value is cleaned to empty string
            value: typedEmail,
          },
        });

        // clear the error (if shown before)
        // case email not present msg err and then retry with empty email
        setFields({
          email: {
            errors: undefined,
            value: "",
          },
        });
        props.clearError();
        return;
      }
      // from here case no "err" (Antd's built-in validator)
      // clear the error (if shown before)
      props.clearError();
      setFields({
        email: {
          errors: undefined,
          value: "",
        },
      });
      // call the server with a post call and then show the new user in list below
      // different API if the share is related to an item or a project
      props.shareType === SHARE_TYPE.ITEM
        ? props.postShareItem(props.itemId, {
            email: typedEmail,
            permission: Permission.READ,
          })
        : props.postShareProject(props.projectId, {
            email: typedEmail,
            permission: Permission.READ,
          });
    });
  };

  // delete a user from the list by clicking on 'x' button
  const removeUser = (id: number, email: string) => {
    // opens a second modal to ask delete confirmation
    Modal.confirm({
      title: (
        <FormattedMessage
          id="confirm unshare"
          defaultMessage="Confirm unshare"
        />
      ),
      content: (
        <FormattedMessage
          id="confirm unshare body"
          defaultMessage="Are you sure you want to delete the share with {email}?"
          values={{ email }}
        />
      ),
      onOk: () => {
        props.removeShare(id);
      },
    });
  };

  // this.props.form is been injected by Form.create()
  const { getFieldDecorator } = props.form;

  const footer: ReactNode = (
    <Row>
      <Col span={24}>
        <Row type="flex" justify="end">
          <Col>
            <Button
              htmlType="submit"
              type="primary"
              className="text-transform--uppercase"
              onClick={props.closeSharingModal}
            >
              <FormattedMessage id="ok" defaultMessage="OK" />
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  return (
    <Modal
      className="share-modal"
      visible={props.visible}
      centered={true}
      destroyOnClose={true}
      onCancel={props.closeSharingModal}
      title={getTitle()}
      afterClose={props.clearError} // resets shown error
      footer={footer}
    >
      <Form>
        <Form.Item
          className={
            "share-modal__input " + (props.addShareError ? "has-error" : "")
          }
        >
          {getFieldDecorator(EMAIL, {
            rules: [
              {
                required: true,
                type: EMAIL,
                message: "Please input a valid email!",
              },
            ],
          })(
            <Input
              placeholder="email@domain.com"
              data-test="share-input"
              addonAfter={
                <Button
                  className="share-modal__btn"
                  type="primary"
                  onClick={addUser}
                  data-test="share-project-button"
                  disabled={
                    getFieldValue(EMAIL) == "" ||
                    getFieldValue(EMAIL) == undefined ||
                    getFieldError(EMAIL) !== undefined
                  }
                >
                  <FormattedMessage id="share" defaultMessage="share" />
                </Button>
              }
            />
          )}
          {props.addShareError && (
            <div className="share-modal__item share-modal__item--error">
              {props.addShareError}
            </div>
          )}
        </Form.Item>

        <List
          className="share-modal__list"
          // emptyText sets the text to display in an empty list
          locale={{
            emptyText: (
              <FormattedMessage
                id="enter share"
                defaultMessage="Enter user's email to start sharing"
              />
            ),
          }}
          dataSource={
            props.shareType === SHARE_TYPE.ITEM
              ? props.itemShares.concat(
                  removeActionOnShareItem(props.projectShares)
                )
              : props.projectShares
          }
          renderItem={(share: AugmentedShare | Share) => (
            <List.Item
              key={share.id}
              actions={
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                share.noAction
                  ? []
                  : [
                      <Icon
                        key={share.id}
                        type="close-circle"
                        theme="filled"
                        data-test="share-project-delete"
                        onClick={() =>
                          share?.id &&
                          removeUser(share.id, share.email ? share.email : "")
                        }
                      />,
                    ]
              }
              className="share-modal__item"
            >
              {share.email}
            </List.Item>
          )}
        />
        {/* Remove share server error */}
        <div className="share-modal__item--error">{props.removeShareError}</div>
      </Form>
    </Modal>
  );
};

const connected = connect(
  (state: { ui: UISharingModal; share: ShareState }) => {
    return {
      visible: state.ui.sharingModal.visible,
      shareType: state.ui.sharingModal.shareType,
      itemShares: state.share.item.itemShares,
      projectShares: state.share.project.projectShares,
      itemId: state.share.item.itemId,
      projectId: state.share.project.projectId,
      addShareError: state.share.addShareError,
      removeShareError: state.share.removeShareError,
    };
  },
  {
    clearError,
    closeSharingModal,
    postShareItem: postItemShare,
    postShareProject: postProjectShare,
    removeShare,
  }
);

const ShareModalWithForm = Form.create()(ShareModal);

export default connected(ShareModalWithForm);
