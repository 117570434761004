import React from "react";

interface Props {
  message?: string;
}

export const Loading: React.FunctionComponent<Props> = ({ message }) => (
  <div className="loading">
    <div className="loading-container">
      <div className="loading-text">{message || "Loading..."}</div>
    </div>
  </div>
);
