import { AuthenticationApi } from "../../generated/axios";
import { apiConfig, interceptors } from "../api";
import { AuthenticationResponse } from "../store/account/types";
import store from "../store/store";
import { AUTH_COOKIE, LOGOUT, SERVER_SESSION_COOKIE } from "./consts";
import cookies from "./cookies";

const authenticationApi = new AuthenticationApi(apiConfig());

export const login = async (
  username: string,
  password: string,
  rememberMe?: boolean
): Promise<AuthenticationResponse> => {
  try {
    interceptors.ejectAuth();

    const { data } = await authenticationApi.authenticate({
      username,
      password,
      rememberMe,
    });

    if (!data["id_token"]) {
      cookies.remove(AUTH_COOKIE, { path: "/" });
    }

    // Temporary need casting because generated AuthenticationResponse (see ...generated/axios/api.ts) is bugged!
    return data as AuthenticationResponse;
  } catch (err) {
    cookies.remove(AUTH_COOKIE, { path: "/" });
    throw err;
  } finally {
    interceptors.handleAuth();
  }
};

export const logout = () => {
  let accessToken = cookies.get(AUTH_COOKIE);

  cookies.remove(AUTH_COOKIE, { path: "/" });
  cookies.remove(SERVER_SESSION_COOKIE, { path: "/" });
  store.dispatch({ type: LOGOUT });

  if (accessToken) {
    if (accessToken.startsWith("Bearer "))
      accessToken = accessToken.substring(7);
    document.location.href =
      "/api/public/logout?" + AUTH_COOKIE + "=" + accessToken;
  }
};

export const isAuthenticated = (): boolean => !!cookies.get(AUTH_COOKIE);
