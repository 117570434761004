import { WrappedFormUtils } from "antd/lib/form/Form";
import React, { ReactNode, useCallback, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import isAnEmptySelectField from "../../../shared/lib/isAnEmptySelectField";
import {
  select,
  setCurrentSection,
} from "../../../shared/store/configurator/actions";
import {
  ConfiguratorSectionState,
  FormFieldWithValue,
} from "../../../shared/store/configurator/types";
import { hasWritePermission } from "../../../shared/store/item/selectors";
import { IStore } from "../../../shared/store/types";
import { Callback } from "../../../types/Callback";
import {
  ConfiguratorSection,
  FormEvent,
  FormValue,
  ValidationMessage,
} from "../../../types/configurator";
import ConfiguratorForm from "./ConfiguratorForm";
import Field, { RenderOptions } from "./Field";

export type ChangeHandler = (e: FormEvent) => void;

const fieldRenderer =
  (
    validations: Record<string, ValidationMessage[]>,
    changeHandler: ChangeHandler,
    canWrite: boolean
  ) =>
  (form: WrappedFormUtils) =>
  (field: FormFieldWithValue, renderOptions?: RenderOptions): ReactNode => {
    // hide empty select field,
    // see also src\shared\store\configurator\lib.ts > validateFields function
    if (isAnEmptySelectField(field)) {
      return null;
    }

    const validationMessages =
      field.fieldId && validations[field.fieldId] && canWrite
        ? validations[field.fieldId]
        : [];

    return (
      <Field
        key={field.fieldId}
        changeHandler={changeHandler}
        field={field}
        form={form}
        renderOptions={renderOptions}
        validationMessages={validationMessages}
      />
    );
  };

export interface OwnProps {
  itemId: number;
  section: ConfiguratorSection;
}

interface StoreProps extends ConfiguratorSectionState {
  blocking?: boolean;
}

interface DispatchProps {
  select: (
    key: string,
    value: FormValue,
    section: ConfiguratorSection,
    onError?: Callback
  ) => Promise<void>;
  setCurrentSection: (currentSection: ConfiguratorSection) => void;
}

type Props = OwnProps & StoreProps & DispatchProps & RouteComponentProps;

const Configurator: React.FunctionComponent<Props> = (props) => {
  const canWrite = useSelector(hasWritePermission);
  const { section, select, setCurrentSection } = props;

  useEffect(() => {
    setCurrentSection(section);
  }, [section, setCurrentSection]);

  const changeHandler: ChangeHandler = useCallback(
    (e: FormEvent) => {
      const key: string = e.field;
      const value = e.value === undefined ? null : e.value;
      const onError = e.onError;
      select(key, value, section, onError);
    },
    [section, select]
  );

  return (
    <ConfiguratorForm config={props.config} blocking={props.blocking}>
      {fieldRenderer(props.validations, changeHandler, canWrite)}
    </ConfiguratorForm>
  );
};

/**
 *
 * @param state "state.config" comes from "state.configurator"
 * @param props
 */
const mapStateToProps = (state: IStore, props: OwnProps): StoreProps => {
  const { section } = props;
  return {
    ...state.configurator[section],
    ...props, // TODO: Verificare se serve
    results: state.item.results,
    blocking: state.item.blocking,
  };
};

const mapDispatchToProps = {
  select,
  setCurrentSection,
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(Configurator);

const WithRouter = withRouter(Connected);

export default WithRouter;
