import { Divider } from "antd";
import React, { SyntheticEvent } from "react";
import { getFooter } from "./BaseModal";
import FormHeader from "./FormHeader";
import "./style.scss";
import { Callback } from "../../../types/Callback";

interface Props {
  closeModal: Callback;
  confirmHandler: Callback;
  subTitle: string | React.ReactElement;
}

class ClearItem extends React.Component<Props> {
  onSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    this.props.confirmHandler();
  };

  onReset = (event: SyntheticEvent) => {
    event.preventDefault();
    this.props.closeModal();
  };

  render() {
    return (
      <form
        className="modal-form"
        onSubmit={this.onSubmit}
        onReset={this.onReset}
      >
        <FormHeader subTitle={this.props.subTitle} />
        <Divider />
        {getFooter()}
      </form>
    );
  }
}

export default ClearItem;
