import { message } from "antd";
import Bowser from "bowser";
import * as React from "react";
import * as ReactDOM from "react-dom";
import Root from "./Root";
import { interceptors } from "./shared/api";
import { tabTracker } from "./shared/lib/TabTracker";
import StoreProvider from "./shared/store/StoreProvider";
import "./styles/ant.less";
import supportedBrowsers from "./supportedBrowsers";

message.config({ maxCount: 1, duration: 2 });
interceptors.initAll();

const browser = Bowser.getParser(window.navigator.userAgent);
const isValidBrowser = browser.satisfies(supportedBrowsers);

// init tab activity tracking
tabTracker.track(window.location.pathname);

const rootElement = document.getElementById("root") as HTMLElement;

if (isValidBrowser) {
  // if browser is updated, launch the app which will override "root" element's content
  ReactDOM.render(
    <StoreProvider>
      <Root />
    </StoreProvider>,
    rootElement
  );
} else {
  // otherwise show error message without loading the App
  rootElement.innerHTML =
    "Your browser is outdated, please install the updates to proceed";
}
