import { Col, Row } from "antd";
import React, { FunctionComponent } from "react";
import plairLogo from "../../assets/images/Login/plair-logo.svg";
import LogoLuve from "../layout/LogoLuve";
import "./layout.scss";

interface Props extends FunctionComponent {
  LeftSide: FunctionComponent;
  RightSide: FunctionComponent;
}

const LeftSide: FunctionComponent = ({ children }) => {
  return (
    <Col span={8} offset={4} className="publicPagesLayout__left">
      <Row>
        <img src={plairLogo} alt="" />
      </Row>
      <Row>
        <Col span={24}>
          <div className="publicPagesLayout__description">
            {/* the left content */}
            {children}
          </div>
        </Col>
      </Row>
    </Col>
  );
};

const RightSide: FunctionComponent = ({ children }) => {
  return (
    <Col span={8} className="publicPagesLayout__right">
      <LogoLuve style={"absolute"} />
      {/* the form */}
      {children}
    </Col>
  );
};

const Layout: Props = ({ children }) => {
  return (
    <div className="publicPagesLayout">
      <Row className="publicPagesLayout__content">{children}</Row>
    </div>
  );
};

Layout.LeftSide = LeftSide;
Layout.RightSide = RightSide;

export default Layout;
