import { message } from "antd";
import { FormattedMessage } from "react-intl";
import { durations } from "../../config/defaults";
import { TypedIterableIterator } from "../../config/messageGenerators";
import { eIntl } from "../eIntl";

interface MessageType {
  content: string | FormattedMessage.MessageDescriptor;
  duration: number;
  type: string;
}

const toMms = (secs: number): number => secs * 1000;

/**
 * displays messages following a timeline
 *
 * usage example see
 * getItems()
 * in src\shared\store\items\actions.ts
 *
 * and
 * calculate()
 * in src\shared\store\items\actionsResults.ts
 *
 */
class MessageRunner {
  static iterator: TypedIterableIterator;

  static id?: ReturnType<typeof setTimeout>;

  /**
   * on timeline step ...
   */
  static onTimeout() {
    const data = MessageRunner.iterator.next();
    MessageRunner.clearTimeout();

    if (!data.done) {
      // restart timeout
      MessageRunner.id = setTimeout(
        MessageRunner.onTimeout,
        toMms(data.value.elapse)
      );
      // show a message
      MessageRunner.onMessage({
        type: data.value.type ?? "info",
        content: data.value.text,
        duration: data.value.elapse,
      });
    } else {
      MessageRunner.clearTimeout();
    }
  }

  // called by MessageRunner user
  static onSuccess({ content = "success" }: Partial<MessageType>) {
    MessageRunner.onMessage({
      content,
      type: "success",
      duration: durations.success,
    });
    MessageRunner.clearTimeout();
  }

  // called by MessageRunner user
  static onError({ content = "error" }: Partial<MessageType>) {
    MessageRunner.onMessage({
      content,
      type: "error",
      duration: durations.error,
    });
    MessageRunner.clearTimeout();
  }

  // a message is unique on the browser and will always be destroyed when closed
  static onMessage({ type, content, duration }: MessageType) {
    const contentMessage =
      typeof content === "string" ? content : eIntl.formatHTMLMessage(content);
    message[type](contentMessage, duration, message.destroy);
  }

  /**
   * start the timeline
   */
  static start(it?: TypedIterableIterator) {
    MessageRunner.clearTimeout();
    if (it) MessageRunner.iterator = it;
    MessageRunner.id = setTimeout(MessageRunner.onTimeout, 0);
  }

  /**
   * stop the timeline
   */
  static stop({ content = "request for stop" }: Partial<MessageType>) {
    MessageRunner.onMessage({
      content,
      type: "info",
      duration: durations.info,
    });
    MessageRunner.clearTimeout();
  }

  /**
   * clear timeout id
   */
  static clearTimeout() {
    if (MessageRunner.id) {
      clearTimeout(MessageRunner.id);
    }
    MessageRunner.id = undefined;
  }

  /**
   * initialize MessageRunner
   */
  static init(it: TypedIterableIterator) {
    MessageRunner.clearTimeout();

    if (it) MessageRunner.iterator = it;
  }
}

export default MessageRunner;
