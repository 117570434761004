import _ from "lodash";
import { Dispatch } from "redux";
import { durations } from "../../config/defaults";
import { Problem } from "../../generated/axios";
import { messageBuilders, payloadVariantMessage } from "./apiErrorMessages";
import { FAILURE } from "./asyncActionHelper";
import MessageRunner from "./messageRunner";
import { errorMessenger } from "./messenger";

// TODO: APP_ERRORS resettare l'errore
// TODO: APP_ERRORS usare ErrorHandler nell'app
// TODO: APP_ERRORS display dell'errore

interface ErrType {
  response?: {
    config: ConfigInErrType;
    status: number;
    statusText: string;
    data?: Problem;
  };
}

interface ConfigInErrType {
  url: string;
}

interface ParamsType {
  dispatch?: Dispatch;
  actionType?: string; // used for FAILURE(actionType)
  hasTimeout?: boolean;
  useMessageRunner?: boolean;
}

export const withUnderscore = (s: string) => _.replace(s, /\//g, "_");
export const removeDomain = (s: string) =>
  _.replace(s, "https://plair.com/", "");

export default ({
    dispatch,
    actionType = "",
    hasTimeout = true,
    useMessageRunner = false,
  }: ParamsType = {}) =>
  (err: ErrType) => {
    if (!err.response) return;

    const { data, statusText, status } = err.response;

    // message to display
    const message = messageBuilders(actionType)({ status, statusText, data });

    // action payload
    const payload = {
      type: "",
      status,
      message: payloadVariantMessage(message) /*key*/,
    };

    // show a messenger error
    if (useMessageRunner) {
      MessageRunner.onError({ content: message });
    } else {
      errorMessenger(status, message);
    }

    // for the single reducer: item, project, ...
    if (actionType && dispatch) {
      // immediate dispatch ...
      dispatch({ type: FAILURE(actionType), payload });
      // deferred dispatch. to remove the error, do not clear error if hasTimeout === false
      hasTimeout &&
        setTimeout(dispatch, durations.clearAfter, {
          type: FAILURE(actionType),
          payload: null,
        });
    }
  };
