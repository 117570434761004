import { DutyMode } from "../../../generated/axios";
import IAction from "../../../types/action";
import { Any } from "../../../types/Any";
import { ConfiguratorSection } from "../../../types/configurator";
import { LOGOUT } from "../../auth/consts";
import { FAILURE, REQUEST, SUCCESS } from "../../lib/asyncActionHelper";
import { NEW_ITEM } from "../item/consts";
import { RESET_BEFORE_RELOAD } from "../utils/consts";
import {
  LOAD,
  SELECT,
  SET_CURRENT_SECTION,
  UPDATE_LABELS_AND_ACTIONS_AFTER_LANG_CHANGE,
  VALIDATION_ERRORS,
} from "./consts";
import { ConfiguratorSectionState, ConfiguratorState } from "./types";

const sectionInitialState: ConfiguratorSectionState = {
  config: [], // used by view components
  dutyMode: DutyMode.DESIGN,
  optionLabels: {},
  optionActions: [],
  validations: {},
  labels: [],
  flatOptionLabels: {},
};

export const initialState: ConfiguratorState = {
  [ConfiguratorSection.THERMAL]: sectionInitialState,
  [ConfiguratorSection.MECHANICAL]: sectionInitialState,
  currentSection: ConfiguratorSection.THERMAL,
  loading: false,
  error: false,
};

export default function reducer(
  state: ConfiguratorState = initialState,
  action: IAction<Any>
): ConfiguratorState {
  const { type, payload } = action;
  switch (type) {
    case REQUEST(LOAD):
    case REQUEST(SELECT):
      // TODO: evaluate to move loading elsewhere
      return { ...state, loading: true };
    case SUCCESS(NEW_ITEM):
      return {
        ...state,
        [ConfiguratorSection.THERMAL]: sectionInitialState,
        [ConfiguratorSection.MECHANICAL]: sectionInitialState,
      };
    case SUCCESS(LOAD):
    case SUCCESS(UPDATE_LABELS_AND_ACTIONS_AFTER_LANG_CHANGE):
      return {
        ...state,
        [payload.section]: payload.state,
        loading: false,
      };

    case FAILURE(UPDATE_LABELS_AND_ACTIONS_AFTER_LANG_CHANGE):
      return { ...state };

    case SET_CURRENT_SECTION:
      return {
        ...state,
        currentSection: payload,
      };
    case SUCCESS(SELECT):
      return {
        ...state,
        [payload.section]: {
          ...state[payload.section],
          ...payload.state,
        },
        loading: false,
      };
    case VALIDATION_ERRORS:
      return {
        ...state,
        [payload.section]: {
          ...state[payload.section],
          validations: payload.validations,
        },
        loading: false,
      };
    case FAILURE(LOAD):
    case FAILURE(SELECT):
      // TODO: evaluate to move loading and errors elsewhere
      return { ...state, ...payload, loading: false, error: true };
    case LOGOUT:
      return initialState;
    case RESET_BEFORE_RELOAD:
      return initialState;
    default:
      return state;
  }
}
