import { Popover } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import React from "react";
import { Callback } from "../../../../types/Callback";
import "./infoBox.scss";

const regex = /(.jpg|.png|.gif|.jpeg)$/i;

function isImage(uri?: string) {
  return uri ? regex.test(uri) : false;
}

interface ContentProps {
  media?: string;
  text?: string;
  title?: string;
}

const Content = ({ media, text, title }: ContentProps) => {
  return (
    <div className="infoBox__content" data-test={title}>
      {isImage(media) && <img src={media} alt="" />}
      {text && <p>{text}</p>}
    </div>
  );
};

interface Props {
  visible?: boolean;
  text?: string;
  title?: string;
  media?: string;
  handleVisibleChange?: Callback;
  placement?: TooltipPlacement;
}

const InfoBox = ({
  title,
  text,
  media,
  placement = "bottom",
  visible = false,
  handleVisibleChange = () => {},
}: Props) => {
  return (
    <div className="infoBox">
      <Popover
        content={<Content text={text} media={media} title={title} />}
        title={title ?? null}
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
        placement={placement}
        className="infoBoxPopover"
      />
    </div>
  );
};

export default InfoBox;
