import { Icon, Tooltip } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { MissingOptionsMap } from "../../../../generated/axios";

interface IProps {
  data?: MissingOptionsMap[];
}

const MissingOptionsWarning: React.FC<IProps> = ({ data }) => {
  return data?.length ? (
    <Tooltip
      overlayClassName="no-arrow"
      title={
        <FormattedMessage
          id="results-table.missing-options"
          defaultMessage="Some of the selected options aren't present in this unit"
        />
      }
    >
      <Icon type="warning" className="missing-options-warning" />
    </Tooltip>
  ) : null;
};

export default MissingOptionsWarning;
