import { Col, Form, Row, Select } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { get } from "lodash";
import React from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { DEFAULT_LOCALE } from "../../../../config/defaults";
import settingsInfo from "../../../../config/settings";
import { GenericSettings, MetricSystem } from "../../../../generated/axios";
import { messages } from "../../../../shared/lib/locales/definedMessages";
import { includeSelectFilter } from "../../../../shared/lib/selectFilterOptions";
import {
  getGenerics,
  GetGenericsParams,
  resetGenericsSettings,
} from "../../../../shared/store/settings/actions";
import {
  languagesSelector,
  metricSystemsSelector,
} from "../../../../shared/store/settings/selectors";
import { Lang } from "../../../../shared/store/settings/types";
import { updateLanguage } from "../../../../shared/store/settingsTmp/actions";
import { IStore } from "../../../../shared/store/types";
import { Callback } from "../../../../types/Callback";
import { Option } from "../../../settings/ViewProfile";
import "./languages.scss";
import LoadingMessage from "./LoadingMessage";
import { SETTING_FIELDS } from "./SettingsModal";

interface StateProps {
  genericSettings?: GenericSettings;
  interfaceLanguage: string;
  languages: Lang[];
  metricSystems: MetricSystem[];
}

interface DispatchProps {
  getGenerics: (params: GetGenericsParams) => Promise<void>;
  resetGenericsSettings: Callback;
  updateLanguage: (payload: object) => void;
}

interface OwnProps {
  sections: string[];
  scope: string;
  itemId?: number;
  projectId?: number;
  hasDataToDisplay: boolean;
}

interface Props
  extends FormComponentProps,
    StateProps,
    DispatchProps,
    OwnProps,
    InjectedIntlProps {}

function getInitialLanguage(
  languages: Lang[],
  interfaceLanguage: string
): number {
  let found = languages.find((lang: Lang) => lang.key === interfaceLanguage);
  if (!found) {
    found = languages.find((lang: Lang) => lang.key === DEFAULT_LOCALE);
  }
  return found ? found.id : 1;
}

class Languages extends React.Component<Props> {
  componentDidMount() {
    const { itemId, projectId, getGenerics, resetGenericsSettings } =
      this.props;

    resetGenericsSettings();
    getGenerics({ itemId, projectId, purpose: "editing" });
  }

  selectHandler = (fieldName: string) => (value: number) => {
    const { updateLanguage } = this.props;
    updateLanguage({ [fieldName]: value });
  };

  renderFormattedLoadingMessage = () => (
    <Row>
      <Col span={24} className="language__container">
        <Row>
          <Col className="language__section-title margin-bottom--40" span={24}>
            <LoadingMessage />
          </Col>
        </Row>
      </Col>
    </Row>
  );

  render() {
    const {
      genericSettings,
      interfaceLanguage,
      // interfaceLanguageForEditing,
      /* chosenMetricSystem, */
      languages,
      metricSystems,
      intl,
      hasDataToDisplay,
      scope,
      sections,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    if (!hasDataToDisplay) return this.renderFormattedLoadingMessage();
    if (!genericSettings) return this.renderFormattedLoadingMessage();

    const { measurementSystem } = genericSettings;

    return (
      <Form className="language">
        <Row>
          <Col span={24} className="language__container">
            <Row>
              <Col
                className="language__section-title margin-bottom--40"
                span={24}
              >
                <FormattedMessage id="languages" defaultMessage="Languages" />
              </Col>
            </Row>

            {/* the following is visible only from user menu / setting */}
            {sections.includes(SETTING_FIELDS.UI_INTERFACE_LANGUAGE) && (
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={
                      <FormattedMessage
                        id="interface language"
                        defaultMessage="Interface Language"
                      />
                    }
                    className="language__label"
                  >
                    {getFieldDecorator("interfaceLanguage", {
                      initialValue: getInitialLanguage(
                        languages,
                        interfaceLanguage
                      ),
                      rules: [
                        {
                          required: true,
                          message: "Please input your language!",
                        },
                      ],
                    })(
                      <Select
                        style={{ width: "100%" }}
                        size="default"
                        placeholder={intl.formatMessage(
                          messages.selectLanguage
                        )}
                        // onChange={this.getSubdivisions}
                        onChange={this.selectHandler("interfaceLanguage")}
                        optionFilterProp="name"
                        filterOption={includeSelectFilter}
                        showSearch={true}
                      >
                        {languages?.map((lang: Lang) => (
                          <Option key={lang.id} value={lang.id}>
                            {lang.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row>
              <Col span={24}>
                <Form.Item
                  label={
                    <FormattedMessage
                      id="measurement system"
                      defaultMessage="Measurement System"
                    />
                  }
                  className="language__label"
                  extra={
                    scope === settingsInfo.scopes.USER ? (
                      <FormattedMessage
                        id="settings.measurement system tip in user scope"
                        defaultMessage="This setting applies only to new projects and not to existing projects. Modify the project configuration if you want to change the measurement system on the item."
                      />
                    ) : undefined
                  }
                >
                  {getFieldDecorator("measurementSystem", {
                    // initialValue: user.metricSystems,
                    initialValue: measurementSystem,
                    rules: [
                      {
                        required: true,
                        message: "Please input your metric system!",
                      },
                    ],
                  })(
                    <Select
                      style={{ width: "100%" }}
                      size="default"
                      placeholder={intl.formatMessage(messages.selectMetricSys)}
                      optionFilterProp="label"
                      onChange={this.selectHandler("measurementSystem")}
                      filterOption={includeSelectFilter}
                      showSearch={true}
                      data-test="measurement-system"
                    >
                      {metricSystems?.map(
                        (option: { id: number; name: string; key: string }) => (
                          <Option
                            key={option.id}
                            value={option.id}
                            data-test={"measurement-system-" + option.key}
                          >
                            {option.name}
                          </Option>
                        )
                      )}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WithIntl = injectIntl<Props>(Languages);

// <StateProps, DispatchProps, OwnProps, ReduxState>
export default connect(
  (state: IStore) => {
    return {
      genericSettings: get(state, "settings.genericSettingsForEditing"),
      interfaceLanguage: get(state, "settings.interfaceLanguage"),
      languages: languagesSelector(state),
      metricSystems: metricSystemsSelector(state),
    };
  },
  { getGenerics, resetGenericsSettings, updateLanguage }
)(
  Form.create<OwnProps & StateProps & DispatchProps & FormComponentProps>()(
    WithIntl
  )
);
