import { Dispatch } from "redux";
import { ProjectApi } from "../../../generated/axios";
import { apiConfig } from "../../api";
import apiErrorHandler from "../../lib/apiErrorHandler";
import { SUCCESS } from "../../lib/asyncActionHelper";
import { GET_PROJECTS } from "./consts";

export const projectApi = new ProjectApi(apiConfig());

export const getProjects = () => (dispatch: Dispatch) => {
  // API (GET) to "/projects"
  projectApi
    .getAllProjects()
    .then((res) => {
      dispatch({
        type: SUCCESS(GET_PROJECTS),
        payload: res.data,
      });
    })
    .catch(apiErrorHandler({ dispatch, actionType: GET_PROJECTS }));
};
