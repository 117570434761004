import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { PortfolioApi, PortfolioUtility } from "../../../generated/axios";
import { apiConfig } from "../../api";
import { FAILURE, REQUEST, SUCCESS } from "../../lib/asyncActionHelper";
import { IStore } from "../types";
import {
  GET_UTILITIES,
  LOAD_ALL_PORTFOLIO,
  UPDATE_SELECTED_PORTFOLIO_ID,
} from "./consts";

const api = new PortfolioApi(apiConfig());

export const getAllPortfolio = () => async (dispatch: Dispatch) => {
  try {
    const { data } = await api.getAllPortfolio();
    dispatch({
      type: SUCCESS(LOAD_ALL_PORTFOLIO),
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FAILURE(LOAD_ALL_PORTFOLIO),
      error,
    });
  }
};

export const updateSelectedPortfolioId = (id: number) => ({
  type: UPDATE_SELECTED_PORTFOLIO_ID,
  payload: id,
});

export const getUtilities =
  (portfolioIdFromUrl: string) =>
  (dispatch: Dispatch, getState: () => IStore) => {
    // Get portfolioId from url or store
    let portfolioId: number | undefined = Number.parseInt(portfolioIdFromUrl);
    if (!Number.isInteger(portfolioId)) {
      portfolioId = getState().portfolio.selectedPortfolioId;
    }

    if (portfolioId !== undefined) {
      dispatch({
        type: REQUEST(GET_UTILITIES),
      });
      api
        .getPortfolioUtilities(portfolioId)
        .then((response: AxiosResponse<PortfolioUtility[]>) => {
          dispatch({
            type: SUCCESS(GET_UTILITIES),
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: FAILURE(GET_UTILITIES),
            error,
          });
        });
    }
  };
