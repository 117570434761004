import React from "react";
import { defineMessages, InjectedIntl, injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import { OptionLabels, OptionsGroup } from "../../../../../generated/axios";
import { showDetailedOptionsPricesSelector } from "../../../../../shared/store/capabilities/selectors";
import { normalizeFieldId } from "../../../../../shared/store/configurator/lib";
import { convertFunctionSelector } from "../../../../../shared/store/settings/selectors";
import { IConversionResult } from "../../../../../shared/store/settings/types";
import { OptionRow } from "./OptionRow";

// No more used. Keep it so "npm run intl" add these translations to basic en/it.json translations file.
// prettier-ignore
defineMessages({
    cablingCover: { id: "optionprice.cablingCover", defaultMessage: "Cabling Cover" },
    casingMaterial: { id: "optionprice.casingMaterial", defaultMessage: "Casing Material" },
    coilFrame: { id: "optionprice.coilFrame", defaultMessage: "Coil Frame" },
    connectionBox: { id: "optionprice.connectionBox", defaultMessage: "Connection Box" },
    connectionBoxType: { id: "optionprice.connectionBoxType", defaultMessage: "Connection Box" },
    cover: { id: "optionprice.cover", defaultMessage: "Cover" },
    defrostSystem: { id: "optionprice.defrostSystem", defaultMessage: "Defrost System" },
    dripTray: { id: "optionprice.dripTray", defaultMessage: "Drip Tray" },
    expansionTank: { id: "optionprice.expansionTank", defaultMessage: "Expansion Tank" },
    fan: { id: "optionprice.fan", defaultMessage: "Fan" },
    feetType: { id: "optionprice.feetType", defaultMessage: "Feet Type" },
    finPack: { id: "optionprice.finPack", defaultMessage: "Fin Pack" },
    flanged: { id: "optionprice.flanged", defaultMessage: "Flanged" },
    header: { id: "optionprice.header", defaultMessage: "Header" },
    insulationType: { id: "optionprice.insulationType", defaultMessage: "Insulated Drip Tray" },
    kit: { id: "optionprice.kit", defaultMessage: "Kit" },
    kitForFan: { id: "optionprice.kitForFan", defaultMessage: "Kit For Fan" },
    packingType: { id: "optionprice.packingType", defaultMessage: "Packing" },
    predispositionFeet: { id: "optionprice.predispositionFeet", defaultMessage: "Predisposition Feet" },
    safetySwitchType: { id: "optionprice.safetySwitchType", defaultMessage: "Safety Switch" },
    sprayWaterType: { id: "optionprice.sprayWaterType", defaultMessage: "Spray Water" },
    switchboardType: { id: "optionprice.switchboardType", defaultMessage: "Switchboard" },
    wiring: { id: "optionprice.wiring", defaultMessage: "Wiring" },
    wiringConfigType: { id: "optionprice.wiringConfigType", defaultMessage: "Wiring Config Type" },
});
const t = (intl: InjectedIntl, fieldId: string) => {
  const id = `optionprice.${fieldId}`;
  if (id in intl.messages) {
    return intl.formatMessage({ id, defaultMessage: fieldId });
  } else {
    console.warn(
      `Missing option price translation key: "${fieldId}": { id: "${id}", defaultMessage: "${fieldId}" },`
    );
    return fieldId;
  }
};

const formatConvertedValue = (result: IConversionResult): string => {
  return `${result.value}${result.symbol ? result.symbol : ""}`;
};

interface IProps {
  intl: InjectedIntl;
  level?: number;
  optionLabels: OptionLabels;
  showPrices: boolean;
  optionsGroup: OptionsGroup;
}

const OptionDetails: React.FC<IProps> = ({
  intl,
  level = 0,
  optionLabels,
  showPrices,
  optionsGroup,
}) => {
  const label = t(intl, normalizeFieldId(optionsGroup.groupKey ?? ""));
  const labelParts = [label];
  const convertFunction = useSelector(convertFunctionSelector);
  const showDetailedPrices = useSelector(showDetailedOptionsPricesSelector);
  optionsGroup.fields
    // Only fields with a value!
    ?.filter(
      (field) =>
        field.value !== "" && field.value !== undefined && field.value !== null
    )
    .forEach((field) => {
      const optionLabelValue = field.fieldKey
        ? optionLabels[field.fieldKey] ?? {}
        : {};
      const value =
        optionLabelValue[field.value] ||
        formatConvertedValue(
          convertFunction(field.fieldKey ?? "", field.value)
        );
      labelParts.push(value);
    });

  // A single OptionRow (all fields showed into label)
  return (
    <>
      <OptionRow
        label={labelParts.join(" - ")}
        level={level}
        price={optionsGroup.price}
        showPrice={showPrices}
      />
      {optionsGroup.subGroups
        ? optionsGroup.subGroups.map((subGroup) => (
            <OptionDetails
              intl={intl}
              level={level + 1}
              key={subGroup.groupKey}
              optionLabels={optionLabels}
              showPrices={showPrices && showDetailedPrices}
              optionsGroup={subGroup}
            />
          ))
        : null}
    </>
  );
};

export default injectIntl(OptionDetails);
