import { History, LocationListener } from "history";
import { get } from "lodash";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CONFIGURATOR_RESULTS } from "../../../config/paths";
import { Callback } from "../../../types/Callback";
import cookies from "../../auth/cookies";
import { cancelAjaxCall } from "../../lib/ajax-token";
import { tabTracker } from "../../lib/TabTracker";
import {
  getGenerics,
  GetGenericsParams,
  GetResultTableParams,
  getResultTableSettings,
  GetTechSpecParams,
  getTechSpecSettings,
  setSettingsContext,
} from "../../store/settings/actions";
import { settingsContextSelector } from "../../store/settings/selectors";
import { IStore } from "../../store/types";
import { resetBeforeReload } from "../../store/utils/actions";
import {
  getActiveSection,
  getItemIdFromPathname,
  getProjectIdFromPathname,
  isPublicRoute,
  mustResetConfiguratorSection,
} from "./lib";

const resultsRegEx = new RegExp(`^/configurator/\\d+${CONFIGURATOR_RESULTS}$`);

interface DispatchProps {
  getGenerics: (params: GetGenericsParams) => Promise<void>;
  getResultTableSettings: (
    params: GetResultTableParams,
    withScope?: string
  ) => Promise<void>;
  getTechSpecSettings: (params: GetTechSpecParams) => Promise<void>;
  resetBeforeReload: Callback;
  setSettingsContext: (payload?: string) => void;
}

interface StoreProps {
  previousSettingsContext: string;
  secondGetResultCancelToken?: string;
  userEmail?: string;
}

interface OwnProps {
  history: History;
}

interface Props
  extends OwnProps,
    StoreProps,
    RouteComponentProps,
    DispatchProps {}

/**
 * RouteChangeHandler listens to route changes
 */
class RouteChangeHandler extends React.PureComponent<Props> {
  removeHandler: Callback;

  constructor(props: Props) {
    super(props);
    this.state = { email: undefined };

    const { listen } = props.history;
    this.removeHandler = listen(this.changeHandler);
  }

  static getDerivedStateFromProps(props: Props, state: { email?: string }) {
    const { pathname } = window.location;
    if (isPublicRoute(pathname)) return null;

    if (props.userEmail !== state.email) {
      const {
        getGenerics,
        getResultTableSettings,
        getTechSpecSettings,
        setSettingsContext,
        userEmail,
      } = props;
      const settingsContext = getActiveSection(pathname);

      setSettingsContext(settingsContext);
      const isAuthenticatedInCookie = cookies.get("AUTH_TOKEN");

      if (!!userEmail || isAuthenticatedInCookie) {
        const itemId = getItemIdFromPathname(pathname);
        const projectId = getProjectIdFromPathname(pathname);

        getGenerics({ itemId, projectId, settingsContext }).finally();
        getResultTableSettings({
          itemId,
          projectId,
          settingsContext,
        }).finally();
        getTechSpecSettings({ itemId, projectId, settingsContext }).finally();
      }
      return { email: userEmail };
    }
    return null;
  }

  /**
   * route change callback
   */
  changeHandler: LocationListener = (location) => {
    const {
      resetBeforeReload,
      setSettingsContext,
      getGenerics,
      getResultTableSettings,
      getTechSpecSettings,
      previousSettingsContext,
      secondGetResultCancelToken,
    } = this.props;
    const { pathname } = location;

    // Sub cambio route: canceling dell'eventuale chiamata Ajax di ricalcolo dei prezzi
    // a meno che il cambio non porti alla pagina dei risultati ("/configurator/{id}/results")
    if (secondGetResultCancelToken && !resultsRegEx.test(pathname)) {
      cancelAjaxCall(secondGetResultCancelToken, "cancel");
    }

    tabTracker.track(pathname);

    /**
     * call an action creator to reset config and item reducer
     */
    if (mustResetConfiguratorSection(pathname)) resetBeforeReload();

    const settingsContext = getActiveSection(pathname);
    setSettingsContext(settingsContext);

    if (previousSettingsContext !== settingsContext && !!this.props.userEmail) {
      const itemId = getItemIdFromPathname(pathname);
      const projectId = getProjectIdFromPathname(pathname);
      getGenerics({ itemId, projectId, settingsContext }).finally();
      getResultTableSettings({ itemId, projectId, settingsContext }).finally();
      getTechSpecSettings({ itemId, projectId, settingsContext }).finally();
    }
  };

  componentWillUnmount() {
    this.removeHandler();
  }

  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}

const ConnectedRouteChangeHandler = connect(
  (state: IStore) => {
    return {
      previousSettingsContext: settingsContextSelector(state),
      secondGetResultCancelToken: get(state, "item.ajaxCancelToken"),
      userEmail: get(state, "account.account.email"),
    };
  },
  {
    getGenerics,
    getResultTableSettings,
    getTechSpecSettings,
    resetBeforeReload,
    setSettingsContext,
  }
)(RouteChangeHandler);

export default withRouter(ConnectedRouteChangeHandler);
