import { defineMessages } from "react-intl";

// prettier-ignore
export const messages = defineMessages({
    "validation.equal": {id: "validation.equal", defaultMessage: "Must be equal to {1}"},
    "validation.gt": {id: "validation.gt", defaultMessage: "Must be greater than {1}"},
    "validation.gte": {id: "validation.gte", defaultMessage: "Must be at least greater than {1}"},
    "validation.in": {id: "validation.in", defaultMessage: "Must be equal to one of the defined options"},
    "validation.lt": {id: "validation.lt", defaultMessage: "Must be lower than {1}"},
    "validation.lte": {id: "validation.lte", defaultMessage: "Must be a maximum of {1}"},
    "validation.max": {id: "validation.max", defaultMessage: "Must be lower than {1}"},
    "validation.max.exTempDifference": {
        id: "validation.max.exTempDifference",
        defaultMessage: "Difference must be lower than {1}"
    },
    "validation.min": {id: "validation.min", defaultMessage: "Must be greater than {1}"},
    "validation.min.exTempDifference": {
        id: "validation.min.exTempDifference",
        defaultMessage: "Difference must be greater than {1}"
    },
    "validation.range": {id: "validation.range", defaultMessage: "Must be between {1} and {2}"},
    "validation.range.evaporatingTemperature": {
        id: "validation.range.evaporatingTemperature",
        defaultMessage: "Evaporating temperature must be between {1} and {2}"
    },
    "validation.regexp": {id: "validation.regexp", defaultMessage: "Must match {1}"},
    "validation.required": {id: "validation.required", defaultMessage: "{1} is required"},
    "validation.requiredIfEmpty": {
        id: "validation.requiredIfEmpty",
        defaultMessage: "At least one of these fields is required"
    },
    "validation.required.capacity": {id: "validation.required.capacity", defaultMessage: "{1} is required"},
});
