import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { BaseProps } from "./types";

const AsLink: FunctionComponent<BaseProps> = React.memo((props) => {
  const { children, linkTo, outerClickHandler } = props;
  return (
    <div className="section__content" onClick={outerClickHandler}>
      <Link className="section__title has-not-dropdown-menu" to={linkTo}>
        {children}
      </Link>
    </div>
  );
});

export default AsLink;
