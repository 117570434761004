import { BaseField } from "../../generated/axios";
import upperCaseLabel from "../lib/upperCaseLabel";
import { IConvert } from "./conversionFunctions";

export const getFieldId = (baseField: BaseField) => baseField.fieldId;

export const convertLabel = (
  baseField: BaseField,
  conversions: Record<string, IConvert>,
  uppercase: boolean = true
): BaseField => {
  const fieldId = getFieldId(baseField);
  const convertable = conversions[fieldId];

  const _baseField = { ...baseField };

  const system = convertable.canBeConvertedOneWay
    ? convertable.chosenSystem
    : convertable.defaultSystem;

  const symbol = (system?.symbol && `[${system.symbol}]`) ?? "";
  const label = (uppercase ? upperCaseLabel(baseField) : baseField.label) ?? "";

  _baseField.label = `${label} ${symbol}`;

  return _baseField;
};

export const convertLabels = (
  baseFields: BaseField[],
  conversions: Record<string, IConvert>,
  uppercase: boolean = true
): BaseField[] => {
  return baseFields.map((baseField) =>
    convertLabel(baseField, conversions, uppercase)
  );
};
