import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { durations } from "../../../config/defaults";
import { ItemApi } from "../../../generated/axios";
import { apiConfig } from "../../api";
import { eIntl } from "../../eIntl";
import apiErrorHandler from "../../lib/apiErrorHandler";
import { SUCCESS } from "../../lib/asyncActionHelper";
import { messages } from "../../lib/locales/definedMessages";
import { resourceDownload } from "../../lib/resourceDownload";
import {
  EXPORT_CSV_MULTIPLE_ITEMS,
  GET_ITEMS,
  SAVE_AND_FINISH,
} from "./consts";
import { Callback } from "../../../types/Callback";
import { message } from "antd";

const itemApi = new ItemApi(apiConfig());

// API "/items/{itemId}/results/{unitId}"
export const saveAndFinish =
  (itemId: number, unitId: string, callback?: Callback) =>
  (dispatch: Dispatch) => {
    itemApi
      .saveItemResults(itemId, unitId)
      .then((res) => {
        message.success(
          eIntl.formatMessage(
            messages["message.configuration_correctly_saved"]
          ),
          durations.success
        );

        dispatch({
          type: SAVE_AND_FINISH,
          payload: res.data,
        });
        callback?.();
      })
      .catch((err) => {
        console.error(err);
        message.error(
          eIntl.formatMessage(
            messages["message.error_in_saving_configuration"]
          ),
          durations.error
        );
      });
  };

export const getItems = (sort?: string) => (dispatch: Dispatch) => {
  message.loading(
    eIntl.formatMessage(messages["message.loading_items"]),
    durations.loading
  );

  itemApi
    .getItems(undefined, undefined, undefined, sort)
    .then((res) => {
      message.success(
        eIntl.formatMessage(messages["message.loading_completed"]),
        durations.success
      );
      dispatch({
        type: SUCCESS(GET_ITEMS),
        payload: res.data,
      });
    })
    .catch(apiErrorHandler({ dispatch, actionType: GET_ITEMS }));
};

/**
 * it uses apiErrorHandler
 *
 * exports and saves locally cvs file for than one item
 */
export const exportCSVMultipleItems =
  (itemIds: number[]) => (dispatch: Dispatch) => {
    message.loading(
      eIntl.formatMessage(messages["message.exporting_csv"]),
      durations.loading
    );

    itemApi
      .exportItemsResults(itemIds)
      .then((res: AxiosResponse) => {
        resourceDownload(res);
        dispatch({
          type: SUCCESS(EXPORT_CSV_MULTIPLE_ITEMS),
        });
        message.success(
          eIntl.formatMessage(messages["message.csv_exported_successfully"]),
          durations.success
        );
        return res.data;
      })
      .catch(
        apiErrorHandler({ dispatch, actionType: EXPORT_CSV_MULTIPLE_ITEMS })
      );
  };
