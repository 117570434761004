import { Col, Layout, Row } from "antd";
import React from "react";
import { Exceptions } from "../../../../shared/components/exceptions";
import "../../../../styles/icon.scss";
import { ConfiguratorSection } from "../../../../types/configurator";
import { CapabilityCheck, capabilityMap } from "../../../capability";
import ConfiguratorSubNav from "../ConfiguratorSubNav";
import LastModified from "../LastModified";
import Sidebar from "../Sidebar";
import Stepper from "../Stepper";
import Switcher from "../Switcher";
import Title from "../Title";
import "./configuratorLayout.scss";

interface ConfiguratorLayoutProps {
  section?: ConfiguratorSection;
}

const ConfiguratorLayout: React.FC<ConfiguratorLayoutProps> = ({
  children,
  section,
}) => (
  <Layout className="configurator-wrapper">
    <Row type="flex">
      <Col span={1} className="sidebar">
        <Sidebar />
      </Col>
      <Col span={1}>{/* vuota */}</Col>

      <Col span={21} className="content">
        <Row gutter={20} className="heading gutter-bug">
          <Col sm={24} md={16}>
            <Title />
            <LastModified />
          </Col>
          {section === ConfiguratorSection.THERMAL ? (
            <CapabilityCheck showIfHasNot={capabilityMap.ITEM_READONLY}>
              <Col sm={8}>
                <Switcher />
              </Col>
            </CapabilityCheck>
          ) : null}
        </Row>

        <Exceptions.AsRow offset={0}>
          <Exceptions.Item hasRedirect={true} />
        </Exceptions.AsRow>

        <Row className="stepper">
          <Stepper />
        </Row>

        <ConfiguratorSubNav section={section} />

        {children}
      </Col>

      <Col span={1} />
    </Row>
  </Layout>
);

export default ConfiguratorLayout;
