import React from "react";
import {
  ADD_NOTE_ITEM,
  CLEAR_ITEM,
  DELETE_ITEM,
  DUPLICATE_ITEM,
  getTooltip,
  NEW_ITEM,
  SAVE_ITEM,
  SHARE_ITEM,
  VIEW_NOTE_ITEM,
} from "../../../../config/tooltips";
import {
  itemIsNotReadOnly,
  itemIsReadOnly,
  itemIsSharable,
  ShowIfCondition,
} from "../../../capability/lib";

const icon = {
  fontSize: "24px",
  width: "24px",
  height: "24px",
};

export interface SidebarItemProps extends ShowIfCondition {
  type: string;
  action: string;
  tooltip?: React.ReactNode;
  fontSize: string;
  width: string;
  height: string;
}

const config: SidebarItemProps[] = [
  {
    ...icon,
    type: "file-add",
    action: "new",
    tooltip: getTooltip(NEW_ITEM), // "Create a new item",
    showIf: itemIsNotReadOnly,
  },
  {
    ...icon,
    type: "save",
    action: "save",
    tooltip: getTooltip(SAVE_ITEM), // "Save item to another project",
    showIf: itemIsNotReadOnly,
  },
  {
    ...icon,
    type: "rollback",
    action: "clear",
    tooltip: getTooltip(CLEAR_ITEM), // "Clear item",
    showIf: itemIsNotReadOnly,
  },
  {
    ...icon,
    type: "copy",
    action: "duplicate",
    tooltip: getTooltip(DUPLICATE_ITEM), // "Duplicate item",
    showIf: itemIsNotReadOnly,
  },
  {
    ...icon,
    type: "share-alt",
    action: "share",
    tooltip: getTooltip(SHARE_ITEM), // "Share item",
    showIf: itemIsSharable,
  },
  {
    ...icon,
    type: "delete",
    action: "delete",
    tooltip: getTooltip(DELETE_ITEM), // "Delete item",
    showIf: itemIsNotReadOnly,
  },
  {
    ...icon,
    type: "file-text",
    action: "note",
    tooltip: getTooltip(ADD_NOTE_ITEM), // "Add a note",
    showIf: itemIsNotReadOnly,
  },
  {
    ...icon,
    type: "file-text",
    action: "note",
    tooltip: getTooltip(VIEW_NOTE_ITEM), // "View note",
    showIf: itemIsReadOnly,
  },
];

export default config;
