import { Table } from "antd";
import React from "react";
import { Project } from "../../generated/axios";
import { PaginationConfig } from "antd/lib/table";

interface IProps {
  columns: object[];
  dataSource: Project[];
  paginationInfo?: false | PaginationConfig;
}

const SummaryTable = (props: IProps) => {
  const { columns, dataSource, paginationInfo } = props;

  return (
    <Table
      rowKey={(record: Project) => (record.id ? record.id.toString() : "")}
      columns={columns}
      dataSource={dataSource}
      pagination={paginationInfo}
    />
  );
};

export default SummaryTable;
