import {
  DEFAULT_CHOSEN_METRIC_SYSTEM,
  DEFAULT_LOCALE,
  DEFAULT_METRIC_SYSTEM,
  INITIAL_RESULT_TABLE_ORDER_DIRECTION,
  INITIAL_RESULT_TABLE_ORDER_FIELD,
} from "../../../config/defaults";
import Action from "../../../types/action";
import { Any } from "../../../types/Any";
import { LOGOUT } from "../../auth/consts";
import { SUCCESS } from "../../lib/asyncActionHelper";
import { GET_LANGUAGES } from "../account/consts";
import * as types from "./consts";
import {
  onGenericSettings,
  onResultTableSettings,
  onTechSpecSettings,
} from "./lib";
import { SettingsState } from "./types";

export const initialState: SettingsState = {
  bounds: {},
  conversions: [],
  metricSystems: [],
  languages: [],
  chosenMetricSystem: DEFAULT_CHOSEN_METRIC_SYSTEM, // TODO to be set once "Settings page will be available"
  defaultMetricSystem: DEFAULT_METRIC_SYSTEM,
  interfaceLanguage: DEFAULT_LOCALE,
  interfaceLanguageForEditing: DEFAULT_LOCALE,
  resultTableParts: undefined,
  updateInfo: {},
  initialResultTableOrderedField: INITIAL_RESULT_TABLE_ORDER_FIELD,
  initialResultTableOrderedDirection: INITIAL_RESULT_TABLE_ORDER_DIRECTION,
  settingsContext: "",
  genericSettingsForEditing: undefined,
  resultsTableSettingsForEditing: undefined,
  techSpecSettingsForEditing: undefined,
};

export default (
  state: SettingsState = initialState,
  action: Action<Any>
): SettingsState => {
  // the reason for settings fetching
  // const { purpose, phase /* , updateScope */ } = action;

  switch (action.type) {
    case SUCCESS(types.GET_CONVERSIONS):
      return { ...state, conversions: action.payload };

    case SUCCESS(types.GET_METRIC_SYSTEMS):
      return { ...state, metricSystems: action.payload };

    case types.SET_CHOSEN_METRIC_SYSTEM:
      return { ...state, chosenMetricSystem: action.payload.key };

    case GET_LANGUAGES:
      return { ...state, languages: action.payload };

    case SUCCESS(types.GET_FIELDS_CONFIG):
      return { ...state, fieldsConfig: action.payload };

    case SUCCESS(types.GET_GENERIC_SETTINGS):
      return onGenericSettings(state, action);

    case SUCCESS(types.GET_RESULT_TABLE_SETTINGS):
      return onResultTableSettings(state, action);

    case SUCCESS(types.GET_TECH_SPEC_SETTINGS):
      return onTechSpecSettings(state, action);

    case types.RESET_RESULT_TABLE_SETTINGS:
      return {
        ...state,
        resultTableParts: undefined,
        resultsTableSettingsForEditing: undefined,
      };

    case types.RESET_TECH_SPEC_SETTINGS:
      return { ...state, techSpecSettingsForEditing: undefined };

    case types.RESET_GENERIC_SETTINGS:
      return { ...state, genericSettingsForEditing: undefined };

    case LOGOUT:
      return initialState;

    case SUCCESS(types.UPDATE_GENERIC_SETTINGS):
      return { ...state, updateInfo: { ...state.updateInfo, generics: true } };

    case SUCCESS(types.UPDATE_RESULT_TABLE_SETTINGS):
      return {
        ...state,
        updateInfo: { ...state.updateInfo, resultTable: true },
      };

    case SUCCESS(types.UPDATE_TECH_SPEC_SETTINGS):
      return { ...state, updateInfo: { ...state.updateInfo, techSpec: true } };

    case types.RESET_UPDATE_INFO:
      return { ...state, updateInfo: {} };

    case types.SETTINGS_CONTEXT:
      return { ...state, settingsContext: action.payload };

    case types.SET_BOUNDS_SETTINGS:
      return { ...state, bounds: action.payload };

    case types.SET_DEFAULT_BOUNDS_SETTINGS: {
      const applicationTypeId = action.payload;
      if (!applicationTypeId) return state;
      const bounds = {
        ...state.bounds,
        forcedDefaults: {
          ...(state.bounds.forcedDefaults ?? {}),
          [applicationTypeId]: true,
        },
      };
      for (const key in bounds) {
        if (key.endsWith("Default")) {
          const property = key.replace(/Default$/, "");
          const defaultValue = bounds[key];
          if (
            !Object.hasOwn(bounds, `${property}Enabled`) ||
            (Array.isArray(bounds[`${property}Enabled`]) &&
              bounds[`${property}Enabled`].includes(applicationTypeId))
          ) {
            bounds[property] = {
              ...(bounds[property] ?? {}),
              [applicationTypeId]: defaultValue,
            };
          }
        }
      }
      return { ...state, bounds };
    }

    case types.UNSET_DEFAULT_BOUNDS_SETTINGS: {
      const applicationTypeId = action.payload;
      if (!applicationTypeId) return state;
      const bounds = {
        ...state.bounds,
        forcedDefaults: {
          ...(state.bounds.forcedDefaults ?? {}),
          [applicationTypeId]: false,
        },
      };
      return { ...state, bounds };
    }

    default:
      return state;
  }
};
