import { Col, Row } from "antd";
import classNames from "classnames";
import React, { FC, memo } from "react";
import { FormattedMessage } from "react-intl";
import { CapabilityCheck, capabilityMap } from "../capability";

interface ColumnHeadersProps {
  showLongVersion?: boolean;
}

export const ColumnHeaders: FC<ColumnHeadersProps> = memo((props) => (
  <>
    <CapabilityCheck
      showIfHas={capabilityMap.SETTINGS_TECH_SPEC_SHOW_EXT_VERSION}
    >
      <Col span={props.showLongVersion ? 4 : 6} className="customize__heading">
        <Row>
          <Col>
            <FormattedMessage
              id="version length"
              defaultMessage="Version length"
            />
          </Col>
        </Row>
      </Col>
    </CapabilityCheck>
    <Col span={props.showLongVersion ? 4 : 6} className="customize__heading">
      <Row>
        <Col>
          <FormattedMessage id="language" defaultMessage="Language" />
        </Col>
      </Row>
    </Col>

    <Col span={props.showLongVersion ? 5 : 6} className="customize__heading">
      <Row>
        <Col>
          <FormattedMessage id="client data" defaultMessage="Client Data" />
        </Col>
      </Row>
    </Col>

    <Col span={props.showLongVersion ? 5 : 6} className="customize__heading">
      <Row>
        <Col>
          <FormattedMessage id="logo" defaultMessage="Logo" />
        </Col>
      </Row>
    </Col>

    <Col span={6} className="customize__heading">
      <Row>
        <Col>
          <FormattedMessage
            id="other information"
            defaultMessage="Other Information"
          />
        </Col>
      </Row>
    </Col>
  </>
));

interface LabelWrapperProps {
  additionalClasses?: string;
}

const LabelWrapper: FC<LabelWrapperProps> = ({
  children,
  additionalClasses = "",
}) => (
  <div className={classNames("customize__label", additionalClasses)}>
    {children}
  </div>
);

export const FirstNameLabel = memo(() => (
  <LabelWrapper>
    <FormattedMessage id="first name" defaultMessage="FIRST NAME" />
  </LabelWrapper>
));

export const SecondNameLabel = memo(() => (
  <LabelWrapper>
    <FormattedMessage id="second name" defaultMessage="SECOND NAME" />
  </LabelWrapper>
));

export const AddressLabel = memo(() => (
  <LabelWrapper>
    <FormattedMessage id="address" defaultMessage="Address" />
  </LabelWrapper>
));

export const LuveLogoLabel = memo(() => (
  <LabelWrapper>
    <FormattedMessage id="luve logo" defaultMessage="LU-VE LOGO" />
  </LabelWrapper>
));

export const OtherLogoLabel = memo(() => (
  <LabelWrapper>
    <FormattedMessage id="otherLogo" defaultMessage="Other logo" />
  </LabelWrapper>
));

export const UploadLabel = memo(() => (
  <LabelWrapper additionalClasses="margin-top--20">
    <FormattedMessage
      id="upload another logo"
      defaultMessage="UPLOAD ANOTHER LOGO"
    />
  </LabelWrapper>
));

export const CertificateLabel = memo(() => (
  <LabelWrapper>
    <FormattedMessage id="certificate logo" defaultMessage="CERTIFICATE LOGO" />
  </LabelWrapper>
));

export const ForcedPdfLabel = memo(() => (
  <LabelWrapper>
    <FormattedMessage id="forcedPdf" defaultMessage="PDF VERSION" />
  </LabelWrapper>
));

export const DrawingLabel = memo(() => (
  <LabelWrapper>
    <FormattedMessage id="drawing" defaultMessage="DRAWING" />
  </LabelWrapper>
));

export const IncludeNotesLabel = memo(() => (
  <LabelWrapper>
    <FormattedMessage id="displayItemNote" defaultMessage="INCLUDE NOTES" />
  </LabelWrapper>
));

export const SpareLabel = memo(() => (
  <LabelWrapper>
    <FormattedMessage id="spare parts" defaultMessage="SPARE PARTS" />
  </LabelWrapper>
));

export const PriceLabel = memo(() => (
  <LabelWrapper>
    <FormattedMessage id="price" defaultMessage="PRICE" />
  </LabelWrapper>
));

export const DiscountLabel = memo(() => (
  <LabelWrapper>
    <FormattedMessage id="apply discount" defaultMessage="APPLY DISCOUNT" />
  </LabelWrapper>
));

export const PercentageLabel = memo(() => (
  <LabelWrapper>
    <FormattedMessage id="percentage" defaultMessage="PERCENTAGE" />
  </LabelWrapper>
));

export const ShowDiscountValuesLabel = memo(() => (
  <LabelWrapper>
    <FormattedMessage
      id="hide discount values"
      defaultMessage="HIDE DISCOUNT VALUES"
    />
  </LabelWrapper>
));

export const ShowSoundPowerLevel = memo(() => (
  <LabelWrapper>
    <FormattedMessage
      id="show sound power level"
      defaultMessage="SHOW SOUND POWER LEVEL"
    />
  </LabelWrapper>
));
