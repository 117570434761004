import { Col, Row } from "antd";
import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";
import { roundWithTwoDecimals } from "../Details";

interface IProps {
  className?: string;
  label?: string | React.ReactElement;
  level?: number;
  price?: number;
  showPrice: boolean;
}

export const OptionRow: React.FC<IProps> = ({
  className,
  label,
  level = 0,
  price,
  showPrice,
}) => {
  const levelClassName = `level-${level}`;
  return (
    <Row className={className}>
      <Col
        offset={level}
        span={(showPrice ? 18 : 24) - level}
        className={classNames(
          "optionPrice",
          "optionPrice--label",
          levelClassName
        )}
      >
        {label}
      </Col>
      {showPrice ? (
        <Col
          span={6}
          className={classNames(
            "optionPrice",
            "optionPrice--price",
            levelClassName
          )}
        >
          {price !== undefined &&
            (price === 0 ? (
              <FormattedMessage
                id="optionprice.included"
                defaultMessage="Included"
              />
            ) : (
              ` € ${roundWithTwoDecimals(price)}`
            ))}
        </Col>
      ) : null}
    </Row>
  );
};
