import { Modal, Table } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ExtendedItem } from "../../shared/store/project/types";
import { Any } from "../../types/Any";
import "./modalPreviewTechnicalSpecification.scss";
import { columnsModal } from "./projectDetailsColumns";

function BodyWithCustomSummary(props: Any) {
  const { data, offerDiscount, ...otherProps } = props;
  const totalItemNetValue = (data as ExtendedItem[])
    .map((item) =>
      Math.round(
        (item.quantity ?? 1) * (item.price ?? 0) * (1 - (item.discount ?? 0))
      )
    )
    .reduce((a: number, b: number) => a + b, 0);
  const discount = (offerDiscount ?? 0) / 100;
  const totalProjectValue = Math.round((1 - discount) * totalItemNetValue);
  return (
    <tbody {...otherProps}>
      {props.children}
      <tr key={"__totalItemNetValue"} className={"ant-table-row custom-footer"}>
        <td className="noBorder" />
        <td className="noBorder" />
        <td>
          <FormattedMessage
            id="modalPreviewTS.totalItemNetValue"
            defaultMessage="TOTAL ITEM NET VALUE"
          />
        </td>
        <td />
        <td />
        <td />
        <td>€ {Math.round(totalItemNetValue)}</td>
        <td className="noBorder" />
      </tr>
      <tr key={"__projectDiscount"} className={"ant-table-row custom-footer"}>
        <td className="noBorder" />
        <td className="noBorder" />
        <td>
          <FormattedMessage
            id="modalPreviewTS.projectDiscount"
            defaultMessage="PROJECT DISCOUNT"
          />
        </td>
        <td />
        <td />
        <td>{(discount * 100).toFixed(2)} %</td>
        <td />
        <td className="noBorder" />
      </tr>
      <tr key={"__totalProjectValue"} className={"ant-table-row custom-footer"}>
        <td className="noBorder" />
        <td className="noBorder" />
        <td className="noBorder">
          <FormattedMessage
            id="modalPreviewTS.totalProjectValue"
            defaultMessage="TOTAL PROJECT VALUE"
          />
        </td>
        <td className="noBorder" />
        <td className="noBorder" />
        <td className="noBorder" />
        <td className="noBorder">€ {Math.round(totalProjectValue)}</td>
        <td className="noBorder" />
      </tr>
    </tbody>
  );
}

interface IProps {
  items?: ExtendedItem[];
  offerDiscount?: number;
  onCancel: () => void;
  onOk: () => void;
  visible: boolean;
}

export const ModalPreviewTechnicalSpecification: React.FC<IProps> = ({
  items = [],
  offerDiscount,
  onCancel,
  onOk,
  visible,
}) => {
  return (
    <Modal
      centered
      className={"modal-preview-ts"}
      keyboard={false}
      onCancel={onCancel}
      onOk={onOk}
      okText={
        <FormattedMessage
          id="modalPreviewTS.downloadTechnicalSpecification"
          defaultMessage="DOWNLOAD"
        />
      }
      title={
        <FormattedMessage
          id="modalPreviewTS.customizeTechnicalSpecification"
          defaultMessage="Customize technical specification"
        />
      }
      visible={visible}
    >
      {visible ? (
        <Table
          rowKey="id"
          columns={columnsModal}
          dataSource={items}
          pagination={false}
          components={{
            body: {
              wrapper: (props) => (
                <BodyWithCustomSummary
                  data={items}
                  offerDiscount={offerDiscount}
                  {...props}
                />
              ),
            },
          }}
        />
      ) : null}
    </Modal>
  );
};
