import { Button } from "antd";
import React, { useContext } from "react";
import { ScrollContext, ScrollDirection } from "./ScrollableListWrapper";

export interface IProps {
  direction: ScrollDirection;
}

const Arrow: React.FC<IProps> = ({ direction }) => {
  const { arrowEnabled, scroll } = useContext(ScrollContext);
  return (
    <Button
      onClick={scroll?.(direction)}
      icon={direction}
      disabled={!arrowEnabled?.[direction]}
      className="scrollbar__arrow"
    />
  );
};

export default Arrow;
