import { eIntl } from "../shared/eIntl";
import { messages } from "../shared/lib/locales/definedMessages";
import { NEW_ITEM } from "../shared/store/item/consts";
import { GET_RESULTS } from "../shared/store/item/constsResults";
import { GET_ITEMS } from "../shared/store/items/consts";

export interface TypedIterableIterator
  extends IterableIterator<IteratorValue> {}

export interface IteratorValue {
  type: string;
  text: string;
  elapse: number;
}

// generators
function* base(): TypedIterableIterator {
  yield {
    type: "loading",
    text: eIntl.formatMessage(messages["message.loading_data"]),
    elapse: 1000,
  };
}

export function* getItems(): TypedIterableIterator {
  yield {
    type: "loading",
    text: eIntl.formatMessage(messages["message.loading_items"]),
    elapse: 2,
  };
  yield {
    type: "loading",
    text: eIntl.formatMessage(messages["message.please_wait"]),
    elapse: 2,
  };
  yield {
    type: "loading",
    text: eIntl.formatMessage(messages["message.please_wait_few_seconds"]),
    elapse: 1000,
  };
}

function* getResults(): TypedIterableIterator {
  yield {
    type: "loading",
    text: eIntl.formatMessage(messages["message.loading_results"]),
    elapse: 3,
  };
  yield {
    type: "loading",
    text: eIntl.formatMessage(
      messages["message.still_loading_results_please_wait"]
    ),
    elapse: 2,
  };
  yield {
    type: "loading",
    text: eIntl.formatMessage(messages["message.operation_still_in_progress"]),
    elapse: 1000,
  };
}

function* getNewItem(): TypedIterableIterator {
  yield {
    type: "loading",
    text: eIntl.formatMessage(messages["message.loading_new_item_data"]),
    elapse: 3,
  };
  yield {
    type: "loading",
    text: eIntl.formatMessage(messages["message.please_wait_still_loading"]),
    elapse: 1000,
  };
}

// action-types to iterators map
export const map = {
  [GET_ITEMS]: getItems,
  [GET_RESULTS]: getResults,
  [NEW_ITEM]: getNewItem,
};

export const createIterator = (
  actionType: string = ""
): TypedIterableIterator => {
  return map[actionType] ? map[actionType]() : base();
};
