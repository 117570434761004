import { Form, Icon, Input } from "antd";
import { FormItemProps } from "antd/lib/form/FormItem";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useAppDispatch } from "../../../../shared/store/hooks";
import { useSelector } from "react-redux";
import { updateItemLabel } from "../../../../shared/store/item/actions";
import { itemSelector } from "../../../../shared/store/item/selectors";

interface IProps {}

const Title: React.FC<IProps> = () => {
  const item = useSelector(itemSelector);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [validLabel, setValidLabel] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  const enableEditLabel = useCallback(() => {
    setEditMode(true);
  }, [setEditMode]);

  const saveNewLabel = useCallback(
    (event) => {
      const newLabel = event.target.value.trim();
      if (newLabel === "") {
        event.preventDefault();
        setValidLabel(false);
        event.target.focus();
      } else {
        if (item.label !== newLabel) {
          dispatch(updateItemLabel(newLabel));
        }
        setEditMode(false);
        setValidLabel(true);
      }
    },
    [item.label, dispatch, setEditMode, setValidLabel]
  );

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === "Tab") {
        // TAB is pressed
        saveNewLabel(event);
      } else if (event.key === "Escape") {
        // ESC is pressed: exit edit mode without saving
        setEditMode(false);
      }
    },
    [saveNewLabel, setEditMode]
  );

  const onKeyUp = useCallback(
    (event) => {
      const newLabel = event.target.value.trim();
      setValidLabel(newLabel !== "");
    },
    [setValidLabel]
  );

  const formItemProps: Partial<FormItemProps> = validLabel
    ? {}
    : {
        validateStatus: "error",
        help: (
          <FormattedMessage
            id="message.invalid_item_name"
            defaultMessage="Invalid item name"
          />
        ),
      };

  return (
    <h1 className="title">
      {editMode ? (
        <Form.Item {...formItemProps}>
          <Input
            defaultValue={item.label}
            onBlur={saveNewLabel}
            onPressEnter={saveNewLabel}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            maxLength={100}
            className="title__input-item"
            autoFocus={true}
          />
        </Form.Item>
      ) : (
        <>
          {item.label}
          {item.permission === "WRITE" ? (
            <>
              &nbsp;
              <Icon
                type="edit"
                className="edit-icon"
                onClick={enableEditLabel}
              />
            </>
          ) : null}
        </>
      )}
    </h1>
  );
};

export default Title;
