import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Problem } from "../../../generated/axios";
import { ApplicationTypeState } from "../../store/applicationType/types";
import "./styles.scss";

interface Props {
  error: Problem;
}

const Message = React.memo(() => (
  <FormattedMessage
    id="exception application types"
    defaultMessage="Error on Application Types"
  />
));

const ApplicationTypesException = connect(
  (state: { applicationType: ApplicationTypeState }) => ({
    error: state.applicationType.error,
  })
)((props: Props) => {
  const error = props.error;
  return error?.status && error.message ? (
    <div className="exception">
      <Message />
      {`: [${error.status}] ${error.message}`}
    </div>
  ) : null;
});

export default ApplicationTypesException;
