import { Divider, Input } from "antd";
import React, { ChangeEvent, SyntheticEvent } from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { messages } from "../../../shared/lib/locales/definedMessages";
import { canEditItemSelector } from "../../../shared/store/item/selectors";
import { AddNoteData } from "../../../shared/store/project/actions";
import { canEditProjectSelector } from "../../../shared/store/project/selectors";
import { IStore } from "../../../shared/store/types";
import { Callback } from "../../../types/Callback";
import { getFooter } from "./BaseModal";
import "./style.scss";

interface Props {
  closeModal: Callback;
  confirmHandler: (data: AddNoteData) => void;
  actionName: string;
  intl: InjectedIntl;
  note: string;
  projectNote: string;
  canEditItem: boolean;
  canEditProject: boolean;
}

interface State {
  text: string;
}

class AddNote extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      text: props.actionName === "note" ? props.note : props.projectNote,
    };
  }

  onSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    this.props.confirmHandler(this.state);
  };

  onReset = (event: SyntheticEvent) => {
    event.preventDefault();
    this.props.closeModal();
  };

  onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.persist();
    this.setState(() => ({ text: e.target.value }));
  };

  render() {
    const { actionName, canEditItem, canEditProject, intl } = this.props;
    const canEdit = actionName === "note" ? canEditItem : canEditProject;
    return (
      <form
        className="modal-form"
        onSubmit={this.onSubmit}
        onReset={this.onReset}
      >
        <div>
          {canEdit ? (
            <Input.TextArea
              value={this.state.text}
              onChange={this.onChange}
              placeholder={intl.formatMessage(messages.placeholder)}
            />
          ) : (
            <p>{this.state.text}</p>
          )}
        </div>
        {canEdit && (
          <>
            <Divider />
            {getFooter()}
          </>
        )}
      </form>
    );
  }
}

const connected = connect((state: IStore) => ({
  note: state.item.note,
  projectNote: state.project.note,
  canEditItem: canEditItemSelector(state),
  canEditProject: canEditProjectSelector(state),
}))(AddNote);

export default injectIntl(connected);
