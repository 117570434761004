import { Menu } from "antd";
import { ClickParam } from "antd/lib/menu";
import React, { FunctionComponent } from "react";
import { Portfolio } from "../../generated/axios";
import LinkContent from "./LinkContent";

interface PortfolioMenuProps {
  clickHandler: (param: ClickParam) => void;
  availablePortfolios: Portfolio[];
}

const PortfolioMenu: FunctionComponent<PortfolioMenuProps> = React.memo(
  (props) => (
    <Menu onClick={props.clickHandler} className="asDropdown-menu">
      {props.availablePortfolios.map((item) => (
        <Menu.Item key={item.id}>
          {item.url ? (
            <a href={`${item.url}`} target="_blank" rel="noopener noreferrer">
              <LinkContent name={item.name} />
            </a>
          ) : (
            <LinkContent name={item.name} />
          )}
        </Menu.Item>
      ))}
    </Menu>
  )
);

export default PortfolioMenu;
