import { Button, Col, Form, Input, Row } from "antd";
import { FormComponentProps } from "antd/lib/form";
import React, { memo } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { EmailReset } from "../../generated/axios";
import { messages } from "../../shared/lib/locales/definedMessages";

const EmailWarning = memo(() => (
  <Col span={24}>
    <div className="setting__email-warning">
      <FormattedMessage
        id="change email warning"
        defaultMessage="Changing email address will change your username. You will receive an email with a link to confirm your action. After that you must use new email address to authenticate to Plair"
      />
    </div>
  </Col>
));

const ChangeEmail = memo(() => (
  <Col className="personal-settings__section-title margin-bottom--20" span={24}>
    <FormattedMessage id="change e-mail" defaultMessage="change e-mail" />
  </Col>
));

interface OwnProps {
  openChangeEMailModal: (changeEmailRequest: EmailReset) => void;
}

interface Props extends OwnProps, FormComponentProps, InjectedIntlProps {}

class ChangeEmailForm extends React.Component<Props> {
  openChangeEMailModal = () => {
    // check form inputs
    this.props.form.validateFields(["current_password", "new_email"], (err) => {
      if (err) {
        return;
      }

      const requestValues: EmailReset = {
        newEmail: this.props.form.getFieldValue("new_email"),
        password: this.props.form.getFieldValue("current_password"),
      };
      // call parent method
      this.props.openChangeEMailModal(requestValues);
    });
  };

  render() {
    const { intl } = this.props;
    const { getFieldDecorator } = this.props.form;

    if (this.props.openChangeEMailModal == undefined) return null;

    return (
      <Form className="configurator-form">
        <Row>
          <Col span={22} offset={1} className="personal-settings__container">
            <Row>
              <ChangeEmail />
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label={
                    <FormattedMessage
                      id="new e-mail"
                      defaultMessage="new e-mail"
                    />
                  }
                  className="personal-settings__label"
                >
                  {getFieldDecorator("new_email", {
                    rules: [
                      {
                        type: "email",
                        message: intl.formatMessage(messages.inputInvalidEmail),
                      },
                      {
                        required: true,
                        message: intl.formatMessage(messages.emailRequired),
                      },
                    ],
                  })(
                    <Input
                      type="email"
                      placeholder={intl.formatMessage(
                        messages.emailPlaceholder
                      )}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <FormattedMessage id="password" defaultMessage="password" />
                  }
                  className="personal-settings__label"
                >
                  {getFieldDecorator("current_password", {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage(messages.passwordRequired),
                      },
                    ],
                  })(
                    <Input
                      type="password"
                      placeholder={intl.formatMessage(
                        messages.passwordPlaceholder
                      )}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <EmailWarning />
            </Row>
          </Col>
        </Row>
        <Row>
          <Col
            span={22}
            offset={1}
            className="margin-top--20 margin-bottom--40"
          >
            <Row type="flex" justify="end">
              <Col>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="text-transform--uppercase"
                  onClick={this.openChangeEMailModal}
                >
                  <FormattedMessage
                    id="change your e-mail"
                    defaultMessage="change your e-mail"
                  />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WithIntl = injectIntl<Props>(ChangeEmailForm);

export default Form.create<OwnProps & FormComponentProps>()(WithIntl);
