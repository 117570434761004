import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import logoLuve from "../../assets/ventolina-gray.svg";
import { LUVEGROUP_URL } from "../../config/defaults";
import {
  isInConfiguratorSection,
  isNewItemPage,
} from "../../shared/components/RouteChangeHandler/lib";
import {
  portfolioBySelectedIdSelector,
  portfolioSelector,
} from "../../shared/store/portfolio/selectors";

const LogoLuveInHeader: FunctionComponent<RouteComponentProps> = (props) => {
  const portfolio = useSelector(portfolioSelector);
  const selectedPortfolio = useSelector(portfolioBySelectedIdSelector);

  const url = portfolio === undefined ? LUVEGROUP_URL : portfolio.url;
  let logo = logoLuve;
  if (portfolio && isInConfiguratorSection(props.location.pathname))
    logo = portfolio.logoUrl;
  if (selectedPortfolio && isNewItemPage(props.location.pathname))
    logo = selectedPortfolio.logoUrl;

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="logo-luve-header"
      data-test={logo}
    >
      <img src={logo} alt="" />
    </a>
  );
};

const LogoLuveHeader = withRouter(LogoLuveInHeader);
export { LogoLuveHeader };
