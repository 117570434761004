import { Button, Col, Form, Input, Row } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import { get } from "lodash";
import React, { ChangeEvent } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { HOME } from "../../config/paths";
import { KeyAndPassword, Problem } from "../../generated/axios";
import { messages } from "../../shared/lib/locales/definedMessages";
import { finishPasswordReset } from "../../shared/store/account/actions";
import { AuthenticationResponse } from "../../shared/store/account/types";
import { IStore } from "../../shared/store/types";
import Layout from "./Layout";
import "./layout.scss";

interface RegistrationConfirmProps
  extends FormComponentProps,
    InjectedIntlProps {
  finishPasswordReset: (keyAndPassword: KeyAndPassword) => void;
  finishPasswordResetResponse?: AuthenticationResponse & Problem;
}

interface IState {
  psw: string;
  confirmationPsw: string;
  key: string; // "key" param in url querystring
  pswEmptyError: boolean;
  confirmationPswEmptyError: boolean;
  pswInconsistent: boolean;
}

const SideContent = React.memo(() => (
  <>
    <span className="publicPagesLayout__action">
      <FormattedMessage id="registration" defaultMessage="Registration" />
    </span>
    <br />
    <FormattedMessage id="step 2" defaultMessage="STEP 2" />
  </>
));

const PwdError = React.memo(() => (
  <span className="publicPagesLayout__error" style={{ color: "red" }}>
    <FormattedMessage
      id="password too weak"
      defaultMessage="Password too weak, it must be greater than 4 characters"
    />
  </span>
));

const PwdInconsistencyError = React.memo(() => (
  <span
    className="publicPagesLayout__error"
    data-test="registration-confirm-error"
  >
    <FormattedMessage
      id="password inconsistent"
      defaultMessage="The two passwords are inconsistent"
    />
  </span>
));

const PwdEmptyError = React.memo(() => (
  <span className="publicPagesLayout__error">
    <FormattedMessage
      id="password empty"
      defaultMessage="Please insert your password"
    />
  </span>
));

const PwdConfirmationEmptyError = React.memo(() => (
  <span className="publicPagesLayout__error">
    <FormattedMessage
      id="password empty"
      defaultMessage="Please insert your password"
    />
  </span>
));

const RequestSuccessMessage = React.memo(() => (
  <Row>
    <Col span={24} className="publicPagesLayout__success">
      <FormattedMessage
        id="registration ok"
        defaultMessage="Registration process completed successfully"
      />
    </Col>
  </Row>
));

const RequestFailureMessage = React.memo(() => (
  <Row>
    <Col span={24} className="publicPagesLayout__failure">
      <FormattedMessage
        id="registration error"
        defaultMessage="Error with registration, please retry later"
      />
    </Col>
  </Row>
));

class RegistrationConfirm extends React.Component<
  RegistrationConfirmProps,
  IState
> {
  constructor(props: RegistrationConfirmProps) {
    super(props);
    this.state = {
      psw: "",
      confirmationPsw: "",
      key: window.location.search?.substring(5) ?? "",
      pswEmptyError: false,
      confirmationPswEmptyError: false,
      pswInconsistent: false,
    };
  }

  handleSubmit = () => {
    const { pswEmptyError, confirmationPswEmptyError } = this.state;
    // if already input but then deleted
    if (pswEmptyError || confirmationPswEmptyError) {
      return;
    }
    // if entered for the first time and press directly to the button leaving inputs empty (avoiding the red empty message error as the user lands on the page)
    const { form } = this.props;
    const pswValue = form.getFieldValue("psw");
    const pswConfirmValue = form.getFieldValue("psw_confirm");
    if (!pswValue || !pswConfirmValue) {
      if (!pswValue) {
        this.setState(() => ({ pswEmptyError: true }));
      }
      if (!pswConfirmValue) {
        this.setState(() => ({ confirmationPswEmptyError: true }));
      }
      return;
    }

    // custom rule: check if passwords are equal between each other
    const { psw, confirmationPsw, key } = this.state;
    const isInconsistent = psw !== confirmationPsw;
    this.setState(() => ({
      pswInconsistent: isInconsistent,
    }));
    if (isInconsistent) return;

    // prepare POST call object input
    const keyAndPassword: KeyAndPassword = {
      key,
      newPassword: psw,
    };
    // call API /account/reset-password/finish
    this.props.finishPasswordReset(keyAndPassword);
  };

  handleChangePassword = (psw: ChangeEvent) => {
    const value = get(psw, "target.value", "");
    this.setState(() => ({
      psw: value,
      pswEmptyError: !value,
    }));
  };

  handleChangeConfirmationPassword = (confirmPsw: ChangeEvent) => {
    const value = get(confirmPsw, "target.value", "");
    this.setState(() => ({
      confirmationPsw: value,
      confirmationPswEmptyError: !value,
    }));
  };

  handleRetry = () => {
    location.reload();
  };

  render() {
    const { intl, form, finishPasswordResetResponse: response } = this.props;
    const { pswEmptyError, confirmationPswEmptyError, pswInconsistent } =
      this.state;
    const { getFieldDecorator } = form; // this.props.form is been injected by Form.create()
    // specific server validation error response which has to be managed with a red message under the first input (as per empty inputs) --> the "!this.state.pswInconsistent" is not to show double error messages (more priority to inconsistent psw)
    const requestFailurePswWeak = !!(
      !pswInconsistent &&
      response &&
      response.status &&
      response.status === 400 &&
      response.type &&
      response.type.endsWith("/problem/invalid-password")
    );
    // generic server error response / P.S. the "!this.state.pswInconsistent" is not to show double error messages (more priority to inconsistent psw)
    const requestFailureGenericError = !!(
      !requestFailurePswWeak &&
      !pswInconsistent &&
      response &&
      response.status &&
      response.status !== 200
    );
    // in case the request successes, the response has no status and anything a part a plain object with id_token property
    const requestSuccess = !!response?.id_token;

    return (
      <Layout>
        <Layout.LeftSide>
          <SideContent />
        </Layout.LeftSide>
        <Layout.RightSide>
          <Form>
            {/* Cases involved: 
              - just entered the page
              - one or more field are empty and the user presses the button
              - password are not equal between each other
              - server side validation error (psw weak)
              The layout is the same for every case a part when an error is printed under the input(s)
            */}
            {(response === undefined ||
              requestFailurePswWeak ||
              pswEmptyError ||
              confirmationPswEmptyError ||
              pswInconsistent) && (
              <>
                <Row>
                  <Form.Item
                    label={
                      <FormattedMessage
                        id="password"
                        defaultMessage="Password"
                      />
                    }
                    colon={false}
                    className="publicPagesLayout__label"
                  >
                    {getFieldDecorator(
                      "psw",
                      {}
                    )(
                      <Input
                        size="large"
                        type="password"
                        className="publicPagesLayout__input publicPagesLayout__input--mail"
                        placeholder={intl.formatMessage(
                          messages.inputPasswordPlaceholder
                        )}
                        data-test="registration-confirm-psw"
                        onChange={this.handleChangePassword}
                      />
                    )}

                    {pswEmptyError && <PwdEmptyError />}

                    {/* condition "!this.state.pswEmptyError" is to avoid the double message error */}
                    {!pswEmptyError &&
                      !confirmationPswEmptyError &&
                      pswInconsistent && <PwdInconsistencyError />}

                    {/* condition "!this.state.pswEmptyError" is to avoid the double message error */}
                    {!pswEmptyError && requestFailurePswWeak && <PwdError />}
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item
                    label={
                      <FormattedMessage
                        id="confirm_password"
                        defaultMessage="Confirm Password"
                      />
                    }
                    colon={false}
                    className="publicPagesLayout__label"
                  >
                    {getFieldDecorator(
                      "psw_confirm",
                      {}
                    )(
                      <Input
                        size="large"
                        type="password"
                        className="publicPagesLayout__input publicPagesLayout__input--mail"
                        placeholder={intl.formatMessage(
                          messages.inputPasswordAgainPlaceholder
                        )}
                        data-test="registration-confirm-psw-2"
                        onChange={this.handleChangeConfirmationPassword}
                      />
                    )}

                    {confirmationPswEmptyError && <PwdConfirmationEmptyError />}
                  </Form.Item>
                </Row>

                {/* Attention: button has to remain direct child of "publicPagesLayout__right" due to positioning */}
                <Button
                  type="primary"
                  htmlType="submit"
                  className="publicPagesLayout__button"
                  data-test="registration-confirm-button"
                  onClick={this.handleSubmit}
                >
                  <FormattedMessage id="go" defaultMessage="GO" />
                </Button>
              </>
            )}

            {/* Case server responds with generic error */}
            {requestFailureGenericError && (
              <>
                <RequestFailureMessage />

                {/* Attention: button has to remain direct child of "registration-email__right" due to positioning */}
                <Button
                  type="primary"
                  htmlType="submit"
                  className="registration-email__button"
                  onClick={this.handleRetry}
                >
                  <FormattedMessage id="retry" defaultMessage="Retry" />
                </Button>
              </>
            )}

            {/* Case registration success & redirect to Home after the user presses on Login */}
            {requestSuccess && (
              <>
                <RequestSuccessMessage />

                {/* Attention: button has to remain direct child of "publicPagesLayout__right" due to positioning */}
                <Link to={HOME}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="publicPagesLayout__button"
                  >
                    <FormattedMessage id="login" defaultMessage="LOGIN" />
                  </Button>
                </Link>
              </>
            )}
          </Form>
        </Layout.RightSide>
      </Layout>
    );
  }
}

const RegistrationComponentWithForm =
  Form.create<RegistrationConfirmProps>()(RegistrationConfirm);

const mapStateToProps = (state: IStore) => ({
  finishPasswordResetResponse: state.account.finishPasswordResetResponse,
});

const WithIntl = injectIntl(RegistrationComponentWithForm);

export default connect(mapStateToProps, { finishPasswordReset })(WithIntl);
