import { Col, Row } from "antd";
import React from "react";
import AccountException from "./AccountException";

import ApplicationTypesException from "./ApplicationTypesException";
import ItemException from "./ItemException";
import ItemsException from "./ItemsException";
import ProjectException from "./ProjectException";
import ProjectsException from "./ProjectsException";

interface WrapperType {
  children: React.ReactNode;
  offset?: number | string;
  span?: number | string;
}

const Exceptions = () => <div>Exceptions placeholder</div>;

/**
 * wraps the error in a Row.Col
 */
const AsRow = ({ span = 23, offset = 1, children }: WrapperType) => {
  return (
    <Row>
      <Col span={span} offset={offset}>
        {children}
      </Col>
    </Row>
  );
};

/**
 * no wrapper
 */
const Base = (props: WrapperType) => (
  <React.Fragment>{props.children}</React.Fragment>
);

// wrappers
Exceptions.AsRow = AsRow;
Exceptions.Base = Base;
// containers
Exceptions.ApplicationTypes = ApplicationTypesException;
Exceptions.Account = AccountException;
Exceptions.Item = ItemException;
Exceptions.Items = ItemsException;
Exceptions.Project = ProjectException;
Exceptions.Projects = ProjectsException;

export default Exceptions;
