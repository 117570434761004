import { PaginationConfig } from "antd/lib/table";

import { paginationConfig } from "../../config/defaults";
import localStorage from "./localStorage";

export const onShowSizeChangeHandler = (current: number, size: number) =>
  localStorage.write("PAGINATION", { size });

const getPageSize = () =>
  localStorage.read("PAGINATION")?.size ?? paginationConfig.defaultPageSize;

/**
 * paginationConfig + override = pagination for a table
 */
export default (override?: PaginationConfig): PaginationConfig => ({
  ...paginationConfig,
  defaultPageSize: getPageSize(),
  onShowSizeChange: onShowSizeChangeHandler,
  ...override,
});
