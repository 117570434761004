import { Popover } from "antd";
import React, { FC, useLayoutEffect, useRef, useState } from "react";
import MultilineText from "../MultilineText/MultilineText";

import "./TwoLinesPopover.scss";

interface Props {
  text: string;
}

const TwoLinesPopover: FC<Props> = ({ text }) => {
  const [visible, setVisible] = useState<{ visible?: boolean }>({});
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (ref.current) {
      const e = ref.current;
      if (e.offsetHeight >= e.scrollHeight) {
        setVisible({ visible: false });
      }
    }
  }, [ref, setVisible]);
  const Ellipsis =
    visible.visible === undefined ? (
      <span className={"two-lines--ellipsis"}>&#x2026;</span>
    ) : null;
  return (
    <Popover
      content={<MultilineText text={text} />}
      placement={"bottomLeft"}
      title="Note"
      {...visible}
    >
      <div ref={ref} className={`two-lines`}>
        {text}
      </div>
      {Ellipsis}
    </Popover>
  );
};

export default TwoLinesPopover;
