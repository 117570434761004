import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { get } from "lodash";
import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { InputProps } from ".";
import {
  areDifferent,
  areDifferentObj,
} from "../../../../shared/lib/areDifferentObj";
import { sortOptionsByProperty } from "../../../../shared/lib/sortArrayByField";
import { hasReadPermission } from "../../../../shared/store/item/selectors";
import { IStore } from "../../../../shared/store/types";
import { FormEvent } from "../../../../types/configurator";
import EnhancedOption from "./EnhancedOption";
import ReadonlyField from "./ReadonlyField";

interface IState {
  value?: string;
  convertedValue?: string;
  valueType: string;
  errorCounter: string;
}

interface ReduxProps {
  isReadonly: boolean;
}

interface IProps extends ReduxProps, InputProps {}

const RadioGroup = Radio.Group;

class RadioInput extends React.Component<IProps, IState> {
  state = {
    value: undefined,
    convertedValue: undefined,
    valueType: "",
    errorCounter: "0",
  };

  static getDerivedStateFromProps(props: IProps, state: IState) {
    const { value, options = [] } = props;
    const valueType: string = typeof value;
    if (
      state.value !== value ||
      state.valueType !== valueType ||
      !state.convertedValue
    ) {
      const convertedValue = options
        .filter((opt) => value === opt.value)
        .map((item) => item.label)[0];
      return { value, valueType, convertedValue };
    }
    return null;
  }

  shouldComponentUpdate(newProps: IProps, newState: IState) {
    return (
      newState.convertedValue !== this.state.convertedValue ||
      areDifferentObj(newProps, this.props, [
        "fieldId",
        "isReadonly",
        "value",
        "optionActions",
      ]) ||
      areDifferent(newProps.options, this.props.options)
    );
  }

  onChangeError = () => {
    this.setState(
      () => ({
        errorCounter: `${Date.now()}`,
      }),
      () => this.forceUpdate()
    );
  };

  onChange = (e: RadioChangeEvent): void => {
    const value = e.target.value;
    if (this.props.onChange) {
      const formEvent: FormEvent = {
        field: this.props.fieldId ? this.props.fieldId : "",
        value: value,
        onError: this.onChangeError,
      };
      this.setState({ value });
      this.props.onChange(formEvent);
    }
  };

  render = (): ReactNode => {
    const { fieldId, isReadonly, value } = this.props;
    const { convertedValue, errorCounter } = this.state;

    if (isReadonly) return <ReadonlyField text={convertedValue} />;

    const sortedOptions = sortOptionsByProperty(
      this.props.options ?? [],
      "label"
    );
    const id = "configuration-form--" + fieldId;

    return (
      <RadioGroup
        id={id}
        key={`${fieldId}-${value}_${errorCounter}`}
        defaultValue={value}
        onChange={this.onChange}
      >
        {sortedOptions.map((option, index) => {
          const actions = get(this.props.optionActions, `${option.value}`, []);

          return (
            <EnhancedOption
              key={option.label ?? index}
              option={option}
              actions={actions}
            >
              <Radio
                key={index}
                className="radio"
                value={option.value}
                disabled={option.disabled}
                data-test={id + "-" + option.label}
              >
                {option.label}
              </Radio>
            </EnhancedOption>
          );
        })}
      </RadioGroup>
    );
  };
}

export default connect((state: IStore) => {
  const isReadonly = hasReadPermission(state);
  return {
    isReadonly,
  };
})(RadioInput);
