import { createContext } from "react";

interface ICapabilitiesContext {
  capabilities: string[];
}

const CapabilitiesContext = createContext<ICapabilitiesContext>({
  capabilities: [],
});

export const CapabilitiesProvider = CapabilitiesContext.Provider;
export const CapabilitiesConsumer = CapabilitiesContext.Consumer;
export default CapabilitiesContext;
