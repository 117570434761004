import isArray from "lodash/isArray";
import React, { Fragment } from "react";

/**
 * used in SidePanel
 * high order functions,
 * given an object of objects{name: value} writes value in place of name
 * @param map
 */
export const convertValue =
  (map: object) =>
  (value: string | string[]): string | string[] => {
    if (isArray(value)) {
      return value.map((v) => map[v] || v);
    }
    return map[value] || value;
  };

/**
 * writes SidePanelItem values
 * @param value
 */
export const writeValue = (value: string[] | string | number) => {
  if (isArray(value)) {
    return (
      <Fragment>
        {value.map((v) => {
          const keyValue = v === "" || value == undefined ? "-" : v.toString();
          return <div key={keyValue}>{keyValue}</div>;
        })}
      </Fragment>
    );
  }
  return value === "" || value == undefined ? "-" : value.toString();
};
