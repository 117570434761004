import _, { debounce } from "lodash";
import React from "react";

const HEIGHT = "scrollHeight"; // use scrollHeight or offsetHeight

interface Element {
  height: number;
  ref: React.RefObject<HTMLDivElement>;
}

type Group = string;

type ElementGroups = Record<Group, Element[]>;

interface State {
  elements: ElementGroups;
}

export interface IContext {
  addElement?: (group: string, ref: React.RefObject<HTMLDivElement>) => void;
}

export const Context: React.Context<IContext> = React.createContext({});

interface IProps {}

export default class EqualHeightsWrapper extends React.Component<
  IProps,
  State
> {
  static contextType = Context;

  state: State = {
    elements: {},
  };

  constructor(props: IProps, context: IContext) {
    super(props, context);
    this.update = debounce(this.update, 500);
  }

  componentDidMount() {
    window.addEventListener("resize", this.update.bind(this));
    this.update();
  }

  componentDidUpdate() {
    this.update();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.update);
  }

  addElement = (group: string, ref: React.RefObject<HTMLDivElement>) => {
    const height: number = this.getRealHeight(ref);
    const element: Element = { height, ref };
    this.setState((state) => ({
      elements: {
        ...state.elements,
        [group]: [...(state.elements[group] || []), element],
      },
    }));
  };

  update = () => {
    setTimeout(this.updateHeights.bind(this), 0);
  };

  private updateHeights = () => {
    const groups = Object.keys(this.state.elements);
    groups.forEach((group) => this.updateGroupHeight(group));
  };

  private updateGroupHeight = (group: string) => {
    const elements = this.state.elements[group];
    const height = this.calculateMaxHeight(elements);
    this.setHeights(elements, height);
  };

  private setHeights = (elements: Element[], height: number) => {
    elements.forEach((element) => this.setHeight(element, height));
  };

  private setHeight = (element: Element, pixels: number | null) => {
    if (
      _.isNil(element) ||
      _.isNil(element.ref) ||
      _.isNil(element.ref.current)
    )
      return;

    if (element.height !== pixels) {
      const height = _.isNil(pixels) ? "auto" : `${pixels}px`;
      try {
        const fixHeight = () => {
          if (element.ref.current?.style)
            element.ref.current.style.height = height;
        };
        setTimeout(fixHeight, 0);
      } catch (error) {
        //
      }
    }
  };

  private calculateMaxHeight = (elements: Element[]): number => {
    return elements.reduce((height: number, element: Element): number => {
      if (!element.ref.current) {
        return height;
      }
      // reset first
      this.setHeight(element, null);
      const elementHeight: number = this.getRealHeight(element.ref);
      return elementHeight > height ? elementHeight : height;
    }, 0);
  };

  private getRealHeight = (ref: React.RefObject<HTMLDivElement>): number =>
    ref.current ? ref.current[HEIGHT] : 0;

  render() {
    const context: IContext = {
      addElement: this.addElement.bind(this),
    };

    return (
      <Context.Provider value={context}>{this.props.children}</Context.Provider>
    );
  }
}
