import { omit } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { ConversionFormula } from "../../../../generated/axios";
import { IStore } from "../../../../shared/store/types";
import {
  getConversions,
  IConvert,
} from "../../../../shared/systemOfMeasurement/conversionFunctions";
import { convertCalculationValues } from "../../../../shared/systemOfMeasurement/convertCalculationValues";
import { convertLabels } from "../../../../shared/systemOfMeasurement/convertLabel";
import { OwnProps, Props } from "./TableResults";

interface ConvertSystemOfMeasurement {
  conversions: ConversionFormula[];
  chosenMetricSystem: string;
  defaultMetricSystem: string;
}

interface ExtendedProps extends Props, ConvertSystemOfMeasurement {}

/**
 * Exported HOC
 */
const Hoc =
  (Component: React.ComponentType<OwnProps>): React.ComponentType<OwnProps> =>
  (props: ExtendedProps) => {
    const _props: Props = omit(
      props,
      "conversions",
      "chosenMetricSystem",
      "defaultMetricSystem"
    );

    const conversions: Record<string, IConvert> = getConversions(
      props.resultFieldConfig,
      props.conversions,
      props.chosenMetricSystem,
      props.defaultMetricSystem
    );

    if (!Object.keys(conversions).length) {
      return <Component {..._props} />;
    }

    const resultFieldConfig = convertLabels(
      props.resultFieldConfig,
      conversions
    );

    const itemResults = props.results.map((itemResult) =>
      convertCalculationValues(itemResult, conversions)
    );

    return (
      <Component {..._props} {...{ resultFieldConfig, results: itemResults }} />
    );
  };

const mapStateToProps = (state: IStore): ConvertSystemOfMeasurement => {
  const { conversions, chosenMetricSystem, defaultMetricSystem } =
    state.settings;
  return { conversions, chosenMetricSystem, defaultMetricSystem };
};

export default (Component: React.ComponentType<OwnProps>) =>
  connect(mapStateToProps)(Hoc(Component));
