import { ITEM_READONLY, USER_SHARE_ITEM, USER_SHOW_PRICES } from "./constants";

export type ShowIfCapabilitiesFunction = (capabilities: string[]) => boolean;

export interface ShowIfCondition {
  showIf?: ShowIfCapabilitiesFunction;
}

/**
 * filter objects using "showIf" capabilities function property
 * @param objects
 * @param capabilities
 */
export function filterByShowIfCondition<T extends ShowIfCondition>(
  objects: T[],
  capabilities: string[] = []
) {
  return (
    objects
      .filter((obj) => !obj.showIf || obj.showIf(capabilities))
      // Remove showIf property from item of type T
      .map((obj) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { showIf, ...cleanedObj } = obj;
        return cleanedObj as T;
      })
  );
}

export const hasCapability =
  (name: string): ShowIfCapabilitiesFunction =>
  (capabilities: string[]) =>
    capabilities.includes(name);

export const hasNotCapability =
  (name: string): ShowIfCapabilitiesFunction =>
  (capabilities: string[]) =>
    !capabilities.includes(name);

// showIf functions
export const itemIsReadOnly: ShowIfCapabilitiesFunction =
  hasCapability(ITEM_READONLY);

export const itemIsNotReadOnly: ShowIfCapabilitiesFunction =
  hasNotCapability(ITEM_READONLY);

export const itemIsSharable: ShowIfCapabilitiesFunction =
  hasNotCapability(ITEM_READONLY) && hasCapability(USER_SHARE_ITEM);

export const pricesAreVisible: ShowIfCapabilitiesFunction =
  hasCapability(USER_SHOW_PRICES);
