import { Icon, List } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ReactSVG } from "react-svg";
import iconPicture from "../../assets/images/icon-picture.svg";
import { ApplicationType } from "../../generated/axios";
import { ErrorHandler } from "../../shared/components/ErrorHandler";
import { ApplicationTypeInfoBox } from "./ApplicationTypeInfoBox";
import TextIcon from "./TextIcon";

interface IItemList {
  items: ApplicationType[];
  create: (applicationTypeId: number) => void;
}

const ItemList: React.ComponentType<IItemList> = (props: IItemList) => (
  <List
    bordered={false}
    dataSource={props.items}
    renderItem={(item: ApplicationType) => {
      const { id, icon, abbreviation = "", name = "" } = item;
      return (
        <ErrorHandler
          warning={
            <FormattedMessage
              id="ERROR_SHOWING_ITEM"
              defaultMessage="Not able to show the item"
            />
          }
        >
          <List.Item>
            <a
              onClick={() => id && props.create(id)}
              className="application-type__item"
            >
              {icon ? (
                <Icon type={icon} />
              ) : abbreviation ? (
                <TextIcon text={abbreviation} visualClassName="inItemList" />
              ) : (
                <ReactSVG
                  src={iconPicture}
                  className="icon icon--40 icon--margined"
                />
              )}
              <span className="application-type__text">{name}</span>
            </a>
            <ApplicationTypeInfoBox {...item} />
          </List.Item>
        </ErrorHandler>
      );
    }}
  />
);

export default ItemList;
