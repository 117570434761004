import React from "react";
import "./Readonly.scss";

interface IProps {
  text?: string;
}

const ReadonlyField = ({ text }: IProps) => (
  <div className="readonly-form-field">{text || "-"}</div>
);

export default ReadonlyField;
