import { Col, Row } from "antd";
import React, { FC } from "react";
import { ReactSVG } from "react-svg";
import { ApplicationType } from "../../generated/axios";
import { ErrorHandler } from "../../shared/components/ErrorHandler";
import ItemList from "./ItemList";

interface ListRendererProps {
  icon: string;
  items: ApplicationType[];
  callback: (applicationTypeId: number) => void;
  offset: number;
  span: number;
  title: React.ReactNode;
}

const ListRenderer: FC<ListRendererProps> = ({
  icon,
  items,
  callback,
  offset,
  title,
  span,
}) => (
  <Col span={span} offset={offset} className="item-selection__column">
    <div className="application-type">
      <Row className="application-type__heading">
        <ReactSVG src={icon} className="icon icon--56 icon--padded" />
        <span className="application-type__title">{title}</span>
      </Row>
      <Row className="application-type__list">
        <ErrorHandler>
          <ItemList items={items} create={callback} />
        </ErrorHandler>
      </Row>
    </div>
  </Col>
);

export default ListRenderer;
