import React from "react";
import { CapabilitiesContext } from "./index";
import { ShowIfCondition } from "./lib";

interface IProps extends ShowIfCondition {
  showIfHas?: string;
  showIfHasNot?: string;
}

export const CapabilityCheck: React.FC<IProps> = ({
  children,
  showIf,
  showIfHas,
  showIfHasNot,
}) => {
  const context = React.useContext(CapabilitiesContext);

  if (showIfHas !== undefined && !context.capabilities.includes(showIfHas))
    return null;

  if (showIfHasNot !== undefined && context.capabilities.includes(showIfHasNot))
    return null;

  if (showIf !== undefined && !showIf(context.capabilities)) return null;

  return typeof children === "function" ? children() : children;
};
