import { Icon, Input, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import { Permission } from "../../generated/axios";
import { messages } from "../../shared/lib/locales/definedMessages";
import { useAppDispatch } from "../../shared/store/hooks";
import { updateItemQuantity } from "../../shared/store/item/actions";

function toNumber(s: string) {
  const value = Number.parseInt(s);
  return isNaN(value) ? 1 : value;
}

interface EssentialItem {
  id?: number;
  quantity?: number;
  updating?: boolean;
  permission?: Permission;
}

interface IProps {
  intl: InjectedIntl;
  item: EssentialItem;
}

const Quantity: React.FC<IProps> = ({ intl, item }) => {
  const id = item.id ?? 0; // Item always has an "id"
  const initialQuantity = String(item.quantity ?? 1);
  const updating = item.updating;
  const [editMode, setEditMode] = useState(false);
  const [quantity, setQuantity] = useState(initialQuantity);
  const dispatch = useAppDispatch();
  const updateQuantity = useCallback(() => {
    setEditMode(false);
    if (quantity !== initialQuantity) {
      dispatch(updateItemQuantity(id, toNumber(quantity)));
    }
  }, [id, quantity, initialQuantity, dispatch, setEditMode]);
  const undoOnEsc = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Escape") {
        setQuantity(initialQuantity);
        setEditMode(false);
      }
    },
    [initialQuantity, setQuantity, setEditMode]
  );
  useEffect(() => {
    if (!updating) setQuantity(initialQuantity);
  }, [updating, setQuantity, initialQuantity]);

  if (item.permission === Permission.READ) return <div>{quantity}</div>;
  return editMode ? (
    <Input
      key={`p${initialQuantity}`}
      placeholder={intl.formatMessage(messages.quantityPlaceholder)}
      value={quantity}
      onChange={(e) => setQuantity(e.target.value)}
      onBlur={updateQuantity}
      onPressEnter={updateQuantity}
      autoFocus={true}
      onKeyDown={undoOnEsc}
      maxLength={10}
      type={"number"}
      min={1}
    />
  ) : (
    <div onClick={() => !updating && setEditMode(true)}>
      {updating ? (
        <Spin size="small" />
      ) : (
        <Icon type="edit" className="edit-icon" />
      )}
      &nbsp;{quantity}
    </div>
  );
};

export default injectIntl(Quantity);
