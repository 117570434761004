import { Any } from "../../types/Any";
import { ByKey } from "../../types/configurator";
import log from "../log";
import { getVarsFromString } from "./getVarsFromString";

export class FillParamsError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "FillParamsError";

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, FillParamsError.prototype);
  }
}

export const fillParam = (
  str: string,
  values: ByKey,
  allowNullParams: boolean = false // Default: algebraic evaluation version
): string => {
  const varsInParams = getVarsFromString(str);
  varsInParams.forEach((v) => {
    const placeholder = v[0];
    const property = v[1];
    if (values[property] === undefined) {
      // property without value
      log.warn(
        `Expression error compiling ${str}: ${placeholder} is undefined in [${Object.keys(
          values
        )}]`
      );
      throw new FillParamsError(`Missing value for property "${property}"`);
    }
    if (!allowNullParams && values[property] === null) {
      // property without null value
      throw new FillParamsError(`Null value for property "${property}"`);
    }
    str = str.replace(placeholder, values[property]);
  });
  return str;
};

export const replaceParam = (str: string, values: ByKey): string => {
  const varsInParams = getVarsFromString(str);
  varsInParams.forEach(([placeholder, property]) => {
    const fieldValue = String(values[property]);
    str = str.replace(placeholder, fieldValue);
  });
  return str;
};

// replace placeholders in inputParameters using field values
export const replaceParams = (
  inputParameters: Any[],
  values: ByKey
): string[] => {
  return inputParameters.map((param) => replaceParam(param, values));
};
