import React from "react";
import { connect } from "react-redux";
import {
  match,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import {
  CONFIGURATOR_MECHANICAL,
  CONFIGURATOR_RESULTS,
  CONFIGURATOR_THERMAL,
} from "../../config/paths";
import { DutyMode, Item } from "../../generated/axios";
import Loading from "../../shared/components/Loading";
import { getItem } from "../../shared/store/item/actions";
import { IStore } from "../../shared/store/types";
import { toggleSidePanel } from "../../shared/store/ui/actions";
import { ConfiguratorSection } from "../../types/configurator";
import CalculatorResults from "./components/CalculatorResults";
import ConfiguratorLayout from "./components/ConfiguratorLayout/ConfiguratorLayout";
import { SidePanel } from "./components/SidePanel";
import Configurator from "./containers/Configurator";
import ConfiguratorLoader from "./containers/ConfiguratorLoader";

interface IRouterProps extends RouteComponentProps {
  match: match<{ itemId: string }>;
}

interface IReduxProps {
  item: Item;
  sidePanelView?: ConfiguratorSection;
  visible: boolean;
}

interface IDispatchProps {
  getItem: (itemId: number) => void;
  toggleSidePanel: (view?: string) => void;
}

interface Props extends IRouterProps, IReduxProps, IDispatchProps {}

// create a context to manage "Rating" button visibility (shown only in Design Mode) to be passed until TableColumn,
// avoiding passing the boolean value to the whole tree
export const DutyModeContext = React.createContext(false);

class ConfiguratorRouter extends React.Component<Props> {
  private readonly itemId: number;

  constructor(props: Props) {
    super(props);

    this.itemId = parseInt(props.match.params.itemId, 10);

    // NOTE: when landing in /configurator/...section, I need to load the item details.
    // In getItem action creator I stop this loading operation if state.item.results.results exists because it means I come from outside this section, but I have an Item I am working on.
    // See a back button from UserSettings page (https://intesys.atlassian.net/browse/ALFAPS-1022)
    props.getItem(this.itemId);
  }

  getCurrentSection = (): ConfiguratorSection | undefined => {
    const url = this.props.location.pathname;
    if (url.endsWith(CONFIGURATOR_THERMAL)) return ConfiguratorSection.THERMAL;
    else if (url.endsWith(CONFIGURATOR_MECHANICAL))
      return ConfiguratorSection.MECHANICAL;
    else if (url.endsWith(CONFIGURATOR_RESULTS))
      return ConfiguratorSection.RESULTS;
    else return undefined;
  };

  render() {
    const { item, match, sidePanelView, toggleSidePanel, visible } = this.props;

    const section = this.getCurrentSection();

    const viewRatingButton = item.dutyMode === DutyMode.DESIGN;
    if (item?.id) {
      return (
        <ConfiguratorLoader itemId={this.itemId}>
          <ConfiguratorLayout section={section}>
            <Switch>
              <Route
                path={match.path + CONFIGURATOR_THERMAL}
                render={() => (
                  <Configurator
                    itemId={this.itemId}
                    section={ConfiguratorSection.THERMAL}
                  />
                )}
              />

              <Route
                path={match.path + CONFIGURATOR_MECHANICAL}
                render={() => (
                  <Configurator
                    itemId={this.itemId}
                    section={ConfiguratorSection.MECHANICAL}
                  />
                )}
              />

              <Route
                exact
                path={match.path + CONFIGURATOR_RESULTS}
                render={() => (
                  <DutyModeContext.Provider value={viewRatingButton}>
                    <CalculatorResults itemId={this.itemId} />
                  </DutyModeContext.Provider>
                )}
              />
            </Switch>
            <SidePanel
              sidePanelView={sidePanelView}
              toggleSidePanel={toggleSidePanel}
              visible={visible}
            />
          </ConfiguratorLayout>
        </ConfiguratorLoader>
      );
    } else {
      return <Loading />;
    }
  }
}

const mapStateToProps = (state: IStore): IReduxProps => ({
  item: state.item,
  sidePanelView: state.ui.sidePanelView,
  visible: state.ui.isSidePanelActive,
});

const mapDispatchToProps = {
  getItem,
  toggleSidePanel,
};

const Connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfiguratorRouter);
const WithRouter = withRouter(Connected);
export default WithRouter;
