import { Table } from "antd";
import { ColumnProps, SortOrder } from "antd/lib/table/interface";
import classNames from "classnames";
import { BaseField, ResultsTableSettings } from "generated/axios/api";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { ItemResultExt } from "../../../../shared/store/item/types";
import { OrderDirection } from "../../../../shared/store/item/typesResults";
import { IStore } from "../../../../shared/store/types";
import "../../../../styles/helper.scss";
import { Any } from "../../../../types/Any";
import { Callback } from "../../../../types/Callback";
import CellNote from "../TableColumn/CellNote";
import CellValue from "../TableColumn/CellValue";
import { getBulletColorCssClass } from "../TableColumn/lib";
import TableCellButtonsList from "../TableColumn/TableCellButtonsList";
import TableCellHeading from "../TableColumn/TableCellHeading";
import TableCellTechButtons from "../TableColumn/TableCellTechButtons";
import TableCellUnit from "../TableColumn/TableCellUnit";
import { filterEmpty, orderedHeaders } from "./lib";
import { compareField } from "./order-lib";
import resultsSystemOfMeasurement from "./resultsSystemOfMeasurement";
import "./tableResult.scss";
import { TableResultsDate } from "./TableResultsDate";
import { TableResultsFooter } from "./TableResultsFooter";
import TableResultsInfo from "./TableResultsInfo";
import { TableResultsSetupContainer } from "./TableResultsSetupContainer";

const sortDirectionsAlternated: SortOrder[] = ["ascend", "descend", "ascend"];
const sortDirectionsAsc: SortOrder[] = ["ascend", "ascend"];

export interface OwnProps {
  exportCsvHandler: Callback;
  handleLockingClick: (lock: boolean, unitId?: string) => void;
  handleRatingButtonClick: (unitId?: string) => void;
  handleViewButtonClick: (unitId?: string) => void;
  lastModifiedDate?: Date | string;
  missingResults?: number;
  openSettingsModal: (sections: string[]) => void;
  orderAsSettings: NonNullable<ResultsTableSettings["fields"]>;
  orderDirectionDefault: OrderDirection;
  orderFieldDefault: string;
  pricesAreVisible: boolean;
  printPdf: (unitId?: string) => void;
  resultFieldConfig: BaseField[];
  results: ItemResultExt[];
  selected?: string;
  waitingPrices?: boolean;
}

interface State {
  headers?: BaseField[];
  results?: ItemResultExt[];
}

export interface Props extends OwnProps {}

class TransposedTableResults extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    // perform operations that where in render()
    const {
      orderAsSettings,
      pricesAreVisible,
      results,
      resultFieldConfig = [],
    } = props;

    // in case of results
    if (results && !!results.length) {
      const baseFields = pricesAreVisible
        ? resultFieldConfig
        : resultFieldConfig.filter((field) => field.fieldId !== "price");

      let headers = filterEmpty(baseFields, props.results);

      if (headers?.length && orderAsSettings) {
        headers = orderedHeaders(orderAsSettings, headers);
      }

      return { ...state, results, headers };
    }

    return null;
  }

  unlockAll = () => {
    this.props.handleLockingClick(false);
  };

  printAllPdf = () => {
    this.props.printPdf();
  };

  defaultSortOrder = (fieldId: string): SortOrder | undefined => {
    const { orderDirectionDefault, orderFieldDefault } = this.props;
    if (fieldId !== orderFieldDefault) return undefined;
    return orderDirectionDefault === OrderDirection.asc ? "ascend" : "descend";
  };

  compareFunction =
    (fieldId: string) =>
    (a: ItemResultExt, b: ItemResultExt, sortOrder?: SortOrder): number => {
      // Locked before unlocked
      if (a.locked !== b.locked) {
        return (sortOrder === "ascend" ? 1 : -1) * (a.locked ? -1 : 1);
      }
      const result = compareField(fieldId, a, b);
      if (result === 0) {
        // secondary sorting criteria
        return compareField("unitPosition", a, b); // unitPosition ASC
      } else {
        return result;
      }
    };

  render(): React.ReactNode {
    const { results, headers } = this.state;
    const {
      exportCsvHandler,
      lastModifiedDate,
      missingResults,
      openSettingsModal,
      waitingPrices,
    } = this.props;

    if (!results) return <div>no data</div>;

    const columns: ColumnProps<ItemResultExt>[] = [
      {
        key: "unit",
        title: (
          <TableResultsInfo
            exportCsvHandler={exportCsvHandler}
            missingResults={missingResults}
            displayedResults={results?.length}
          />
        ),
        fixed: "left",
        align: "center",
        width: 168,
        onCell: (itemResult) => ({
          className:
            this.props.selected === itemResult.unitId ? "selected-unit" : "",
        }),
        render: (_, itemResult: ItemResultExt) => {
          const bulletColorCssClass = getBulletColorCssClass(itemResult);
          return (
            <>
              <TableCellUnit
                className={""}
                bulletColorCssClass={bulletColorCssClass}
                isSelected={false}
                unitName={itemResult.unitName}
              />
              {(itemResult.bestChoice || itemResult.bestRatio) && (
                <TableCellHeading
                  bestChoice={itemResult.bestChoice}
                  bestRatio={itemResult.bestRatio}
                />
              )}
            </>
          );
        },
      },
      {
        key: "actions",
        title: (
          <TableResultsSetupContainer
            className={"table-cell-buttons"}
            openSettingsModal={openSettingsModal}
            unlockAll={this.unlockAll}
          />
        ),
        fixed: "left",
        width: 140,
        align: "center" as ColumnProps<ItemResultExt>["align"],
        render: (_, itemResult: ItemResultExt) => (
          <TableCellButtonsList
            handleLock={this.props.handleLockingClick}
            handleRate={this.props.handleRatingButtonClick}
            handleView={this.props.handleViewButtonClick}
            lockChanging={itemResult.lockChanging}
            locked={itemResult.locked}
            unitId={itemResult.unitId}
          />
        ),
      },
      {
        key: "note",
        title: (
          <div>
            <FormattedMessage
              id={"results-table.note"}
              defaultMessage={"NOTE"}
            />
          </div>
        ),
        width: 85,
        align: "center" as ColumnProps<ItemResultExt>["align"],
        render: (_, itemResult: ItemResultExt) => {
          const { coreCalculationMessages, optionsData } = itemResult;
          const isWarning = Boolean(
            (optionsData?.missingOptions &&
              optionsData.missingOptions.length > 0) ||
              (coreCalculationMessages && coreCalculationMessages.length > 0)
          );
          return (
            <CellNote
              isWarning={isWarning}
              messages={coreCalculationMessages}
              whisperer={itemResult.calculationValues?.whisperer}
            />
          );
        },
      },
      ...(headers
        ? headers.map((header) => ({
            key: header.fieldId,
            title: header.label,
            sorter: this.compareFunction(header.fieldId),
            defaultSortOrder: this.defaultSortOrder(header.fieldId),
            sortDirections:
              header.fieldId === "score"
                ? sortDirectionsAsc
                : sortDirectionsAlternated,
            width: header.fieldId === "dllName" ? 190 : 140,
            // remove type cast when no warning
            align: "center" as ColumnProps<ItemResultExt>["align"],
            render: (_: Any, itemResult: ItemResultExt) => {
              const { fieldId } = header;
              const calculationValues = itemResult.calculationValues;
              return (
                <CellValue
                  fieldId={fieldId}
                  value={calculationValues?.[fieldId] ?? null}
                  missingOptionsMap={itemResult.missingOptionsMap}
                  waitingPrices={waitingPrices}
                />
              );
            },
          }))
        : []),
      {
        key: "actions2",
        title: "",
        fixed: "right",
        width: 116,
        render: (_, itemResult: ItemResultExt) => (
          <TableCellTechButtons
            className={"table-cell-buttons"}
            drawingUrl={itemResult.drawingUrl}
            printPdf={this.props.printPdf}
            unitId={itemResult.unitId}
          />
        ),
      },
    ];

    return (
      <>
        <div style={{ marginTop: "15px" }}>
          <Table<ItemResultExt>
            className={"table-results"}
            columns={columns}
            dataSource={results}
            pagination={false}
            rowClassName={(itemResult) =>
              classNames({
                "selected-unit": itemResult.selected,
                "lock-changed-unit": itemResult.lockChanged,
                "lock-changing-unit": itemResult.lockChanging,
                "locked-unit": itemResult.locked,
                "unlocked-unit": !itemResult.locked,
              })
            }
            rowKey={"unitId"}
            scroll={{ x: "100%", y: 456 }}
          />
        </div>

        <TableResultsDate lastModifiedDate={lastModifiedDate} />

        <TableResultsFooter
          exportCsvHandler={exportCsvHandler}
          openSettingsModal={openSettingsModal}
          printAllPdf={this.printAllPdf}
        />
      </>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    orderDirectionDefault: state.settings.initialResultTableOrderedDirection,
    orderFieldDefault: state.settings.initialResultTableOrderedField,
  };
};

const ConnectedTransposedTableResults = connect(mapStateToProps)(
  TransposedTableResults
);

export default resultsSystemOfMeasurement(ConnectedTransposedTableResults);
