import { BaseField, ResultsTableSettings } from "generated/axios/api";
import React, { createRef } from "react";
import { FormattedMessage } from "react-intl";
import { EqualHeightsElement } from "../../../../shared/components/EqualHeights";
import { ScrollableList } from "../../../../shared/components/ScrollableList";
import { Scrollbar } from "../../../../shared/components/ScrollableList/Scrollbar";
import { ItemResultExt } from "../../../../shared/store/item/types";
import "../../../../styles/helper.scss";
import { Callback } from "../../../../types/Callback";
import TableColumn from "../TableColumn/TableColumn";
import Headers from "./Headers";
import { filterEmpty, orderedHeaders } from "./lib";
import { MemoScrollbarPosition } from "./MemoScrollbarPosition";
import orderResults, { OrderProps } from "./orderResults";
import resultsSystemOfMeasurement from "./resultsSystemOfMeasurement";
import { ScrollToSelected } from "./ScrollToSelected";
import "./tableResult.scss";
import { TableResultsDate } from "./TableResultsDate";
import { TableResultsFooter } from "./TableResultsFooter";
import { TableResultsSetupContainer } from "./TableResultsSetupContainer";
import { TableResultsWarning } from "./TableResultsWarning";

export interface OwnProps {
  exportCsvHandler: Callback;
  handleLockingClick: (lock: boolean, unitId?: string) => void;
  handleRatingButtonClick: (unitId?: string) => void;
  handleViewButtonClick: (unitId?: string) => void;
  initialVisibleIndex?: number;
  lastModifiedDate?: Date | string;
  missingResults?: number;
  openSettingsModal: (sections: string[]) => void;
  orderAsSettings: NonNullable<ResultsTableSettings["fields"]>;
  pricesAreVisible: boolean;
  printPdf: (unitId?: string) => void;
  resultFieldConfig: BaseField[];
  results: ItemResultExt[];
  selected?: string;
  waitingPrices?: boolean;
}

interface State {
  headers?: BaseField[];
  isSticky: boolean;
  results?: ItemResultExt[];
}

export interface Props extends OwnProps, OrderProps {}

class TableResults extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSticky: false,
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    // perform operations that where in render()
    const {
      orderAsSettings,
      pricesAreVisible,
      results,
      resultFieldConfig = [],
    } = props;

    // in case of results
    if (results && !!results.length) {
      // NB: props.reorder() update props.results!
      const orderedResults = props.reorder(props.results);
      const baseFields = pricesAreVisible
        ? resultFieldConfig
        : resultFieldConfig.filter((field) => field.fieldId !== "price");

      let headers = filterEmpty(baseFields, orderedResults);

      if (headers?.length && orderAsSettings) {
        headers = orderedHeaders(orderAsSettings, headers);
      }

      return { ...state, results, headers };
    }

    return null;
  }

  toggleStickiness = ({ top, bottom }: DOMRect) => {
    const isSticky =
      top < 0 &&
      // When scrolling from bottom to top when and
      // the last row is visible enough, sticky header will be triggered.
      // This number (68) could be adjusted or skipped.
      bottom > 2 * 68;
    this.setState((state) => {
      if (state.isSticky === isSticky) return state;
      else
        return {
          ...state,
          isSticky,
        };
    });
  };

  private stickyDivRef = createRef<HTMLDivElement>();

  handleScroll = () => {
    if (this.stickyDivRef.current) {
      this.toggleStickiness(this.stickyDivRef.current.getBoundingClientRect());
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.document.removeEventListener("scroll", this.handleScroll);
  }

  unlockAll = () => {
    this.props.handleLockingClick(false);
  };

  handleViewButtonClick = (unitId: string): void => {
    this.props.handleViewButtonClick(unitId);
  };

  printAllPdf = () => {
    this.props.printPdf();
  };

  render(): React.ReactNode {
    const { results, headers, isSticky } = this.state;
    const {
      exportCsvHandler,
      getOrderIcon,
      initialVisibleIndex,
      lastModifiedDate,
      missingResults,
      openSettingsModal,
      orderAsSettings,
      waitingPrices,
    } = this.props;
    // performance issue
    const orderIcon = getOrderIcon();

    if (!results) return <div>no data</div>;

    const lastLockedIndex =
      results.filter((result) => result.locked).length - 1;

    let selectedIndex: number | undefined = undefined;
    const selIndex = results.findIndex((res) => res.selected);
    if (selIndex !== -1) {
      selectedIndex = selIndex;
    }

    return (
      <React.Fragment>
        <MemoScrollbarPosition />
        <Scrollbar />
        <div ref={this.stickyDivRef} className="table">
          <div className="table__headers">
            <EqualHeightsElement group={"__table_headers__"}>
              <div className="table__warning-container">
                <TableResultsWarning
                  exportCsvHandler={exportCsvHandler}
                  missingResults={missingResults}
                  displayedResults={results?.length}
                />
              </div>
            </EqualHeightsElement>

            <TableResultsSetupContainer
              className={"table__setup-container"}
              openSettingsModal={openSettingsModal}
              unlockAll={this.unlockAll}
            />

            <div className="table__header">
              <FormattedMessage id="note" defaultMessage="Note" />
            </div>

            {headers && (
              <Headers
                headers={headers}
                orderField={this.props.orderField}
                orderIcon={orderIcon}
                toggleDirection={this.props.toggleDirection}
              />
            )}

            <div className="table__header">&nbsp;</div>
          </div>
          <ScrollableList
            initialVisibleIndex={initialVisibleIndex ?? selectedIndex}
          >
            {results?.map((result, index) => (
              <TableColumn
                orderAsSettings={orderAsSettings}
                index={index}
                itemResult={result}
                resultFieldConfig={headers ?? []}
                key={result.unitId + (result.locked ? "1" : "0")}
                handleLockingClick={this.props.handleLockingClick}
                handleViewButtonClick={this.handleViewButtonClick}
                handleRatingButtonClick={this.props.handleRatingButtonClick}
                isSticky={isSticky}
                printPdf={this.props.printPdf}
                isSelected={this.props.selected === result.unitId}
                orderField={this.props.orderField}
                lastLocked={lastLockedIndex === index}
                waitingPrices={waitingPrices}
              />
            ))}
          </ScrollableList>
        </div>

        <ScrollToSelected
          initialVisibleIndex={initialVisibleIndex}
          selectedIndex={selectedIndex}
        />

        <TableResultsDate lastModifiedDate={lastModifiedDate} />

        <TableResultsFooter
          exportCsvHandler={exportCsvHandler}
          openSettingsModal={openSettingsModal}
          printAllPdf={this.printAllPdf}
        />
      </React.Fragment>
    );
  }
}

const OrderedTable = orderResults(TableResults);
export default resultsSystemOfMeasurement(OrderedTable);
