import { createSelector } from "reselect";
import { BaseField, MetricSystem } from "../../../generated/axios";
import { Any } from "../../../types/Any";
import convert from "../../systemOfMeasurement/conversionFunctions";
import { tryConvert } from "../../systemOfMeasurement/convertCalculationValues";
import { IStore } from "../types";
import { ConversionFunction, IConversionResult } from "./types";

const findMetricSystem = (
  metricSystem: string,
  metricSystems: MetricSystem[]
): MetricSystem | undefined => {
  return metricSystems.find((m) => m.key === metricSystem);
};

export const metricSystemsSelector = (state: IStore) =>
  state.settings.metricSystems ?? [];

export const chosenMetricSystemSelector = (state: IStore) =>
  state.settings.chosenMetricSystem;

export const defaultMetricSystemSelector = (state: IStore) =>
  state.settings.defaultMetricSystem;

/**
 * @returns string - key of the chosen metric system. If not chosen, returns key of the default metric system.
 */
export const tmpMetricSystemSelector = (state: IStore) => {
  if (!state.settingsTmp.languages) {
    return state.settings.chosenMetricSystem;
  }

  const measurementSystem = state.settings.metricSystems.find(
    (m) => m.id === state.settingsTmp.languages?.measurementSystem
  )?.["key"];

  if (measurementSystem) {
    return measurementSystem;
  }

  return state.settings.chosenMetricSystem;
};

export const settingsContextSelector = (state: IStore) =>
  state.settings.settingsContext;

export const interfaceLanguageSelector = (state: IStore) =>
  state.settings.interfaceLanguage;

export const languagesSelector = (state: IStore) => state.settings.languages;

const fieldsConfigSelector = (state: IStore) =>
  state.settings.fieldsConfig ?? [];

const conversionFormulaSelector = (state: IStore) => state.settings.conversions;

export const hasMetricSystems = createSelector(metricSystemsSelector, (list) =>
  Boolean(list && list.length > 0)
);

export const fieldsConfigForItemDetailsSelector = createSelector(
  fieldsConfigSelector,
  (list) =>
    list.filter((item: BaseField) => item["sections"].includes("itemDetail"))
);

export const fieldsConfigForResultTableSelector = createSelector(
  fieldsConfigSelector,
  (list) =>
    list.filter((item: BaseField) => item["sections"].includes("resultTable"))
);

export const chosenMetricSystemAsObjectSelector = createSelector(
  [chosenMetricSystemSelector, metricSystemsSelector],
  findMetricSystem
);

export const convertFunctionSelector = createSelector(
  fieldsConfigSelector,
  chosenMetricSystemSelector,
  defaultMetricSystemSelector,
  conversionFormulaSelector,
  (
    fieldsConfig,
    chosenMetricSystem,
    defaultMetricSystem,
    conversions
  ): ConversionFunction => {
    // Return a function
    return (fieldId: string, value: Any): IConversionResult => {
      // Find unitOfMeasures for fieldId
      const unitOfMeasures = fieldsConfig.find(
        (fieldConfig) => fieldConfig.fieldId === fieldId
      )?.unitOfMeasures;

      if (unitOfMeasures) {
        const conversion = convert(
          unitOfMeasures,
          conversions,
          chosenMetricSystem,
          defaultMetricSystem
        );

        const system = conversion.canBeConvertedOneWay
          ? conversion.chosenSystem
          : conversion.defaultSystem;

        return { value: tryConvert(value, conversion), symbol: system?.symbol };
      }
      return { value };
    };
  }
);

export const conversionsSelector = (state: IStore) =>
  state.settings.conversions;

export const boundsSelector = (state: IStore) => state.settings.bounds;
