import { Menu } from "antd";
import { ClickParam } from "antd/lib/menu";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Portfolio } from "../../generated/axios";
import LinkContent from "./LinkContent";

interface PortfolioMenuLinkProps {
  clickHandler: (param: ClickParam) => void;
  availablePortfolios: Portfolio[];
  linkTo: string;
}

const PortfolioMenuLink: FunctionComponent<PortfolioMenuLinkProps> = React.memo(
  (props) => {
    return (
      <Menu onClick={props.clickHandler} className="asDropdown-menu">
        {props.availablePortfolios.map((item) => (
          <Menu.Item key={item.id}>
            {item.url ? (
              <a href={`${item.url}`} target="_blank" rel="noopener noreferrer">
                <LinkContent name={item.name} />
              </a>
            ) : (
              <Link to={`${props.linkTo}/${item.id}`}>
                <LinkContent name={item.name} />
              </Link>
            )}
          </Menu.Item>
        ))}
      </Menu>
    );
  }
);

export default PortfolioMenuLink;
