import React from "react";
import {
  MissingOptionsMap,
  ResultsTableSettings,
} from "../../../../generated/axios";
import { EqualHeightsElement } from "../../../../shared/components/EqualHeights";
import { ErrorHandler } from "../../../../shared/components/ErrorHandler";
import { Any } from "../../../../types/Any";
import { orderedFields } from "../TableResults/lib";
import CellValue from "./CellValue";

interface IProps {
  data: Record<string, Any>;
  missingOptionsMap?: MissingOptionsMap[];
  orderAsSettings: NonNullable<ResultsTableSettings["fields"]>;
  orderField?: string;
  waitingPrices?: boolean;
}

const Cells: React.FC<IProps> = ({
  data,
  missingOptionsMap,
  orderAsSettings,
  orderField,
  waitingPrices,
}) => {
  const ordered = orderedFields(orderAsSettings);

  return (
    <React.Fragment>
      {ordered.map((fieldId) => {
        const value = data?.[fieldId];
        if (value === undefined) {
          return null;
        }
        const css =
          fieldId === orderField ? "table-cell table--selected" : "table-cell";

        return (
          <EqualHeightsElement key={`${fieldId}`} group={fieldId}>
            <ErrorHandler>
              <div className={css}>
                <CellValue
                  fieldId={fieldId}
                  value={value}
                  missingOptionsMap={missingOptionsMap}
                  waitingPrices={waitingPrices}
                />
              </div>
            </ErrorHandler>
          </EqualHeightsElement>
        );
      })}
    </React.Fragment>
  );
};

export default Cells;
