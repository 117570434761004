import { BaseField } from "../../../../generated/axios";
import { Any } from "../../../../types/Any";
import { Detail } from "./ItemDetails";

export const filterResults = (
  results: Record<string, Any>,
  configs: BaseField[]
): Detail[] => {
  return configs.map((config) => {
    const fieldId = config.fieldId;
    const value = results[fieldId];
    return {
      label: config.label ?? config.fieldId ?? "",
      value: value || value === 0 ? value : "",
      fieldId: config.fieldId,
    };
  });
};
