import { Action } from "redux";
import { FAILURE, REQUEST, SUCCESS } from "../../lib/asyncActionHelper";
import { GET_ITEM, NEW_ITEM } from "../item/consts";
import { GET_PROJECT_DETAILS } from "../project/consts";
import { LOAD_CAPABILITIES } from "./const";
import {
  onError,
  onItemData,
  onLoad,
  onProjectDetails,
  onRequest,
} from "./controllers";

export interface IActionArray extends Action {
  payload?: [];
  error?: object;
}

export interface IActionObject extends Action {
  payload?: object;
  error?: object;
}

export interface CapabilitiesState {
  list: string[];
  error?: object;
}

const initialState: CapabilitiesState = {
  list: [],
};

const handlers = {
  [REQUEST(LOAD_CAPABILITIES)]: onRequest,
  [SUCCESS(LOAD_CAPABILITIES)]: onLoad,
  [FAILURE(LOAD_CAPABILITIES)]: onError,
  [SUCCESS(GET_ITEM)]: onItemData,
  [SUCCESS(NEW_ITEM)]: onItemData,
  [SUCCESS(GET_PROJECT_DETAILS)]: onProjectDetails,
};

export default (
  state: CapabilitiesState = initialState,
  action: Action
): CapabilitiesState => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};
