import { isNil, isNumber } from "lodash";
import numeral from "numeral";
import { BaseField, OptionLabelValue } from "../../generated/axios";
import { Any } from "../../types/Any";
import { PseudoNumber, PseudoValue } from "./conversionFunctions";

/**
 * @param field the field it to apply the precision
 * @param valueWithDecimal the converted
 * @param unitOfMeasure if present, extract the precision base on the chosen unit of measure
 */
const convertPrecisionString = (
  field: BaseField | undefined,
  valueWithDecimal: number,
  unitOfMeasure: string
) => {
  // if the field hasn't the UoM chosen by the user for the item (or at project level or user level)
  // return as it is (in string format)
  if (
    !field ||
    isNaN(valueWithDecimal) ||
    field?.unitOfMeasures === undefined ||
    field?.unitOfMeasures?.["base"] === undefined
  ) {
    return valueWithDecimal + "";
  }
  // if the field has the corresponding wanted UoM format it to the corresponding precision
  if (field.unitOfMeasures?.[unitOfMeasure]?.precision != undefined) {
    return valueWithDecimal.toFixed(
      field.unitOfMeasures[unitOfMeasure].precision
    );
  }
  if (field.unitOfMeasures?.["base"]) {
    // fallback with 'base' metric system
    return valueWithDecimal.toFixed(field.unitOfMeasures["base"].precision);
  }
  return 0;
};

export const applyPrecision =
  (precision?: number) =>
  (x: PseudoNumber): PseudoValue => {
    if (isNil(x) || x === "") {
      return null;
    }

    if (precision === undefined) {
      return numeral(x).value();
    }

    const value: number = numeral(x).value();
    if (isNumber(value)) {
      return Number(value).toFixed(precision);
    }

    return value;
  };

/**
 * Converts fieldId value into a string with a certain precision based on the metricSystem
 * (default one or the chosen one) --> ONLY TO PRINT DATA IN THE PAGE, not use it to calculate
 * @param fieldId the fieldId label to convert
 * @param data the array contains all the fields
 * @param defaultMetricSystem the default metric system given when user is created
 * @param resultFieldConfig the conversions array where to look for fieldId's precision
 * @param chosenMetricSystem the chosen metric system (if user has changed it from the default)
 * @param optionLabelValue translated labels for field value
 */
export const printWithDecimal = (
  fieldId: string,
  data: Record<string, Any>,
  defaultMetricSystem: string,
  resultFieldConfig: BaseField[],
  chosenMetricSystem?: string,
  optionLabelValue?: OptionLabelValue
) => {
  if (optionLabelValue !== undefined) {
    let content = optionLabelValue[data[fieldId]];
    if (content === undefined) {
      content = data[fieldId]; // Fallback if missing value in optionLabelValue translations
    }
    return content;
  }

  const valueWithDecimal = Number(data[fieldId]);
  // case like "connections: "28mm - 54mm" or "fanErp: null" ecc
  if (isNaN(valueWithDecimal) || valueWithDecimal == null) {
    return data[fieldId];
  }

  const unitOfMeasure = chosenMetricSystem ?? defaultMetricSystem ?? "sis";
  const fieldTmp = resultFieldConfig.find((field) => field.fieldId === fieldId);
  return convertPrecisionString(fieldTmp, valueWithDecimal, unitOfMeasure);
};
