import { Icon } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { exportingCSVSelector } from "../../../../shared/store/item/selectors";
import { Callback } from "../../../../types/Callback";

interface IProps {
  clickHandler: Callback;
}

const ExportButton: React.FC<IProps> = (props: IProps) => {
  const exporting = useSelector(exportingCSVSelector);
  return exporting ? (
    <div className="table-footer__text as-button no-pointer">
      <Icon type="download" theme="outlined" className="icon--blue" />
      <FormattedMessage
        id="wait for the download"
        defaultMessage="Wait for the download"
      />
    </div>
  ) : (
    <div
      className="table-footer__text as-button"
      onClick={() => props.clickHandler()}
    >
      <Icon type="download" theme="outlined" className="icon--blue" />
      <FormattedMessage
        id="export csv"
        defaultMessage="Export results in CSV"
      />
    </div>
  );
};

export default ExportButton;
