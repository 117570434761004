import { defineMessages } from "react-intl";

// prettier-ignore
export const messages = defineMessages({
    ERROR_UPPERCASE: {id: "ERROR_UPPERCASE", defaultMessage: "ERROR"},
    accountAlreadyActivated: {id: "accountAlreadyActivated", defaultMessage: "The account has already been activated"},
    accountDeactivated: {id: "accountDeactivated", defaultMessage: "The account has been deactivated"},
    addressPlaceholder: {id: "address.placeholder", defaultMessage: "Insert your address here"},
    companyPlaceholder: {id: "company.placeholder", defaultMessage: "Insert your company name here"},
    confirmPasswordPlaceholder: {id: "confirm.password.placeholder", defaultMessage: "Confirm your new password!"},
    confirmPasswordRequired: {id: "confirm.password.required", defaultMessage: "Please confirm your new password!"},
    customPdf: {id: "customPdf", defaultMessage: "Custom PDF"},
    discountPlaceholder: {id: "discountPlaceholder", defaultMessage: "Discount"},
    emailPlaceholder: {id: "email.placeholder", defaultMessage: "Insert your new e-mail here"},
    emailRequired: {id: "email.required", defaultMessage: "Please input your new e-mail!"},
    fileExceedsSizeLimit: {id: "fileExceedLimit", defaultMessage: "The file size exceeds the {1} MB limit"},
    firstNamePlaceholder: {id: "firstname.placeholder", defaultMessage: "Insert your first name here"},
    inputEmailPlaceholder: {id: "registration.email.placeholder", defaultMessage: "johansson.erik@luvegroup.com"},
    inputInsertAddressPlaceholder: {id: "input.insert.address.placeholder", defaultMessage: "Insert address here"},
    inputInsertLastNamePlaceholder: {
        id: "input.insert.lastname.placeholder",
        defaultMessage: "Insert second name here"
    },
    inputInsertNamePlaceholder: {id: "input.insert.name.placeholder", defaultMessage: "Insert name here"},
    inputInsertNotePlaceholder: {id: "input.insert.note.placeholder", defaultMessage: "Insert note here"},
    inputInsertOfferDiscountPlaceholder: {
        id: "input.insert.offer_discount.placeholder",
        defaultMessage: "Insert offer discount here"
    },
    inputInsertOfferNumberPlaceholder: {
        id: "input.insert.offer_number.placeholder",
        defaultMessage: "Insert offer number here"
    },
    inputInsertReferencePlaceholder: {
        id: "input.insert.reference.placeholder",
        defaultMessage: "Insert reference here"
    },
    inputInsertSubjectPlaceholder: {id: "input.insert.subject.placeholder", defaultMessage: "Insert subject here"},
    inputInvalidEmail: {id: "email.invalid", defaultMessage: "The input is not valid E-mail!"},
    inputNewProjectPlaceholder: {id: "placeholder.input.new.project", defaultMessage: "Type your project title here"},
    inputNumberExamplePercPlaceholder: {id: "placeholder.inputNumber.ExamplePerc", defaultMessage: "i.e. 10%"},
    inputPasswordAgainPlaceholder: {id: "placeholder.input.passwordAgain", defaultMessage: "Type your password again"},
    inputPasswordErr: {id: "err.input.password", defaultMessage: "Please input your password!"},
    inputPasswordPlaceholder: {id: "placeholder.input.password", defaultMessage: "Type your password here"},
    inputRequiredEmail: {id: "registration.required.email", defaultMessage: "Please input your email!"},
    inputRequiredPrivacyPolicy: {id: "inputRequiredPrivacyPolicy", defaultMessage: "Required"},
    inputSearchPlaceholder: {id: "placeholder.input.search", defaultMessage: "input search text"},
    inputUsernameErr: {id: "err.input.username", defaultMessage: "Please input your username!"},
    inputUsernamePlaceholder: {id: "placeholder.input.username", defaultMessage: "me"},
    lastNamePlaceholder: {id: "lastname.placeholder", defaultMessage: "Insert your last name here"},
    loginMessage: {id: "login.message", defaultMessage: "to access your account"},
    newPasswordPlaceholder: {id: "new.password.placeholder", defaultMessage: "Insert your new password here"},
    newPasswordRequired: {id: "new.password.required", defaultMessage: "Please input your new password!"},
    noCustomPdf: {id: "noCustomPdf", defaultMessage: "No custom PDF"},
    noOtherLogo: {id: "no.other.logo", defaultMessage: "No other logo in use"},
    note: {id: "note", defaultMessage: "Note"},
    oldPasswordPlaceholder: {id: "old.password.placeholder", defaultMessage: "Insert your old password here"},
    oldPasswordRequired: {id: "old.password.required", defaultMessage: "Please input your old password!"},
    otherLogo: {id: "otherLogo", defaultMessage: "Other logo"},
    passwordInvalid: {id: "password.invalid", defaultMessage: "Please input your current password!"},
    passwordPlaceholder: {id: "password.placeholder", defaultMessage: "Insert your current password here"},
    passwordRequired: {id: "password.required", defaultMessage: "Please input your password!"},
    placeholder: {id: "note placeholder", defaultMessage: "Type text here. Max 40 words."},
    positionPlaceholder: {id: "positionPlaceholder", defaultMessage: "Position"},
    customerRefPlaceholder: {id: "customerRefPlaceholder", defaultMessage: "Customer ref."},
    priceListInfo: {id: "priceListInfo", defaultMessage: 'The prices refer to the "{name}" price list of {date}.'},
    priceListInfoExpired: {
        id: "priceListInfoExpired",
        defaultMessage: 'The prices refer to the "{name}" price list of {date}. The new price list "{new_name}" is available from {expired}. Update prices pressing "Calculate results" button.'
    },
    privacyPolicyUrl: {
        id: "privacyPolicyUrl",
        defaultMessage: "https://www.luve.it/cms/view/registrati/informativa-iscrizione-area-riservata/s91/c1769"
    },
    quantityPlaceholder: {id: "quantityPlaceholder", defaultMessage: "Quantity"},
    reportProblemPlaceholder: {id: "placeholder.report.problem", defaultMessage: "Type text here. Max 300 words."},
    reportProblemTextAreaRequired: {id: "textarea.required", defaultMessage: "A description text is mandatory"},
    selectCityPlaceholder: {id: "select.city.placeholder", defaultMessage: "Select your city"},
    selectCountryPlaceholder: {id: "registration.select.placeholder", defaultMessage: "Select your country"},
    selectLanguage: {id: "select.lang", defaultMessage: "Select your language"},
    selectMetricSys: {id: "select.metric.sys", defaultMessage: "Select your metric system"},
    // selectMultiplePlaceholder: { id: "select.multiple.placeholder", defaultMessage: "placeholder text to be inserted here" },
    selectProjectTitle: {id: "select.project.title", defaultMessage: "Type the title of an existing project"},
    selectRequiredCountry: {id: "registration.select.required", defaultMessage: "Please input your country!"},
    subtitle: {id: "note subtitle", defaultMessage: "Type your note below. Maximum lenght 40 words"},
    textInsertNote: {id: "text.insert.note", defaultMessage: "Insert your note here ..."},
    typePasswordAgainPlaceholder: {
        id: "type.password.again.placeholder",
        defaultMessage: "Type your password again here"
    },
    typePasswordPlaceholder: {id: "type.password.placeholder", defaultMessage: "Type your password here"},
    userExists: {id: "user exists", defaultMessage: "User already exists in the system"},
    userNotFound: {id: "userNotFound", defaultMessage: "User not found in the system"},
    versionPlaceholder: {id: "versionPlaceholder", defaultMessage: "Version"},
    zipPlaceholder: {id: "zip.placeholder", defaultMessage: "Insert your zip code here"},
    "message.account_updated": {id: "message.account_updated", defaultMessage: "Account updated"},
    "message.adding_share": {id: "message.adding_share", defaultMessage: "Adding share"},
    "message.adding_the_note": {id: "message.adding_the_note", defaultMessage: "Adding the note"},
    "message.all_units_unlocked": {id: "message.all_units_unlocked", defaultMessage: "All unit unlocked"},
    "message.clear_completed": {id: "message.clear_completed", defaultMessage: "Clear completed"},
    "message.clearing_item": {id: "message.clearing_item", defaultMessage: "Clearing item"},
    "message.configuration_correctly_saved": {
        id: "message.configuration_correctly_saved",
        defaultMessage: "Configuration correctly saved"
    },
    "message.creating_the_project": {id: "message.creating_the_project", defaultMessage: "Creating the project"},
    "message.creating_quote": {id: "message.creating_quote", defaultMessage: "Creating quote"},
    "message.csv_exported_successfully": {
        id: "message.csv_exported_successfully",
        defaultMessage: "CSV exported successfully"
    },
    "message.data_loading": {id: "message.data_loading", defaultMessage: "Data loading"},
    "message.delete_completed": {id: "message.delete_completed", defaultMessage: "Delete completed"},
    "message.deleting_item": {id: "message.deleting_item", defaultMessage: "Deleting item"},
    "message.deleting_project": {id: "message.deleting_project", defaultMessage: "Deleting project"},
    "message.duplicate_completed": {id: "message.duplicate_completed", defaultMessage: "Duplicate completed"},
    "message.duplicating_item": {id: "message.duplicating_item", defaultMessage: "Duplicating item"},
    "message.duplicating_project": {id: "message.duplicating_project", defaultMessage: "Duplicating project"},
    "message.error_in_saving_configuration": {
        id: "message.error_in_saving_configuration",
        defaultMessage: "Error in saving configuration"
    },
    "message.error_loading_results_prices": {
        id: "message.error_loading_results_prices",
        defaultMessage: "Error loading prices"
    },
    "message.error_saving_item": {id: "message.error_saving_item", defaultMessage: "Error saving item"},
    "message.exporting_csv": {id: "message.exporting_csv", defaultMessage: "Exporting CSV"},
    "message.form_not_valid": {id: "message.form_not_valid", defaultMessage: "Form is not valid, please check"},
    "message.generating_pdf": {id: "message.generating_pdf", defaultMessage: "Generating PDF"},
    "message.import_completed": {id: "message.import_completed", defaultMessage: "Import completed"},
    "message.import_item_completed": {id: "message.import_item_completed", defaultMessage: "Import item completed"},
    "message.importing_shared_item": {
        id: "message.importing_shared_item",
        defaultMessage: "Importing shared item"
    },
    "message.importing_shared_project": {
        id: "message.importing_shared_project",
        defaultMessage: "Importing shared project"
    },
    "message.invalid_data": {id: "message.invalid_data", defaultMessage: "Invalid data"},
    "message.item_shared_successfully": {
        id: "message.item_shared_successfully",
        defaultMessage: "Item shared successfully"
    },
    "message.loading_completed": {id: "message.loading_completed", defaultMessage: "Loading completed"},
    "message.loading_data": {id: "message.loading_data", defaultMessage: "Loading data"},
    "message.loading_data_item": {id: "message.loading_data_item", defaultMessage: "Loading data item"},
    "message.loading_item_completed": {id: "message.loading_item_completed", defaultMessage: "Loading item completed"},
    "message.loading_items": {id: "message.loading_items", defaultMessage: "Loading items"},
    "message.loading_new_item_data": {id: "message.loading_new_item_data", defaultMessage: "Loading new item data..."},
    "message.loading_results": {id: "message.loading_results", defaultMessage: "Loading results..."},
    "message.locking_unit": {id: "message.locking_unit", defaultMessage: "Locking unit..."},
    "message.locking_unit_error": {id: "message.locking_unit_error", defaultMessage: "Error locking unit"},
    "message.mechanical_configuration_form_not_valid": {
        id: "message.mechanical_configuration_form_not_valid",
        defaultMessage: "Mechanical configuration form is not valid, please check"
    },
    "message.no_result_found_check_thermal_configuration": {
        id: "message.no_result_found_check_thermal_configuration",
        defaultMessage: "No result found, please check thermal configuration and press calculate"
    },
    "message.operation_failed": {id: "message.operation_failed", defaultMessage: "Operation failed"},
    "message.operation_failed_try_later": {
        id: "message.operation_failed_try_later",
        defaultMessage: "Operation failed. Please retry later"
    },
    "message.operation_still_in_progress": {
        id: "message.operation_still_in_progress",
        defaultMessage: "This operation is still in progress"
    },
    "message.operation_success": {id: "message.operation_success", defaultMessage: "Operation success"},
    "message.pdf_is_ready": {id: "message.pdf_is_ready", defaultMessage: "PDF is ready"},
    "message.please_wait": {id: "message.please_wait", defaultMessage: "Please wait"},
    "message.please_wait_few_seconds": {
        id: "message.please_wait_few_seconds",
        defaultMessage: "Just a few seconds, please wait"
    },
    "message.please_wait_still_loading": {
        id: "message.please_wait_still_loading",
        defaultMessage: "Please wait, still loading"
    },
    "message.project_created": {id: "message.project_created", defaultMessage: "Project created"},
    "message.project_shared_successfully": {
        id: "message.project_shared_successfully",
        defaultMessage: "Project shared successfully"
    },
    "message.removing_share": {id: "message.removing_share", defaultMessage: "Removing share"},
    "message.request_canceled_by_the_user": {
        id: "request_canceled_by_the_user",
        defaultMessage: "Request canceled by the user"
    },
    "message.results_loaded": {id: "message.results_loaded", defaultMessage: "Results loaded"},
    "message.saved": {id: "message.saved", defaultMessage: "Saved"},
    "message.saving_completed": {id: "message.saving_completed", defaultMessage: "Saving completed"},
    "message.saving_data": {id: "message.saving_data", defaultMessage: "Saving data"},
    "message.saving_item_in_project": {id: "message.saving_item_in_project", defaultMessage: "Saving item in project"},
    "message.share_removed": {id: "message.share_removed", defaultMessage: "Share removed"},
    "message.still_loading_results_please_wait": {
        id: "message.still_loading_results_please_wait",
        defaultMessage: "Still loading results, please wait"
    },
    "message.switching_duty_mode": {id: "message.switching_duty_mode", defaultMessage: "Switching duty mode"},
    "message.thermal_configuration_form_not_valid": {
        id: "message.thermal_configuration_form_not_valid",
        defaultMessage: "Thermal configuration form is not valid, please check"
    },
    "message.unit_locked": {id: "message.unit_locked", defaultMessage: "Unit locked"},
    "message.unit_unlocked": {id: "message.unit_unlocked", defaultMessage: "Unit unlocked"},
    "message.unlocking_all_units": {id: "message.unlocking_all_units", defaultMessage: "Unlocking all unit..."},
    "message.unlocking_all_units_error": {
        id: "message.unlocking_all_units_error",
        defaultMessage: "Error unlocking all unit"
    },
    "message.unlocking_unit": {id: "message.unlocking_unit", defaultMessage: "Unlocking unit..."},
    "message.unlocking_unit_error": {id: "message.unlocking_unit_error", defaultMessage: "Error unlocking unit"},
    "message.updating_completed": {id: "message.updating_completed", defaultMessage: "Updating completed"},
    "message.updating_data": {id: "message.updating_data", defaultMessage: "Updating data"},
    "message.updating_item_name": {id: "message.updating_item_name", defaultMessage: "Updating item name"},
    "message.updating_the_project": {id: "message.updating_the_project", defaultMessage: "Updating the project"},
    "message.you_have_new_item": {id: "message.you_have_new_item", defaultMessage: "You have a new item!!!"},
    "message.item-active-in-another-tab": {
        id: "message.item-active-in-another-tab",
        defaultMessage: "This item is active in another tab. You will be redirected to the home page."
    },
});

// prettier-ignore
export const apiErrorsMessages = defineMessages({
    "api.error.message.400.get_results": {
        id: "api.error.message.400.get_results",
        defaultMessage: "Calculation returned an error. Try to change thermal data and press calculate."
    },
    "api.error.message.400.get_results.inpage": {
        id: "api.error.message.400.get_results.inpage",
        defaultMessage: "Calculation returned an error.<br>Try to change thermal data and press calculate."
    },
    "api.error.message.403.get_results": {
        id: "api.error.message.403.get_results",
        defaultMessage: "Core calculation returned too many results. Download the csv file to see all the results."
    },
    "api.error.message.403.get_results.inpage": {
        id: "api.error.message.403.get_results.inpage",
        defaultMessage: "Core calculation returned too many results.<br>Download the csv file to see all the results."
    },
    "api.error.message.404.get_results": {
        id: "api.error.message.404.get_results",
        defaultMessage: "No result available, press calculate."
    },
    "api.error.message.409.get_results": {
        id: "api.error.message.409.get_results",
        defaultMessage: "Error during server comunication. Please press Calculate again."
    },
    "api.error.message.500": {id: "api.error.message.500", defaultMessage: "Error 500: Internal Server Error"},
    "api.error.message.502": {
        id: "api.error.message.502",
        defaultMessage: "Error 502: Service unavailable. Please try again in a few minutes."
    },
    "api.error.message.502.inpage": {
        id: "api.error.message.502.inpage",
        defaultMessage: "Error 502: Service unavailable.<br>Please try again in a few minutes."
    },
    "api.error.message.503": {
        id: "api.error.message.503",
        defaultMessage: "Error 503: Service unavailable. Please try again in a few minutes."
    },
    "api.error.message.503.inpage": {
        id: "api.error.message.503.inpage",
        defaultMessage: "Error 503: Service unavailable.<br>Please try again in a few minutes."
    },
    "api.error.message.503.get_results": {
        id: "api.error.message.503.get_results",
        defaultMessage: "Calculation service unavailable. Please try again in a few minutes."
    },
    "api.error.message.503.get_results.inpage": {
        id: "api.error.message.503.get_results.inpage",
        defaultMessage: "Calculation service unavailable.<br>Please try again in a few minutes."
    },
    //"api.error.message.default": { id: "api.error.message.default", defaultMessage: "A generic error occurs." },
    "api.error.message.default": {id: "api.error.message.default", defaultMessage: "Operation failed, retry later"},
    "api.error.message.default.get_results": {
        id: "api.error.message.default.get_results",
        defaultMessage: "Calculation returned a generic error."
    },
    "api.error.message.400": {
        id: "api.error.message.400",
        defaultMessage: "The request cannot be satisfied due to errors."
    },
    "api.error.message.400.csv": {
        id: "api.error.message.400.csv",
        defaultMessage: "One or more items among those selected cannot be exported"
    },
    "api.error.message.account-already-activated": {
        id: "api.error.message.account-already-activated",
        defaultMessage: "Account already activated"
    },
    "api.error.message.account-deactivated": {
        id: "api.error.message.account-deactivated",
        defaultMessage: "The account has been deactivated, contact the administrators"
    },
    "api.error.message.account-not-confirmed": {
        id: "api.error.message.account-not-confirmed",
        defaultMessage: "User has not confirmed registration"
    },
    // "api.error.message.conc-failure": { id: "", defaultMessage: "" },
    // "api.error.message.constraint-violation": { id: "", defaultMessage: "" },
    // "api.error.message.core-calculation-error": { id: "", defaultMessage: "" },
    // "api.error.message.core-calculation-invocation-error": { id: "", defaultMessage: "" },
    "api.error.message.email-already-used": {
        id: "api.error.message.email-already-used",
        defaultMessage: "Email is already in use!"
    },
    "api.error.message.email-not-found": {
        id: "api.error.message.email-not-found",
        defaultMessage: "Email address not registered"
    },
    // "api.error.message.entity-not-found": { id: "", defaultMessage: "" },
    "api.error.message.incorrect-password": {
        id: "api.error.message.incorrect-password",
        defaultMessage: "The old password is wrong"
    },
    "api.error.message.login-already-used": {
        id: "api.error.message.login-already-used",
        defaultMessage: "Login name already used!"
    },
    "api.error.message.missing-capability": {
        id: "api.error.message.missing-capability",
        defaultMessage: "Missing necessary permissions to perform this operation"
    },
    // "api.error.message.no-results-in-item": { id: "", defaultMessage: "" },
    // "api.error.message.parameterized": { id: "", defaultMessage: "" },
    // "api.error.message.problem-with-message": { id: "", defaultMessage: "" },
    "api.error.message.same-email": {
        id: "api.error.message.same-email",
        defaultMessage: "The provided email is the same as the current email"
    },
    // "api.error.message.too-many-results": { id: "", defaultMessage: "" },
    "api.error.message.weak-password": {
        id: "api.error.message.weak-password",
        defaultMessage: "The password is too weak"
    }
});

// prettier-ignore
export const noValueMessages = defineMessages({
    noValue: {id: "noValue", defaultMessage: "-"},
    "noValue.tubePattern": {id: "noValue.tubePattern", defaultMessage: "Not selected"},
    "noValue.connectionBoxType": {id: "noValue.connectionBoxType", defaultMessage: "Not selected"},
});
