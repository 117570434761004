import { Dispatch } from "redux";
import {
  cancelAjaxCall,
  createAjaxToken,
  destroyAjaxToken,
  getAxiosCancelToken,
} from "../../lib/ajax-token";
import { FAILURE, REQUEST, SUCCESS } from "../../lib/asyncActionHelper";
import { languageApi } from "../account/actions";
import { IStore } from "../types";

export const LOAD_TRANSLATIONS = "translations/LOAD_TRANSLATIONS";

export const loadTranslations = () => {
  return (dispatch: Dispatch, getState: () => IStore) => {
    cancelAjaxCall(getState().translations.ajaxCancelToken, "cancel");
    const ajaxCancelToken = createAjaxToken();
    dispatch({ type: REQUEST(LOAD_TRANSLATIONS), payload: ajaxCancelToken });
    languageApi
      .getLabelTranslations({
        cancelToken: getAxiosCancelToken(ajaxCancelToken),
      })
      .then((res) => {
        dispatch({
          type: SUCCESS(LOAD_TRANSLATIONS),
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: FAILURE(LOAD_TRANSLATIONS),
          payload: ajaxCancelToken,
          error: e.message,
        });
      })
      .finally(() => {
        destroyAjaxToken(ajaxCancelToken);
      });
  };
};
