import { Drawer } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ConfiguratorSection } from "../../../../types/configurator";
import "./SidePanel.scss";
import SidePanelContent from "./SidePanelContent";
import { Callback } from "../../../../types/Callback";

interface IProps {
  sidePanelView?: ConfiguratorSection;
  toggleSidePanel: Callback;
  visible: boolean;
}

export const SidePanel: React.FC<IProps> = ({
  visible,
  toggleSidePanel,
  sidePanelView = ConfiguratorSection.THERMAL,
}) => {
  const title =
    sidePanelView === ConfiguratorSection.MECHANICAL ? (
      <FormattedMessage
        id="mechanicalConfigurationSidebarTitle"
        defaultMessage="Mechanical Configuration"
      />
    ) : sidePanelView === ConfiguratorSection.THERMAL ? (
      <FormattedMessage
        id="thermalConfigurationSidebarTitle"
        defaultMessage="Thermal Configuration"
      />
    ) : null;
  return (
    <Drawer
      className="SidePanel"
      closable={true}
      onClose={() => toggleSidePanel()}
      visible={visible}
      placement="right"
      title={<h3 className="SidePanel-title">{title}</h3>}
      width={400}
    >
      <div className="indrawer">
        <SidePanelContent />
      </div>
    </Drawer>
  );
};
