import { Item } from "../../../generated/axios";
import { Any } from "../../../types/Any";
import { LOGOUT } from "../../auth/consts";
import { FAILURE, REQUEST, SUCCESS } from "../../lib/asyncActionHelper";
import { GET_ITEM, GET_ITEM_PROJECT } from "../item/consts";
import { ON_SHARED_ITEM } from "../share/consts";
import * as types from "./consts";
import {
  GET_ITEMS_TECHNICAL_SPECIFICATIONS_PDF,
  UPDATE_CUSTOMER_REFERENCE,
  UPDATE_ITEM_POSITION,
  UPDATE_ITEM_QUANTITY,
  UPDATE_ITEM_VERSION,
} from "./consts";
import { ProjectState } from "./types";

const initialState: ProjectState = {
  calculating: false,
};

export default (
  state: ProjectState = initialState,
  action: Any
): ProjectState => {
  switch (action.type) {
    case SUCCESS(types.GET_PROJECT):
      return { ...action.payload };
    case SUCCESS(types.NEW_PROJECT):
      return { ...action.payload };
    case SUCCESS(GET_ITEM):
    case SUCCESS(GET_ITEM_PROJECT):
      // after fetching an item, update it with the project to which the item belongs to
      return { ...state, ...action.payload.project };

    case SUCCESS(types.DELETE_PROJECT):
      return { ...action.payload };
    case SUCCESS(types.GET_PROJECT_DETAILS):
      return { ...action.payload };

    case SUCCESS(types.UPDATE_PROJECT):
      return { ...action.payload };

    case SUCCESS(GET_ITEMS_TECHNICAL_SPECIFICATIONS_PDF):
      return {
        ...state,
        ...action.payload,
        calculating: false,
      };

    case REQUEST(UPDATE_ITEM_POSITION):
    case REQUEST(UPDATE_CUSTOMER_REFERENCE):
    case REQUEST(UPDATE_ITEM_QUANTITY):
    case REQUEST(UPDATE_ITEM_VERSION):
      return {
        ...state,
        items: state.items?.map((item) => {
          return item.id === action.payload.id
            ? {
                ...item,
                [action.type !== REQUEST(UPDATE_CUSTOMER_REFERENCE)
                  ? "updating"
                  : "updatingCR"]: true,
              }
            : item;
        }),
      };
    case SUCCESS(UPDATE_ITEM_POSITION):
    case SUCCESS(UPDATE_CUSTOMER_REFERENCE):
    case SUCCESS(UPDATE_ITEM_QUANTITY):
    case SUCCESS(UPDATE_ITEM_VERSION):
      return {
        ...state,
        items: state.items?.map((item) => {
          return item.id === action.payload.id
            ? {
                ...item,
                ...action.payload,
                [action.type !== SUCCESS(UPDATE_CUSTOMER_REFERENCE)
                  ? "updating"
                  : "updatingCR"]: undefined,
              }
            : item;
        }),
      };
    case FAILURE(UPDATE_ITEM_POSITION):
    case FAILURE(UPDATE_CUSTOMER_REFERENCE):
    case FAILURE(UPDATE_ITEM_QUANTITY):
    case FAILURE(UPDATE_ITEM_VERSION):
      return {
        ...state,
        items: state.items?.map((item) => {
          return item.id === action.payload.id
            ? {
                ...item,
                [action.type !== FAILURE(UPDATE_CUSTOMER_REFERENCE)
                  ? "updating"
                  : "updatingCR"]: undefined,
              }
            : item;
        }),
      };

    case ON_SHARED_ITEM:
      return {
        ...state,
        items: state.items
          ? state.items.reduce((acc: Item[], item: Item) => {
              return item.id == action.payload
                ? [...acc, { ...item, shared: true }]
                : [...acc, item];
            }, [])
          : [],
      };
    case REQUEST(GET_ITEMS_TECHNICAL_SPECIFICATIONS_PDF):
      return {
        ...state,
        calculating: true,
      };

    case FAILURE(types.NEW_PROJECT):
      // apiErrorHandler return payload: errore intercettato nella catch
      return { ...state, error: action.payload };

    case FAILURE(types.DELETE_PROJECT):
      return { ...state, error: action.payload };

    case FAILURE(types.DUPLICATE_PROJECT):
      return { ...state, error: action.payload };

    case FAILURE(types.UPDATE_PROJECT):
      return { ...state, error: action.payload };

    case FAILURE(types.GET_PROJECT_DETAILS):
      return {
        error: action.payload,
        calculating: false,
      };
    case FAILURE(GET_ITEMS_TECHNICAL_SPECIFICATIONS_PDF):
      return {
        ...state,
        calculating: false,
        error: action.payload,
      };

    case "RESET_PROJECT_REDUCER":
      return initialState;
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
