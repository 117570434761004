import { createSelector } from "reselect";
import { Portfolio } from "../../../generated/axios";
import { itemPortfolioIdSelector } from "../item/selectors";
import { IStore } from "../types";

export const selectedPortfolioSelector = (state: IStore) =>
  state.portfolio.selectedPortfolioId;

export const allPortfoliosSelector = (state: IStore) =>
  state.portfolio.allPortfolios;

export const availablePortfoliosSelector = createSelector(
  allPortfoliosSelector,
  (allPortfolios) =>
    allPortfolios.filter((portfolio: Portfolio) => !!portfolio.available)
);

export const portfolioSelector = createSelector(
  allPortfoliosSelector,
  itemPortfolioIdSelector,
  (list, id) => {
    return list.find((portfolio) => portfolio.id === id);
  }
);

export const portfolioBySelectedIdSelector = createSelector(
  allPortfoliosSelector,
  selectedPortfolioSelector,
  (list, id) => {
    return list.find((portfolio) => portfolio.id === id);
  }
);
