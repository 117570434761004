import { Col, Row, Select } from "antd";
import _ from "lodash";
import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import {
  Country,
  EmailReset,
  PasswordChange,
  Problem,
  Subdivision,
  User,
} from "../../generated/axios";
import {
  cleanResponses,
  getCountries,
  getSubdivisions,
  passwordChange,
  requestEmailReset,
  saveAccount,
} from "../../shared/store/account/actions";
import { IStore } from "../../shared/store/types";
import { Callback } from "../../types/Callback";
import BackButton from "../configurator/components/BackButton";
import ConfirmModal, {
  ConfirmModalProps,
} from "../configurator/modals/ConfirmModal";
import ChangeEmailForm from "./ChangeEmailForm";
import ChangePasswordForm from "./ChangePasswordForm";
import PersonalDataForm from "./PersonalDataForm";
import "./viewProfile.scss";

const Heading = memo(() => (
  <Row className="item-selection__title gutter-bug" gutter={16}>
    <Col span={1} offset={1}>
      <BackButton />
    </Col>
    <Col span={22}>
      <div className="personal-settings__title">
        <FormattedMessage
          id="personal settings"
          defaultMessage="Personal settings"
        />
      </div>
    </Col>
  </Row>
));

export const Option = Select.Option;

enum MODAL_TYPE {
  INITIAL_EMPTY,
  CHANGE_EMAIL_QUESTION,
  CHANGE_PSW_QUESTION,
}

interface DispatchProps {
  cleanResponses: Callback;
  getCountries: () => Promise<void>;
  getSubdivisions: (countryId?: number) => Promise<void>;
  passwordChange: (passwordChange: PasswordChange) => void;
  requestEmailReset: (emailResetRequest: EmailReset) => void;
  saveAccount: (user: User) => void;
}

interface ReduxProps {
  // Account
  user: User;
  countries: Country[];
  subdivisions: Subdivision[];
  // Password
  passwordChangeError?: Problem;
  passwordChangeResponse?: Response;
  // E-mail
  requestEmailResetError?: Problem;
  requestEmailResetResponse?: Response;
}

interface ModalState extends ConfirmModalProps {
  changeEmailRequest?: EmailReset;
  changePswRequest?: PasswordChange;
  modalType?: MODAL_TYPE;
}

export interface Props extends ReduxProps, DispatchProps {}

class ViewProfile extends React.Component<Props, ModalState> {
  state: ModalState = {
    buttonCancelHidden: false,
    content: "",
    hideCancelButton: false,
    modalVisibile: false,
    okText: <FormattedMessage id="ok" defaultMessage="Ok" />,
    onCancel: undefined,
    onOk: undefined,
    title: <FormattedMessage id="confirm" defaultMessage="Confirm" />,

    changeEmailRequest: undefined,
    changePswRequest: undefined,
    modalType: MODAL_TYPE.INITIAL_EMPTY,
  };

  // Call to API /account with POST method, with data received from child Component
  updateUser = (updatedUser: User) => {
    this.props.saveAccount(updatedUser);
  };

  openChangeEMailModal = (changeEmailRequest: EmailReset) => {
    // if API has already been called show the message returned (both with success or error respond)
    if (!_.isEmpty(this.props.requestEmailResetError)) {
      this.setState(() => ({
        buttonCancelHidden: false,
        modalVisibile: true,
        okText: <FormattedMessage id="ok" defaultMessage="Ok" />,
        onCancel: this.closeModal,
        onOk: this.updateEmail,
        title: <FormattedMessage id="confirm" defaultMessage="Confirm" />,

        changeEmailRequest: changeEmailRequest,
        modalType: MODAL_TYPE.CHANGE_EMAIL_QUESTION,
      }));
    } else {
      this.setState(() => ({
        buttonCancelHidden: false,
        modalVisibile: true,
        onOk: this.updateEmail,
        title: <FormattedMessage id="error" defaultMessage="Error" />,

        changeEmailRequest: changeEmailRequest,
        modalType: MODAL_TYPE.CHANGE_EMAIL_QUESTION,
      }));
    }
  };

  updateEmail = () => {
    if (!_.isEmpty(this.state.changeEmailRequest)) {
      this.props.requestEmailReset(this.state.changeEmailRequest);
    }
  };

  openChangePswModal = (changePswRequest: PasswordChange) => {
    // if API has already been called show the message returned (both with success or error respond)
    const buttonCancelHidden = !_.isEmpty(this.props.passwordChangeError);
    this.setState(() => ({
      buttonCancelHidden,
      modalVisibile: true,
      okText: <FormattedMessage id="ok" defaultMessage="Ok" />,
      onCancel: this.closeModal,
      onOk: this.updatePsw,
      title: <FormattedMessage id="confirm" defaultMessage="Confirm" />,

      changePswRequest: changePswRequest,
      modalType: MODAL_TYPE.CHANGE_PSW_QUESTION,
    }));
  };

  updatePsw = () => {
    if (!_.isEmpty(this.state.changePswRequest)) {
      this.props.passwordChange(this.state.changePswRequest);
    }
  };

  closeModal = () => {
    // close modal and reset its values (so they aren't visualized next times, there are recalculated based on what kind of modal it's being opening )
    this.props.cleanResponses();

    this.setState({
      buttonCancelHidden: false,
      modalVisibile: false,
      okText: <FormattedMessage id="ok" defaultMessage="Ok" />,
      onCancel: undefined,
      onOk: undefined,
      title: <FormattedMessage id="confirm" defaultMessage="Confirm" />,

      changeEmailRequest: undefined,
      changePswRequest: undefined,
      modalType: MODAL_TYPE.INITIAL_EMPTY,
    });
  };

  /** One unique modal with dynamic title/content/buttons inside it, could be one of these cases:
   *  1) Confirm question (change email / change psw)
   *  2) Request successfully handled (change email / change psw)
   *  3) Error response --> the content will be the server error, eventually managed & formatted based on specific error's status code and message (i.e.: password incorrect)
   * */
  getModalProps = (): ConfirmModalProps => {
    const error: boolean =
      !_.isEmpty(this.props.passwordChangeError) ||
      !_.isEmpty(this.props.requestEmailResetError);
    const success: boolean =
      !_.isUndefined(this.props.requestEmailResetResponse) ||
      !_.isUndefined(this.props.passwordChangeResponse);
    const { modalType } = this.state;

    return {
      buttonCancelHidden: error || success,
      content: this.getContent(error),
      hideCancelButton: error,
      modalVisibile: this.state.modalVisibile,
      okText: error ? (
        <FormattedMessage id="retry" defaultMessage="Retry" />
      ) : (
        <FormattedMessage id="ok" defaultMessage="Ok" />
      ),
      onCancel: this.closeModal,
      onOk:
        error || success
          ? this.closeModal
          : modalType === MODAL_TYPE.CHANGE_PSW_QUESTION
          ? this.updatePsw
          : this.updateEmail,
      title: error ? (
        <FormattedMessage id="error" defaultMessage="Error" />
      ) : (
        <FormattedMessage id="confirm" defaultMessage="Confirm" />
      ),
    };
  };

  // on Country selection, update the Subdivisions (Cities)
  handleSelectCountry = (countryId: number) => {
    this.props.getSubdivisions(countryId).finally();
  };

  async componentDidMount() {
    await this.props.getCountries();
    if (this.props.user.countryId) {
      this.props.getSubdivisions(this.props.user.countryId).finally();
    }
  }

  getContent = (error: boolean) => {
    const { modalType } = this.state;
    const successEMailResponse = !_.isUndefined(
      this.props.requestEmailResetResponse
    );
    const successPswResponse = !_.isUndefined(
      this.props.passwordChangeResponse
    );

    if (error) {
      return ""; // content removed - error message managed by ConfirmModal/AccountException components
    } else if (successEMailResponse) {
      return (
        <FormattedMessage
          id="change email ok"
          defaultMessage="Change email request processed, an e-mail was sent to you"
        />
      );
    } else if (successPswResponse) {
      return (
        <FormattedMessage
          id="change psw ok"
          defaultMessage="Change password request processed"
        />
      );
    } else if (modalType === MODAL_TYPE.CHANGE_EMAIL_QUESTION) {
      return (
        <FormattedMessage
          id="change email warning"
          defaultMessage="Changing email address will change your username. You will receive an email with a link to confirm your action. After that you must use new email address to authenticate to Plair"
        />
      );
    } else if (modalType === MODAL_TYPE.CHANGE_PSW_QUESTION) {
      return (
        <FormattedMessage
          id="confirmation question"
          defaultMessage="Are you sure you want to change your password?"
        />
      );
    }
    return ""; // fallback
  };

  render() {
    return (
      <div className="personal-settings">
        <Heading />

        {/* Personal Data - first row */}
        <PersonalDataForm
          user={this.props.user}
          countries={this.props.countries}
          subdivisions={this.props.subdivisions}
          updateUser={this.updateUser}
          handleSelectCountry={this.handleSelectCountry}
        />

        {/* Change email - second row */}
        <ChangeEmailForm openChangeEMailModal={this.openChangeEMailModal} />

        {/* Change Password - third row */}
        <ChangePasswordForm openChangePswModal={this.openChangePswModal} />

        {/* Same Modal for Change E-mail & change password */}
        <ConfirmModal {...this.getModalProps()} />
      </div>
    );
  }
}

const mapStateToProps = (state: IStore): ReduxProps => ({
  // Account
  user: state.account.account,
  countries: state.account.countries,
  subdivisions: state.account.subdivisions,
  // password
  passwordChangeError: state.account.passwordChangeError,
  passwordChangeResponse: state.account.passwordChangeResponse,
  // e-mail
  requestEmailResetError: state.account.requestEmailResetError,
  requestEmailResetResponse: state.account.requestEmailResetResponse,
});

const mapDispatchToProps = {
  cleanResponses,
  getCountries,
  getSubdivisions,
  passwordChange,
  requestEmailReset,
  saveAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewProfile);
