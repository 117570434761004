import { message } from "antd";
import { History } from "history";
import { get } from "lodash";
import { Dispatch } from "redux";
import { Any } from "types/Any";
import { durations } from "../../../config/defaults";
import { createIterator } from "../../../config/messageGenerators";
import {
  itemConfiguratorDynamicPath,
  projectDetailsDynamicPath,
} from "../../../config/paths";
import {
  DutyMode,
  Item,
  ItemApi,
  ItemSettingsApi,
  ProjectApi,
  TechnicalSpecificationConfig,
} from "../../../generated/axios";
import Action from "../../../types/action";
import { ConfiguratorSection } from "../../../types/configurator";
import Response from "../../../types/response";
import { apiConfig } from "../../api";
import { eIntl } from "../../eIntl";
import apiErrorHandler from "../../lib/apiErrorHandler";
import { FAILURE, REQUEST, SUCCESS } from "../../lib/asyncActionHelper";
import { messages } from "../../lib/locales/definedMessages";
import MessageRunner from "../../lib/messageRunner";
import { errorMessenger } from "../../lib/messenger";
import { LOAD } from "../configurator/consts";
import { getApiFunctions, loadItem } from "../configurator/lib";
import * as types from "../project/consts";
import { GET_PROJECT_DETAILS, NEW_PROJECT } from "../project/consts";
import { IStore } from "../types";
import { closeSettingsModal } from "../ui/actions";
import { CLOSE_MODAL_FORM } from "../ui/consts";
import { CLEAR_TEMP_PROJECT_ID } from "../utils/consts";
import {
  CLEAR_ITEM,
  DELETE_ITEM,
  DUPLICATE_ITEM,
  GET_ITEM,
  GET_ITEM_PROJECT,
  GET_TECHNICAL_SPECIFICATION_CONFIG,
  GUIDED_SELLING,
  NEW_ITEM,
  SAVE_ITEM,
  SET_ITEM,
  SET_TECHNICAL_SPECIFICATION_CONFIG,
} from "./consts";
import { NewItemParams } from "./types";

const itemApi = new ItemApi(apiConfig());

const projectApi = new ProjectApi(apiConfig());

const itemSettingsApi = new ItemSettingsApi(apiConfig());

const closeModal = (dispatch: Dispatch) => {
  dispatch({ type: CLOSE_MODAL_FORM });
};

const saveItemIntoProject =
  (itemId: number, projectId: number) => (dispatch: Dispatch) => {
    message.loading(
      eIntl.formatMessage(messages["message.saving_item_in_project"]),
      durations.loading
    );
    itemApi
      .updateItemInfo(itemId, { project: { id: projectId } })
      .then((res: { data: Any }) => {
        dispatch({
          type: SUCCESS(SAVE_ITEM),
          payload: res.data,
        });
        message.success(
          eIntl.formatMessage(messages["message.saving_completed"]),
          durations.success
        );
        closeModal(dispatch);
      })
      .catch(apiErrorHandler({ dispatch, actionType: SAVE_ITEM }));
  };

const refreshProjectDetails = (id: number, dispatch: Dispatch) => {
  dispatch({
    type: GET_PROJECT_DETAILS,
    payload: projectApi.getProjectDetails(id).then((res) => res.data),
  });
};

export const getItem =
  (itemId: number) => (dispatch: Dispatch, getState: () => IStore) => {
    // I need to check if state.item has results, see https://intesys.atlassian.net/browse/ALFAPS-1022 and NOTE in ConfiguratorRouter
    const hasResults = get(getState().item, "results.results");
    if (hasResults) return;
    itemApi
      .getItemDetails(itemId)
      .then((res: Response<Item>) => {
        dispatch({
          type: SUCCESS(GET_ITEM),
          payload: res.data,
        });
      })
      .catch(
        apiErrorHandler({ dispatch, actionType: GET_ITEM, hasTimeout: false })
      );
  };

export const getItemProject = (itemId: number) => (dispatch: Dispatch) => {
  // Aggiorna solo la porzione di stato dell'item che contiene le informazioni sul progetto.
  itemApi
    .getItemDetails(itemId)
    .then((res: Response<Item>) => {
      dispatch({
        type: SUCCESS(GET_ITEM_PROJECT),
        payload: res.data,
      });
    })
    .catch(
      apiErrorHandler({
        dispatch,
        actionType: GET_ITEM_PROJECT,
        hasTimeout: false,
      })
    );
};

/**
 * it uses apiErrorHandler
 * create a new item
 */
export const createNewItem =
  ({ applicationTypeId, projectId, push }: NewItemParams) =>
  (dispatch: Dispatch, getState: () => IStore) => {
    MessageRunner.init(createIterator(NEW_ITEM));
    MessageRunner.start();

    // read from store
    const portfolioId = getState().portfolio.selectedPortfolioId;

    itemApi
      .createItem(applicationTypeId, projectId, portfolioId)
      .then((res: Response<Item>) => {
        MessageRunner.onSuccess({
          content: messages["message.you_have_new_item"],
        });
        dispatch({
          type: SUCCESS(NEW_ITEM),
          payload: res.data,
        });
        push?.(`/configurator/${res.data.id}/thermal`);
        return res.data;
      })
      .catch(
        apiErrorHandler({
          dispatch,
          actionType: NEW_ITEM,
          useMessageRunner: true,
        })
      )
      .finally(() => {
        dispatch({
          type: CLEAR_TEMP_PROJECT_ID,
        });
      });
  };

export const setItem =
  (dispatch: Dispatch) => async (itemId: number, newItemData: Item) => {
    dispatch({
      type: REQUEST(SET_ITEM),
    });
    return itemApi
      .updateItemConfiguration(itemId, newItemData)
      .then(({ data }) => {
        dispatch({
          type: SUCCESS(SET_ITEM),
          payload: data,
        });
        message.success(
          eIntl.formatMessage(messages["message.saved"]),
          durations.success
        );
        return true;
      })
      .catch((error) => {
        dispatch({
          type: FAILURE(SET_ITEM),
          payload: error,
        });
        message.error(
          eIntl.formatMessage(messages["message.error_saving_item"]),
          durations.error
        );
        return false;
      });
  };

/**
 * it uses apiErrorhandler
 */
export const toggleDutyMode =
  () => (dispatch: Dispatch, getState: () => IStore) => {
    const id = getState().item.id;
    if (!id) return;
    const dutyMode = getState().item.dutyMode;
    const updatedDutyMode =
      dutyMode === DutyMode.DESIGN ? DutyMode.RATING : DutyMode.DESIGN;

    const itemData = {
      thermalData: {},
      mechanicalData: {},
      dutyMode: updatedDutyMode,
    };

    message.info(
      eIntl.formatMessage(messages["message.switching_duty_mode"]),
      durations.info
    );

    dispatch({
      type: REQUEST(SET_ITEM),
    });
    itemApi
      .updateItemConfiguration(id, itemData)
      .then((res: { data: Any }) => {
        dispatch({
          type: SUCCESS(SET_ITEM),
          payload: res.data,
        });
      })
      .catch(apiErrorHandler({ dispatch, actionType: SET_ITEM }));
  };

/**
 * it uses apiErrorHandler
 *
 * @param projectId
 * @param projectName
 */
export const saveItem =
  (projectId?: number, projectName?: string) =>
  (dispatch: Dispatch, getState: () => IStore) => {
    const item = getState().ui.modalForm.meta.item;
    const itemId = item?.id;
    if (!itemId || (!projectId && !projectName)) {
      message.info(
        eIntl.formatMessage(messages["message.invalid_data"]),
        durations.info
      );
      return;
    }

    if (projectId) {
      saveItemIntoProject(itemId, projectId)(dispatch);
    } else if (projectName) {
      message.loading(
        eIntl.formatMessage(messages["message.creating_the_project"]),
        durations.loading
      );
      projectApi
        .createProject({ title: projectName })
        .then((response) => {
          const project = response.data;
          dispatch({
            type: SUCCESS(NEW_PROJECT),
            payload: project,
          });
          saveItemIntoProject(itemId, project.id as number)(dispatch);
        })
        .catch(apiErrorHandler({ dispatch, actionType: NEW_PROJECT }));
    }
  };

/**
 * it uses apiErrorHandler
 *
 * delete item, see modal form
 * TODO: refresh data in project details page
 * WARNING: the list of projects in the page comes from item.project.items
 */
export const deleteItem =
  (push?: History["push"]) => (dispatch: Dispatch, getState: () => IStore) => {
    const { item, refresh } = getState().ui.modalForm.meta;
    const id = item?.id;
    const projectId = item?.project?.id;
    if (!id) return;

    message.loading(
      eIntl.formatMessage(messages["message.deleting_item"]),
      durations.loading
    );
    itemApi
      .deleteItem(id)
      .then((res: { data: Any }) => {
        message.success(
          eIntl.formatMessage(messages["message.delete_completed"]),

          durations.success
        );
        dispatch({
          type: SUCCESS(DELETE_ITEM),
          payload: res.data,
        });
        // TODO: extract next 4 lines to be used in catch too
        if (refresh && projectId) {
          refreshProjectDetails(projectId, dispatch);
        }
      })
      .catch(apiErrorHandler({ dispatch, actionType: DELETE_ITEM }))
      .finally(() => {
        closeModal(dispatch);
        if (push && projectId) push(projectDetailsDynamicPath(projectId));
      });
  };

/**
 * clear item, see modal form
 */
export const clearItem = () => (dispatch: Dispatch, getState: () => IStore) => {
  const { currentSection } = getState().configurator;
  const { item } = getState().ui.modalForm.meta;
  const id = item?.id;
  if (!id) return;

  message.loading(
    eIntl.formatMessage(messages["message.clearing_item"]),
    durations.loading
  );
  itemApi
    .resetItemData(id)
    .then((res: { data: Any }) => {
      message.success(
        eIntl.formatMessage(messages["message.clear_completed"]),
        durations.success
      );
      /* TODO: need to update configurator too */

      const state = getState();
      const apiFunctions = getApiFunctions(currentSection);
      const item: Item = res.data;

      dispatch({
        type: LOAD,
        payload: loadItem(
          id,
          currentSection,
          item,
          apiFunctions,
          state,
          dispatch,
          LOAD
        ),
      });

      dispatch({
        type: SUCCESS(CLEAR_ITEM),
        payload: item,
      });
    })
    .catch(apiErrorHandler({ dispatch, actionType: CLEAR_ITEM }))
    .finally(() => {
      dispatch({
        type: CLOSE_MODAL_FORM,
      });
    });
};

/**
 * it uses apiErrorHandler
 * duplicate item, see modal form
 */
export const duplicateItem =
  () => (dispatch: Dispatch, getState: () => IStore) => {
    const { item } = getState().ui.modalForm.meta;
    const id = item?.id;
    if (!id) return;

    message.loading(
      eIntl.formatMessage(messages["message.duplicating_item"]),
      durations.loading
    );
    // start the journey ...
    itemApi
      .cloneItem(id)
      .then((res: { data: Any }) => {
        message.success(
          eIntl.formatMessage(messages["message.duplicate_completed"]),
          durations.success
        );
        // tell the system that the duplication is done
        dispatch({
          type: SUCCESS(DUPLICATE_ITEM),
          payload: res.data,
        });
        const url = itemConfiguratorDynamicPath(
          res.data.id,
          ConfiguratorSection.THERMAL
        );
        const win = window.open(url, "_blank") as Window;
        win.focus();
      })
      .catch(apiErrorHandler({ dispatch, actionType: DUPLICATE_ITEM }))
      .finally(() => closeModal(dispatch));
  };

/**
 * it uses apiErrorhandler
 *
 * // TODO: export a type, this is like the one in project.actions
 * add note, see modal form
 */
interface AddNoteData {
  text: string;
}

export const addNoteToItem =
  (data: AddNoteData) => (dispatch: Dispatch, getState: () => IStore) => {
    const id = getState().item.id ?? 0;
    message.loading(
      eIntl.formatMessage(messages["message.adding_the_note"]),
      durations.loading
    );
    itemApi
      .updateItemInfo(id, { note: data.text })
      .then((res: { data: Any }) => {
        message.success(
          eIntl.formatMessage(messages["message.operation_success"]),
          durations.success
        );
        dispatch({
          type: SUCCESS(SAVE_ITEM),
          payload: res.data,
        });
      })
      .catch(apiErrorHandler({ dispatch, actionType: SAVE_ITEM }))
      .finally(() => {
        dispatch({
          type: CLOSE_MODAL_FORM,
        });
      });
  };

/**
 * TODO: needs to be a thunk to use apiErrorHandler
 */
// read Item config to fill the form
// GET --> API '/items/{itemId}/technical-specifications/settings'
export const getItemTechnicalSpecificationSettings = (
  itemId: number
): Action<Any> => ({
  type: GET_TECHNICAL_SPECIFICATION_CONFIG,
  payload: itemSettingsApi
    .getItemTechnicalSpecificationSettings(itemId)
    .then((res) => res.data),
});

/**
 * Once clicked on 'Print', update config with an API POST to /items/{itemId}/technical-specifications/settings
 * Then if ok, if passed 3rd param to true then obtain the pdf calling API GET to /items/{itemId}/technical-specifications
 */
export const updateItemTechnicalSpecificationSettings =
  (
    itemId: number,
    technicalSpecificationConfig: TechnicalSpecificationConfig
  ) =>
  (dispatch: Dispatch) => {
    itemSettingsApi
      .updateItemTechnicalSpecificationSettings(
        itemId,
        technicalSpecificationConfig
      )
      .then((res) => {
        dispatch({
          type: SET_TECHNICAL_SPECIFICATION_CONFIG,
          payload: technicalSpecificationConfig,
        });
        dispatch(closeSettingsModal());
        return res.data;
      })
      .catch(
        apiErrorHandler({
          dispatch,
          actionType: SET_TECHNICAL_SPECIFICATION_CONFIG,
        })
      );
  };

export const updateItemLabel =
  (label: string) => (dispatch: Dispatch, getState: () => IStore) => {
    const oldItem = getState().item;
    const item = { ...oldItem, label };
    dispatch({
      type: SUCCESS(SAVE_ITEM),
      payload: item,
    });

    message.loading(
      eIntl.formatMessage(messages["message.updating_item_name"]),
      durations.loading
    );

    itemApi
      .updateItemInfo(item.id ?? 0, { label })
      .then((res: { data: Any }) => {
        message.success(
          eIntl.formatMessage(messages["message.operation_success"]),
          durations.success
        );
        dispatch({
          type: SUCCESS(SAVE_ITEM),
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SUCCESS(SAVE_ITEM),
          payload: oldItem,
        });
        apiErrorHandler({ actionType: SAVE_ITEM })(error);
      });
  };

export const updateItemPosition =
  (id: number, position: string) => (dispatch: Dispatch) => {
    dispatch({
      type: REQUEST(types.UPDATE_ITEM_POSITION),
      payload: { id, position },
    });
    itemApi
      .updateItemInfo(id, { position })
      .then((response) => {
        dispatch({
          type: SUCCESS(types.UPDATE_ITEM_POSITION),
          payload: { id, position: response.data.position },
        });
      })
      .catch((e) => {
        if (e.response) {
          const { data, statusText, status } = e.response;
          const message = data?.type && data.title ? data.title : statusText;
          errorMessenger(status, message);
        }
        dispatch({
          type: FAILURE(types.UPDATE_ITEM_POSITION),
          payload: { id },
        });
      });
  };

export const updateCustomerReference =
  (id: number, customerReference: string) => (dispatch: Dispatch) => {
    dispatch({
      type: REQUEST(types.UPDATE_CUSTOMER_REFERENCE),
      payload: { id, customerReference },
    });
    itemApi
      .updateItemInfo(id, { customerReference })
      .then((response) => {
        dispatch({
          type: SUCCESS(types.UPDATE_CUSTOMER_REFERENCE),
          payload: { id, customerReference: response.data.customerReference },
        });
      })
      .catch((e) => {
        if (e.response) {
          const { data, statusText, status } = e.response;
          const message = data?.type && data.title ? data.title : statusText;
          errorMessenger(status, message);
        }
        dispatch({
          type: FAILURE(types.UPDATE_CUSTOMER_REFERENCE),
          payload: { id },
        });
      });
  };

export const updateItemQuantity =
  (id: number, quantity: number) => (dispatch: Dispatch) => {
    dispatch({
      type: REQUEST(types.UPDATE_ITEM_QUANTITY),
      payload: { id, quantity },
    });
    itemApi
      .updateItemInfo(id, { quantity })
      .then((response) => {
        dispatch({
          type: SUCCESS(types.UPDATE_ITEM_QUANTITY),
          payload: { id, quantity: response.data.quantity },
        });
      })
      .catch((e) => {
        if (e.response) {
          const { data, statusText, status } = e.response;
          const message = data?.type && data.title ? data.title : statusText;
          errorMessenger(status, message);
        }
        dispatch({
          type: FAILURE(types.UPDATE_ITEM_QUANTITY),
          payload: { id },
        });
      });
  };

export const updateItemVersion =
  (id: number, version: string) => (dispatch: Dispatch) => {
    dispatch({
      type: REQUEST(types.UPDATE_ITEM_VERSION),
      payload: { id, version },
    });
    itemApi
      .updateItemInfo(id, { version })
      .then((response) => {
        dispatch({
          type: SUCCESS(types.UPDATE_ITEM_VERSION),
          payload: { id, version: response.data.version },
        });
      })
      .catch((e) => {
        if (e.response) {
          const { data, statusText, status } = e.response;
          const message = data?.type && data.title ? data.title : statusText;
          errorMessenger(status, message);
        }
        dispatch({
          type: FAILURE(types.UPDATE_ITEM_VERSION),
          payload: { id },
        });
      });
  };

export const guidedSelling =
  (itemId: number, unitId: string) => (dispatch: Dispatch) => {
    return itemApi
      .guidedSelling(itemId, unitId)
      .then((response) => {
        dispatch({
          type: SUCCESS(GUIDED_SELLING),
          payload: { productId: response.data, unitId },
        });
      })
      .catch(
        apiErrorHandler({
          dispatch,
          actionType: GUIDED_SELLING,
        })
      );
  };
