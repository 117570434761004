import { message } from "antd";
import { Dispatch } from "redux";
import { durations } from "../../../config/defaults";
import { NewShare, ProjectApi, ShareApi } from "../../../generated/axios";
import { apiConfig } from "../../api";
import { isProjectDetailsPage } from "../../components/RouteChangeHandler/lib";
import { eIntl } from "../../eIntl";
import apiErrorHandler from "../../lib/apiErrorHandler";
import { FAILURE, SUCCESS } from "../../lib/asyncActionHelper";
import { messages } from "../../lib/locales/definedMessages";
import { GET_PROJECT_DETAILS, NEW_PROJECT } from "../project/consts";
import { GET_PROJECTS } from "../projects/consts";
import { IStore } from "../types";
import { openSharingModal } from "../ui/actions";
import { CLOSE_MODAL_FORM } from "../ui/consts";
import {
  CLEAR_SHARE_MODAL_ERROR,
  GET_ITEM_SHARES,
  GET_PROJECT_SHARES,
  GET_PROJECTS_SHARED_WITH_ME,
  IMPORT_SHARED_ITEM,
  IMPORT_SHARED_PROJECT,
  ON_SHARED_ITEM,
  ON_SHARED_PROJECT,
  POST_ITEM_SHARES,
  POST_PROJECT_SHARES,
  REMOVE_SHARES,
} from "./consts";
import { SHARE_TYPE } from "./types";

export const shareApi = new ShareApi(apiConfig());
export const projectApi = new ProjectApi(apiConfig());

export const getItemShares =
  (itemId: number) => async (dispatch: Dispatch, getState: () => IStore) => {
    const { id: projectId = 0 } = getState().project;
    try {
      const { data: itemShares } = await shareApi.getItemShares(itemId);
      const { data: projectShares } =
        await shareApi.getProjectShares(projectId);

      dispatch({
        type: SUCCESS(GET_ITEM_SHARES),
        payload: {
          itemShares,
          itemId,
        },
      });

      dispatch({
        type: SUCCESS(GET_PROJECT_SHARES),
        payload: {
          projectShares,
          projectId,
        },
      });

      dispatch(openSharingModal(SHARE_TYPE.ITEM));
    } catch (error) {
      console.error({ error });
    }
  };

/**
 * @summary Gets the list of sharing for the project
 */
export const getProjectShares = (projectId: number) => (dispatch: Dispatch) => {
  shareApi
    .getProjectShares(projectId)
    .then((res) => {
      // response's data received --> save them into the store
      dispatch({
        type: SUCCESS(GET_PROJECT_SHARES),
        payload: {
          projectShares: res.data,
          projectId,
        },
      });

      dispatch(openSharingModal(SHARE_TYPE.PROJECT));
    })
    .catch(apiErrorHandler({ dispatch, actionType: GET_PROJECT_SHARES }));
};

/**
 * @summary Share the project with another user (email)
 * @param {number} projectId
 * @param {NewShare} newShare the email-permission couple to be added
 */
export const postProjectShare =
  (projectId: number, newShare: NewShare) => async (dispatch: Dispatch) => {
    message.loading(
      eIntl.formatMessage(messages["message.adding_share"]),
      durations.success
    );
    // POST to `/projects/{projectId}/shares`
    await shareApi
      .shareProject(projectId, newShare)
      .then((res) => {
        // response's data received --> save them into the store
        dispatch({
          type: SUCCESS(POST_PROJECT_SHARES),
          payload: res.data,
        });
        dispatch({
          type: ON_SHARED_PROJECT,
          payload: projectId,
        });
        message.success(
          eIntl.formatMessage(messages["message.project_shared_successfully"]),
          durations.success
        );
      })
      // save the server-side error into the store, and as result it's shown
      // in the modal because is connected to Redux
      .catch((err) =>
        dispatch({
          type: FAILURE(POST_PROJECT_SHARES),
          payload: err,
        })
      );
  };

/**
 * @summary Share the item with another user (email)
 * @param {number} itemId
 * @param {NewShare} newShare the email-permission couple to be added
 *
 */
export const postItemShare =
  (itemId: number, newShare: NewShare) => async (dispatch: Dispatch) => {
    message.loading(
      eIntl.formatMessage(messages["message.adding_share"]),
      durations.success
    );
    // POST to `/items/{itemId}/shares`
    await shareApi
      .shareItem(itemId, newShare)
      .then((res) => {
        // response's data received --> save them into the store
        dispatch({
          type: SUCCESS(POST_ITEM_SHARES),
          payload: res.data,
        });
        dispatch({
          type: ON_SHARED_ITEM,
          payload: itemId,
        });
        message.success(
          eIntl.formatMessage(messages["message.item_shared_successfully"]),
          durations.success
        );
      })
      // save the server-side error into the store, and as result it's shown
      // in the modal because is connected to Redux
      .catch((err) =>
        dispatch({
          type: FAILURE(POST_ITEM_SHARES),
          payload: err,
        })
      );
  };

/**
 * fetch projects shared with me
 */
export const getSharedProjects =
  (
    page: number = 0,
    size: number = 1000,
    sort: string = "lastModifiedDate,desc"
  ) =>
  (dispatch: Dispatch) => {
    shareApi
      .getProjectSharedWithMe(page, size, sort)
      .then((response) => {
        dispatch({
          type: SUCCESS(GET_PROJECTS_SHARED_WITH_ME),
          payload: response.data,
        });
      })
      .catch(console.error);
  };

export const importSharedProject =
  (projectId: number) => async (dispatch: Dispatch) => {
    try {
      message.loading(
        eIntl.formatMessage(messages["message.importing_shared_project"]),
        durations.success
      );
      if (!projectId) throw new Error("missing project id");
      const { data } = await shareApi.importProject(projectId);
      // captured to remove a shared project from Store.share.project.projectSharedWithMe
      // and to add a project to Store.projects.list
      dispatch({
        type: SUCCESS(IMPORT_SHARED_PROJECT),
        payload: data,
      });
      message.success(
        eIntl.formatMessage(messages["message.import_completed"]),
        durations.success
      );
    } catch (error) {
      const onError = apiErrorHandler({
        dispatch,
        actionType: IMPORT_SHARED_PROJECT,
      });
      onError(error);
    }
  };

export const importSharedItemIntoProject =
  (itemId: number, projectId?: number) => (dispatch: Dispatch) => {
    message.loading(
      eIntl.formatMessage(messages["message.importing_shared_item"]),
      durations.loading
    );
    shareApi
      .importItem(itemId, projectId)
      .then((response) => {
        dispatch({
          type: SUCCESS(IMPORT_SHARED_ITEM),
          payload: response.data,
        });
        message.success(
          eIntl.formatMessage(messages["message.import_item_completed"]),
          durations.success
        );
        dispatch({ type: CLOSE_MODAL_FORM });
      })
      .catch(
        apiErrorHandler({
          dispatch,
          actionType: IMPORT_SHARED_ITEM,
        })
      );
  };

export const importSharedItem =
  (projectId?: number, projectName?: string) =>
  (dispatch: Dispatch, getState: () => IStore) => {
    const item = getState().ui.modalForm.meta.item;
    const itemId = item?.id;
    if (!itemId || (!projectId && !projectName)) {
      message.info(
        eIntl.formatMessage(messages["message.invalid_data"]),
        durations.info
      );
      return;
    }

    if (projectId) {
      importSharedItemIntoProject(itemId, projectId)(dispatch);
    } else if (projectName) {
      message.loading(
        eIntl.formatMessage(messages["message.creating_the_project"]),
        durations.loading
      );
      projectApi
        .createProject({ title: projectName })
        .then((response) => {
          const project = response.data;
          importSharedItemIntoProject(itemId, project.id as number)(dispatch);
        })
        .catch(apiErrorHandler({ dispatch, actionType: NEW_PROJECT }));
    }
  };

/**
 * @summary Delete an existing sharing - it could be an item-share or a project-share
 * @param {number} shareId
 */
export const removeShare = (shareId: number) => async (dispatch: Dispatch) => {
  message.loading(
    eIntl.formatMessage(messages["message.removing_share"]),
    durations.success
  );
  // POST to `/shares/{shareId}`
  await shareApi
    .removeShare(shareId)
    .then(() => {
      dispatch({
        type: SUCCESS(REMOVE_SHARES),
        payload: shareId,
      });
      message.success(
        eIntl.formatMessage(messages["message.share_removed"]),
        durations.success
      );
    })
    .catch((error) => {
      dispatch({
        type: FAILURE(REMOVE_SHARES),
        payload: error.response.data.title,
      });
    });

  // refresh data in page after remove a share
  try {
    const { data: me } = await shareApi.getProjectSharedWithMe(
      0,
      1000,
      "lastModifiedDate,desc"
    );
    const { data: all } = await projectApi.getAllProjects();

    dispatch({
      type: SUCCESS(GET_PROJECTS),
      payload: all,
    });

    dispatch({
      type: SUCCESS(GET_PROJECTS_SHARED_WITH_ME),
      payload: me,
    });

    const { pathname } = window.location;

    if (isProjectDetailsPage(pathname)) {
      const projectId =
        window.location.pathname.split("/")[
          window.location.pathname.split("/").length - 2
        ];
      const { data: items } = await projectApi.getProjectDetails(
        Number.parseInt(projectId)
      );
      dispatch({
        type: SUCCESS(GET_PROJECT_DETAILS),
        payload: items,
      });
    }
  } catch (error) {
    console.error({ error });
    // dispatch failure
  }
};

export const clearError = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_SHARE_MODAL_ERROR,
  });
};
