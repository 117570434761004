import { isEmpty } from "lodash";
import { createSelector } from "reselect";
import { OptionLabelValue } from "../../../generated/axios";
import {
  ConfiguratorSection,
  ValidationMessage,
} from "../../../types/configurator";
import { IStore } from "../types";
import { FormFieldWithValue } from "./types";

function hasErrors(validations: Record<string, ValidationMessage[]> = {}) {
  return !isEmpty(
    Object.entries(validations).filter(([, validationMessages]) =>
      validationMessages.find((message) => message.status === "error")
    )
  );
}

export const configuratorValidationsSelector = (state: IStore) => ({
  ...state.configurator[ConfiguratorSection.MECHANICAL].validations,
  ...state.configurator[ConfiguratorSection.THERMAL].validations,
});

export const isValidConfigurationSelector = createSelector(
  configuratorValidationsSelector,
  (validations) => !!validations && !hasErrors(validations)
);

const addLabelsToFieldsCombiner = (
  fields: Record<string, FormFieldWithValue>,
  labels: Record<string, string>,
  optionLabels: Record<string, OptionLabelValue>
) => {
  return Object.entries(fields).reduce((acc, [fieldId, field]) => {
    let f = field;
    if (labels[fieldId]) {
      f = { ...f, label: labels[fieldId] };
    }
    if (f.options && optionLabels[fieldId]) {
      f.options = f.options.map((option) => {
        if (optionLabels[fieldId][option.value]) {
          return { ...option, label: optionLabels[fieldId][option.value] };
        }
        return option;
      });
    }
    acc[fieldId] = f;
    return acc;
  }, {});
};

export const thermalLabels = (state: IStore): Record<string, string> =>
  state.configurator.thermal.labels.reduce((acc, obj) => {
    if (obj.fieldId !== undefined && obj.label !== undefined) {
      acc[obj.fieldId] = obj.label;
    }
    return acc;
  }, {});

export const thermalOptionLabelsSelector = (state: IStore) =>
  state.configurator.thermal.optionLabels;

export const thermalFieldsConfigSelector = (
  state: IStore
): Record<string, FormFieldWithValue> =>
  state.configurator.thermal.config.reduce((acc, formSection) => {
    formSection.fields.forEach((field) => (acc[field.fieldId] = field));
    return acc;
  }, {});

export const thermalFieldsSelector = createSelector(
  thermalFieldsConfigSelector,
  thermalLabels,
  thermalOptionLabelsSelector,
  addLabelsToFieldsCombiner
);

export const mechanicalLabels = (state: IStore): Record<string, string> =>
  state.configurator.mechanical.labels.reduce((acc, obj) => {
    if (obj.fieldId !== undefined && obj.label !== undefined) {
      acc[obj.fieldId] = obj.label;
    }
    return acc;
  }, {});

export const mechanicalOptionLabelsSelector = (state: IStore) =>
  state.configurator.mechanical.optionLabels;

export const mechanicalFieldsConfigSelector = (
  state: IStore
): Record<string, FormFieldWithValue> =>
  state.configurator.mechanical.config.reduce((acc, formSection) => {
    formSection.fields.forEach((field) => (acc[field.fieldId] = field));
    return acc;
  }, {});

export const mechanicalFieldsSelector = createSelector(
  mechanicalFieldsConfigSelector,
  mechanicalLabels,
  mechanicalOptionLabelsSelector,
  addLabelsToFieldsCombiner
);

export const optionLabelsSelector = createSelector(
  mechanicalOptionLabelsSelector,
  thermalOptionLabelsSelector,
  (mOptionLabels, tOptionLabels) => ({ ...mOptionLabels, ...tOptionLabels })
);

export const sidePanelFieldsSelector = createSelector(
  (state: IStore) => state.ui.sidePanelView,
  thermalFieldsSelector,
  mechanicalFieldsSelector,
  (view, thermalFields, mechanicalFields) => {
    if (view === ConfiguratorSection.THERMAL) return thermalFields;
    else if (view === ConfiguratorSection.MECHANICAL) return mechanicalFields;
    else return {};
  }
);
