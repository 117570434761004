import IAction from "../../../types/action";
import { FAILURE, REQUEST, SUCCESS } from "../../lib/asyncActionHelper";
import { LOAD_CURRENT_PRICE_LIST } from "./consts";
import { PriceListState } from "./types";

export const initialState: PriceListState = {
  current: undefined,
  isError: false,
  isLoading: false,
};

export default function reducer(
  state: PriceListState = initialState,
  action: IAction
): PriceListState {
  switch (action.type) {
    case REQUEST(LOAD_CURRENT_PRICE_LIST):
      return { ...state, ...initialState, isLoading: true };
    case SUCCESS(LOAD_CURRENT_PRICE_LIST):
      return { ...state, current: action.payload, isLoading: false };
    case FAILURE(LOAD_CURRENT_PRICE_LIST):
      return { ...state, isError: true, isLoading: false };
    default:
      return state;
  }
}
