import React from "react";
import { Redirect } from "react-router-dom";
import { isAuthenticated } from "../../shared/auth";

interface Props {
  to?: string;
  children: React.ReactNode;
}

export default (props: Props) => {
  const to = props.to || "/";

  if (isAuthenticated()) {
    return <Redirect to={to} />;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};
