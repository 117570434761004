import React from "react";
import { connect } from "react-redux";
import { sidePanelFieldsSelector } from "../../../../shared/store/configurator/selectors";
import { FormFieldWithValue } from "../../../../shared/store/configurator/types";
import { IStore } from "../../../../shared/store/types";
import SidePanelField from "./SidePanelField";

interface IReduxProps {
  fields: Record<string, FormFieldWithValue>;
}

interface IProps extends IReduxProps {}

const SidePanelContent: React.FC<IProps> = ({ fields }) => {
  const fieldsArray = Object.entries(fields)
    .map(([, field]) => field)
    .filter((field) => field.___isVisible);
  return (
    <>
      {fieldsArray.map((field) => (
        <SidePanelField key={field.fieldId} field={field} />
      ))}
    </>
  );
};

const mapStateToProps = (state: IStore): IReduxProps => ({
  fields: sidePanelFieldsSelector(state),
});

export default connect<IReduxProps>(mapStateToProps)(SidePanelContent);
