import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";

interface IProps {
  lastModifiedDate?: Date | string;
}

export const TableResultsDate: React.FC<IProps> = ({ lastModifiedDate }) => {
  return lastModifiedDate ? (
    <div className="calculationResultsOf">
      <FormattedMessage
        id="calculationResultsOf"
        defaultMessage="Calculation results of {date}"
        values={{
          date: moment(lastModifiedDate).format("LLL"),
        }}
      />
    </div>
  ) : null;
};
