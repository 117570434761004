import { Divider, Icon, Input, Select } from "antd";
import React, { SyntheticEvent } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Project } from "../../../generated/axios";
import { messages } from "../../../shared/lib/locales/definedMessages";
import { includeSelectFilter } from "../../../shared/lib/selectFilterOptions";
import { getProjects } from "../../../shared/store/projects/actions";
import { IStore } from "../../../shared/store/types";
import { Callback } from "../../../types/Callback";
import { getFooter } from "./BaseModal";
import FormHeader from "./FormHeader";
import "./style.scss";

const { Option } = Select;

interface ReduxProps {
  projects: Project[];
  getProjects: Callback;
}

interface OwnProps {
  closeModal: Callback;
  confirmHandler: (projectId?: number, projectName?: string) => void;
  subTitle: string | React.ReactElement;
}

interface Props extends OwnProps, ReduxProps, InjectedIntlProps {}

interface State {
  projectId: number;
  projectName: string;
  submitDisabled: boolean;
}

class SaveItem extends React.Component<Props, State> {
  state = {
    projectId: 0,
    projectName: "",
    submitDisabled: true,
  };

  componentDidMount() {
    this.props.getProjects();
  }

  onChange = (value: SyntheticEvent) => {
    if (!value?.nativeEvent?.target) return;
    // keep event data alive
    value.persist();
    const text = value["nativeEvent"].target["value"];
    this.setState((prevState) => ({
      projectName: text,
      submitDisabled: !text && !prevState.projectId,
    }));
  };

  onChangeSelect = (value: number) => {
    this.setState((prevState) => ({
      projectId: value,
      submitDisabled: !value && !prevState.projectName,
    }));
  };

  onSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    this.props.confirmHandler(this.state.projectId, this.state.projectName);
  };

  onReset = (event: SyntheticEvent) => {
    event.preventDefault();
    this.props.closeModal();
  };

  render() {
    const { intl } = this.props;
    return (
      <form
        className="modal-form"
        onSubmit={this.onSubmit}
        onReset={this.onReset}
      >
        <FormHeader subTitle={this.props.subTitle} />
        <div>
          <div>
            <Icon type="search" />
            <Divider type="vertical" />
            <span>
              <FormattedMessage
                id="search existing project"
                defaultMessage="Search an existing project"
              />
            </span>
          </div>
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder={intl.formatMessage(messages.selectProjectTitle)}
            onChange={this.onChangeSelect}
            filterOption={includeSelectFilter}
            value={this.state.projectId}
          >
            <Option key={0} value={0}>
              <FormattedMessage
                id="select a project"
                defaultMessage="Select a project"
              />
            </Option>
            {this.props.projects?.map((project) => (
              <Option key={project.id} value={project.id}>
                {project.title}
              </Option>
            ))}
          </Select>
        </div>

        <Divider>
          <FormattedMessage id="or" defaultMessage="or" />
        </Divider>

        <div>
          <div>
            <Icon type="folder" />
            <Divider type="vertical" />
            <span>
              <FormattedMessage
                id="create new project"
                defaultMessage="Create new project"
              />
            </span>
          </div>
          <Input
            value={this.state.projectName}
            onChange={this.onChange}
            placeholder={intl.formatMessage(
              messages.inputNewProjectPlaceholder
            )}
          />
        </div>
        <Divider />
        {getFooter()}
      </form>
    );
  }
}

const WithIntl = injectIntl<OwnProps>(SaveItem);

export default connect(
  (state: IStore) => ({
    projects: state.projects.list,
  }),
  { getProjects }
)(WithIntl);
