import { Button, Col, Form, Input, Row } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import _ from "lodash";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { shortenType } from "../../shared/lib/apiErrorMessages";
import {
  apiErrorsMessages,
  messages,
} from "../../shared/lib/locales/definedMessages";
import { requestPasswordReset } from "../../shared/store/account/actions";
import { IStore } from "../../shared/store/types";
import { ACCOUNT_DEACTIVATED, PAGE_STATE } from "./consts";
import Layout from "./Layout";
import "./layout.scss";

interface PasswordResetProps extends FormComponentProps, IntlProps {
  requestPasswordReset: (passwordReset: { email: string }) => void;
  passwordResetResponse?: Response;
}

interface IState {
  pageState: PAGE_STATE;
}

interface IntlProps {
  intl: ReactIntl.InjectedIntl;
}

const SideContent = React.memo(() => (
  <>
    <span className="publicPagesLayout__action">
      <FormattedMessage id="forgot psw" defaultMessage="Forgot password?" />
    </span>
    <br />
    <FormattedMessage id="step 1" defaultMessage="STEP 1" />
  </>
));

const DoneSuccessMessage = React.memo(() => (
  <Row>
    <Col span={24} className="publicPagesLayout__success">
      <FormattedMessage
        id="reset ok with mail"
        defaultMessage="Reset password request processed, an e-mail was sent to you"
      />
    </Col>
  </Row>
));

const ServerErrorMessage = React.memo(() => (
  <Row>
    <Col span={24} className="publicPagesLayout__failure">
      <FormattedMessage
        id="reset error"
        defaultMessage="Error with reset password request, please retry later"
      />
    </Col>
  </Row>
));

class PasswordReset extends React.Component<PasswordResetProps, IState> {
  constructor(props: PasswordResetProps) {
    super(props);
    this.state = {
      pageState: PAGE_STATE.ENTERED_OR_USER_NOT_EXISTS,
    };
  }

  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        console.log("error: ", err);
      } else {
        this.props.requestPasswordReset(values);
      }
    });
  };

  render() {
    const { intl } = this.props;
    const { getFieldDecorator } = this.props.form; // this.props.form is been injected by Form.create()

    return (
      <Layout>
        <Layout.LeftSide>
          <SideContent />
        </Layout.LeftSide>
        <Layout.RightSide>
          <Form>
            {this.state.pageState === PAGE_STATE.ENTERED_OR_USER_NOT_EXISTS && (
              <>
                <Row>
                  <Form.Item
                    label={
                      <FormattedMessage id="email" defaultMessage="Email" />
                    }
                    colon={false}
                  >
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage(
                            messages.inputRequiredEmail
                          ),
                        },
                        {
                          type: "email",
                          message: intl.formatMessage(
                            messages.inputInvalidEmail
                          ),
                        },
                      ],
                    })(
                      <Input
                        size="large"
                        className="publicPagesLayout__input publicPagesLayout__input--mail"
                        placeholder={intl.formatMessage(
                          messages.inputEmailPlaceholder
                        )}
                        data-test="publicPagesLayout-input"
                      />
                    )}
                  </Form.Item>
                </Row>
                {/* Attention: button has to remain direct child of "publicPagesLayout__right" due to positioning */}
                <Button
                  type="primary"
                  htmlType="submit"
                  className="publicPagesLayout__button"
                  data-test="publicPagesLayout-button"
                  onClick={this.handleSubmit}
                >
                  <FormattedMessage
                    id="reset password"
                    defaultMessage="Reset password"
                  />
                </Button>
              </>
            )}

            {this.state.pageState == PAGE_STATE.DONE_WITHOUT_ERRORS && (
              <DoneSuccessMessage />
            )}

            {this.state.pageState == PAGE_STATE.ERROR_SERVER && (
              <>
                <ServerErrorMessage />

                <Button
                  type="primary"
                  htmlType="submit"
                  className="publicPagesLayout__button"
                  onClick={() =>
                    this.setState({
                      pageState: PAGE_STATE.ENTERED_OR_USER_NOT_EXISTS,
                    })
                  }
                >
                  <FormattedMessage id="retry" defaultMessage="Retry" />
                </Button>
              </>
            )}
          </Form>
        </Layout.RightSide>
      </Layout>
    );
  }

  componentDidUpdate(prevProps: PasswordResetProps) {
    const { passwordResetResponse: response, form, intl } = this.props;

    if (response !== prevProps.passwordResetResponse) {
      // if ok, change right part of the page into success part and Login button link
      if (_.isEmpty(response)) {
        this.setState({ pageState: PAGE_STATE.DONE_WITHOUT_ERRORS });
        return;
      } else if (response) {
        const type = response.type ? shortenType(response.type) : "";
        let error = "";
        switch (type) {
          case "email-not-found":
            error = intl.formatMessage(messages.userNotFound);
            break;
          case ACCOUNT_DEACTIVATED:
            error = intl.formatMessage(
              apiErrorsMessages["api.error.message.account-deactivated"]
            );
            break;
        }
        if (error) {
          // add & show red message under the email input
          form.setFields({
            email: {
              value: form.getFieldValue("email"), // TODO verify: it shouldn't be necessary
              errors: [new Error(error)],
            },
          });
          // it should be always false
          if (this.state.pageState !== PAGE_STATE.ENTERED_OR_USER_NOT_EXISTS) {
            this.setState(() => ({
              pageState: PAGE_STATE.ENTERED_OR_USER_NOT_EXISTS,
            }));
          }
        } else if (response.status != 201) {
          // for all other server error's types
          this.setState(() => ({ pageState: PAGE_STATE.ERROR_SERVER }));
          return;
        }
      }
    }
  }
}

const WithIntl = injectIntl<IntlProps>(PasswordReset);

const mapStateToProps = (state: IStore) => ({
  passwordResetResponse: state.account.passwordResetResponse,
});

const PasswordResetWithForm = Form.create<PasswordResetProps>()(WithIntl);

export default connect(mapStateToProps, { requestPasswordReset })(
  PasswordResetWithForm
);
