import { Action } from "redux";
import {
  CLEAR_TEMP_PROJECT_ID,
  RESET_BEFORE_RELOAD,
  STORE_TEMP_PROJECT_ID,
} from "./consts";
import { UtilsAction } from "./types";

export const storeProjectId = (id: number): UtilsAction => ({
  type: STORE_TEMP_PROJECT_ID,
  payload: id,
});

export const clearProjectId = (): UtilsAction => ({
  type: CLEAR_TEMP_PROJECT_ID,
});

// questa azione è intercettata nei reducer item e configurator
export const resetBeforeReload = (): Action => ({
  type: RESET_BEFORE_RELOAD,
});
