import React, { useContext, useEffect } from "react";
import { ScrollContext } from "../../../../shared/components/ScrollableList";
import { usePreviousValue } from "../../../../shared/hooks/usePreviousValue";

interface IProps {
  initialVisibleIndex?: number;
  selectedIndex?: number;
}

export const ScrollToSelected: React.FC<IProps> = ({
  initialVisibleIndex,
  selectedIndex,
}) => {
  const { itemCount, scrollTo, visibleChildren } = useContext(ScrollContext);
  const oldSelectedIndex = usePreviousValue(selectedIndex);
  const lastSelectableIndex = Number(itemCount) - Number(visibleChildren);

  useEffect(() => {
    if (
      initialVisibleIndex === undefined &&
      oldSelectedIndex === undefined &&
      selectedIndex !== undefined
    ) {
      const index = Math.min(selectedIndex, lastSelectableIndex);
      if (index > 0) {
        scrollTo?.(selectedIndex);
      }
    }
  }, [
    lastSelectableIndex,
    scrollTo,
    selectedIndex,
    initialVisibleIndex,
    oldSelectedIndex,
  ]);

  return null;
};
