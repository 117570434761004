import { Icon, Tooltip } from "antd";
import { get } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Item, Portfolio } from "../../../../generated/axios";
import { availablePortfoliosSelector } from "../../../../shared/store/portfolio/selectors";
import { getItemShares } from "../../../../shared/store/share/actions";
import { IStore } from "../../../../shared/store/types";
import { openModalWithForm } from "../../../../shared/store/ui/actions";
import { ModalFormMetaData } from "../../../../shared/store/ui/types";
import "../../../../styles/icon.scss";
import { filterByShowIfCondition } from "../../../capability/lib";
import PortfolioSelector from "../../../portfolioSelector";
import config, { SidebarItemProps } from "./config";
import { getProjectId } from "./lib";

import "./sidebar.scss";

interface ConfigItem {
  action: string;
  src: string;
  type: string;
  fontSize: string;
  width: string;
  height: string;
  tooltip: string;
}

interface IPropsTip {
  title: string;
}

const Tip: React.FC<IPropsTip> = ({ title, children }) => (
  <Tooltip placement="right" title={title} overlayClassName="no-arrow">
    {children}
  </Tooltip>
);

interface IState {
  capabilities: string[];
  filteredConfig: SidebarItemProps[];
  imOwner?: boolean;
}

interface StateProps {
  activeItem: Item;
  capabilities?: string[];
  imOwner?: boolean;
  availablePortfolios: Portfolio[];
}

interface DispatchProps {
  openModalWithForm: (formType: string, metaData: ModalFormMetaData) => void;
  getItemShares: (itemId: number) => Promise<void>;
}

interface IProps extends StateProps, DispatchProps {}

// TODO: Refactoring using a functional component/sub-components (with useMemo, useSelector...)
class Sidebar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      capabilities: [],
      filteredConfig: [],
      imOwner: undefined,
    };
  }

  static getDerivedStateFromProps(props: IProps, state: IState) {
    if (
      props.capabilities !== state.capabilities ||
      props.imOwner !== state.imOwner
    ) {
      return {
        capabilities: props.capabilities,
        filteredConfig: filterByShowIfCondition(
          config.filter((x) => x.action !== "share" || props.imOwner),
          props.capabilities
        ),
        imOwner: props.imOwner,
      };
    }
    return null;
  }

  onItemClick = (action: string, metaData: ModalFormMetaData) => {
    const { activeItem, getItemShares, openModalWithForm } = this.props;
    if (action === "share") {
      const itemId = get(activeItem, "id");
      itemId && getItemShares(itemId);
    } else {
      openModalWithForm(action, metaData);
    }
  };

  renderIcon = (item: ConfigItem) => {
    return (
      <Icon
        {...item}
        className="icon-link__icon"
        onClick={() =>
          this.onItemClick(item.action, { item: this.props.activeItem })
        }
      />
    );
  };

  renderPortfolioSelector = (item: ConfigItem) => {
    const { availablePortfolios } = this.props;
    const link: string = getProjectId(this.props.activeItem);

    return availablePortfolios.length > 1 ? (
      <PortfolioSelector
        availablePortfolios={availablePortfolios}
        menuOpenerIcon={
          <Icon {...item} className="icon-link__icon as-menu-opener" />
        }
        linkTo={link}
        usedBy="sidebar"
      />
    ) : (
      <Link to={link}>
        <Icon {...item} className="icon-link__icon" />
      </Link>
    );
  };

  renderItems = () => {
    const { filteredConfig } = this.state;
    return filteredConfig.map((item: ConfigItem) => (
      <li key={item.action} className="sidebar_list_item">
        <Tip title={item.tooltip}>
          {item.action === "new"
            ? this.renderPortfolioSelector(item)
            : this.renderIcon(item)}
        </Tip>
      </li>
    ));
  };

  render() {
    return <ol className="sidebar_list">{this.renderItems()}</ol>;
  }
}

export default connect(
  (state: IStore) => ({
    activeItem: state.item,
    availablePortfolios: availablePortfoliosSelector(state),
    capabilities: state.capabilities.list,
    imOwner: state.item.owner?.isMe,
  }),
  { getItemShares, openModalWithForm }
)(Sidebar);
