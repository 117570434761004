import { Col, Form, Row } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { WrappedFormUtils } from "antd/lib/form/Form";
import classNames from "classnames";
import React, { ReactNode } from "react";
import { FormField } from "../../../generated/axios";
import {
  FormConfigWithValues,
  FormSectionWithValues,
} from "../../../shared/store/configurator/types";
import { SectionView } from "../components/Section";
import { RenderOptions } from "./Field";

export const hasHTLT = (section: FormSectionWithValues): boolean =>
  Boolean(section.fields.find((field) => /\.(ht|lt)$/.test(field.fieldId)));

interface OwnProps {
  config: FormConfigWithValues;
  children: (
    form: WrappedFormUtils
  ) => (item: FormField, renderOptions?: RenderOptions) => ReactNode;
  blocking?: boolean;
}

interface Props extends OwnProps, FormComponentProps {}

class ConfiguratorForm extends React.Component<Props> {
  render() {
    const { config, form, children, blocking } = this.props;

    if (!config?.length) {
      return null;
    }

    const visibleSections = config.filter((section: FormSectionWithValues) => {
      // Controllo se la sezione è visibile (basta almeno un campo!)
      for (const formField of section.fields) {
        if (formField.___isVisible) return true;
      }
      return false;
    });

    const sectionCount = visibleSections.length;

    return (
      <Form className="configurator-form">
        <Row gutter={16} type="flex" className="configurator-form__container">
          {visibleSections.map((section, index) => (
            <Col
              key={section.name}
              className={classNames("card", { large: hasHTLT(section) })}
              xs={24}
              sm={12}
              md={8}
              xl={6}
            >
              <SectionView
                index={index}
                sectionCount={sectionCount}
                sectionData={section}
              >
                {children(form)}
              </SectionView>
            </Col>
          ))}
        </Row>
        {blocking && <div className="blocking" />}
      </Form>
    );
  }
}

export default Form.create<Props>()(ConfiguratorForm);
