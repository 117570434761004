import React from "react";
import { FormattedMessage } from "react-intl";
import { MetricSystem } from "../../../generated/axios";

interface Props {
  metricSystem?: MetricSystem;
}

export const MeasurementSystem = (props: Props) => {
  if (props.metricSystem === undefined) {
    return null;
  }
  return (
    <div id="showedMeasurementSystem">
      <FormattedMessage
        id="measurement system"
        defaultMessage="Measurement System"
      />
      : {props.metricSystem.name || props.metricSystem.key}
    </div>
  );
};
