import { AxiosResponse } from "axios";
import FileSaver from "file-saver";

const extractFilenameFromContentDisposition = (
  res: AxiosResponse
): string | undefined => {
  const contentDisposition = res.headers?.["content-disposition"];
  if (contentDisposition) {
    // return just the "plair-technical-specifications-26840269.pdf" part
    return contentDisposition.substring(
      contentDisposition.lastIndexOf("=") + 2,
      contentDisposition.length - 1
    );
  }
  return undefined;
};

export const resourceDownload = (res: AxiosResponse) => {
  if (res.headers?.["content-type"]) {
    const blob = new Blob([res.data], {
      type: res.headers["content-type"],
    });
    const filename = extractFilenameFromContentDisposition(res);
    filename ? FileSaver.saveAs(blob, filename) : FileSaver.saveAs(blob);
  }
};
