import { FormFieldWithValue } from "../../../shared/store/configurator/types";
import { INPUT_TYPES } from "../../../types/inputs";

// formFields con options come RADIO, SELECT, SELECT_MULTIPLE, CHECKBOX e CHECKBOX_MULTIPLE
// per essere visibili devono avere un numero consistente di options.
export const isCompleteField = (formField: FormFieldWithValue): boolean => {
  const { options, inputType } = formField;
  if (options) {
    switch (inputType) {
      case INPUT_TYPES.SELECT:
      case INPUT_TYPES.SELECT_MULTIPLE:
      case INPUT_TYPES.CHECKBOX:
      case INPUT_TYPES.CHECKBOX_MULTIPLE:
        return options.length > 0;
      case INPUT_TYPES.RADIO:
        return options.length > 1;
    }
  }
  return true;
};
