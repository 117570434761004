import { Modal } from "antd";
import React from "react";
import { Exceptions } from "../../../shared/components/exceptions";
import "../../../styles/helper.scss";
import { Callback } from "../../../types/Callback";

export interface ConfirmModalProps {
  buttonCancelHidden: boolean;
  content?: React.ReactNode;
  hideCancelButton?: boolean;
  modalVisibile: boolean;
  okText?: React.ReactNode;
  onCancel?: Callback;
  onOk?: Callback;
  title?: React.ReactNode;
}

const ConfirmModal: React.FC<ConfirmModalProps> = (
  props: ConfirmModalProps
) => {
  return (
    <Modal
      visible={props.modalVisibile}
      centered={true}
      destroyOnClose={true}
      onOk={props.onOk}
      onCancel={props.onCancel}
      title={props.title}
      cancelButtonProps={{
        className: props.buttonCancelHidden ? "display--none" : "",
      }}
      okText={props.okText}
    >
      {props.content}

      <Exceptions.Base>
        <Exceptions.Account />
      </Exceptions.Base>
    </Modal>
  );
};

export default ConfirmModal;
